












import { Component, Prop, Vue } from 'vue-property-decorator';
import { FieldDecoratorOptions, WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { Unit } from '@/interfaces/unit';

@Component({
  components: {}
})
export default class UnitForm extends Vue {
  @Prop() unit: Unit;

  disabled = true;
  form: WrappedFormUtils;

  get decorators(): { [key: string]: FieldDecoratorOptions } {
    return {
      name: {
        initialValue: this.unit.Name,
        rules: [{ required: true, message: this.$t('unitNameIsRequired').toString() }, { max: 50 }]
      }
    };
  }

  created(): void {
    this.form = this.$form.createForm(this, {
      name: 'edit_unit',
      onFieldsChange: (t, p, fields) => {
        const fieldsArray: { errors?: string[]; touched: boolean }[] = Object.values(fields);
        const errorExist = fieldsArray.some((field) => Array.isArray(field.errors));
        const formTouched = fieldsArray.some((field) => field.touched);
        // dirty property is not updated, relying on touched
        this.disabled = errorExist || !formTouched;
      }
    } as any);
  }

  onSave(e: Event): void {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err) {
        this.$emit('onUpdate', {
          ...this.unit,
          Name: values.name
        } as Unit);
      }
    });
  }

  onClose(): void {
    this.$emit('onClose');
  }
}
