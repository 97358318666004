
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { FieldDecoratorOptions, WrappedFormUtils } from 'ant-design-vue/types/form/form';
import moment from 'moment';
import { CropType } from '@/interfaces/cropType';
import { Farm } from '@/interfaces/farm';
import { EditableParcel } from '@/interfaces/editableParcel';
import API from '@/services/api';
import { Unit } from '@/interfaces/unit';
import { FarmHierarchy } from '@/interfaces/farmHierarchy';
import Constants from '@/services/constants';

@Component({
  components: {}
})
export default class ParcelForm extends Vue {
  @Prop() parcel: EditableParcel;
  @Prop() cropTypes: CropType[];
  @Prop() farms: Farm[];
  @Prop() unit: Unit;

  disabled = true;
  form: WrappedFormUtils;
  farmNameWarning = '';
  farmCodeWarning = '';
  DATE_FORMAT_LOCALIZED = Constants.DATE_FORMAT_LOCALIZED;

  get decorators(): { [key: string]: FieldDecoratorOptions } {
    return {
      cropRotation: {
        initialValue: null
      },
      farmName: {
        initialValue: this.parcelFarm ? this.parcelFarm.Name : '',
        rules: [{ required: true, message: this.$t('farmNameIsRequired').toString() }, { max: 50 }]
      },
      farmCode: {
        initialValue: this.parcelFarm ? this.parcelFarm.Code : '',
        rules: [{ max: 50 }]
      },
      variety: {
        initialValue: this.parcel?.Variety,
        rules: [{ max: 50 }]
      },
      lastHarvestDate: {
        initialValue: this.parcel.LastHarvestDate ? moment(this.parcel.LastHarvestDate) : null,
        rules: []
      }
    };
  }

  private get parcelFarm(): Farm {
    return this.farms.find((f: Farm) => f.id === this.parcel.FarmID);
  }

  onFarmName(name: string) {
    const selectedFarm = this.farms.find((f) => f.Name === name);
    if (selectedFarm) {
      // keep farm code in sync with selected farm
      this.farmNameWarning = '';
      this.farmCodeWarning = '';
      this.form.setFieldsValue({ farmCode: selectedFarm.Code });
    } else {
      this.farmNameWarning = this.$t('noFarmNameExistWarning').toString();
    }
  }

  onFarmCode(e: Event): void {
    if (this.farmNameWarning) {
      // we already showing warning about new farm in name field
      this.farmCodeWarning = '';
      return;
    }
    const farmName = this.form.getFieldValue('farmName');
    const filteredFarms = this.farms.filter((f: Farm) => f.Name === farmName);
    if (filteredFarms.some((f: Farm) => (f.Code ?? '') === (e.target as any).value)) {
      this.farmCodeWarning = '';
    } else {
      this.farmCodeWarning = this.$t('noFarmCodeExistWarning').toString();
    }
  }

  get varieties() {
    const farms = this.$store.state.selectedUnitHierarchy?.Farms ?? [];
    const parcelsVarieties = farms.map((farm: FarmHierarchy) =>
      farm.Parcels.reduce((acc, parcel) => {
        if (parcel.Variety) {
          acc.push(parcel.Variety);
        }
        return acc;
      }, [])
    );
    return [...new Set([].concat(...parcelsVarieties))].filter(Boolean);
  }

  get farmNameSources() {
    const nameKeys = new Set(this.farms.map((farm: Farm) => farm.Name)).values();
    return Array.from(nameKeys).map((name) => ({
      text: name,
      value: name
    }));
  }

  created(): void {
    this.form = this.$form.createForm(this, {
      name: 'edit_parcel',
      onFieldsChange: (t, p, fields) => {
        const fieldsArray: { errors?: string[]; touched: boolean }[] = Object.values(fields);
        const errorExist = fieldsArray.some((field) => Array.isArray(field.errors));
        const formTouched = fieldsArray.some((field) => field.touched);
        // dirty property is not updated, relying on touched
        this.disabled = errorExist || !formTouched;
      }
    } as any);
  }

  onSave(e: Event): void {
    e.preventDefault();
    this.form.validateFields(async (err, values) => {
      if (!err) {
        const selectedFarm = this.farms.find((f: Farm) => f.Name === values.farmName && f.Code == values.farmCode);
        let farmId;
        if (!selectedFarm) {
          await this.$store.dispatch('setIsGlobalLoaderVisible', true);
          const { ID } = await API.createFarm(this.unit.id, values.farmName, values.farmCode);
          await this.$store.dispatch('setIsGlobalLoaderVisible', false);
          farmId = ID;
        } else {
          farmId = selectedFarm.id;
        }

        this.$emit('onUpdate', {
          ...this.parcel,
          Variety: values.variety,
          FarmID: farmId,
          LastHarvestDate: values.lastHarvestDate
        } as EditableParcel);
      }
    });
  }

  onClose(): void {
    this.$emit('onClose');
  }
}
