





















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TipsInfo extends Vue {
  visible = false;

  getPopupContainer() {
    return document.getElementById('tips-container');
  }
}
