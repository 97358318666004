

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PieChart from '@/components/charts/PieChart.vue';
import { AnalyticData } from '@/interfaces/analyticData';
import { getUnitMeasure, isValueInBucket, parcelMatchFilters } from '@/services/analyticData';
import { m2ToHa } from '@/services/units';
import { AnalyticType } from '@/enums/analyticType';
import { Parcel } from '@/interfaces/parcel';
import { stringToMomentDate } from '@/services/date';
import { ProductType } from '@/enums/productType';

interface HarvestedInfo {
  harvested: {
    [key: string]: Parcel;
  };
  partialHarvested: {
    [key: string]: Parcel;
  };
}

@Component({
  components: {
    PieChart
  }
})
export default class AnalyticsPieChart extends Vue {
  @Prop() analytics: AnalyticData[];
  @Prop() label: string;
  @Prop() bucket: any;

  TYPE = {
    AREA: 'area',
    NUMBER: 'number'
  };

  selectedType: string = this.TYPE.NUMBER;

  chartData = null;
  chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'right',
      labels: {
        padding: 12,
        usePointStyle: true,
        fontSize: 14,
        fontColor: '#161C26',
        fontFamily: 'Inter'
      }
    },
    tooltips: {
      enabled: true
    }
  };

  mounted(): void {
    this.setupChartData();
  }

  get measure(): string {
    return getUnitMeasure(this.$store.state.analytic.selectedAnalyticType);
  }

  @Watch('analytics')
  onAnalyticsChanged(): void {
    this.setupChartData();
  }

  setupChartData(): void {
    if (this.analytics.length) {
      const data = this.getChartData();
      const harvestedInfo = this.getHarvestedInfo();
      const t1 = this.bucketLabels;
      this.chartData = {
        datasets: [
          {
            datalabels: {
              color: '#161c26'
            },
            data: this.formatValues(Object.values(data)),
            backgroundColor: Object.keys(data)
          }
        ],
        labels: t1.concat(`Harvested`)
      };
    } else {
      this.chartData = {
        datasets: [],
        labels: []
      };
    }
  }

  private formatValues(values): any {
    if (this.selectedType === this.TYPE.AREA) {
      return values.map((value) => (value !== null ? m2ToHa(value) : null));
    }
    return values;
  }

  private getChartData(): any {
    const data = {};
    Object.keys(this.bucket).forEach((color: string) => {
      let total = 0;
      this.analytics.forEach((analytic: AnalyticData) => {
        if (isValueInBucket(analytic.Value, this.bucket[color])) {
          if (this.selectedType === this.TYPE.AREA) {
            const parcel = this.$store.state.parcels[analytic.ParcelID];
            if (parcel) {
              total += parcel.Area;
            }
          } else {
            total += 1;
          }
        }
      });
      data[color] = total || null;
    });
    if (this.selectedType !== this.TYPE.AREA) {
      const harvestedInfo = this.getHarvestedInfo();
      let newColor = '#D6D58E';
      data[newColor] = Object.keys(harvestedInfo.harvested).length;
    } else {
      const harvestedInfo = this.getHarvestedInfo();
      let newColor = '#D6D58E';
      let totalHarvestedParcel = 0;
      let harvestedParcelArea = Object.values(harvestedInfo.harvested);
      harvestedParcelArea.forEach((harvest) => {
        for (let key in harvest) {
          if (`${key}` === 'Area') {
            totalHarvestedParcel += harvest[key];
          }
        }
      });
      data[newColor] = totalHarvestedParcel;
    }
    return data;
  }

  get bucketLabels(): string[] {
    let allLabels;
    if (this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_PREDICTION) {
      allLabels = Object.values(this.bucket).map((range) => `${range[0]} - ${range[1]} ${this.measure}`);
      allLabels.pop();
      allLabels.push('14.5 % and above');
    } else if (this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_YIELD) {
      allLabels = Object.values(this.bucket).map((range) => `${range[0]} - ${range[1]} ${this.measure}`);
      allLabels.pop();
      allLabels.push('140 ton/ha and above');
    } else {
      allLabels = Object.values(this.bucket).map((range) => `${range[0]} - ${range[1]} ${this.measure}`);
    }
    return allLabels;
  }

  private getHarvestedInfo(): HarvestedInfo {
    if (
      this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_PREDICTION ||
      this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_YIELD
    ) {
      return this.getSugarContentHarvestedInfo();
    }
    return null;
  }

  private getSugarContentHarvestedInfo(): HarvestedInfo {
    const harvested = {};
    const momentTo = stringToMomentDate(this.$store.state.analytic.fromTo.to);
    this.$store.getters.filteredParcelIds.forEach((parcelId: string) => {
      const parcel = this.$store.state.parcels[parcelId] as Parcel;
      if (
        parcel &&
        parcel.HarvestDates &&
        parcel.HarvestDates.length &&
        parcelMatchFilters(parcel, this.$store.state.mapParcelFilters, this.$store.state.analytic.fromTo) &&
        momentTo.isSameOrAfter(stringToMomentDate(parcel.HarvestDates[0]))
      ) {
        harvested[parcel.id] = parcel;
      }
    });
    return {
      harvested,
      partialHarvested: null
    };
  }
}
