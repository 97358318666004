import moment, { Moment } from 'moment';
import Constants from '@/services/constants';

export function stringToDate(input: string): Date {
  return input.includes('T') ? new Date(input) : new Date(`${input}T00:00:00`);
}

export function stringToMomentDate(input: string): Moment {
  return moment(stringToDate(input));
}

export function getLocalizedDate(value: string): string {
  return stringToMomentDate(value).format(Constants.DATE_FORMAT_LOCALIZED);
}
