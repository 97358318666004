import { EditableParcel } from '@/interfaces/editableParcel';
import API from '@/services/api';
const worker = new Worker('./workers/shapes-management.worker', { type: 'module' });

export const getOverlapData = (parcels: EditableParcel[], changedParcels: EditableParcel[]): Promise<any> => {
  return new Promise((resolve) => {
    const onMessage = (result: MessageEvent) => {
      resolve(result.data);
      worker.removeEventListener('message', onMessage);
    };
    worker.addEventListener('message', onMessage);
    worker.postMessage({
      parcels,
      changedParcels,
      PRODUCT_MANAGER_URL: API.PRODUCT_MANAGER_URL
    });
  });
};
