



















import { Component, Vue } from 'vue-property-decorator';
import { ProductType } from '@/enums/productType';
import { Survey } from '@/interfaces/survey';
import Constants from '@/services/constants';
import { Parcel } from '@/interfaces/parcel';
import { getLocalizedDate } from '@/services/date';

@Component({
  components: {}
})
export default class ParcelsInfo extends Vue {
  productType = ProductType;

  getFromToRange(): string {
    let minDate = null;
    let maxDate = null;
    this.$store.state.analytic.selectedSurveys.forEach((survey: Survey) => {
      if (!minDate || survey.MomentDate.isBefore(minDate)) {
        minDate = survey.MomentDate;
      }
      if (!maxDate || survey.MomentDate.isAfter(maxDate)) {
        maxDate = survey.MomentDate;
      }
    });
    if (minDate && maxDate) {
      const from = minDate.format(Constants.DATE_FORMAT);
      const to = maxDate.format(Constants.DATE_FORMAT);

      if (from === to) {
        return this.$root
          .$t('onDate', {
            date: getLocalizedDate(from)
          })
          .toString();
      }
      return this.$root
        .$t('fromToDates', {
          from: getLocalizedDate(from),
          to: getLocalizedDate(to)
        })
        .toString();
    }
    return '';
  }

  unSelectParcels(): void {
    this.$store.dispatch('analytic/clearSelectedParcels');
  }

  closeParcel(e, parcel: Parcel): void {
    e.preventDefault();
    this.$store.dispatch('analytic/unselectParcel', { parcel });
  }
}
