















import { Component, Prop, Vue } from 'vue-property-decorator';
import { featureCollection } from '@turf/helpers';
import { EditableParcel } from '@/interfaces/editableParcel';

@Component({
  components: {}
})
export default class UploadStatus extends Vue {
  @Prop() parcels: EditableParcel[];

  get loadedParcels(): EditableParcel[] {
    return this.parcels.filter((parcel: EditableParcel) => parcel.isEdited || parcel.isNew || parcel.isDeleted);
  }

  get skippedParcels(): EditableParcel[] {
    return this.parcels.filter((parcel: EditableParcel) => !parcel.isEdited && !parcel.isNew && !parcel.isDeleted);
  }

  async download(name: string, path: string): Promise<void> {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.download = name;
    link.href = path;
    link.click();
    link.remove();
  }

  downloadShapes(): void {
    const features = this.parcels
      .filter((parcel: EditableParcel) => !parcel.isDeleted)
      .map((parcel: EditableParcel) => parcel.Shape.GeoJson.features)
      .flat();
    const blob = new Blob([JSON.stringify(featureCollection(features))], { type: 'application/json' });
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.download = 'shapes.geojson';
    link.href = URL.createObjectURL(blob);
    link.click();
    link.remove();
  }

  onClose(): void {
    this.$emit('onClose');
  }
}
