






































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { LayerGroupName } from '@/enums/layerGroupName';
import { LayerName } from '@/enums/layerName';
import { ProductType } from '@/enums/productType';
import SugarContentPredictionInfo from '@/components/info/SugarContentPrediction.vue';
import SugarYieldInfo from '@/components/info/SugarYield.vue';
import { Farm } from '@/interfaces/farm';
import { Parcel } from '@/interfaces/parcel';
import GeneralInfoDefault from '@/components/info/GeneralInfoDefault.vue';
import { Role } from '@/enums/role';
import { getLocalizedDate, stringToMomentDate } from '@/services/date';
import { AnalyticData } from '@/interfaces/analyticData';
import Constants from '@/services/constants';
import WarningParcelInfo from '@/components/info/WarningParcelInfo.vue';
import { Survey } from '@/interfaces/survey';

@Component({
  filters: {
    date(value: string) {
      return getLocalizedDate(value);
    }
  },
  components: {
    SugarContentPredictionInfo,
    SugarYieldInfo,
    GeneralInfoDefault,
    WarningParcelInfo
  }
})
export default class ParcelInfo extends Vue {
  @Prop() analyticData;
  @Prop() groundTruth;

  productType = ProductType;
  getLocalizedDate = getLocalizedDate;

  Role = Role;
  layerName = LayerName;
  printFarmReport = false;

  get selectedParcelAnalytic(): AnalyticData {
    return (
      this.$store.state.analytic.selectedParcel &&
      this.$store.state.analytic.filteredAnalyticData.find(
        (analytic: AnalyticData) => analytic.ParcelID === this.$store.state.analytic.selectedParcel.id
      )
    );
  }

  get isCloudy(): boolean {
    return this.selectedParcelAnalytic?.Value === Constants.CLOUDY_ANALYTIC_VALUE;
  }

  get isTooYoung(): boolean {
    return this.selectedParcelAnalytic?.Value === Constants.TOO_YOUNG_ANALYTIC_VALUE;
  }

  get isNoClustering(): boolean {
    return this.selectedParcelAnalytic?.Value === Constants.NO_CLUSTERING_ANALYTIC_VALUE;
  }

  get hasAnalytic(): boolean {
    return (
      !!this.$store.state.analytic.selectedSurvey &&
      this.$store.state.analytic.filteredAnalyticData.find(
        (analytic: AnalyticData) => analytic.SurveyID === this.$store.state.analytic.selectedSurvey.id
      ) !== undefined
    );
  }

  get farmForSelectedParcel(): Farm {
    if (this.$store.state.analytic.selectedParcel && this.$store.state.selectedUnitHierarchy) {
      return this.$store.state.selectedUnitHierarchy.Farms.find((farm) => {
        return !!farm.Parcels.find((parcel) => parcel.id === this.$store.state.analytic.selectedParcel.id);
      });
    }
    return null;
  }

  get selectedParcel(): Parcel {
    return this.$store.state.analytic.selectedParcel;
  }

  get selectedSurvey(): Survey {
    return this.$store.state.analytic.selectedSurvey;
  }

  get selectedProductType(): ProductType {
    return this.$store.state.analytic.selectedProductType;
  }

  print() {
    this.$store.dispatch('setPrint', { print: true, printAllParcels: this.printFarmReport });
  }

  get hasDownloadButtons(): boolean {
    return (
      this.hasAnalytic &&
      this.$store.state.analytic.selectedProductType !== ProductType.SUGAR_CONTENT_PREDICTION &&
      this.$store.state.analytic.selectedProductType !== ProductType.SUGAR_CONTENT_YIELD
    );
  }

  onOpenParcelEditor(): void {
    this.$store.dispatch('setShapeEditingModePreSelectedParcel', this.$store.state.analytic.selectedParcel);
    this.$store.dispatch('setIsShapeEditingMode', true);
  }

  unSelectParcel(): void {
    this.$store.dispatch('analytic/clearSelectedParcels');
  }

  isHarvested(): boolean {
    return (
      this.$store.state.analytic.selectedParcel &&
      this.$store.state.analytic.selectedParcel.HarvestDates &&
      this.$store.state.analytic.selectedParcel.HarvestDates.length &&
      stringToMomentDate(this.$store.state.analytic.fromTo.to).isSameOrAfter(
        stringToMomentDate(this.$store.state.analytic.selectedParcel.HarvestDates[0])
      )
    );
  }

  isAnalyticLayerSelected(name: LayerName): boolean {
    return (
      this.$store.state.analytic.selectedLayers.find(
        (layer) => layer.groupName === LayerGroupName.ANALYTIC && layer.name === name
      ) !== undefined
    );
  }

  isAnalyticLayerSelectedAndHasAnalytic(name: LayerName): boolean {
    return this.isAnalyticLayerSelected(name) && this.hasAnalytic;
  }

  async download(name: string, path: string): Promise<void> {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.download = name;
    link.href = path;
    link.click();
    link.remove();
  }
}
