






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Parcel } from '@/interfaces/parcel';
import { defaultSelectedParcelsColor, selectedParcelsColor } from '@/services/analyticData';

@Component
export default class ParcelsSelection extends Vue {
  @Prop() parcels: Parcel[];

  getParcelColor(index: number, total: number): string {
    if (total > selectedParcelsColor.length) {
      return defaultSelectedParcelsColor;
    }

    return selectedParcelsColor[index];
  }

  defaultColor = defaultSelectedParcelsColor;
}
