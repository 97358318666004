var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.analytic.selectedParcel && _vm.$store.state.selectedOrganization && _vm.$store.state.selectedUnit)?_c('div',{staticClass:"container-info"},[_c('div',{staticClass:"container-header"},[_c('div',{staticClass:"parcel-line"},[_c('span',[_vm._v(_vm._s(_vm.$t('parcelName', { name: _vm.$store.state.analytic.selectedParcel.Name }))+" "),_c('a-icon',{staticClass:"icon-button tool",attrs:{"type":"tool","theme":"filled"},on:{"click":_vm.onOpenParcelEditor}})],1),_c('a-icon',{staticClass:"icon-button",attrs:{"data-cy":"unSelectParcelFromLegend","type":"close"},on:{"click":_vm.unSelectParcel}})],1),(this.$store.state.analytic.selectedSurvey)?_c('div',{staticClass:"survey-line"},[_vm._v(" "+_vm._s(_vm.$t('onDate', { date: _vm.getLocalizedDate(_vm.$store.state.analytic.selectedSurvey.Date) }))+" ")]):_vm._e()]),(_vm.isCloudy)?_c('WarningParcelInfo',{attrs:{"title":_vm.$t('cloudyDay'),"description":_vm.$t('cloudyDayDescription')}}):_vm._e(),(_vm.isTooYoung)?_c('WarningParcelInfo',{attrs:{"title":_vm.$t('parcelIsTooYoung'),"description":_vm.$t('parcelIsTooYoungDescription')}}):_vm._e(),(_vm.isNoClustering)?_c('WarningParcelInfo',{attrs:{"title":_vm.$t('noResultsAvailable'),"description":_vm.$t('noResultsAvailableDescription')}}):_vm._e(),_c('a-collapse',{attrs:{"defaultActiveKey":"info"}},[_c('a-collapse-panel',{key:"info",attrs:{"header":_vm.$t('information')}},[_c('GeneralInfoDefault',{attrs:{"hasAnalytic":_vm.hasAnalytic,"selectedFarm":_vm.farmForSelectedParcel}})],1)],1),(
      _vm.$store.state.analytic.selectedProductType === _vm.productType.SUGAR_CONTENT_PREDICTION &&
      _vm.isAnalyticLayerSelectedAndHasAnalytic(_vm.layerName.SUGAR_CONTENT_PREDICTION)
    )?_c('a-collapse',{attrs:{"defaultActiveKey":"sugar_content_prediction"}},[_c('a-collapse-panel',{key:"sugar_content_prediction",attrs:{"header":_vm.$t('estimatedSugarContent')}},[_c('SugarContentPredictionInfo',{attrs:{"analytic-data":_vm.analyticData}})],1)],1):_vm._e(),(
      _vm.$store.state.analytic.selectedProductType === _vm.productType.SUGAR_CONTENT_YIELD &&
      _vm.isAnalyticLayerSelectedAndHasAnalytic(_vm.layerName.SUGAR_CONTENT_YIELD) &&
      !_vm.isHarvested()
    )?_c('a-collapse',{attrs:{"defaultActiveKey":"sugar_content_yield"}},[_c('a-collapse-panel',{key:"sugar_content_yield",attrs:{"header":_vm.$t('sugarYield')}},[_c('SugarYieldInfo',{attrs:{"analytic-data":_vm.analyticData}})],1)],1):_vm._e(),(_vm.hasDownloadButtons)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"download-buttons",attrs:{"data-html2canvas-ignore":"true"}},[_c('a-button',{attrs:{"icon":"download","type":"primary"},on:{"click":_vm.print}},[_vm._v(_vm._s(_vm.$t('print')))]),_c('a-checkbox',{model:{value:(_vm.printFarmReport),callback:function ($$v) {_vm.printFarmReport=$$v},expression:"printFarmReport"}},[_vm._v(_vm._s(_vm.$t('allFarm')))])],1)]):_vm._e(),(
      _vm.$store.state.userInfo &&
      _vm.$store.state.userInfo.Roles &&
      _vm.$store.state.userInfo.Roles.find(function (role) { return role === _vm.Role.ADMIN; })
    )?_c('a-collapse',{staticClass:"admin",attrs:{"data-html2canvas-ignore":"true"}},[_c('a-collapse-panel',{attrs:{"header":_vm.$t('adminInformation')}},[_c('div',{staticClass:"parcel-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('organizationId')))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.$store.state.selectedOrganization.id))])]),_c('div',{staticClass:"parcel-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('unitId')))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.$store.state.selectedUnit.id))])]),(_vm.farmForSelectedParcel)?_c('div',{staticClass:"parcel-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('farmId')))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.farmForSelectedParcel.id))])]):_vm._e(),_c('div',{staticClass:"parcel-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('parcelId')))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.$store.state.analytic.selectedParcel.id))])]),(_vm.$store.state.analytic.selectedSurvey)?_c('div',{staticClass:"parcel-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('surveyId')))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.$store.state.analytic.selectedSurvey.id))])]):_vm._e()])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }