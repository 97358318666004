








import Dashboard from '@/components/Dashboard.vue';
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    Dashboard,
    Header,
    Footer
  }
})
export default class DashboardPage extends Vue {}
