


















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditableParcel } from '@/interfaces/editableParcel';
import { FieldDecoratorOptions, WrappedFormUtils } from 'ant-design-vue/types/form/form';
import moment from 'moment';
import { CropType } from '@/interfaces/cropType';
import Constants from '@/services/constants';

@Component({
  components: {}
})
export default class ParcelItem extends Vue {
  @Prop() parcel: EditableParcel;
  @Prop() isShapeInValid: boolean;
  @Prop() isValid: boolean;
  @Prop() isBlocked: boolean;
  @Prop() isSelected: boolean;
  @Prop() cropTypes: CropType[];
  form: WrappedFormUtils;
  DATE_FORMAT_LOCALIZED = Constants.DATE_FORMAT_LOCALIZED;

  created(): void {
    this.form = this.$form.createForm(this, {
      name: 'edit_parcel_item',
      onFieldsChange: (t, p, fields) => {
        this.$emit('onUpdate', {
          ...this.parcel,
          Name: fields.parcelName.value,
          CropType: fields.cropType.value,
          Created: fields.creationDate.value ? fields.creationDate.value.toISOString() : null,
          Planted: fields.plantingDate.value ? fields.plantingDate.value.toISOString() : null,
          isCreatedModified: p.creationDate ? true : this.parcel.isCreatedModified
        } as EditableParcel);
      }
    } as any);
  }

  get decorators(): { [key: string]: FieldDecoratorOptions } {
    return {
      parcelName: {
        initialValue: this.parcel.Name,
        rules: [
          { required: true, message: this.$t('parcelNameIsRequired').toString() },
          { max: 50, message: this.$t('parcelNameToLongError').toString() }
        ]
      },
      cropType: {
        initialValue: this.parcel.CropType,
        rules: [{ required: true, message: this.$t('cropTypeIsRequired').toString() }]
      },
      plantingDate: {
        initialValue: this.parcel.Planted ? moment(this.parcel.Planted) : null,
        rules: [{ required: true, message: this.$t('plantingDateIsRequired').toString() }]
      },
      creationDate: {
        initialValue: this.parcel.Created ? moment(this.parcel.Created) : null,
        rules: [{ required: true, message: this.$t('creationDateIsRequired').toString() }]
      }
    };
  }

  onSelect(): void {
    this.$emit('onSelect', this.parcel.id);
  }

  onDelete(): void {
    this.$emit('onDelete', this.parcel);
  }

  onEdit(): void {
    this.$emit('onEdit', this.parcel);
  }

  get isActive(): boolean {
    return this.parcel.isActive;
  }

  setActive(flag: boolean) {
    this.$set(this.parcel, 'isActive', flag);
  }
}
