import mapboxgl from 'mapbox-gl';
import cursorCross from '../assets/icon/cursor.svg';

export const appendCanvas = (container: HTMLElement, className: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = container.offsetWidth;
  canvas.height = container.offsetHeight;
  canvas.classList.add(className);

  container.append(canvas);

  return {
    hide: () => (canvas.style.display = 'none'),
    show: () => {
      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.style.display = 'block';
    },
    canvas
  };
};

export const loadIcon = (source: string) => {
  return new Promise<HTMLImageElement>((res, rej) => {
    const icon = new Image();
    icon.src = source;

    icon.onload = () => res(icon);
    icon.onerror = () => rej(`Failed to load ${source}`);
  });
};

interface DrawCursorOptions {
  x: number;
  y: number;
  canvas: HTMLCanvasElement;
  icon: HTMLImageElement;
}
export const drawFakeCursor = ({ x, y, canvas, icon }: DrawCursorOptions) => {
  const ctx = canvas.getContext('2d')!;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(icon, x - 12, y - 12, 26, 26);
  ctx.imageSmoothingEnabled = false;
};

// Appends drawing canvas to mapMirror container, draws cursor with same coords as in mapMain
export const initDrawingFakeCursor = async (mapMain: mapboxgl.Map, mapMirror: mapboxgl.Map) => {
  const icon = await loadIcon(cursorCross);
  const containerMirror = mapMirror.getContainer();
  const { canvas, hide, show } = appendCanvas(containerMirror, 'fake-cursor-canvas');

  const onMove = (event: mapboxgl.MapMouseEvent) => {
    window.requestAnimationFrame(() =>
      drawFakeCursor({
        canvas,
        icon,
        x: containerMirror.offsetLeft + event.point.x,
        y: containerMirror.offsetTop + event.point.y
      })
    );
  };
  const onDrag = (event: mapboxgl.MapMouseEvent) => {
    window.requestAnimationFrame(() =>
      drawFakeCursor({
        canvas,
        icon,
        x: containerMirror.offsetLeft + (event.originalEvent as any).layerX,
        y: containerMirror.offsetTop + (event.originalEvent as any).layerY
      })
    );
  };

  mapMain.on('mouseover', () => {
    show();
    mapMain.on('mousemove', onMove);
    mapMain.on('drag', onDrag);
  });
  mapMain.on('mouseout', () => {
    hide();
    mapMain.off('mousemove', onMove);
    mapMain.off('drag', onDrag);
  });
};
