



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LineChart from '@/components/charts/LineChart.vue';
import { getParcelCropStage, getParcelCycle, getParcelStatus } from '@/services/analyticData';
import { DashboardAnalyticData } from '@/interfaces/dashboardAnalyticData';
import { CHART_TYPE, getColor } from '@/services/charts';

@Component({
  components: {
    LineChart
  }
})
export default class AnalyticsLineChartPerGrowthStage extends Vue {
  @Prop() analytics: DashboardAnalyticData[];
  @Prop() label: string;

  TYPE = {
    FARM_NAME: 'farmName',
    VARIETY: 'variety',
    STATUS: 'status',
    NUMBER_OF_CYCLES: 'numberOfCycles'
  };

  selectedType: string = CHART_TYPE.VARIETY;
  CHART_TYPE = CHART_TYPE;

  chartData = null;
  chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 12,
        usePointStyle: true,
        fontSize: 14,
        fontColor: '#161C26',
        fontFamily: 'Inter'
      }
    },
    tooltips: {
      enabled: true
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: '#4E596D'
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: '#4E596D'
          },
          offset: true
        }
      ]
    }
  };

  mounted(): void {
    this.setupChartData();
  }

  @Watch('analytics')
  onAnalyticDataChanged(): void {
    this.setupChartData();
  }

  setupChartData(): void {
    this.chartData = this.getChartData();
  }

  private getChartData(): any {
    const data = {};
    this.analytics.forEach((analyticData: DashboardAnalyticData) => {
      const parcel = this.$store.state.parcels[analyticData.ParcelID];
      if (parcel) {
        let fieldName = null;
        if (this.selectedType === CHART_TYPE.VARIETY) {
          fieldName = parcel.Variety;
        }
        if (this.selectedType === CHART_TYPE.STATUS) {
          fieldName = getParcelStatus(
            parcel,
            this.$store.state.analytic.fromTo.to,
            this.$root.$i18n,
            this.$store.state.selectedCountry
          );
        }
        if (this.selectedType === CHART_TYPE.FARM_NAME && this.$store.state.selectedUnitHierarchy) {
          const farm = this.$store.state.selectedUnitHierarchy.Farms.find((farm) => farm.id === parcel.FarmID);
          if (farm) {
            fieldName = farm.Name + (farm.Code ? ` (${farm.Code})` : '');
          }
        }
        if (this.selectedType === CHART_TYPE.NUMBER_OF_CYCLES) {
          fieldName = getParcelCycle(parcel, analyticData.SurveyDate);
        }
        if (fieldName !== null) {
          const stage = getParcelCropStage(parcel, this.$store.state.analytic.fromTo.to);
          data[fieldName] = data[fieldName] || { totalArea: 0, stages: {} };
          data[fieldName].totalArea += parcel.Area;
          data[fieldName].stages[stage] = data[fieldName].stages[stage] || { totalValue1: 0, totalValue2: 0 };
          data[fieldName].stages[stage].totalValue1 += analyticData.value1;
          data[fieldName].stages[stage].totalValue2 += analyticData.value2;
        }
      }
    });

    let labels = Object.keys(data)
      .sort((a: string, b: string) => (data[a].totalArea > data[b].totalArea ? -1 : 1))
      .slice(0, 20);

    if (this.selectedType === CHART_TYPE.NUMBER_OF_CYCLES) {
      labels = Object.keys(data).sort((a: string, b: string) => (parseInt(a) < parseInt(b) ? -1 : 1));
    }

    const stages = [
      ...new Set(labels.map((key) => Object.keys(data[key].stages).map((key) => parseInt(key))).flat())
    ].sort((a, b) => (a < b ? -1 : 1));

    const datasets = labels.map((key) => {
      const color = getColor(this.selectedType, key);
      const sortedStageData = stages.map((stage) => {
        const stageData = data[key].stages[stage.toString()];
        return stageData && stageData.totalValue2
          ? Math.round((stageData.totalValue1 / stageData.totalValue2) * 100 * 100) / 100
          : 0;
      });

      return {
        datalabels: {
          labels: {
            title: null
          }
        },
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        pointRadius: 2,
        fill: false,
        lineTension: 0,
        label: key === 'undefined' ? this.$t('undefined') : key,
        data: sortedStageData
      };
    });

    return { datasets, labels: stages };
  }
}
