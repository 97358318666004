













































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import readXlsxFile from 'read-excel-file';
import API from '@/services/api';
import { message } from 'ant-design-vue';
import Constants from '@/services/constants';
import moment from 'moment';
import Axios from 'axios';
import LineChart from '@/components/charts/LineChart.vue';
import { stringToMomentDate } from '@/services/date';
import { Parcel } from '@/interfaces/parcel';
import { AnalyticType } from '@/enums/analyticType';
import { AnalyticData } from '@/interfaces/analyticData';
import { getGroundTruthFieldsForSurvey, getYieldGroundTruthFieldsForSurvey } from '@/services/analyticData';
import Header from '@/components/Header.vue';
import { ProductType } from '@/enums/productType';
import { Role } from '@/enums/role';
import { TransformedYieldData, YieldData } from '@/interfaces/transformYieldData';

const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Parcel name',
    width: 250
  },
  {
    title: 'Date | Sugar Recovery',
    dataIndex: 'gt',
    key: 'gt',
    scopedSlots: { customRender: 'gt' },
    width: 250
  },
  {
    title: 'Date | Prediction',
    dataIndex: 'prediction',
    key: 'prediction',
    scopedSlots: { customRender: 'prediction' },
    width: 250
  },
  {
    dataIndex: 'accuracy',
    key: 'accuracy',
    title: 'Trend Accuracy',
    width: 150,
    align: 'center'
  },
  {
    title: 'Chart',
    key: 'chart',
    scopedSlots: { customRender: 'chart' }
  }
];

const yieldColumns = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Parcel name',
    width: 250
  },
  {
    title: 'Date | Sugar Yield',
    dataIndex: 'gt',
    key: 'gt',
    scopedSlots: { customRender: 'gt' },
    width: 250
  },
  {
    title: 'Date | Prediction',
    dataIndex: 'finalPredictionDate',
    key: 'finalPredictionDate',
    scopedSlots: { customRender: 'finalPredictionDate' },
    width: 250
  },
  {
    dataIndex: 'accuracy',
    key: 'accuracy',
    title: 'Accuracy',
    width: 150,
    align: 'center'
  }
];

@Component({
  components: {
    LineChart,
    Header
  }
})
export default class Admin extends Vue {
  tableData = [];
  yieldTableData = [];
  columns = columns;
  yieldColumns = yieldColumns;
  gtChartData = null;
  predictionsChartData = null;
  isChartsVisible = false;
  productType = ProductType;
  showMetricDetails = [];
  metric = {
    totalMetric: { avg: 0, min90: 0 },
    numGTPoints: 0,
    numGTParcels: 0,
    gtParcelsPercentage: 0,
    numFreshGTParcels: 0,
    gtFreshParcelsPercentage: 0,
    numRatoonGTParcels: 0,
    gtRatoonParcelsPercentage: 0,
    numGTParcels1: 0,
    numGTParcels2: 0,
    numGTParcels3: 0,
    percentageGT1: 0,
    percentageGT2: 0,
    percentageGT3: 0
  };
  yieldMetric = {
    totalYieldMetric: { avg: 0, min90: 0 },
    numYieldGTParcels: 0,
    numYieldFreshGTParcels: 0,
    numYieldRatoonGTParcels: 0,
    gtYieldParcelsPercentage: 0
  };
  private chartsMin = 0;
  private chartsMax = 0;

  chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: (item, data) => {
          const dataSetItem = data.datasets[item.datasetIndex];
          const value = `${Math.round(item.yLabel * 1000) / 1000}`;
          return dataSetItem.parcelName ? `${dataSetItem.parcelName}: ${value}` : value;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 18
          }
        }
      ],
      xAxes: [
        {
          display: true,
          type: 'time',
          time: {
            tooltipFormat: Constants.DATE_FORMAT_LOCALIZED
          },
          offset: true
        }
      ]
    }
  };

  get gtChartOptions() {
    return Object.assign(
      {
        title: {
          display: true,
          text: 'Ground Truth'
        }
      },
      this.chartOptions
    );
  }

  get predictionChartOptions() {
    const options = Object.assign(
      {
        title: {
          display: true,
          text: 'Prediction'
        }
      },
      this.chartOptions
    );
    (options.scales.xAxes[0].time as any).min = this.chartsMin;
    (options.scales.xAxes[0].time as any).max = this.chartsMax;
    return options;
  }

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  addDays(date) {
    const result = new Date(date);
    result.setDate(result.getDate() + 7);
    return this.formatDate(result);
  }

  private getChartDataset(items, color, parcelName?) {
    return {
      datalabels: {
        display: false
      },
      parcelName,
      borderColor: color,
      borderWidth: 2,
      pointBackgroundColor: color,
      pointBorderColor: color,
      fill: false,
      tension: 0,
      data: items.map((item) => {
        if (color === 'green') {
          return {
            x: stringToMomentDate(item.date).add(7, 'days'),
            y: item.value.toFixed(2)
          };
        } else {
          return {
            x: stringToMomentDate(item.date),
            y: item.value.toFixed(2)
          };
        }
      })
    };
  }

  getChartData(item) {
    return {
      datasets: [this.getChartDataset(item.gt, 'red'), this.getChartDataset(item.prediction, 'green')]
    };
  }

  private updateComparisonChartData(): void {
    const gtDatasets = [];
    const predictionDatasets = [];
    this.tableData.forEach((item) => {
      let predictionDate = item.prediction.map((i) => ({ date: this.addDays(i.date), value: i.value }));
      const color = `#${Math.random().toString(16).substr(2, 6)}`;
      gtDatasets.push(this.getChartDataset(item.gt, color, item.name));
      predictionDatasets.push(this.getChartDataset(predictionDate, color, item.name));
    });
    this.gtChartData = { datasets: gtDatasets };
    this.predictionsChartData = { datasets: predictionDatasets };
  }

  private getParsedDate(value: any): string {
    if (value instanceof Date) {
      return moment.utc(value).format(Constants.DATE_FORMAT);
    }
    if (typeof value === 'string' || value instanceof String) {
      return moment(value.toString(), Constants.GT_PARSE_DATE_FORMAT).format(Constants.DATE_FORMAT);
    }
    return moment
      .utc(new Date(1900, 0, 1))
      .add(value - 1, 'days')
      .format(Constants.DATE_FORMAT);
  }

  mounted() {
    this.loadTableData();
    this.loadYieldTableData();
    if (
      this.$store.state.analytic.selectedProductType !== null &&
      this.$store.state.analytic.selectedProductType !== undefined
    ) {
      this.runActivityLog();
    }
    this.$watch(
      () => this.$store.state.analytic.selectedProductType,
      (newValue) => {
        if (newValue !== null && newValue !== undefined) {
          this.runActivityLog();
        }
      }
    );
  }

  runActivityLog(): void {
    API.userActivityLog(this.$store.state.userInfo.Email, this.$store.state.analytic.selectedProductType, 'Admin').then(
      (result) => {
        this.$store.dispatch('setUserActivity', result);
      }
    );
  }

  get selectedUnitHierarchy() {
    return this.$store.state.selectedUnitHierarchy;
  }

  @Watch('selectedUnitHierarchy')
  onSelectedUnitHierarchyChanged(): void {
    this.loadTableData();
    this.loadYieldTableData();
  }

  private updateMinMaxDatesChartsOptions(tableData): void {
    let min = null;
    let max = null;
    tableData.forEach((item) => {
      let predictionDate = item.prediction.map((i) => ({ date: this.addDays(i.date), value: i.value }));
      [...item.gt, ...predictionDate].forEach(({ date }) => {
        const dateMoment = stringToMomentDate(date);
        if (min === null || dateMoment.isBefore(min)) {
          min = dateMoment;
        }
        if (max === null || dateMoment.isAfter(max)) {
          max = dateMoment;
        }
      });
    });
    this.chartsMin = min.unix() * 1000;
    this.chartsMax = max.unix() * 1000;
  }

  private updateMinMaxChartOptions(tableData): void {
    const options = Object.assign({}, this.chartOptions);
    let min = null;
    let max = null;
    tableData.forEach((item) => {
      [...item.gt, ...item.prediction].forEach(({ value }) => {
        if (min === null || value < min) {
          min = value;
        }
        if (max === null || value > max) {
          max = value;
        }
      });
    });
    min = Math.floor(min);
    max = Math.ceil(max);
    options.scales.yAxes[0].ticks.min = min % 2 === 0 ? min : min - 1;
    options.scales.yAxes[0].ticks.max = max % 2 === 0 ? max : max + 1;
    this.chartOptions = options;
  }

  private async loadTableData() {
    if (!this.selectedUnitHierarchy) {
      return;
    }

    await this.$store.dispatch('setIsGlobalLoaderVisible', true);

    try {
      const gtData = await Axios.get(
        `https://storage.googleapis.com/${process.env.VUE_APP_GROUND_TRUTH_FOLDER}/${
          this.$store.state.selectedUnit.id
        }.json?v=${Date.now()}`
      ).then((response) => response.data);

      const parcelNames = Object.keys(gtData);
      const parcelNameAnalytic = {};
      const parcelNameParcel = {};
      const parcelAnalyticsTasks = [];
      const parcels = Object.values(this.$store.state.parcels) as Parcel[];
      parcelNames.forEach((name) => {
        const parcel = parcels.find((p: Parcel) => p.Name === name);
        if (parcel) {
          const harvestDates = [...(parcel.HarvestDates || [])].sort();
          const firstHarvestDate = harvestDates.length ? harvestDates[0] : null;

          parcelNameParcel[name] = parcel;
          const task = API.getAnalyticDataForParcel(parcel.id, AnalyticType.SUGAR_CONTENT_PREDICTION).then(
            (data: AnalyticData[]) => {
              parcelNameAnalytic[name] = data.filter((dataItem) => {
                const surveyDate = new Date(dataItem.SurveyDate);
                if (firstHarvestDate && surveyDate >= new Date(firstHarvestDate)) {
                  return false;
                }

                return true;
              });
            }
          );
          parcelAnalyticsTasks.push(task);
        }
      });
      await Promise.all(parcelAnalyticsTasks);

      let metricStats = {};
      const tableData = parcelNames.map((name) => {
        metricStats[name] = [];
        const item = gtData[name];
        const gt = Object.keys(item)
          .map((date) => {
            return { date, value: item[date][0].sugarRecovery || 0 };
          })
          .sort((a, b) => a.date.localeCompare(b.date));
        const prediction = parcelNameAnalytic[name]
          ? parcelNameAnalytic[name]
              .map((item) => {
                return {
                  date: item.SurveyDate,
                  value: item.Value
                };
              })
              .sort((a, b) => a.date.localeCompare(b.date))
          : [];

        const predictionAccuracies = [];
        prediction.forEach((item) => {
          const fields = getGroundTruthFieldsForSurvey(parcelNameParcel[name], item.date, item.value, gtData);
          if (fields.gtDate) {
            metricStats[name].push(fields.sugarRecoveryAccuracy);
            predictionAccuracies.push(fields.sugarRecoveryAccuracy);
          }
        });
        const predictionAccuraciesTotal = predictionAccuracies.reduce((a, b) => a + b, 0);
        const accuracy =
          predictionAccuracies.length > 0 ? Math.round(predictionAccuraciesTotal / predictionAccuracies.length) : 0;
        return {
          name,
          gt,
          prediction,
          accuracy: accuracy + '%'
        };
      });

      this.updateMinMaxChartOptions(tableData);
      this.updateMinMaxDatesChartsOptions(tableData);
      this.tableData = tableData;

      this.calculateMillMetrics(metricStats, gtData);
    } catch {
      this.tableData = [];
    } finally {
      this.updateComparisonChartData();
      await this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  transformYieldData(data: YieldData[]): TransformedYieldData[] {
    return data.map((item) => {
      return {
        Parcel: item.name,
        GT: `${item.gt.harvestDates} / ${parseFloat(item.gt.yield).toFixed(2)}`,
        PredictionDate: item.finalPredictionDate.map((pred) => `${pred.date} - ${pred.value.toFixed(2)}`).join(' | '),
        Accuracy: item.accuracy
      };
    });
  }

  convertToCSV(data: YieldData[]): string {
    const transformedData = this.transformYieldData(data);
    let str = '';

    const headers = Object.keys(transformedData[0]).join(',') + '\r\n';
    str += headers;

    for (let i = 0; i < transformedData.length; i++) {
      let line = '';

      for (let index in transformedData[i]) {
        if (line !== '') line += ',';
        line += transformedData[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  downloadCSV(csv: string, filename: string): void {
    const csvFile = new Blob([csv], { type: 'text/csv' });

    const downloadLink = document.createElement('a');
    downloadLink.download = filename;

    const url = window.URL.createObjectURL(csvFile);
    downloadLink.href = url;
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  downloadDataAsCSV(): void {
    const csv = this.convertToCSV(this.yieldTableData);
    this.downloadCSV(csv, 'yield_table_data.csv');
  }

  private async loadYieldTableData() {
    if (!this.selectedUnitHierarchy) {
      return;
    }

    await this.$store.dispatch('setIsGlobalLoaderVisible', true);

    try {
      const gtData = await Axios.get(
        `https://storage.googleapis.com/${process.env.VUE_APP_GROUND_TRUTH_FOLDER}/${
          this.$store.state.selectedUnit.id
        }_YIELD.json?v=${Date.now()}`
      ).then((response) => response.data);

      const parcelNames = Object.keys(gtData);
      const parcelNameAnalytic = {};
      const parcelNameParcel = {};
      const parcelAnalyticsTasks = [];
      const parcels = Object.values(this.$store.state.parcels) as Parcel[];
      parcelNames.forEach((name) => {
        const parcel = parcels.find((p: Parcel) => p.Name === name);
        if (parcel) {
          const harvestDates = [...(parcel.HarvestDates || [])].sort();
          const firstHarvestDate = harvestDates.length ? harvestDates[0] : null;

          parcelNameParcel[name] = parcel;
          const task = API.getAnalyticDataForParcel(parcel.id, AnalyticType.SUGAR_CONTENT_YIELD).then(
            (data: AnalyticData[]) => {
              parcelNameAnalytic[name] = data.filter((dataItem) => {
                const surveyDate = new Date(dataItem.SurveyDate);
                if (firstHarvestDate && surveyDate >= new Date(firstHarvestDate)) {
                  return false;
                }

                return true;
              });
            }
          );
          parcelAnalyticsTasks.push(task);
        }
      });
      await Promise.all(parcelAnalyticsTasks);

      let metricStats = {};
      const yieldTableData = parcelNames.map((name) => {
        metricStats[name] = [];
        const item = gtData[name];
        const gt = item;
        const prediction = parcelNameAnalytic[name]
          ? parcelNameAnalytic[name]
              .map((item) => {
                return {
                  date: item.SurveyDate,
                  value: item.Value
                };
              })
              .sort((a, b) => a.date.localeCompare(b.date))
          : [];

        const predictionAccuracies = [];
        const finalPredictionDate = [];
        prediction.forEach((item) => {
          const fields = getYieldGroundTruthFieldsForSurvey(parcelNameParcel[name], item.date, item.value, gtData);
          if (fields.gtDate) {
            finalPredictionDate.push({ date: fields.predictionDate, value: fields.predictionDateValue });
            metricStats[name].push(fields.sugarYieldRecoveryAccuracy);
            predictionAccuracies.push(fields.sugarYieldRecoveryAccuracy);
          }
        });
        const predictionAccuraciesTotal = predictionAccuracies.reduce((a, b) => a + b, 0);
        const accuracy =
          predictionAccuracies.length > 0 ? Math.round(predictionAccuraciesTotal / predictionAccuracies.length) : 0;
        return {
          name,
          gt,
          finalPredictionDate,
          accuracy: accuracy + '%'
        };
      });

      this.yieldTableData = yieldTableData;
      this.calculateYieldMillMetrics(metricStats, gtData);
    } catch {
      this.yieldTableData = [];
    } finally {
      await this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  calculateYieldMillMetrics(stats, gtData): void {
    const hierarchy = this.$store.state.selectedUnitHierarchy;
    let parcelsStatus = {};
    let parcelNum = 0;
    hierarchy.Farms.forEach((f) => {
      parcelNum += f.Parcels.length;
      f.Parcels.forEach((p) => {
        parcelsStatus[p.Name] = p.Status;
      });
    });
    let totalYieldMetric = { avg: 0, min90: 0 };
    let numYieldGTParcels = 0;
    let numYieldFreshGTParcels = 0;
    let numYieldRatoonGTParcels = 0;
    let gtYieldParcelsPercentage = 0;
    Object.keys(gtData).forEach((parcelName) => {
      const parcel = gtData[parcelName];
      const gtValues = Object.keys(parcel);
      if (gtValues.length > 0) {
        numYieldGTParcels++;
        if (parcelsStatus[parcelName] === 'Ratoon') {
          numYieldRatoonGTParcels++;
        } else {
          numYieldFreshGTParcels++;
        }
      }
    });

    gtYieldParcelsPercentage = Math.round((10 * 100 * numYieldGTParcels) / parcelNum) / 10;

    let parcelArr = [];
    let sumPoints = 0;
    let countPoints = 0;
    Object.values(stats).forEach((parcelAccuracies: any) => {
      if (parcelAccuracies.length === 0) return;
      parcelArr.push(parcelAccuracies.reduce((a, b) => a + b, 0) / parcelAccuracies.length);
      parcelAccuracies.forEach((acc) => {
        sumPoints += acc;
        countPoints += 1;
      });
    });
    totalYieldMetric.avg = Math.round((100 * sumPoints) / countPoints) / 100;

    let count95 = 0;
    parcelArr.forEach((val) => {
      if (Math.ceil(val) >= 85) {
        count95++;
      }
    });
    totalYieldMetric.min90 = Math.round((10 * 100 * count95) / parcelArr.length) / 10;

    this.yieldMetric = {
      totalYieldMetric,
      numYieldGTParcels,
      numYieldFreshGTParcels,
      numYieldRatoonGTParcels,
      gtYieldParcelsPercentage
    };
  }

  calculateMillMetrics(stats, gtData): void {
    const hierarchy = this.$store.state.selectedUnitHierarchy;
    let parcelsStatus = {};
    let parcelNum = 0;
    hierarchy.Farms.forEach((f) => {
      parcelNum += f.Parcels.length;
      f.Parcels.forEach((p) => {
        parcelsStatus[p.Name] = p.Status;
      });
    });
    let totalMetric = { avg: 0, min90: 0 };
    let numGTParcels = 0;
    let gtParcelsPercentage = 0;
    let numFreshGTParcels = 0;
    let numRatoonGTParcels = 0;
    let gtFreshParcelsPercentage = 0;
    let gtRatoonParcelsPercentage = 0;
    let numGTPoints = 0;
    let numGTParcels1 = 0;
    let numGTParcels2 = 0;
    let numGTParcels3 = 0;
    Object.keys(gtData).forEach((parcelName) => {
      const parcel = gtData[parcelName];
      const gtValues = Object.keys(parcel);
      if (gtValues.length > 0) {
        numGTPoints += gtValues.length;
        numGTParcels++;
        if (parcelsStatus[parcelName] === 'Ratoon') {
          numRatoonGTParcels++;
        } else {
          numFreshGTParcels++;
        }
      }
      if (gtValues.length === 1) {
        numGTParcels1++;
      }
      if (gtValues.length === 2) {
        numGTParcels2++;
      }
      if (gtValues.length >= 3) {
        numGTParcels3++;
      }
    });

    gtParcelsPercentage = Math.round((10 * 100 * numGTParcels) / parcelNum) / 10;
    gtFreshParcelsPercentage = Math.round((10 * 100 * numFreshGTParcels) / parcelNum) / 10;
    gtRatoonParcelsPercentage = Math.round((10 * 100 * numRatoonGTParcels) / parcelNum) / 10;

    let parcelArr = [];
    let sumPoints = 0;
    let countPoints = 0;
    Object.values(stats).forEach((parcelAccuracies: any) => {
      if (parcelAccuracies.length === 0) return;
      parcelArr.push(parcelAccuracies.reduce((a, b) => a + b, 0) / parcelAccuracies.length);
      parcelAccuracies.forEach((acc) => {
        sumPoints += acc;
        countPoints += 1;
      });
    });
    totalMetric.avg = Math.round((100 * sumPoints) / countPoints) / 100;

    let count95 = 0;
    parcelArr.forEach((val) => {
      if (Math.ceil(val) >= 90) {
        count95++;
      }
    });
    totalMetric.min90 = Math.round((10 * 100 * count95) / parcelArr.length) / 10;

    this.metric = {
      totalMetric,
      numGTParcels,
      gtParcelsPercentage,
      numFreshGTParcels,
      gtFreshParcelsPercentage,
      numRatoonGTParcels,
      gtRatoonParcelsPercentage,
      numGTPoints,
      numGTParcels1,
      numGTParcels2,
      numGTParcels3,
      percentageGT1: Math.round((10 * 100 * numGTParcels1) / numGTParcels) / 10,
      percentageGT2: Math.round((10 * 100 * numGTParcels2) / numGTParcels) / 10,
      percentageGT3: Math.round((10 * 100 * numGTParcels3) / numGTParcels) / 10
    };
  }

  get tableScrollOpt() {
    return { y: window.innerHeight - 240 };
  }

  importFromExcel(event): void {
    if (this.$store.state.selectedUnit && event.target.files.length) {
      const schema = {
        'Sr. No.': {
          prop: 'serialNumber',

          type: Number,
          required: true
        },
        'Plot ID': {
          prop: 'parcel',
          type: String,
          required: true
        },
        'Date of measurement \r\n(in mm-dd-yyyy)': {
          prop: 'date',
          type: String,
          required: true,
          parse: this.getParsedDate
        },
        'Sugar Content (%) / Pol in Cane (%)': {
          prop: 'sugarRecovery',
          type: Number,
          required: true
        }
      };

      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      readXlsxFile(event.target.files[0], { schema })
        .then((data) => {
          if (data && data.errors && data.errors.length) {
            this.$store.dispatch('setIsGlobalLoaderVisible', false);
            const err = JSON.stringify(data.errors[0], null, 2);
            message.error(this.$root.$t('dataInFileContainErrors').toString() + ': ' + err, 10);
            // eslint-disable-next-line no-console
            console.log(data);
            return;
          }
          if (data && data.rows && data.rows.length) {
            const output = {};
            const parcels = Object.values(this.$store.state.parcels) as Parcel[];
            data.rows.forEach((row) => {
              const number = parcels.find((p: Parcel) => p.Name === row.parcel.toString());
              if (!number) {
                message.error(this.$root.$t('plotIdIsNotRegistered').toString() + ' ' + row.parcel);
              }
              if (number) {
                output[row.parcel] = output[row.parcel] || {};
                output[row.parcel][row.date] = output[row.parcel][row.date] || [];
                const { date, parcel, ...params } = row;
                output[parcel][date].push(params);
              }
            });
            if (Object.keys(output).length === data.rows.length) {
              message.success(this.$root.$t('plotIdIsRegistered').toString());
              API.saveGroundTruth(this.$store.state.selectedUnit.id, output)
                .then(() => {
                  message.success(this.$root.$t('successfullyUploaded').toString());
                  this.loadTableData();
                })
                .finally(() => {
                  this.$store.dispatch('setIsGlobalLoaderVisible', false);
                });
              return;
            }
          }
          this.$store.dispatch('setIsGlobalLoaderVisible', false);
          message.info(this.$root.$t('noDataInFile').toString());
        })
        .catch((e) => {
          this.$store.dispatch('setIsGlobalLoaderVisible', false);
          // eslint-disable-next-line no-console
          console.error(e);
          message.error(this.$root.$t('errorReadingFile').toString());
        });
    }
    const input = document.getElementById('file-upload') as any;
    if (input) {
      input.value = '';
    }
  }

  importFromYieldExcel(event): void {
    if (this.$store.state.selectedUnit && event.target.files.length) {
      const schema = {
        'Sr. No.': {
          prop: 'serialNumber',

          type: Number,
          required: true
        },
        'Plot ID': {
          prop: 'parcel',
          type: String,
          required: true
        },
        'Date of Harvest': {
          prop: 'date',
          type: String,
          required: true,
          parse: this.getParsedDate
        },
        'Yield (Tons/Hectare)': {
          prop: 'yield',
          type: String,
          required: true
        }
      };

      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      readXlsxFile(event.target.files[0], { schema })
        .then((data) => {
          if (data && data.errors && data.errors.length) {
            this.$store.dispatch('setIsGlobalLoaderVisible', false);
            const err = JSON.stringify(data.errors[0], null, 2);
            message.error(this.$root.$t('dataInFileContainErrors').toString() + ': ' + err, 10);
            // eslint-disable-next-line no-console
            console.log(data);
            return;
          }
          if (data && data.rows && data.rows.length) {
            const output = {};
            const parcels = Object.values(this.$store.state.parcels) as Parcel[];
            data.rows.forEach((row) => {
              const number = parcels.find((p: Parcel) => p.Name === row.parcel.toString());
              if (!number) {
                message.error(this.$root.$t('plotIdIsNotRegistered').toString() + ' ' + row.parcel);
              }
              if (number) {
                output[row.parcel] = {
                  harvestDates: row.date,
                  yield: row.yield.toFixed(2)
                };
              }
            });
            if (Object.keys(output).length === data.rows.length) {
              message.success(this.$root.$t('plotIdIsRegistered').toString());
              API.saveYieldGroundTruth(this.$store.state.selectedUnit.id, output)
                .then(() => {
                  message.success(this.$root.$t('successfullyUploaded').toString());
                  this.loadYieldTableData();
                })
                .finally(() => {
                  this.$store.dispatch('setIsGlobalLoaderVisible', false);
                });
              return;
            }
          }
          this.$store.dispatch('setIsGlobalLoaderVisible', false);
          message.info(this.$root.$t('noDataInFile').toString());
        })
        .catch((e) => {
          this.$store.dispatch('setIsGlobalLoaderVisible', false);
          // eslint-disable-next-line no-console
          console.error(e);
          message.error(this.$root.$t('errorReadingFile').toString());
        });
    }
    const input = document.getElementById('file-upload') as any;
    if (input) {
      input.value = '';
    }
  }

  downloadCharts() {
    const canvases = document.querySelectorAll('.charts canvas');
    canvases.forEach((canvas: Element, idx: number) => {
      const link = document.createElement('a');
      link.download = (idx == 0 ? 'GroundTruth' : 'Prediction') + '.png';
      link.href = (canvas as HTMLCanvasElement).toDataURL();
      link.click();
    });
  }

  get isAdminMenuAvailable(): boolean {
    return (
      this.$store.state.userInfo &&
      this.$store.state.userInfo.Roles &&
      this.$store.state.userInfo.Roles.includes(Role.MAHINDRA_ADMIN)
    );
  }
}
