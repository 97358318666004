










































































































import { getParcelCropStage, getParcelCycle } from '@/services/analyticData';
import { Component, Vue, Watch } from 'vue-property-decorator';
import MapFilterTag from '@/components/radar/MapFilterTag.vue';
import { Parcel } from '@/interfaces/parcel';
import Constants from '@/services/constants';

@Component({
  components: {
    MapFilterTag
  }
})
export default class MapFilters extends Vue {
  isPanelOpened = false;
  isVarietiesOpened = false;
  isStagesOpened = false;
  isCyclesOpened = false;
  indeterminate = {
    varieties: false,
    stages: false,
    cycles: false
  };
  checkedAll = {
    varieties: false,
    stages: false,
    cycles: false
  };

  get showCyclesFilter(): boolean {
    return !Constants.indiaCountryIds.includes(this.$store.state.selectedCountry.id);
  }

  get showStagesFilter(): boolean {
    return !Constants.indiaCountryIds.includes(this.$store.state.selectedCountry.id);
  }

  get filter() {
    const varieties = [];
    const stages = [];
    const cycles = [];
    Object.values(this.$store.state.parcels as { [key: string]: Parcel }).forEach((parcel: Parcel) => {
      if (parcel.Variety) {
        varieties.push(parcel.Variety);
      }
      const stage = getParcelCropStage(parcel, this.$store.state.analytic.fromTo.to);
      if (!Number.isNaN(stage)) {
        stages.push(getParcelCropStage(parcel, this.$store.state.analytic.fromTo.to));
      }
      const cycle = getParcelCycle(parcel, this.$store.state.analytic.fromTo.to);
      if (!Number.isNaN(cycle)) {
        cycles.push(cycle);
      }
    });
    return {
      varieties: [...new Set(varieties)].sort(),
      stages: [...new Set(stages)].sort((a, b) => (a < b ? -1 : 1)).map((value) => `${value}`),
      cycles: [...new Set(cycles)].sort((a, b) => (a < b ? -1 : 1)).map((value) => `${value}`)
    };
  }

  get productType() {
    return this.$store.state.analytic.selectedProductType;
  }

  get dateRange() {
    return this.$store.state.analytic.fromTo;
  }

  // clear values when switching between dates and products
  @Watch('productType')
  onProductChange(): void {
    this.clearAll();
  }

  @Watch('dateRange')
  onDateChange(): void {
    this.clearAll();
  }

  clickToContainer(e): void {
    e.stopPropagation();
  }

  private onClickOutside(): void {
    this.isPanelOpened = false;
  }

  created(): void {
    window.addEventListener('click', this.onClickOutside);
  }

  destroyed(): void {
    window.removeEventListener('click', this.onClickOutside);
  }

  clear(filter: string): void {
    this.$store.dispatch('updateMapParcelFilter', { filter, value: [] });
    this.indeterminate[filter] = false;
    this.checkedAll[filter] = false;
  }

  clearAll(): void {
    this.$store.dispatch('clearMapParcelFilter');
    this.indeterminate.varieties = false;
    this.indeterminate.cycles = false;
    this.indeterminate.stages = false;
    this.checkedAll.varieties = false;
    this.checkedAll.cycles = false;
    this.checkedAll.stages = false;
  }

  onCheckAll(filter: string, list, e): void {
    this.indeterminate[filter] = false;
    this.checkedAll[filter] = e.target.checked;
    this.$store.dispatch('updateMapParcelFilter', {
      filter,
      value: e.target.checked ? list : []
    });
  }

  onChange(filter: string, list, checkedList): void {
    this.indeterminate[filter] = !!checkedList.length && checkedList.length < list.length;
    this.checkedAll[filter] = checkedList.length === list.length;
    this.$store.dispatch('updateMapParcelFilter', { filter, value: checkedList });
  }
}
