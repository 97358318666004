

























import Mapbox from 'mapbox-gl-vue';
import bbox from '@turf/bbox';
import { LayerGroupName } from '@/enums/layerGroupName';
import { LayerName } from '@/enums/layerName';

import moment from 'moment';
import constants from '@/services/constants';
import {
  analyticMatchFilters,
  defaultColor,
  defaultSelectedParcelColor,
  getColor,
  isAllowToLoadUnpublishedAnalytics,
  isAnalyticLayerAllowedForProduct,
  sugarContentPredictionBucket,
  sugarContentYieldBucket,
  transformFilteredAnalyticsToMap
} from '@/services/analyticData';
import API from '@/services/api';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AnalyticData } from '@/interfaces/analyticData';
import { ProductType } from '@/enums/productType';
import { FromTo } from '@/interfaces/fromTo';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Parcel } from '@/interfaces/parcel';
import { message } from 'ant-design-vue';
import { Survey } from '@/interfaces/survey';
import { BaseLayers } from '@/services/layers/base';
import { FeaturesSelector } from '@/services/layers/featuresSelector';
import { AnyLayer, AttributionControl, MapboxGeoJSONFeature } from 'mapbox-gl';
import { getLocalizedDate, stringToDate, stringToMomentDate } from '@/services/date';
import { MapCoordinates } from '@/interfaces/mapCoordinates';
import { AnalyticType } from '@/enums/analyticType';
import { LayerGroup } from '@/interfaces/layerGroup';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { UserInfo } from '@/interfaces/userInfo';
import { VegetativeIndex } from '@/interfaces/vegetativeIndex';
import { Unit } from '@/interfaces/unit';
import { SelectedLayer } from '@/interfaces/selectedLayer';
import { Country } from '@/interfaces/country';
import { Farm } from '@/interfaces/farm';
import { Organization } from '@/interfaces/organization';
import { UnitHierarchy } from '@/interfaces/unitHierarchy';
import { BBox } from '@turf/helpers';
import turfBuffer from '@turf/buffer';
import bboxPolygon from '@turf/bbox-polygon';
import { Shape } from '@/interfaces/shape';
import cloudIcon from '@/assets/icon/cloud-no-info.png';
import warningIcon from '@/assets/icon/warning.png';
import lineIcon from '@/assets/icon/line.png';
import { addMapTooltip } from '@/services/map-tooltip';
import { ParcelPixel } from '@/interfaces/parcelPixel';

export interface MapFitBoundsShift {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

@Component({
  components: {
    Mapbox
  }
})
export default class Map extends Vue {
  @Prop() mapId: string;
  @Prop() fromTo: FromTo;
  @Prop() selectedSurvey: Survey;
  @Prop() userInfo: UserInfo;
  @Prop() mapParcelFilters: any;
  @Prop() applyFilterGroundMeasurements: boolean;
  @Prop() applyParcelPixel: boolean;
  @Prop() vegetativeIndex: VegetativeIndex;
  @Prop() parcels: { [key: string]: Parcel };
  @Prop() selectedUnit: Unit;
  @Prop() selectedSurveys: Survey[];
  @Prop() selectedParcels: Parcel[];
  @Prop() selectedProductType: ProductType;
  @Prop() selectedLayers: SelectedLayer[];
  @Prop() filteredAnalyticData: AnalyticData[];
  @Prop() analyticData: AnalyticData[];
  @Prop() selectedParcel: Parcel;
  @Prop() selectedCountry: Country;
  @Prop() availableLayerGroups: LayerGroup[];
  @Prop() selectedAnalyticType: AnalyticType;
  @Prop() unitSummary: AnalyticSummaryItem[];
  @Prop() selectedUnitHierarchy: UnitHierarchy;
  @Prop() selectedFarm: Farm;
  @Prop() print: boolean;
  @Prop() printAllParcels: boolean;
  @Prop() mapCoordinates: MapCoordinates;
  @Prop() selectedOrganization: Organization;
  @Prop() initLatLon: [number, number, number];
  @Prop() filteredParcelIds: string[];
  @Prop() showScaleControl: boolean;
  @Prop() showNavControl: boolean;
  @Prop({ default: () => ({ left: 0, top: 0, right: 0, bottom: 0 }) }) fitBoundsShift: MapFitBoundsShift;
  @Prop() logoPosition: string;
  @Prop() attributionPosition: string;

  $refs: {
    legend: HTMLDivElement;
  };
  mapBoxToken = constants.mapBoxToken;
  private map = null;

  private basemapSourceId = 'basemap-source';
  private basemapLayerId = 'basemap-layer';
  private basemapExtraSourceId = 'basemap-extra-source';
  private basemapExtraLayerId = 'basemap-extra-layer';
  private parcelsSourceId = 'parcels-source';
  private parcelNamesLayerId = 'parcels-names-layer';
  private parcelsLayerId = 'parcels-layer';
  private parcelsCloudLayerId = 'parcels-cloud-layer';
  private parcelsWarningLayerId = 'parcels-warning-layer';
  private parcelsPartialHarvestedLayerId = 'parcels-partial-harvested-layer';
  private parcelsHighlightedLayerId = 'parcels-highlighted-layer';
  private parcelsBorderLayerId = 'parcels-border-layer';
  private printing = false;
  private featuresSelector = new FeaturesSelector();
  private pdf = null;
  private parcelsToPrint = [];
  private currentParcelIndex = -1;
  private PRINT_PDF_MARGIN = 10;
  private selectedFarmForPrinting: Farm = null;

  @Watch('fromTo')
  onFromToChanged(): void {
    this.reDrawParcelTiles();
    if (
      this.$store.state.analytic.selectedProductType === 'vegetative-index' &&
      this.$store.state.analytic.selectedParcel !== null
    ) {
      this.updateSelectedParcelVegetativeIndexLayerDateChange();
    }
  }

  @Watch('mapParcelFilters')
  onMapParcelFiltersChange(newFilters, oldFilters) {
    const isEmpty = (filters: { [key: string]: Array<string | number> }) =>
      Object.values(filters).every((arr) => arr.length === 0);
    // do not run without map loaded or when map filters wasn't touched from clean state
    if (this.map && this.map.getLayer(this.parcelsLayerId) && !(isEmpty(oldFilters) && isEmpty(newFilters))) {
      this.map.setPaintProperty(this.parcelsLayerId, 'fill-color', this.getParcelsStylingRules());
    }
  }

  @Watch('applyFilterGroundMeasurements')
  onExternalParamsChange() {
    if (this.map && this.map.getLayer(this.parcelsLayerId)) {
      this.map.setPaintProperty(this.parcelsLayerId, 'fill-color', this.getParcelsStylingRules());
    }
  }

  @Watch('userInfo')
  onUserInfoChanged(): void {
    this.updateBasemapLayer();
  }

  @Watch('selectedParcels')
  onSelectedParcelsChanged(): void {
    this.updateAnalyticLayer(true);
  }

  @Watch('vegetativeIndex')
  onVegetativeIndexChange(): void {
    if (this.$store.state.analytic.selectedProductType === 'vegetative-index') {
      this.map.setPaintProperty(this.parcelsLayerId, 'fill-color', this.getParcelsStylingRules());
    }
  }

  @Watch('selectedParcel')
  onSelectedParcelChanged(): void {
    if (this.selectedParcel) {
      let parcelBbox = [
        this.selectedParcel.LLLong,
        this.selectedParcel.LLLat,
        this.selectedParcel.URLong,
        this.selectedParcel.URLat
      ] as BBox;
      if (!this.print) {
        parcelBbox = bbox(turfBuffer(bboxPolygon(parcelBbox), 1));
      }
      this.fitBoundsByBbox(parcelBbox);
    }
    if (this.$store.state.applyParcelPixel && this.$store.state.analytic.selectedParcel !== null) {
      if (this.map.getLayer('parcel-pixel-plot')) {
        this.map.removeLayer('parcel-pixel-plot');
        this.map.removeSource('parcel-pixel-plot');
      }
      const selectedParcel = this.$store.state.analytic.selectedParcel;
      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      API.getParcelPixel(
        this.$store.state.selectedUnit.id,
        this.$store.state.analytic.fromTo.from,
        this.$store.state.analytic.selectedParcel.id
      )
        .then((result: ParcelPixel[]) => {
          this.$store.dispatch('setParcelPixelSummary', result || []);
          if (this.$store.state.parcelPixelSummary?.features?.length > 0) {
            this.map.addLayer({
              id: 'parcel-pixel-plot',
              type: 'fill',
              source: {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: this.$store.state.parcelPixelSummary?.features?.map((item) => {
                    return {
                      type: 'Feature',
                      geometry: {
                        type: 'Polygon',
                        coordinates: [item['geometry']['coordinates'][0]]
                      },
                      properties: {
                        color:
                          selectedParcel && selectedParcel.HarvestDates?.length > 0
                            ? '#ffffff'
                            : item.properties.is_harvested === false
                            ? '#00ff00'
                            : '#ffffff'
                      }
                    };
                  })
                }
              },
              paint: {
                'fill-color': ['get', 'color'], // blue color fill
                'fill-opacity': 0.5
              }
            });
          } else {
            message.error('Parcel Pixel Data Not Found');
          }
        })
        .finally(() => {
          this.$store.dispatch('setIsGlobalLoaderVisible', false);
        });
    }
    if (
      this.$store.state.analytic.selectedProductType === 'vegetative-index' &&
      this.$store.state.analytic.selectedParcel !== null
    ) {
      if (this.map.getLayer('parcel-pixel-plot')) {
        this.map.removeLayer('parcel-pixel-plot');
        this.map.removeSource('parcel-pixel-plot');
      }
      const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
        (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
      );
      const key = fieldInfoGroup.name.toLowerCase();
      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      API.getParcelPixelColor(
        this.$store.state.selectedUnit.id,
        this.$store.state.analytic.fromTo.from,
        this.$store.state.analytic.selectedParcel.Name
      )
        .then((result) => {
          this.$store.dispatch('setParcelPixelSummary', result || []);
          if (this.$store.state.parcelPixelSummary?.features?.length > 0) {
            this.map.addLayer({
              id: 'parcel-pixel-plot',
              type: 'fill',
              source: {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: this.$store.state.parcelPixelSummary?.features?.map((item) => {
                    return {
                      type: 'Feature',
                      geometry: {
                        type: 'Polygon',
                        coordinates: [item['geometry']['coordinates'][0]]
                      },
                      properties: {
                        color: item['properties']['colors'][key]
                      }
                    };
                  })
                }
              },
              paint: {
                'fill-color': ['get', 'color'],
                'fill-opacity': 0.5
              }
            });
          } else {
            message.error('Parcel Pixel Data Not Found');
          }
        })
        .finally(() => {
          this.$store.dispatch('setIsGlobalLoaderVisible', false);
        });
    }
  }

  @Watch('analyticData')
  onAnalyticDataChanged(): void {
    this.updateAnalyticLayer();
  }

  @Watch('selectedFarm')
  onFarmChanged(farm: Farm): void {
    if (this.initLatLon) {
      this.map.jumpTo({ center: [this.initLatLon[0], this.initLatLon[1]], zoom: this.initLatLon[2] });
      this.$emit('clearInitLatLon');
      return;
    }
    if (farm) {
      API.getShape(farm.ShapeID).then((shape: Shape) => {
        if (shape) {
          this.fitBounds(shape.GeoJson);
        }
      });
    }
  }

  @Watch('selectedUnit')
  onUnitChanged(unit: Unit): void {
    if (unit && unit.ShapeID && !this.initLatLon) {
      API.getShape(unit.ShapeID).then((shape: Shape) => {
        if (shape && !this.selectedFarm && !this.selectedParcel) {
          this.fitBounds(shape.GeoJson);
        }
      });
    }
    this.reDrawParcelTiles();
  }

  @Watch('selectedLayers')
  onSelectedLayersChanged(): void {
    this.updateAnalyticLayer();
    if (
      this.$store.state.analytic.selectedProductType === 'vegetative-index' &&
      this.$store.state.analytic.selectedParcel !== null
    ) {
      this.updateSelectedParcelVegetativeIndexLayer();
    }
  }

  loaded(map) {
    this.map = map;

    let isFeaturesSelectorClick = false;
    this.featuresSelector.init(map, this.parcelsLayerId, (features: MapboxGeoJSONFeature[]) => {
      const parcels: Parcel[] = [];
      const ids = [...new Set(features.map((feature: MapboxGeoJSONFeature) => feature.properties.id))];
      ids.forEach((id: string) => {
        const parcel = this.parcels[id];
        if (parcel) {
          parcels.push(parcel);
        }
      });
      this.$emit('setMultipleSelectedParcels', { parcels });
      isFeaturesSelectorClick = true;
      window.setTimeout(() => {
        isFeaturesSelectorClick = false;
      }, 100);
    });

    this.map.on('click', this.parcelsLayerId, (ev) => {
      if (isFeaturesSelectorClick) {
        return;
      }
      ev.originalEvent.preventDefault();
      const parcel = this.parcels[ev.features[0].properties.id];
      if (parcel) {
        this.$emit('changeSelectedParcel', {
          parcel,
          isMultiSelectionMode: ev.originalEvent.shiftKey
        });
      }
    });

    this.map.on('click', (ev) => {
      if (isFeaturesSelectorClick) {
        return;
      }
      if (ev.originalEvent.defaultPrevented) {
        return;
      }
      this.$emit('clearSelectedParcels');
      if (this.$store.state.analytic.selectedParcel === null) {
        if (this.map.getLayer('parcel-pixel-plot')) {
          this.map.removeLayer('parcel-pixel-plot');
          this.map.removeSource('parcel-pixel-plot');
        }
      }
      this.$store.dispatch('setParcelPixelSummary', []);
    });

    this.map.on('mousemove', (e) => {
      const coordinates = e.lngLat.wrap();
      this.$emit('setMapCoordinates', {
        isMapCenter: false,
        lon: coordinates.lng,
        lat: coordinates.lat,
        zoom: this.map.getZoom()
      });
    });

    this.map.on('mouseout', () => {
      const coordinates = this.map.getCenter();
      this.$emit('setMapCoordinates', {
        isMapCenter: true,
        lon: coordinates.lng,
        lat: coordinates.lat,
        zoom: this.map.getZoom()
      });
    });

    this.map.on('contextmenu', () => {
      if (this.mapCoordinates) {
        const coordinates = {
          lat: this.mapCoordinates.lat,
          lon: this.mapCoordinates.lon
        };
        this.$copyText(JSON.stringify(coordinates)).then(
          () => {
            message.success(this.$root.$t('coordinatesCopiedToClipboard').toString());
          },
          () => {
            message.error(this.$root.$t('cantCopyCoordinates').toString());
          }
        );
      }
    });

    addMapTooltip(
      this.map,
      this.parcelsCloudLayerId,
      () => {
        return this.$t('cloudyDay').toString();
      },
      () => {
        return this.$t('cloudyDayDescription').toString();
      }
    );
    addMapTooltip(
      this.map,
      this.parcelsWarningLayerId,
      () => {
        return this.$t('noResultsAvailable').toString();
      },
      () => {
        return this.$t('noResultsAvailableDescription').toString();
      }
    );

    if (this.attributionPosition) {
      this.map.addControl(new AttributionControl(), this.attributionPosition);
    }

    this.map.loadImage(cloudIcon, (err, image) => {
      this.map.addImage('cloud', image);
    });

    this.map.loadImage(warningIcon, (err, image) => {
      this.map.addImage('warning', image);
    });

    this.map.loadImage(lineIcon, (err, image) => {
      this.map.addImage('line', image);
    });

    this.onUnitChanged(this.selectedUnit);
    this.onFarmChanged(this.selectedFarm);
    this.onSelectedParcelChanged();
    this.updateBasemapLayer();
    this.updateAnalyticLayer();

    this.$emit('onLoaded', map);
  }

  fitBounds(geoJson): void {
    if (!this.map) {
      return;
    }

    const geoBbox = bbox(geoJson);
    this.map.fitBounds(
      [
        [geoBbox[0], geoBbox[3]],
        [geoBbox[2], geoBbox[1]]
      ],
      {
        padding: {
          top: this.fitBoundsShift.top,
          bottom: this.fitBoundsShift.bottom,
          left: this.fitBoundsShift.left,
          right: this.fitBoundsShift.right
        },
        duration: 0
      }
    );
  }

  async fitBoundsByBbox(bbox): Promise<void> {
    if (!this.map) {
      return Promise.resolve();
    }
    this.map.fitBounds(bbox, {
      padding: {
        top: this.fitBoundsShift.top,
        bottom: this.fitBoundsShift.bottom,
        left: this.fitBoundsShift.left,
        right: this.fitBoundsShift.right
      },
      duration: 100
    });
    await new Promise<void>((resolve) => this.map.once('moveend', resolve));
  }

  private showSatelliteMapBoxLayer(): void {
    this.map.addSource(this.basemapSourceId, {
      type: 'raster',
      url: 'mapbox://mapbox.satellite'
    });

    this.map.addLayer({
      id: this.basemapLayerId,
      source: this.basemapSourceId,
      type: 'raster'
    });
    this.reOrderLayers();
  }

  private showSatellitePlacesLayer(): void {
    this.map.addSource(this.basemapExtraSourceId, {
      type: 'vector',
      url: 'mapbox://mapbox.mapbox-streets-v8'
    });

    this.map.addLayer({
      id: this.basemapExtraLayerId,
      'source-layer': 'place_label',
      source: this.basemapExtraSourceId,
      type: 'symbol',
      layout: {
        'text-field': '{name_en}',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-size': {
          stops: [
            [4, 9],
            [6, 12]
          ]
        }
      },
      paint: {
        'text-color': '#a6a6a6',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(0, 0, 0, 0.6)'
      }
    });

    this.showSatelliteMapBoxLayer();
  }

  private showSatelliteRoadsLayer(): void {
    this.map.addSource(this.basemapExtraSourceId, {
      type: 'vector',
      url: 'mapbox://mapbox.mapbox-streets-v8'
    });

    this.map.addLayer({
      id: this.basemapExtraLayerId,
      'source-layer': 'road',
      source: this.basemapExtraSourceId,
      type: 'line',
      paint: { 'line-color': '#ffffff' }
    });

    this.showSatelliteMapBoxLayer();
  }

  private showOSMLayer(): void {
    this.map.addSource(this.basemapSourceId, {
      type: 'raster',
      tiles: [
        'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
        'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
        'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
      ],
      tileSize: 256
    });
    this.map.addLayer({
      id: this.basemapLayerId,
      type: 'raster',
      source: this.basemapSourceId
    });
    this.reOrderLayers();
  }

  private showGoogleSatelliteLayer(): void {
    this.map.addSource(this.basemapSourceId, {
      type: 'raster',
      tiles: ['https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}']
    });
    this.map.addLayer({
      id: this.basemapLayerId,
      type: 'raster',
      source: this.basemapSourceId
    });
    this.reOrderLayers();
  }

  updateBasemapLayer(): void {
    if (!this.map) {
      return;
    }

    this.removeBasemapLayer();

    switch (this.userInfo?.BaseMap) {
      case LayerName.OSM:
        this.showOSMLayer();
        break;
      case LayerName.GOOGLE_SATELLITE:
        this.showGoogleSatelliteLayer();
        break;
      case LayerName.SATELLITE:
        this.showSatelliteMapBoxLayer();
        break;
      case LayerName.SATELLITE_PLACES:
        this.showSatellitePlacesLayer();
        break;
      case LayerName.SATELLITE_ROADS:
        this.showSatelliteRoadsLayer();
        break;
    }
  }

  private async addAnalyticLayer(layerName: LayerName): Promise<void> {
    if (this.selectedSurveys.length > 0) {
      switch (layerName) {
        case LayerName.SUGAR_CONTENT_YIELD: {
          const analyticData = this.filteredAnalyticData.find((analytic: AnalyticData) => {
            return analytic.SurveyID === this.selectedSurveys[0].id;
          });
          this.$emit('analyticData', analyticData);
          break;
        }
        case LayerName.SUGAR_CONTENT_PREDICTION:
          await API.getAnalyticDataForParcel(
            this.selectedParcel.id,
            AnalyticType.SUGAR_CONTENT_PREDICTION,
            isAllowToLoadUnpublishedAnalytics(this.userInfo, this.selectedCountry)
          ).then((data: AnalyticData[]) => {
            this.$emit('analyticData', data);
          });
          break;
      }
      await new Promise<void>((resolve) => {
        if (this.printing) {
          //map's idle event is not fired always, perhaps, when the layer data is loaded from cache.
          let resolved = false;
          let timer = setTimeout(() => {
            resolved = true;
            timer = null;
            resolve();
          }, 5000);

          this.map.once('idle', () => {
            if (timer) {
              clearTimeout(timer);
            }
            if (!resolved) resolve();
          });
        } else {
          resolve();
        }
      });
    }
  }

  async updateAnalyticLayer(keepAddedAnalyticLayers = false): Promise<void> {
    if (!this.map) {
      return;
    }

    const keepIds = keepAddedAnalyticLayers
      ? [
          ...this.selectedSurveys.map((survey: Survey) => survey.id),
          ...this.selectedParcels.map((parcel: Parcel) => parcel.id)
        ]
      : [];
    this.removeAnalyticLayer(keepIds);
    this.updateParcelTiles();

    const analyticGroups = this.selectedLayers.filter((layer) => layer.groupName === LayerGroupName.ANALYTIC);
    if (analyticGroups && analyticGroups.length) {
      await Promise.all(
        analyticGroups
          .filter((analyticGroup) =>
            isAnalyticLayerAllowedForProduct(this.availableLayerGroups, analyticGroup.name, this.selectedProductType)
          )
          .map((analyticGroup) => this.addAnalyticLayer(analyticGroup.name))
      );
      if (this.printing) {
        //All layers loaded, can print now.
        await this.printSelectedParcel();
      }
    }
    this.map.resize();
  }

  private updateSelectedParcelVegetativeIndexLayer(): void {
    if (this.map.getLayer('parcel-pixel-plot')) {
      this.map.removeLayer('parcel-pixel-plot');
      this.map.removeSource('parcel-pixel-plot');
    }
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    const key = fieldInfoGroup.name.toLowerCase();
    if (this.$store.state.parcelPixelSummary?.features?.length > 0) {
      this.map.addLayer({
        id: 'parcel-pixel-plot',
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: this.$store.state.parcelPixelSummary?.features?.map((item) => {
              return {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [item['geometry']['coordinates'][0]]
                },
                properties: {
                  color: item['properties']['colors'][key]
                }
              };
            })
          }
        },
        paint: {
          'fill-color': ['get', 'color'], // blue color fill
          'fill-opacity': 0.5
        }
      });
    }
  }

  private updateSelectedParcelVegetativeIndexLayerDateChange(): void {
    if (this.map.getLayer('parcel-pixel-plot')) {
      this.map.removeLayer('parcel-pixel-plot');
      this.map.removeSource('parcel-pixel-plot');
    }
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    const key = fieldInfoGroup.name.toLowerCase();
    this.$store.dispatch('setIsGlobalLoaderVisible', true);
    API.getParcelPixelColor(
      this.$store.state.selectedUnit.id,
      this.$store.state.analytic.fromTo.from,
      this.$store.state.analytic.selectedParcel.Name
    )
      .then((result) => {
        this.$store.dispatch('setParcelPixelSummary', result || []);
        if (this.$store.state.parcelPixelSummary?.features?.length > 0) {
          this.map.addLayer({
            id: 'parcel-pixel-plot',
            type: 'fill',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: this.$store.state.parcelPixelSummary?.features?.map((item) => {
                  return {
                    type: 'Feature',
                    geometry: {
                      type: 'Polygon',
                      coordinates: [item['geometry']['coordinates'][0]]
                    },
                    properties: {
                      color: item['properties']['colors'][key]
                    }
                  };
                })
              }
            },
            paint: {
              'fill-color': ['get', 'color'],
              'fill-opacity': 0.5
            }
          });
        } else {
          message.error('Parcel Pixel Data Not Found');
        }
      })
      .finally(() => {
        this.$store.dispatch('setIsGlobalLoaderVisible', false);
      });
  }

  private getAnalyticColorForBucket(value, bucket, isGrayScale) {
    if (isGrayScale) {
      const light = parseInt('cc', 16);
      const dark = parseInt('66', 16);
      const keys = Object.keys(bucket);
      if (keys.length > 1) {
        const length = keys.length - 1;
        const step = (light - dark) / length;
        const greyBucket = {};
        keys.forEach((key, index) => {
          const n = Math.round(light - step * index).toString(16);
          greyBucket[`#${n}${n}${n}`] = bucket[key];
        });
        return getColor(value, greyBucket);
      }
      return '#cccccc';
    }
    return getColor(value, bucket);
  }

  private isGrayScaled(analyticData: AnalyticData, parcel: Parcel) {
    if (this.selectedFarm && parcel?.FarmID !== this.selectedFarm.id) {
      return true;
    }
    // grayed in case it doesn't pass map filters
    return !analyticMatchFilters(analyticData, this.mapParcelFilters, this.fromTo, this.parcels);
  }

  private getAnalyticColor(analyticData: AnalyticData) {
    const parcel = this.parcels[analyticData.ParcelID] as Parcel;
    const isGrayScale = this.isGrayScaled(analyticData, parcel);
    if (this.selectedProductType === ProductType.SUGAR_CONTENT_PREDICTION) {
      if (this.applyFilterGroundMeasurements && parcel && parcel.GroundTruth !== 1) {
        return defaultColor;
      }
      return this.getAnalyticColorForBucket(analyticData.Value, sugarContentPredictionBucket, isGrayScale);
    }
    if (this.selectedProductType === ProductType.SUGAR_CONTENT_YIELD) {
      if (this.applyFilterGroundMeasurements && parcel && parcel.GroundTruth !== 1) {
        return defaultColor;
      }
      return this.getAnalyticColorForBucket(analyticData.Value, sugarContentYieldBucket, isGrayScale);
    }
    return defaultColor;
  }

  private getSelectedParcelCondition() {
    const matchByIdRules = [];
    const ids = new Set();
    this.selectedParcels.forEach((selectedParcel: Parcel) => {
      ids.add(selectedParcel.id);
    });
    [...ids].forEach((id) => {
      matchByIdRules.push(id, 'rgba(0, 0, 0, 0)');
    });
    return matchByIdRules;
  }

  get getMinValue(): string {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    let filteredValues = [];
    const key = fieldInfoGroup.name.toLowerCase();

    for (let i = 0; i < this.$store.state.vegetativeIndexSummary.data.length; i++) {
      if (this.$store.state.vegetativeIndexSummary.data[i].index_averages[key] !== null) {
        filteredValues.push(this.$store.state.vegetativeIndexSummary.data[i].index_averages[key]);
      }
    }

    return this.$store.state.vegetativeIndexSummary.data?.reduce((min, p) => {
      const currentValue = p.index_averages[key]?.toFixed(2);
      return currentValue !== null && currentValue < min ? currentValue : min;
    }, filteredValues?.[0]);
  }

  get getMaxValue(): string {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    const key = fieldInfoGroup.name.toLowerCase();

    return this.$store.state.vegetativeIndexSummary.data?.reduce((max, p) => {
      const currentValue = p.index_averages[key];
      return currentValue !== null && currentValue > max ? currentValue : max;
    }, this.$store.state.vegetativeIndexSummary.data[0].index_averages[key]);
  }

  private normalize(ndvi, key, id): any {
    const startDate = new Date(this.$store.state.parcels[this.getParcelID(id)]?.Planted).getTime();
    const endDate = new Date(this.$store.state.analytic.fromTo.from).getTime();
    const timeDifference = endDate - startDate;
    const timeDifferenceinDays = timeDifference / (1000 * 60 * 60 * 24);
    const preRoundAge = timeDifferenceinDays / 30;
    let age;
    if (preRoundAge - Math.floor(preRoundAge) === 0.5) {
      age = Math.floor(preRoundAge);
    } else {
      age = Math.round(preRoundAge);
    }
    if (age) {
      const minValue = this.$store.state.minMaxCropMonitoring.data[key][age + '.0'].min?.toFixed(2);
      const maxValue = this.$store.state.minMaxCropMonitoring.data[key][age + '.0'].max?.toFixed(2);
      const value = (ndvi - minValue) / (maxValue - minValue);
      return value.toFixed(2);
    }
  }

  private parcelNormalize(ndvi, key): any {
    if (ndvi === null) {
      return '#ffffff';
    } else {
      const startDate = new Date(this.$store.state.analytic.selectedParcel?.Planted).getTime();
      const endDate = new Date(this.$store.state.analytic.fromTo.from).getTime();
      const timeDifference = endDate - startDate;
      const timeDifferenceinDays = timeDifference / (1000 * 60 * 60 * 24);
      const preRoundAge = timeDifferenceinDays / 30;
      let age;
      if (preRoundAge - Math.floor(preRoundAge) === 0.5) {
        age = Math.floor(preRoundAge);
      } else {
        age = Math.round(preRoundAge);
      }
      if (age >= 0) {
        const minValue = this.$store.state.minMaxCropMonitoring.data[key][age + '.0'].min?.toFixed(2);
        const maxValue = this.$store.state.minMaxCropMonitoring.data[key][age + '.0'].max?.toFixed(2);
        const value = (ndvi - minValue) / (maxValue - minValue);
        return this.generateColor(value.toFixed(2));
      }
    }
  }

  private generateColor(vegetativeIndex): any {
    if (vegetativeIndex !== 'NaN') {
      if (vegetativeIndex < 0.4) {
        return '#FF0000';
      } else if (0.4 <= vegetativeIndex && vegetativeIndex < 0.6) {
        return '#FFFF00';
      } else if (0.6 <= vegetativeIndex && vegetativeIndex < 0.8) {
        return '#50C878';
      } else if (0.8 <= vegetativeIndex && vegetativeIndex < 1) {
        return '#097969';
      } else {
        return '#097969';
      }
    } else {
      return '';
    }
  }

  private getParcelID(parcelName): any {
    const parcels = Object.values(this.$store.state.parcels) as Parcel[];
    const parcel = parcels.find((p: Parcel) => p.Name?.trim() === parcelName?.trim());
    return parcel.id;
  }

  private getHarvestStatus(id): any {
    const parcel = this.$store.state.parcels[id];
    if (parcel?.HarvestDates?.length > 0) {
      const selectedDate = this.$store.state.analytic.fromTo.from;
      const harvestDate = parcel.HarvestDates[0];

      if (harvestDate <= selectedDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private generateResultArray(): any {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    const key = fieldInfoGroup.name.toLowerCase();
    const normalizeArray = this.$store.state.vegetativeIndexSummary?.data
      .map((x) => {
        if (x.parcel_id.trim() !== '') {
          return {
            value: x.index_averages[key]?.toFixed(2),
            id: this.getParcelID(x.parcel_id),
            normalizeValue: this.normalize(x.index_averages[key]?.toFixed(2), key, x.parcel_id)
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);
    const colorArray = normalizeArray.map((x) => ({
      value: x.value,
      id: x.id,
      color: this.getHarvestStatus(x.id) ? '#ffffff' : this.generateColor(x.normalizeValue)
    }));

    return colorArray;
  }

  private getParcelsStylingRulesForRadar(defaultFillColor: string) {
    if (this.$store.state.analytic.selectedProductType === 'field_info') {
      const caseRules = [];
      const fieldInfoGroup = this.selectedLayers.find((layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX);
      switch (fieldInfoGroup.name) {
        case LayerName.STATUS: {
          if (constants.indiaCountryIds.includes(this.selectedCountry.id)) {
            const rules = [];
            Object.keys(constants.statusColorMap).forEach((value) => {
              rules.push(value, constants.statusColorMap[value]);
            });
            return ['match', ['get', 'status'], ...rules, defaultFillColor];
          }
          const month = this.selectedUnit && this.selectedUnit.LLLat < 0 ? 11 : 5;
          const ts = new Date(stringToDate(this.fromTo.to).getFullYear() - 2, month, 1).getTime();
          return [
            'case',
            ['>=', ['get', 'plantedTs'], ts],
            constants.statusColorMap.Fresh,
            ['<', ['get', 'plantedTs'], ts],
            constants.statusColorMap.Ratoon,
            defaultFillColor
          ];
        }
        case LayerName.VARIETY: {
          const rules = [];
          Object.keys(constants.varietiesColorMap).forEach((value) => {
            rules.push(value, constants.varietiesColorMap[value]);
          });
          return ['match', ['get', 'variety'], ...rules, defaultFillColor];
        }
        case LayerName.PLANTING_DATE: {
          Object.keys(constants.plantingDateColorMap).forEach((index) => {
            const month = parseInt(index);
            caseRules.push(
              ['!=', ['index-of', `-${month < 9 ? '0' : ''}${month + 1}-`, ['get', 'planted']], -1],
              constants.plantingDateColorMap[index]
            );
          });
          return ['case', ...caseRules, defaultFillColor];
        }
        case LayerName.NUMBER_OF_CYCLES: {
          const to = moment(this.fromTo.to, constants.DATE_FORMAT);
          const curYear = to.year();
          for (let cycle = 0; cycle <= 6; cycle++) {
            const ts = new Date(curYear - cycle - 1, 11, 1).getTime();
            caseRules.push(['>=', ['get', 'plantedTs'], ts], constants.numberOfCyclesColorMap[cycle]);
          }
          caseRules.push(['>=', ['get', 'plantedTs'], 28857600000], constants.numberOfCyclesColorMap['7']);
          return ['case', ...caseRules, defaultFillColor];
        }
        case LayerName.CROP_STAGE: {
          let to = moment(this.fromTo.to, constants.DATE_FORMAT);
          const now = moment();
          if (to.isAfter(now)) {
            to = now;
          }
          for (let stage = 0; stage <= 12; stage++) {
            const dateTs = moment(to).subtract(stage, 'month').unix() * 1000;
            caseRules.push(
              ['>=', ['number', ['get', 'harvestedTs'], ['get', 'plantedTs']], dateTs],
              constants.cropStageColorMap[stage]
            );
          }
          caseRules.push(
            ['>=', ['number', ['get', 'harvestedTs'], ['get', 'plantedTs']], 28857600000],
            constants.cropStageColorMap[12]
          );
          return ['case', ...caseRules, defaultFillColor];
        }
        case LayerName.HARVESTED: {
          return [
            'match',
            ['to-string', ['get', 'harvested']],
            'true',
            constants.harvestedColor,
            ['match', ['to-string', ['get', 'partial']], 'true', constants.partialHarvestColor, defaultFillColor]
          ];
        }
        case LayerName.FARM: {
          const rules = [];
          constants.farmsColorMap.forEach((item) => {
            rules.push(item.id, item.color);
          });
          return ['match', ['get', 'farmId'], ...rules, defaultFillColor];
        }
      }
    } else if (this.$store.state.analytic.selectedProductType === 'vegetative-index') {
      if (this.$store.state.vegetativeIndexSummary) {
        let filteredArray = this.$store.state.vegetativeIndexSummary;
        const fieldInfoGroup = this.selectedLayers.find((layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX);
        switch (fieldInfoGroup.name) {
          case LayerName.NDVI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['ndvi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.EVI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['evi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.VARI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['vari']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.RECI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['reci']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.MSAVI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['msavi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.GNDVI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['gndvi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.NDWI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['ndwi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.SAVI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['savi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.OSAVI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['osavi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.ARVI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['arvi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.SIPI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['sipi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.GCI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['gci']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          case LayerName.NDMI: {
            const rules = [];
            filteredArray.forEach((item) => {
              rules.push(item.id, item.colors['ndmi']);
            });
            return ['match', ['get', 'id'], ...rules, defaultFillColor];
          }
          default:
            return '';
        }
      }
    }
    return [];
  }

  private isAllowShowAnalytic(): boolean {
    const analyticGroup = this.availableLayerGroups.find(
      (layerGroup: LayerGroup) =>
        layerGroup.allowedProductTypes.includes(this.selectedProductType) && layerGroup.name === LayerGroupName.ANALYTIC
    ) as LayerGroup;
    const layers = analyticGroup && analyticGroup.overviewLayers ? analyticGroup.overviewLayers : [];

    return this.selectedLayers.some(
      (layer) => layer.groupName === LayerGroupName.ANALYTIC && layers.includes(layer.name)
    );
  }

  private getParcelsStylingRulesForAnalytic(): string[] {
    if (!this.isAllowShowAnalytic()) {
      return [];
    }

    const sugarContentLayer = this.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.ANALYTIC && layer.name === LayerName.SUGAR_CONTENT_PREDICTION
    );
    if (this.selectedProductType === ProductType.SUGAR_CONTENT_PREDICTION && sugarContentLayer) {
      return this.getHarvestedSugarContentRules();
    }

    const sugarContentYieldLayer = this.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.ANALYTIC && layer.name === LayerName.SUGAR_CONTENT_YIELD
    );
    if (this.selectedProductType === ProductType.SUGAR_CONTENT_YIELD && sugarContentYieldLayer) {
      return this.getHarvestedSugarContentRules();
    }

    // use default coloring
    const matchByIdRules = [];
    this.filteredAnalyticData.forEach((analyticData: AnalyticData) => {
      if (analyticData.Value !== constants.CLOUDY_ANALYTIC_VALUE) {
        matchByIdRules.push(analyticData.ParcelID, this.getAnalyticColor(analyticData));
      }
    });
    if (this.selectedFarm) {
      for (let parcelId in this.parcels) {
        if (this.parcels[parcelId].FarmID !== this.selectedFarm.id) {
          matchByIdRules.push(parcelId, '#a3a3a3');
        }
      }
    }
    return matchByIdRules;
  }

  private getHarvestedSugarContentRules(): string[] {
    const analyticsMap = transformFilteredAnalyticsToMap(this.filteredAnalyticData);
    const rules = [];
    const momentTo = stringToMomentDate(this.fromTo.to);
    this.filteredParcelIds.forEach((parcelId: string) => {
      const parcel = this.parcels[parcelId] as Parcel;
      if (
        parcel &&
        parcel.HarvestDates &&
        parcel.HarvestDates.length &&
        momentTo.isSameOrAfter(stringToMomentDate(parcel.HarvestDates[0]))
      ) {
        rules.push(parcel.id, constants.harvestedColor);
      } else if (analyticsMap[parcelId]) {
        rules.push(parcelId, this.getAnalyticColor(analyticsMap[parcelId]));
      }
    });
    return rules;
  }

  private getParcelsStylingRules() {
    const selectedParcelMatchByIdRules = this.getSelectedParcelCondition();
    const analyticMatchByIdRules = this.getParcelsStylingRulesForAnalytic();
    const radarRules = this.getParcelsStylingRulesForRadar(defaultColor);

    let expression = radarRules.length ? radarRules : defaultColor;
    if (analyticMatchByIdRules.length) {
      expression = ['match', ['get', 'id'], ...analyticMatchByIdRules, expression];
    }
    if (selectedParcelMatchByIdRules.length) {
      expression = ['match', ['get', 'id'], ...selectedParcelMatchByIdRules, expression];
    }

    return expression;
  }

  private updateParcelsPartialHarvested(): void {
    if (![ProductType.SUGAR_CONTENT_PREDICTION, ProductType.SUGAR_CONTENT_YIELD].includes(this.selectedProductType)) {
      return;
    }

    this.map.setFilter(
      this.parcelsPartialHarvestedLayerId,
      ['match', ['to-string', ['get', 'partial']], 'true', true, false],
      {
        validate: false
      }
    );
  }

  private updateParcelTiles() {
    if (!this.selectedUnit) {
      return;
    }

    if (this.map.getLayer(this.parcelsLayerId)) {
      this.map.setPaintProperty(this.parcelsLayerId, 'fill-color', this.getParcelsStylingRules());
    }

    const hasCloudsLayer = !!this.map.getLayer(this.parcelsCloudLayerId);
    const hasWarningsLayer = !!this.map.getLayer(this.parcelsWarningLayerId);

    if (hasCloudsLayer || hasWarningsLayer) {
      const cloudParcelIds = [];
      const warningParcelIds = [];
      this.filteredAnalyticData.forEach((analyticData: AnalyticData) => {
        if (analyticData.Value === constants.CLOUDY_ANALYTIC_VALUE) {
          cloudParcelIds.push(analyticData.ParcelID);
        }
        if (analyticData.Value === constants.NO_CLUSTERING_ANALYTIC_VALUE) {
          warningParcelIds.push(analyticData.ParcelID);
        }
      });

      if (hasCloudsLayer) {
        const cloudParcelIdsRule = ['in', ['get', 'id'], ['literal', cloudParcelIds]];
        this.map.setFilter(this.parcelsCloudLayerId, cloudParcelIdsRule, {
          validate: false
        });
      }

      if (hasWarningsLayer) {
        const warningParcelIdsRule = ['in', ['get', 'id'], ['literal', warningParcelIds]];
        this.map.setFilter(this.parcelsWarningLayerId, warningParcelIdsRule, {
          validate: false
        });
      }
    }

    this.updateParcelsPartialHarvested();

    if (this.map.getLayer(this.parcelsHighlightedLayerId)) {
      const ids = this.selectedParcels.map((selectedParcel: Parcel) => selectedParcel.id);

      let lineColorExpression = defaultSelectedParcelColor as any;
      this.map.setPaintProperty(this.parcelsHighlightedLayerId, 'line-color', lineColorExpression);
      this.map.setFilter(this.parcelsHighlightedLayerId, ['in', ['get', 'id'], ['literal', ids]], { validate: false });
    }
  }

  reDrawParcelTiles() {
    if (!this.map) {
      return;
    }

    if (this.map.getLayer(this.parcelNamesLayerId)) {
      this.map.removeLayer(this.parcelNamesLayerId);
    }
    if (this.map.getLayer(this.parcelsLayerId)) {
      this.map.removeLayer(this.parcelsLayerId);
    }
    if (this.map.getLayer(this.parcelsCloudLayerId)) {
      this.map.removeLayer(this.parcelsCloudLayerId);
    }
    if (this.map.getLayer(this.parcelsWarningLayerId)) {
      this.map.removeLayer(this.parcelsWarningLayerId);
    }
    if (this.map.getLayer(this.parcelsPartialHarvestedLayerId)) {
      this.map.removeLayer(this.parcelsPartialHarvestedLayerId);
    }
    if (this.map.getLayer(this.parcelsHighlightedLayerId)) {
      this.map.removeLayer(this.parcelsHighlightedLayerId);
    }
    if (this.map.getLayer(this.parcelsBorderLayerId)) {
      this.map.removeLayer(this.parcelsBorderLayerId);
    }
    if (this.map.getSource(this.parcelsSourceId)) {
      this.map.removeSource(this.parcelsSourceId);
    }

    if (
      !this.selectedUnit ||
      !(this.selectedUnit.LLLong && this.selectedUnit.LLLat && this.selectedUnit.URLong && this.selectedUnit.URLat)
    ) {
      return;
    }

    this.map.addSource(this.parcelsSourceId, {
      type: 'vector',
      tiles: [
        `${process.env.VUE_APP_VECTOR_TILES_SERVER}/tile/${this.selectedUnit.id}/${this.fromTo.to}/{z}/{x}/{y}?v=${this.selectedUnit.LastUpdate}&ndvi`
      ],
      bounds: [this.selectedUnit.LLLong, this.selectedUnit.LLLat, this.selectedUnit.URLong, this.selectedUnit.URLat]
    });

    this.map.addLayer({
      id: this.parcelsLayerId,
      source: this.parcelsSourceId,
      'source-layer': 'geojsonLayer',
      type: 'fill',
      paint: {
        'fill-color': defaultColor
      }
    });

    this.map.addLayer({
      id: this.parcelsCloudLayerId,
      source: this.parcelsSourceId,
      'source-layer': 'geojsonLayer',
      type: 'fill',
      paint: {
        'fill-pattern': 'cloud'
      },
      filter: ['in', ['get', 'id'], ['literal', []]]
    });

    this.map.addLayer({
      id: this.parcelsWarningLayerId,
      source: this.parcelsSourceId,
      'source-layer': 'geojsonLayer',
      type: 'fill',
      paint: {
        'fill-pattern': 'warning'
      },
      filter: ['in', ['get', 'id'], ['literal', []]]
    });

    this.map.addLayer({
      id: this.parcelsPartialHarvestedLayerId,
      source: this.parcelsSourceId,
      'source-layer': 'geojsonLayer',
      type: 'fill',
      paint: {
        'fill-pattern': 'line'
      },
      filter: ['in', ['get', 'id'], ['literal', []]]
    });

    this.map.addLayer({
      id: this.parcelsBorderLayerId,
      source: this.parcelsSourceId,
      'source-layer': 'geojsonLayer',
      type: 'line',
      paint: {
        'line-color': 'lightgray',
        'line-width': 1
      }
    });

    this.map.addLayer({
      id: this.parcelsHighlightedLayerId,
      type: 'line',
      source: this.parcelsSourceId,
      'source-layer': 'geojsonLayer',
      paint: {
        'line-color': defaultSelectedParcelColor,
        'line-width': 5
      },
      filter: ['in', ['get', 'id'], ['literal', []]]
    });

    this.map.addLayer({
      id: this.parcelNamesLayerId,
      type: 'symbol',
      source: this.parcelsSourceId,
      'source-layer': 'nameLayer',
      minzoom: 13,
      layout: {
        'text-field': '{title}',
        'text-justify': 'center',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
    this.reOrderLayers();
  }

  private removeAnalyticLayer(keepIds: string[]) {
    this.$emit('analyticData', null);
    this.$emit('groundTruth', null);
  }

  private removeBasemapLayer() {
    if (this.map.getLayer(this.basemapLayerId)) {
      this.map.removeLayer(this.basemapLayerId);
    }
    if (this.map.getSource(this.basemapSourceId)) {
      this.map.removeSource(this.basemapSourceId);
    }
    if (this.map.getLayer(this.basemapExtraLayerId)) {
      this.map.removeLayer(this.basemapExtraLayerId);
    }
    if (this.map.getSource(this.basemapExtraSourceId)) {
      this.map.removeSource(this.basemapExtraSourceId);
    }
  }

  private getOrderLayersList(): AnyLayer[] {
    const parcelsLayer = this.map.getLayer(this.parcelsLayerId);
    const parcelsCloudLayer = this.map.getLayer(this.parcelsCloudLayerId);
    const parcelsWarningLayer = this.map.getLayer(this.parcelsWarningLayerId);
    const parcelsPartialHarvestedLayer = this.map.getLayer(this.parcelsPartialHarvestedLayerId);
    const parcelsBorderLayer = this.map.getLayer(this.parcelsBorderLayerId);
    const parcelsHighlightedLayer = this.map.getLayer(this.parcelsHighlightedLayerId);
    const backgroundLayer = this.map.getLayer(this.basemapLayerId);
    const backgroundExtraLayer = this.map.getLayer(this.basemapExtraLayerId);
    const parcelNamesLayer = this.map.getLayer(this.parcelNamesLayerId);
    // layers ordering from top to bottom
    const layersOrderingList = [
      parcelNamesLayer,
      parcelsHighlightedLayer,
      parcelsBorderLayer,
      parcelsPartialHarvestedLayer,
      parcelsWarningLayer,
      parcelsCloudLayer,
      parcelsLayer,
      backgroundExtraLayer,
      backgroundLayer
    ];
    return layersOrderingList.filter((layer) => !!layer);
  }

  private reOrderLayers(): void {
    const availableLayers = this.getOrderLayersList();
    if (availableLayers.length > 1) {
      for (let i = availableLayers.length - 1; i > -1; i--) {
        this.map.moveLayer(availableLayers[i].id);
      }
    }
  }

  @Watch('print')
  async printReport() {
    if (!this.print) {
      this.printing = false;
      return;
    }

    if (!this.selectedFarmForPrinting) {
      this.selectedFarmForPrinting = this.selectedUnitHierarchy.Farms.find((farm) => {
        return !!farm.Parcels.find((parcel) => parcel.id === this.selectedParcel.id);
      });
    }

    this.parcelsToPrint = this.printAllParcels
      ? this.selectedUnitHierarchy.Farms.find((x) => x.id === this.selectedFarmForPrinting.id).Parcels.filter(
          (parcel) => {
            return (
              this.filteredAnalyticData.find((analytic: AnalyticData) => analytic.ParcelID === parcel.id) !== undefined
            );
          }
        )
      : [];
    this.$emit('changePrintPages', { currentPage: 1, totalPages: this.parcelsToPrint.length + 1 });
    this.$emit('changeIsGlobalLoaderVisible', true);

    this.printing = true;

    if (this.printAllParcels) {
      this.$emit('clearSelectedParcels');
      await this.fitBoundsByBbox([
        this.selectedFarmForPrinting.LLLong,
        this.selectedFarmForPrinting.LLLat,
        this.selectedFarmForPrinting.URLong,
        this.selectedFarmForPrinting.URLat
      ]);
    } else {
      await this.fitBoundsByBbox([
        this.selectedParcel.LLLong,
        this.selectedParcel.LLLat,
        this.selectedParcel.URLong,
        this.selectedParcel.URLat
      ]);
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));

    const canvas = await html2canvas(document.querySelector('main'));
    this.pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: this.getPrintPageFormat(canvas),
      compress: true
    });
    this.currentParcelIndex = -1;
    await this.printParcels();
  }

  private getPrintPageFormat(canvas: HTMLCanvasElement): [number, number] {
    return [
      Math.ceil(canvas.width + 2 * this.PRINT_PDF_MARGIN),
      Math.ceil(canvas.height + 2 * this.PRINT_PDF_MARGIN + 150)
    ];
  }

  printHeader(pdf: jsPDF, title: string, heading: string, pageWidth: number) {
    const logo =
      `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAN4AAAA4CAYAAACSekYoAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAABh0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMS41ZEdYUgAAGOJJREFUeF7tnYlTFVe+x/0TZjJTSV7m1UxmJplX9TJT770sk0wmr95LqqZS9UaNS4zEfUncNYtR477HqCCgqFEEXCJu4AKIIgqISDQqCrKpqCA7XLjAvVx2fu98m1zse/rX93ZfMIlJW/UB7jm/c/rc7v72+Z3fOacdQEQWFhbf` +
      `M2yihYXFo4VNtLCweLSwiRYWFo8WNrG/sDXdo8KyJPr29h66cmev8ne9o1hk8fYWFj8X2MS+4GyxUWbBDtp3PoBC496gwGMvfcfLFHT8JZH237QvLYC+uRVOsJXLW1j8HGATzdLUXE35paco/soC2pzQI7aNR30ghLg5/g1KuLKQCkoTyeGqEVXx9VtY/NRgE41id5bSyauLaNOJl2kDJy6DoGzQiZco8eoSanCWi6r541lY/FRgE71ha7xHqTlBFJ0+loJErxUoiaivbDr+Cu1PH0fnc4MJx5KPb2HxU4BNlGlsrlACI4cyptKmODFei32ZFU1/ExL3Ch0Wx8Sx0Qa5XRYWjytso` +
      `puy2uvixp9CWxPf7A2SBAm3EkESLaL3Q774jV4LBJ8ArwoBvUqh8X8TvCbGgK/TlpN/F+7pKz11MoKTQWBm28k36fDFKVRamyWaxrfXwuJxQZNQZssSbl4IRZ17VxGR++aHW5l9P4aq6vOppuGWcAOLFFewrqmE7I4HYmxWRo3OCmpqrlICJU6XjZpb68jVahc0Umu7g9ranYJmau9wifxaul2eQpFnh3uIzCuxQoSiTWgbXNHyuhuiyZ7tt7B4HOj941b5OdqW+I/vxMa4kqJ3Ck8eRElZq+la0UEqrs6kanshNbmqhbAaqLOzjbq6u0RVJv51dysC2nj0Re3xfICeEkGd7afepqLK` +
      `86jN44tZWPyYUX5cvhMhBPcKe4NzuN1OuIAhcW9QeNJAOnBhPMVkzhai3E+NrkpFhN1CWF7/ifz0vM2iTvPCU4N2XC36GjVqvqCFxY8R5UdxzTe0W3ItzSPEI1xB9IzBcS/TvtSxdOP+EaVH1BMg0jPyt/WUZev0TaB4YESdG0GltquoUvMFLSx+jAzISLhJJbeqqbOrnfJLTgt383+V3oy7yc3zMoUlvkG3K1JZ8SEts2AnbfBHeKKNW0Vb80oSqKOznTJKnRR2tW8rYRoaGig7+wYdOXKEIiMjKCbmMF2/fp2QLtv+GGlpaRG/+Lz+or+OYaYe2MrINkboaz1m7b0x4PjOi7Tk/Sjav` +
      `TaJ7uSUUXOLnXKKj9KuM0OUgAp705sk6NgrlJa7SQjNcwwI4V0qjKQNscaFhzYhIJNTcowcoq1pJU008lgJPROaR58km598LyoqorCwMJo+fTq9885gGjx4kMKgQQOV30gDM2fOoK1bw+jevf6dW6yrq6OxY8dQQMBIKi72fx3r4cOHaOjQITR79iwqLS0VSbydv2RkZNB7741QjvHtt9+KJN7OCFu2bFHqWbfuC/GRt3HT1NREEyaMF/ZDPQgIeI/u3zd2LXBecX7lOlBvY2OjMOHLqdm9O4qGDRtKX3yxVnzkbcwwoPRONS0OiKBF7+2ixSMj6WBIKlUW11FXZ5cYrx2iPakjFHeOE4EZ` +
      `0ItiPNfV1SGO2/MPwrty52tD84KYntibMoKu34uhjq5uyq1toQlxpfRUcC49tSmHngrJo0tlTlTLflEZm81GERERNGTIO71CMwJst2/fTvX19aIavm4zoHd1H3/NmtUiibfzxYEDB3rrGT9+HD148EAk87ZmSU1N7a0bvy9duiSSeVtfVFZW0ogR7yp1vf9+gCFvIjk5WXn4oYyaOXNmiWy+jJrAwI2asuDw4cMimy+jprq6qvf4I0e+Rw6HQyTztkZRfnzx4X5FeG5WjttLu1Ymkr3WQS1tTVRuuyHGUUOVIAYnCqMEHf8r3a++2Ot24vf1e4e8Cg+CjUoZThV12aItTippbKPBR4rp2bA8I` +
      `bibPaIT/PuOQlSp+YIchYUFytNOvhBm+OCDyX3uWWpqanpvQoCLWltbK7J4e2+ohQfGjBlNubm5Iou3N0psbAy9++7w3nr7Krzo6P29dYHw8J0imbdVM2fObI9yYODAgXTlyhWRzZcBELb6HLvBA7e6ulqY8OXU4BwMHPjP3rIQsmxjFuVH+PJEWjTiofDAYsHyMbvpZNQlqi6rp87ODsp/cLJn9YoQECcSI4SfGUjOljpFdHA9s4tj2El0iPTQhSmU9yCB2sUY7pbNRUvSqui3W/Lo6aAesakZdbxEfBX+S6rJzMxUnrTqi2AW3HyBgYF+jzXcHDx4wOOCgn379oks3t4bsvDAuHFj6epV7zem` +
      `N9Abw71S19kX4eFBg4eLuj64fFVVVSKbL+MmLu6EptfDuVu48HORzZcByFeXcbNv316RzZdRY7fblfOoLotzUF7etzXFyo/UmGwhth53k2PpyCg6tfdbcjlbhavYJXqfm7QrebAYb/khwFjRg50bQfeqMulW2Vnanvi2R36gqHNX8j+pvC6HOsWx7C0dtCi1gp4KzdeIrZfgbNp23bfrl5OTw7osanBSAZ6InC3yNmxYL6rjj2GGiRMnaOoHGPfJtr7ghAeGDx9Od+/eFSZ8OT2+/nqfpi6Ac+Kv8CIjI9n64uPjRDZfRs2kSRPZ8ikpZ0W21j4/P1+5jnIZuOKyrR579+7RPBzxGb2gbGsG5Ud9d` +
      `RMtGxXFiu4hEfTl1AN0bHsGORpc1N7RItzGTNErTaPgOPMCdM8FKp+FGLGELObiLCquuURtHa1U5Wyn2UkVwoUsEOJ66FJy/IsY3xXVtYqvwn9JgEH0hx9+4HEC1eACLl68iNLSUhX3JTs7W+kt0tPTlaeqeywYEhJMTqfxsaQee/fuZduBY6Cnke19oSc8AFcR4zS5jB4INnE3LMAx/BUeJxwwffo0kc2XUYPvwJWHGyrbglWrVrL2O3fuENlaew4MKbg6EBBrbm4WJnw5X/T+8fX6ZEZsHBG0cuxeuhCXQw02h+IyltRcoeOXZ1NI/OsagfkiNO7vdOzyHCqtvUZdoq6ypnbads0mxnD5wqX0Ljg3` +
      `Y+N9j7XWr1/PnkDw0UdzKC8vT5jxZcHNmzdpy5bQPruXAA8B2X1RM2PGDGHGl9XDm/AAhJSSck6Y8uWBy+VSxlze6vFXeHv27NatF+lJSUnCjC/rBucNNzxXPjPzojB5aHvt2jXWYxk16n3DHsXx48c05dXExsYKM76sL3r/uHWtlD734m7KYAy4ZuJ+Som9rkRAO8U4zOGqFj3gh2LM5jsIgzWX0ecnKqtcMH5EpHLdxWp67qtbrLj0eCYkhy6Xu8RX0H45N4hgvvuudoAN1q3rn/CwGU6dOuX15sYNg0ieXM4bvoQHID5vN/iGDRvYcmpwDLPCs9lqlXA+Vx+A6/bJJx8LU768mtTUc2wdw4cP85gaW` +
      `Lp0icYGbY+Oju618Qai1lxQRo1eT2sEjw+BM2JYkXljsRDrxpmHKeXIDWp2tChjwAc1V+n0tRUUKnpArKfceAzzdC8qfyPtdNZKKqn+VllWZmvuoHWZtfQf4UJwwcZ6ODWv7b1LjrZO0XzPL6YmKCiQPXELFszvF7fRDLigiDhy7VGD+Tj0QHJ5PYwID/TcfPtFkYdlMVe2du1aw+XNCu/YMe89B8C83u3bt4U5X4canBuujoSEeJFNA+C9oD45H20vLy9TbHxx+vQpTXkZHCM396Yw5+vwhseHe/mVtDQgkhWYb8Jpxdh9dP18kRKEQcQS1DTcodziBMotSaAa++3edLurnQ7n2+l3YYVKcIQTlS+e2ZxH` +
      `Fx54n1NBJI27oRDZ7M+VCEY5ceKEpi0cuKh37xaJInw9MkaFB2D31VfbRTEagDmpzz9fwNpxoDc2IzyIWo5k6gGXXy7PgXPIfVdMEWH6AMETOQ9g/lWuSw+9wJcatAFjQLmsETQJkatOMaIyzuKREbTpo1j65nSeMv6DyNz/8DfGcV9l2ehve+7Q0370cL0E59C4E74niTF9wJ2wqKhIkc2XeZRMmzZV0x49Fi5cIIrw9cjoCY9LA3A7g4I20ty5n+ra6NVnRnj793vO23kDojbSg+CBOXnyJLYOuH9cuzE2NOrdIGIpl9cDx/IVH+DQJNRWNNCXUw+yojIDxoChnx6luznKfIfy73yJg17dXURP9kVw3/Hn8` +
      `Nt0p853j3XgQLTmZOECI9Qs23IguombFGV8gehhs5eLe+LEcU1bwGefzaVZs3j3KS/P2CQ4Jzz0AJiv4twugLGVHCp3A3cYkVd5Hg/f04zwpk6d4lEeoJ2IOMrtxefQ0FBRjK9LDcbA6rK+CA0NEcX4umSwPJCrA0vc5DScP38m1NnE29fLaJHouThBmWXN+Giqq2qku/ZW+s3W26yIzPKMEO61SmNuIk64fHONGjVKZPH2Mpj707s5ZTBnpvdURfrkydpwOkR9//59MT5J0OThuEZvRD3hIe/QoUOaPG+gTXjgYI2jLFrUY1R4OO6gQdrjolfC+HXEiIcrYtxA2Eaijgik+Ap+AJxDfB+jC93T09PYczV+/HiR` +
      `TQMmTNC6oLAvKTG2gMMNmwiy0opo6Whfc3u+WRIQQcUFVZR410lPBfk3llPzm9BcOphvbGErWL16leZEffyxsQgaMCe8YbrCw3ygfEHx+eOPPxLZPUGXUaO0K2oQDTSyjMyb8EBS0mn2hpLBNEdBQYFSri/Cw/cZPVobRMI5ci8Gx+4POR/1G+2dEB2Wy3NggbNclgNiHj16lKY82lxUdEeY0ICTJxM05xGfg4M3KflG8fjQ7Woix+651FVzn7q6uikj/iatGLeHFZRRsAD7Xl4lJRT1XXjPhuVSZLZdmb7ouF1Ftgm7qNPm3W9fvny5x0kC8+bNE1m8vYwZ4eEJzAVsMNHKrQ3FTX316sN9hFi0y13UkBDfN6Iv4` +
      `YGLFy8qN5HaRg0CCuond1+El5x8RtMeoF7iVVFRwbYHE+1GJ6d9jZnRfqO90fnzaUqPK9eB4JPbpqqq0mPtqhuMIc0E6zQJ9Qtfp4a5L5IzfhN1d3XR3dxyWvb+bmXMxgnLF/0lvGe33aHL5c3KdEXjupNU8bt5VPnnpaLJnu2XWbduneYkYQuQbKeHGeHh5MvlAULT3AVdsmSxyH5ohwgst44U9drt3pfEGREeyM/P1YzbALb8yOsP/RUe3EhMVKvLAZwDuLBqW2yzke1AXJyxZWRY5cOVB2jr5s2bhRlfVgbjUflaow48sNR2WNmjtnFjJmCnSXAEBVDN1D9Q7fTnqCl0DLUXZpKzwUWxW9OVbUOcuLzRV+E9GZxL` +
      `s5LKqcbRTi3JeWQbGEKlT8ykB7+YQXUBX4kme7ZfBmFz+QThiYXFr7ItB25GuCoy27ZtFTeS57IqvRUncG3VdgAXFHvcZNs1a9awtvHxPXNUehgVHsBSOLXAZ82aybqz/goPUUG5LWD58mUi29MWx4XoZVujW4Z69uvxoX+4jciXy3AkJiaybcZcr2yL4QT3gBw2bAhhsYBsz6FJaIsLVIRXM+2PCvUznqem6BXU3dosBFRBX354UJky4ETG4a/wng7Oob9E3KWsStHLOVvJPv8QVfxqDj34pRAd+MVMcm46I5rs2X6ZkydPak4Qnrznz/ftBUnnzp3TXKgvvtBu7Dx79ix7QfF0lW0BtqpwvSPcWJdL3/0yIzwA9wvjR` +
      `4hOznPjr/C4qCB6Er0NtGvXrmG9irS0NJGttZfBahy5LNqJhROyrR4Ya8ttwGe9bUerV6/2sHVz7hy/YFtGk9DxQPQq03tE56Z2muj9Vr1NrZeOUouzhS6dzlfWaxpxP/0R3m/DCijiRh01ujrIEZlONS+votJfznooOkHFE7OpLcv3jm3MsXA3MoIusq1RMOmMKJdcJ6J4ajuMU7AAWLZDlA0urNpWDcLW3I3obbmXWeEB7L7Hcjo53Q0nPJxLb8JDdJZ70Hhbf4pxLneNMM0i23JgxQvXTowzZVsOfB9cE3V5gDGv3uoh3FeyPTC64JtNbPpyiIfw3NTN/BM1hs+ijooiqq92UHRgCq30EXwxI7w/hOXTB6fKqUK4le35` +
      `FWQbF05l6l5Ohe2NdaKp2rbLYMDLLdHChfH3FQaHDh0UwvCsDzebPDeIpyV3Q6GX8bYcDO3ihMe5PW78EZ4vzPZ4cOu4qCDqyM/3PskMkcnlcKzLl733roATHsqePet7vSvcRm7h9ZAhQ+jGDe/vbYXrLJfDcX0tRgdsYlv6fjHGe54VH6if8wK5Tm+lrs5uqq9poi3zjisbaRf6K7ygm/Q/+4qopKGNOjs6yb7iOFU88ykrOAUxxnMduiyaqm07x5kzZzQnCMDVwqsIZHtvQBRccIJb7sQtO8KF4cZ2MhCnXBYixsuXZFvwYxAeghBqW4AHiJGlYNhNILcfZX1tdAV9ER6+i/xwxHGNLNpGm9Xl3CxcuFBk82XcsIld9eXU8` +
      `NELrOjc2MTYz75+GLXlX6D2tnbKOn+HNs89qgnAeBVecC79I/ouHcm3U4sQXEtSLlW/tUH0cp5upUzl7xdQZ5XxuTw81fT2gkEc164ZezUgNmxyq+zhpsiCUL+nRM28eZ952OmBAIgcvAF6vd4PLTz04FOmfOhh68bIgwa95Zgx2p4HgTBMO8j2avoiPAhMXc7NmTO+tylhKMFt78KD2ddrQdhE4Mo4qIiLE52a+pnPU/P+RdRZX0kdQjzpx3NovWrJGS+8m/SX8ELanmWjNszJldVT/ZTdnsETHcp+PYcc4eYDI5hslS+OG6TjDWJlZWUaFxDjudLSB7RyJb+pEhf4008/8SgDIBDZFk9So+MOPCy4jbu4EbkV9j+08BITT2qO` +
      `D4yO0wD2v8l14POKFStENl8G+Cs8vYcjgkOyrR4ZGRfYOubP1x8WADbRjXPzWFZsMrXT/0j2Ba9RS9o+ZeK9sa6Z4qO+oSWi91scEC6EV/Gd8IToQvJpWXolVTrwyvducm5Locp/+5xKn/DeyykIF7PufeO7h2U2bw5lT5IbhLXh4i1btkwRGubZEH30NumMJ568xAkXgxusT5o0ydQkq/oNZGq4V098X8KDW8YJj+s5YGs2ejx5snbHN+rx9voKf4U3f/48jzJuMJEu23oDARV5TO6rzWyim057FTWsGciKjaNWgLm/toKL1N3ZSffzK+nIlnSqKW+g6zWtND2pkq5WOEVelxBpAdn+L5jKjAgOCNHVvrmBOsr9f60e5oXwegdv4` +
      `gPukygHUGTQ+1y+rB1rcvvFcCHkiVgjTJyodZHRfvmdkj+k8C5ezGDPKdx7l8nXI4SHh2vqAZg7lW3d+CM8vFGAezhiS5HRuT832F0v14N7aMcO/U6CTVTT1VBNTXP/i2qmP8eKjaNOuKjO6CXU3S56ta6H24LQw3W3dojxYzSVPzGbFxiHEF318wups8bcCdEDbqAv8fkCvSP3+rzCwkLl5pTtsW/Ln3d04IaT60Lb8aZrtd0P5Wq2tLjY/W+w8zZlogeCXdz5w9ha7/z5I7xx4/g2+/NwxBrPYcO0XtHQoe/oTtdoEji6HPXk2DFdM7/nDax8aVz2FrVeisVGPGVvnvPAJar+z+U9K084gTGUC+onRVKnre8vEVUTExPDXmBf4OLA` +
      `rdLbg8W9/xFgjCnbGqGkpJhtp7y/7IcSHqK88nGBP++NcYPxNlen3iv5zAoPwbTBg7Xn1MzYTgZvUOPajB5ctgWaBG+0JO8k26cvKi4lJzYO+0cvUGdlEbVdL6HKXxvv5UoF5b+fR86tvudE/AVPV0ykY56PO2nAnY7fiNphj5reOA1PS4gEtmrgesq2ZsDKd7lOHEd9I+JdIrJNfwgP41t1nTiu2r3Gcjh1vht/HzQAE/sQklwnFkTLtgDCQyRRbQs3Uk94ixb1DDfU4HsdPer/y4vwtgC5ToAAmWwLNAm+6LCVkfPLwcpqFk5oMrUz/kQdt7+htrhsISiD4zlB3dubqLMP4zkzIDiCpzg2Z8KFxEXAhcdvTAhjdwB6OHUPw5GVlaWsq` +
      `VSDlRwFBcY23eqBLTaIGsp1p6SkiOwem1u3bmnyMX/pzvcHuFBov2e9cb0voMWibs+8HoyE4n2BOri61S80coN1t1rbODakj3G+1jaesLRQtjULzrdcL94Dw62D9fhghpak7VTvY64PmBPeLKr817nkCE1W1mfKx/y+wOAaF6g/3pFvYcHBJhqlq6GKHOsGKeM5TnTAqPBKfzWLbG9tFG7p4/FfYllY9AU20QxdriZqSdlN9TriMyK8yic/Jmd4OnU2Gn+dnYXF4wyb6A8dZYXkCB6t6f28CQ87Dure2UxteeWiCr5eC4ufImxiX3CdiyL7DN/Cw/IwZ0S6KMLXY2HxU4ZN7CsdxTepUfR+mPfzEN4TM6lMiM42LIzasvvvP060sHjcYBP7` +
      `i5bEMLIveJU67lymtoQcqnh+ATlD9FcTWFj8XGAT+5Ou+p4tHd2OVmUXgjvdwuLnDJtoYWHxaGETLSwsHi1sooWFxaOEBvw//7vDgimOtUkAAAAASUVORK5CYII=`;

    const bar = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAAAPCAIAAAAu+9WAAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACBSURBVEhLY4i37Epz7Et1wED2vSG2c6a7xnwJYngQwPiQCHQvgOG/MwN3+VWG2scMVdcZqm+QjCqvM0x6N12v7R2D+1WGiGvUQFcYAu9Yl436c9SfuNCoP4lGo/4kG436E4JG/YkLjfqTaDTqT7LRqD8haNSfuNCoP4lGNPKndRkACMSUsgb2EiQAAAAASUVORK5CYII=`;
    pdf.addImage(logo, 'PNG', pageWidth - 240, 10, 180, 40);
    pdf.addImage(bar, 'PNG', 20, 20, pageWidth - 280, 12);
    pdf.setFontSize(30);
    pdf.setFont('bold');
    pdf.text(title, pageWidth / 2, 60, { align: 'center' });
    pdf.setFontSize(24);
    pdf.setFont('normal');
    pdf.text(heading, pageWidth / 2, 90, { align: 'center' });
  }

  async printParcels() {
    const idx = this.currentParcelIndex;
    if (idx >= this.parcelsToPrint.length) {
      let name =
        this.selectedFarmForPrinting.Name +
        (this.selectedFarmForPrinting.Code ? ` (${this.selectedFarmForPrinting.Code})` : '');
      if (!this.printAllParcels && this.selectedParcel) {
        name = this.selectedParcel.Name;
        if (this.selectedSurvey) {
          name += `_${getLocalizedDate(this.selectedSurvey.Date)}`;
        }
      }
      this.pdf.save(`${name}_Report.pdf`);

      this.$emit('setPrint', { print: false, printAllParcels: false });
      this.selectedFarmForPrinting = null;
      document.querySelectorAll('.ant-collapse-arrow[data-html2canvas-ignore]').forEach((icon: Element) => {
        icon.removeAttribute('data-html2canvas-ignore');
      });
      this.pdf = null;
      this.currentParcelIndex = -2;
      this.parcelsToPrint = [];
      this.printing = false;
      this.$emit('changeIsGlobalLoaderVisible', false);
      return;
    }
    this.$emit('changePrintPages', { currentPage: idx + 2, totalPages: this.parcelsToPrint.length + 1 });
    if (idx >= 0) {
      this.$emit('changeSelectedParcel', { parcel: this.parcelsToPrint[idx] });
      await new Promise((resolve) => setTimeout(resolve, 2000));
    } else {
      await this.printSelectedParcel();
    }
  }

  async printSelectedParcel(): Promise<void> {
    const idx = this.currentParcelIndex;
    if (!this.pdf) {
      return;
    }
    const coordinates = this.map.getCenter();
    this.$emit('setMapCoordinates', {
      isMapCenter: true,
      lon: coordinates.lng,
      lat: coordinates.lat,
      zoom: this.map.getZoom()
    });

    document.querySelectorAll('.ant-collapse-arrow').forEach((icon: Element) => {
      icon.setAttribute('data-html2canvas-ignore', 'true');
    });

    const canvas = await html2canvas(document.querySelector('main'));
    if (idx >= 0) {
      this.pdf.addPage(this.getPrintPageFormat(canvas));
      this.pdf.setPage(idx + 2);
    }
    const canvasData = canvas.toDataURL('image/png', 1);
    this.printHeader(
      this.pdf,
      this.selectedUnitHierarchy.Name +
        ' UNIT - ' +
        this.selectedFarmForPrinting.Name +
        (this.selectedFarmForPrinting.Code ? ` (${this.selectedFarmForPrinting.Code})` : '') +
        ' FARM' +
        (this.selectedSurvey ? ' ' + getLocalizedDate(this.selectedSurvey.Date) : ''),
      this.$i18n.t(`productTypeLabel.${this.selectedProductType}`) as string,
      canvas.width + 2 * this.PRINT_PDF_MARGIN
    );
    this.pdf.addImage(
      canvasData,
      'PNG',
      this.PRINT_PDF_MARGIN,
      this.PRINT_PDF_MARGIN + 100,
      canvas.width,
      canvas.height
    );
    this.currentParcelIndex++;
    await this.printParcels();
  }
}
