
































import { Component, Vue } from 'vue-property-decorator';
import LayerSelector from '@/components/radar/LayerSelector.vue';
import MapFilters from '@/components/radar/MapFilters.vue';
import { LayerGroupName } from '@/enums/layerGroupName';
import { ProductGroupName } from '@/enums/productGroupName';

@Component({
  components: {
    LayerSelector,
    MapFilters
  }
})
export default class HeaderInfo extends Vue {
  get isFiltersAvailable(): boolean {
    return ![ProductGroupName.FIELD_INFO].includes(this.$store.state.analytic.selectedProductGroup);
  }

  layerChanged(groupName: LayerGroupName): void {
    this.$emit('layerChanged', groupName);
  }

  handleTransparencyChange(value: number): void {
    this.$store.dispatch('analytic/transparency', { value });
  }

  handleChangeNdviZonesRange(range: [number, number]): void {
    this.$store.dispatch('setNdviZonesRange', range);
  }

  handleApplyFilterGroundMeasurementsChange(isSelected: boolean): void {
    this.$store.dispatch('applyFilterGroundMeasurements', isSelected);
  }

  handleApplyParcelPixel(isSelected: boolean): void {
    this.$store.dispatch('applyParcelPixel', isSelected);
  }

  handleLayerSelect(payload): void {
    this.$store.dispatch('analytic/selectLayer', payload);
  }

  handleUnSelectLayer(payload): void {
    this.$store.dispatch('analytic/unSelectLayer', payload);
  }

  handleUnSelectAllLayers(layerGroupName: LayerGroupName): void {
    this.$store.dispatch('analytic/unSelectAllLayers', layerGroupName);
  }

  handleSetProductType(payload): void {
    this.$store.dispatch('analytic/setProductType', payload);
  }
}
