





















import Timeline from '@/components/Timeline.vue';
import { Component, Vue } from 'vue-property-decorator';
import { AnalyticType } from '@/enums/analyticType';
import { ProductType } from '@/enums/productType';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { FromTo } from '@/interfaces/fromTo';
import { Parcel } from '@/interfaces/parcel';
import { Farm } from '@/interfaces/farm';
import { UserInfo } from '@/interfaces/userInfo';
import { Country } from '@/interfaces/country';
import { isAdvancedModeEnabled } from '@/services/user';
import Constants from '@/services/constants';

@Component({
  components: {
    Timeline
  }
})
export default class Footer extends Vue {
  productType = ProductType;

  get unitSummary(): AnalyticSummaryItem[] {
    return this.$store.state.unitSummary;
  }

  get farmSummary(): AnalyticSummaryItem[] {
    return this.$store.state.farmSummary;
  }

  get parcelsSummary(): AnalyticSummaryItem[] {
    return this.$store.state.analytic.parcelsSummary;
  }

  get selectedAnalyticType(): AnalyticType {
    return this.$store.state.analytic.selectedAnalyticType;
  }

  get fromTo(): FromTo {
    return this.$store.state.analytic.fromTo;
  }

  get analyticSummary(): AnalyticSummaryItem[] {
    return this.$store.getters['analytic/analyticSummary'];
  }

  get selectedParcels(): Parcel[] {
    return this.$store.state.analytic.selectedParcels;
  }

  get selectedProductType(): ProductType {
    return this.$store.state.analytic.selectedProductType;
  }

  get selectedCountry(): Country {
    return this.$store.state.selectedCountry;
  }

  get selectedFarm(): Farm {
    return this.$store.state.selectedFarm;
  }

  get userInfo(): UserInfo {
    return this.$store.state.userInfo;
  }

  get isAllowToSelectMonth(): boolean {
    return this.selectedProductType === ProductType.FIELD_INFO;
  }

  get isAllowToUseCalendar(): boolean {
    if (!this.userInfo) {
      return false;
    }
    return (
      isAdvancedModeEnabled(this.userInfo) ||
      (this.selectedCountry && Constants.indiaCountryIds.includes(this.selectedCountry.id)) ||
      this.isAllowToSelectMonth
    );
  }

  handleTimelineFromToChange(fromTo: FromTo): void {
    this.$store.dispatch('analytic/setTimelineFromTo', fromTo);
  }

  handleFromToChange(fromTo: FromTo): void {
    this.$store.dispatch('analytic/setFromTo', fromTo);
  }
}
