





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Constants from '@/services/constants';
import { Farm } from '@/interfaces/farm';
import { Parcel } from '@/interfaces/parcel';
import { getParcelStatus, getParcelCycle, getParcelCropStageAsString } from '@/services/analyticData';
import { getLocalizedDate, stringToMomentDate } from '@/services/date';

@Component({
  filters: {
    date(value: string) {
      return getLocalizedDate(value);
    }
  }
})
export default class GeneralInfoDefault extends Vue {
  @Prop() hasAnalytic: boolean;
  @Prop() selectedFarm: Farm | null;

  get showNumberOfCycles(): boolean {
    return !Constants.indiaCountryIds.includes(this.$store.state.selectedCountry?.id);
  }

  get parcelArea(): number {
    return Math.round(this.$store.state.analytic.selectedParcel.Area / 100) / 100;
  }

  getStatus(parcel: Parcel): string {
    return getParcelStatus(
      parcel,
      this.$store.state.analytic.fromTo.to,
      this.$root.$i18n,
      this.$store.state.selectedCountry
    );
  }

  getLastCutDate(parcel: Parcel): string {
    const harvestDates = [...(parcel.HarvestDates || []), ...(parcel.PartialHarvest || [])];
    const date = this.$store.state.analytic.selectedSurvey
      ? this.$store.state.analytic.selectedSurvey.Date
      : this.$store.state.analytic.fromTo.to;
    const momentDate = stringToMomentDate(date);
    let lastCutDate = null;
    harvestDates.forEach((harvestDate) => {
      const momentHarvestDate = stringToMomentDate(harvestDate);
      if (momentHarvestDate.isSameOrBefore(momentDate) && (!lastCutDate || momentHarvestDate.isAfter(lastCutDate))) {
        lastCutDate = momentHarvestDate;
      }
    });
    return lastCutDate ? lastCutDate.format(Constants.DATE_FORMAT) : parcel.Planted;
  }

  getCycle(parcel: Parcel): number {
    return getParcelCycle(parcel, this.$store.state.analytic.fromTo.to);
  }

  getCropStage(parcel: Parcel): string {
    return getParcelCropStageAsString(parcel, this.$store.state.analytic.fromTo.to, this.$root.$i18n);
  }
}
