














































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import API from '@/services/api';
import { message } from 'ant-design-vue';
import constants from '@/services/constants';
import { UserInfo } from '@/interfaces/userInfo';
import { Role } from '@/enums/role';
import { LayerName } from '@/enums/layerName';
import { LayerGroup } from '@/interfaces/layerGroup';
import { LayerGroupName } from '@/enums/layerGroupName';
import moment from 'moment';

@Component
export default class Profile extends Vue {
  public userForm = {
    email: '',
    firstName: '',
    lastName: '',
    useAdvancedMode: false,
    baseMap: ''
  };

  public passwordForm = {
    current: '',
    new: '',
    confirm: ''
  };
  public passwordAsyncError = '';

  // @todo: wrap and reuse redundant interfaces from antd
  public passwordFormRules: Record<string, any[]> = {
    current: [{ required: true, message: this.$root.$t('typePassword').toString() }],
    new: [
      { required: true, message: this.$root.$t('typePassword').toString() },
      {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        message: this.$root.$t('passwordMustBeLong').toString()
      },
      { validator: this.validateNewPassword }
    ],
    confirm: [
      { required: true, message: this.$root.$t('typePassword').toString() },
      { min: 8, max: 30, message: this.$root.$t('passwordMustBeLong').toString() },
      { validator: this.validateConfirmPassword }
    ]
  };

  public lang = this.$root.$i18n.locale;

  public isModalVisible = false;

  get userInfo(): UserInfo {
    return this.$store.state.userInfo;
  }

  get isAdvancedAdmin(): UserInfo {
    return this.$store.state.userInfo?.Roles.includes(Role.ADVANCED_ADMIN);
  }

  get isLanguagePristine(): boolean {
    return this.$store.state.userInfo.Language === this.lang;
  }

  get isUserInfoPristine(): boolean {
    return (
      this.$store.state.userInfo.FirstName === this.userForm.firstName &&
      this.$store.state.userInfo.LastName === this.userForm.lastName &&
      this.$store.state.userInfo.UseAdvancedMode === this.userForm.useAdvancedMode &&
      this.$store.state.userInfo.BaseMap === this.userForm.baseMap
    );
  }

  get isUserFormValid(): boolean {
    return !!this.userForm.firstName && !!this.userForm.lastName;
  }

  get availableLayerGroups(): LayerGroup[] {
    return this.$store.state.availableLayerGroups;
  }

  get availableBaseMapLayers(): LayerName[] {
    return this.availableLayerGroups
      .filter((l) => l.name === LayerGroupName.BASEMAP)
      .reduce((acc, group) => {
        acc = [...group.layers];
        return acc;
      }, []);
  }

  mounted(): void {
    this.onUserInfoChange(this.userInfo);
    if (
      this.$store.state.analytic.selectedProductType !== null &&
      this.$store.state.analytic.selectedProductType !== undefined
    ) {
      this.runActivityLog();
    }
  }

  runActivityLog(): void {
    API.userActivityLog(
      this.$store.state.userInfo.Email,
      this.$store.state.analytic.selectedProductType,
      'Profile'
    ).then((result) => {
      this.$store.dispatch('setUserActivity', result);
    });
  }

  private validateConfirmPassword(rule, value: string, callback: (message?: string) => void) {
    if (value && value !== this.passwordForm.new) {
      callback(this.$root.$t('passwordMustMatch').toString());
    } else {
      callback();
    }
  }

  private validateNewPassword(rule, value: string, callback: (message?: string) => void): void {
    const form: any = this.$refs.passForm;
    if (value && this.passwordForm.confirm) {
      form.validateField('confirm', { force: true });
    }
    callback();
  }

  @Watch('userInfo')
  private onUserInfoChange(userInfo: UserInfo): void {
    this.userForm.email = userInfo ? userInfo.Email : '';
    this.userForm.firstName = userInfo ? userInfo.FirstName : '';
    this.userForm.lastName = userInfo ? userInfo.LastName : '';
    this.userForm.useAdvancedMode = userInfo ? userInfo.UseAdvancedMode : false;
    this.userForm.baseMap = userInfo ? userInfo.BaseMap : LayerName.SATELLITE_PLACES;
    this.lang = userInfo ? userInfo.Language : this.$root.$i18n.locale;
  }

  public toogleModalVisibility(isVisible: boolean): void {
    this.isModalVisible = isVisible;
  }

  public onSubmitUserInfo(): void {
    const { id, Roles } = this.$store.state.userInfo;
    const { firstName, lastName, useAdvancedMode, baseMap } = this.userForm;

    API.changeUserInfo({ id, firstName, lastName, language: this.lang, useAdvancedMode, baseMap, roles: Roles }).then(
      () => {
        this.$store.dispatch('updateUserInfo', {
          Language: this.lang,
          FirstName: firstName,
          LastName: lastName,
          UseAdvancedMode: useAdvancedMode,
          BaseMap: baseMap
        });

        localStorage.setItem(constants.LOCAL_STORAGE_KEYS.LANG, this.lang);
        this.$root.$i18n.locale = this.lang;
        moment.locale(this.$root.$i18n.locale);
        constants.DATE_FORMAT_LOCALIZED = moment.localeData().longDateFormat('L');

        message.success(this.$root.$t('successfullyChanged').toString());
      }
    );
  }

  public onSubmitPassword(): void {
    const form: any = this.$refs.passForm;
    form.validate((success) => {
      if (success) {
        this.onChangePassword();
      }
    });
  }

  public async onChangePassword(): Promise<void> {
    try {
      const isChanged = await API.changePassword(
        this.$store.state.userInfo.id,
        this.passwordForm.current,
        this.passwordForm.confirm
      );
      if (isChanged) {
        this.passwordForm.current = '';
        this.passwordForm.new = '';
        this.passwordForm.confirm = '';
        this.passwordAsyncError = '';
        message.success(this.$root.$t('successfullyChanged').toString());
        this.toogleModalVisibility(false);
      } else {
        this.passwordAsyncError = this.$root.$t('invalidPassword').toString();
      }
    } catch (e) {
      message.error(this.$root.$t('failedToChange').toString());
    }
  }
}
