



import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CountItem extends Vue {
  @Prop() count: number;
  @Prop() isSelected: boolean;

  get countView(): string {
    if (this.count) {
      if (this.count > 100) {
        return '99+';
      }
      return `${this.count}`;
    }
    return '';
  }

  get countClass(): string {
    let cls = '--one';
    if (this.countView.length === 2) {
      cls = '--two';
    }
    if (this.countView.length === 3) {
      cls = '--three';
    }
    if (this.isSelected) {
      cls += ' --selected';
    }
    return cls;
  }
}
