





import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class DateItem extends Vue {
  @Prop() date: string; // 2020-12-21

  formatDate(date: string): string {
    return moment(date).format('DD MMMM YYYY');
  }
}
