import { LayerGroup } from '@/interfaces/layerGroup';
import { SelectedLayer } from '@/interfaces/selectedLayer';
import { LayerName } from '@/enums/layerName';
import { LayerGroupName } from '@/enums/layerGroupName';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { AnalyticState } from '@/interfaces/analyticState';
import { State } from '@/interfaces/state';
import moment from 'moment';
import constants from '@/services/constants';
import { FromTo } from '@/interfaces/fromTo';
import { stringToMomentDate } from '@/services/date';
import { Parcel } from '@/interfaces/parcel';
import {
  analyticMatchFilters,
  isAllowToLoadUnpublishedAnalytics,
  isAllowToUpdateAnalyticData,
  isAllowToUpdateFullAnalyticData,
  isShowingLoaderAnalyticData
} from '@/services/analyticData';
import Constants from '@/services/constants';
import API from '@/services/api';
import { AnalyticData } from '@/interfaces/analyticData';
import { AnalyticDataType } from '@/enums/AnalyticDataType';
import { Survey } from '@/interfaces/survey';
import {
  getFilteredAnalyticData,
  getPreSelectFarm,
  getSelectedSurveys,
  getSurveyForSelectedParcelForAnalytic
} from '@/store/storeUtils';
import { AnalyticType } from '@/enums/analyticType';
import { ProductType } from '@/enums/productType';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { PageName } from '@/enums/pageName';
import { LayerProductGroup } from '@/interfaces/layerProductGroup';
import { ProductGroupName } from '@/enums/productGroupName';
import { TablePagination } from '@/interfaces/pagination';
import { GroupedAnalyticData } from '@/interfaces/tableItem';

export const state = (): AnalyticState => {
  return {
    selectedAnalyticType: AnalyticType.SUGAR_CONTENT_PREDICTION,
    selectedParcels: [],
    selectedParcel: null,
    selectedSurvey: null,
    selectedSurveys: [],
    analyticDataLoading: '',
    fullAnalyticDataLoading: '',
    analyticData: [],
    analyticFullData: [],
    filteredAnalyticData: [],
    filteredFullAnalyticData: [],
    fromTo: {
      from: moment().startOf('year').format(constants.DATE_FORMAT),
      to: moment().endOf('year').format(constants.DATE_FORMAT)
    },
    timelineFromTo: {
      from: moment().startOf('year').format(constants.DATE_FORMAT),
      to: moment().endOf('year').format(constants.DATE_FORMAT)
    },
    parcelsSummary: [],
    histogramData: null,
    selectedProductType: null,
    selectedProductGroup: null,
    selectedLayers: [
      { groupName: LayerGroupName.BASEMAP, name: LayerName.SATELLITE_PLACES },
      { groupName: LayerGroupName.FIELD_INFO, name: LayerName.BOUNDARIES },
      { groupName: LayerGroupName.VEGETATIVE_INDEX, name: LayerName.NDVI }
    ],
    transparency: 0,
    analyticTableData: [],
    totalAnalyticTableData: 0,
    tablePagination: {
      skip: 0,
      limit: 10,
      calcCount: true
    }
  };
};

export const actions: ActionTree<AnalyticState, State> = {
  setAnalyticDataLoading: ({ commit }, key: string) => {
    commit('setAnalyticDataLoading', key);
  },
  setFullAnalyticDataLoading: ({ commit }, key: string) => {
    commit('setFullAnalyticDataLoading', key);
  },
  setFromTo: ({ commit, dispatch, state, rootState }, fromTo: FromTo) => {
    if (state.fromTo.from !== fromTo.from || state.fromTo.to !== fromTo.to) {
      if (state.selectedParcels.length) {
        const momentTo = stringToMomentDate(fromTo.to);
        const selectedParcelsWithoutDeleted = state.selectedParcels.filter((parcel: Parcel) => {
          return (
            stringToMomentDate(parcel.Created).isBefore(momentTo) &&
            stringToMomentDate(parcel.Deleted).isAfter(momentTo)
          );
        });
        if (selectedParcelsWithoutDeleted.length !== state.selectedParcels.length) {
          dispatch('setMultipleSelectedParcels', { parcels: selectedParcelsWithoutDeleted });
        }
      }
      commit('setFromTo', fromTo);
    }
  },
  setTimelineFromTo: ({ commit, state }, timelineFromTo: FromTo) => {
    if (state.timelineFromTo.from !== timelineFromTo.from || state.timelineFromTo.to !== timelineFromTo.to) {
      commit('setTimelineFromTo', timelineFromTo);
    }
  },
  clearSelectedParcels: ({ commit, state }) => {
    if (state.selectedParcels.length) {
      commit('setSelectedParcel', { parcel: null, survey: null });
      commit('setSelectedParcels', []);
      commit('setSelectedSurveys', []);
    }
  },
  setMultipleSelectedParcels: ({ commit, state, rootState }, { parcels, needPreSelectFarm }) => {
    if (parcels.length === 1) {
      const survey = getSurveyForSelectedParcelForAnalytic(parcels[0], state.filteredAnalyticData);
      commit('setSelectedParcel', { parcel: parcels[0], survey: survey });
    } else {
      commit('setSelectedParcel', { parcel: null, survey: null });
    }

    const surveys = getSelectedSurveys(parcels, state.selectedSurveys, state.filteredAnalyticData);
    commit('setSelectedSurveys', surveys);
    commit('setSelectedParcels', parcels);

    if (needPreSelectFarm) {
      const farm = getPreSelectFarm(state, rootState);
      if (farm !== undefined) {
        commit('setSelectedFarm', farm);
      }
    }
  },
  setProductGroup: ({ commit, dispatch }, { productGroup, pageName }) => {
    commit('setProductGroup', productGroup);
    localStorage.setItem(constants.LOCAL_STORAGE_KEYS.SELECTED_PRODUCT, productGroup);

    const productType = constants.defaultProductGroupAnalyticLayersConfiguration[productGroup][0].name;
    dispatch('setProductType', {
      productType,
      pageName
    });
  },
  setProductType: (
    { commit, state, rootGetters, dispatch },
    { productType, pageName, preSelectedAnalyticLayerName }
  ) => {
    commit('setProductType', productType);

    if (isAllowToUpdateAnalyticData(pageName)) {
      commit(
        'setFilteredAnalyticData',
        getFilteredAnalyticData(
          state.analyticData,
          constants.productAnalyticMap[productType],
          rootGetters.filteredParcelIds
        )
      );
    }
    if (isAllowToUpdateFullAnalyticData(pageName)) {
      commit(
        'setFilteredFullAnalyticData',
        getFilteredAnalyticData(
          state.analyticFullData,
          constants.productAnalyticMap[productType],
          rootGetters.filteredParcelIds
        )
      );
    }

    if (state.selectedParcel) {
      const survey = getSurveyForSelectedParcelForAnalytic(state.selectedParcel, state.filteredAnalyticData);
      commit('setSelectedParcel', { parcel: state.selectedParcel, survey });
    }
    if (state.selectedParcels.length) {
      const surveys = getSelectedSurveys(state.selectedParcels, [], state.filteredAnalyticData);
      commit('setSelectedSurveys', surveys);
    }
    commit('setSelectedAnalyticType', { productType });

    let preSelectLayerName = constants.defaultAnalyticLayerName[productType];
    const layersList = constants.defaultProductGroupAnalyticLayersConfiguration[state.selectedProductGroup];
    if (preSelectedAnalyticLayerName) {
      const hasPreSelectedAnalyticLayerName = layersList.some((productGroup: LayerProductGroup) =>
        productGroup.layers.some((layerProduct) => layerProduct.name === preSelectedAnalyticLayerName)
      );
      if (hasPreSelectedAnalyticLayerName) {
        preSelectLayerName = preSelectedAnalyticLayerName;
      }
    }
    if (preSelectLayerName) {
      dispatch('unSelectAllLayers', LayerGroupName.ANALYTIC);
      dispatch('selectLayer', {
        layer: { groupName: LayerGroupName.ANALYTIC, name: preSelectLayerName },
        pageName
      });
    }
    dispatch('selectLayer', {
      layer: { groupName: LayerGroupName.FIELD_INFO, name: LayerName.BOUNDARIES },
      pageName
    });
  },
  setAnalyticType: ({ commit }, analyticType: AnalyticType) => {
    commit('setSelectedAnalyticType', { analyticType });
  },
  updateSelectedParcel: ({ commit }, parcel: Parcel) => {
    commit('updateSelectedParcel', parcel);
  },
  unselectParcel: ({ dispatch, state }, { parcel }) => {
    const selectedParcelIndex = state.selectedParcels.findIndex(
      (selectedParcel: Parcel) => selectedParcel.id === parcel.id
    );
    if (selectedParcelIndex !== -1) {
      dispatch('setMultipleSelectedParcels', {
        parcels: state.selectedParcels.filter((p: Parcel) => p.id !== parcel.id)
      });
    }
  },
  setSelectedParcel: ({ commit, state, rootState }, { parcel, isMultiSelectionMode, needPreSelectFarm }) => {
    const selectedParcelIndex = state.selectedParcels.findIndex(
      (selectedParcel: Parcel) => selectedParcel.id === parcel.id
    );
    if (selectedParcelIndex !== -1) {
      return;
    }
    let parcels = [parcel];
    if (isMultiSelectionMode && state.selectedParcels.length) {
      parcels = [...state.selectedParcels, parcel];
      commit('setSelectedParcel', { parcel: null, survey: null });
    } else {
      const survey = getSurveyForSelectedParcelForAnalytic(parcel, state.filteredAnalyticData);
      commit('setSelectedParcel', { parcel: parcel, survey: survey });
    }

    const surveys = getSelectedSurveys(parcels, state.selectedSurveys, state.filteredAnalyticData);
    commit('setSelectedSurveys', surveys);
    commit('setSelectedParcels', parcels);

    if (needPreSelectFarm) {
      const farm = getPreSelectFarm(state, rootState);
      if (farm !== undefined) {
        commit('setSelectedFarm', farm);
      }
    }
  },
  setAnalyticFullData: ({ commit, state, getters, rootGetters }, analyticFullData: AnalyticData[]) => {
    commit(
      'setFilteredFullAnalyticData',
      getFilteredAnalyticData(analyticFullData, state.selectedAnalyticType, rootGetters.filteredParcelIds)
    );
    commit('setAnalyticFullData', analyticFullData);
  },
  setAnalyticData: ({ commit, state, getters, rootGetters }, analyticData: AnalyticData[]) => {
    commit(
      'setFilteredAnalyticData',
      getFilteredAnalyticData(analyticData, state.selectedAnalyticType, rootGetters.filteredParcelIds)
    );
    commit('setAnalyticData', analyticData);

    if (state.selectedParcel) {
      const survey = getSurveyForSelectedParcelForAnalytic(state.selectedParcel, state.filteredAnalyticData);
      commit('setSelectedParcel', { parcel: state.selectedParcel, survey });
    }
    if (state.selectedParcels.length) {
      const surveys = getSelectedSurveys(state.selectedParcels, [], state.filteredAnalyticData);
      commit('setSelectedSurveys', surveys);
    }
  },
  updateAnalyticFullData: ({ state, dispatch, commit, rootState }, { force, pageName }) => {
    if (!rootState.selectedUnit) {
      return;
    }
    if (isAllowToUpdateFullAnalyticData(pageName)) {
      const source = Constants.productSurveySource[state.selectedProductType];
      const key = `${rootState.selectedUnit.id}_${state.fromTo.from}_${state.fromTo.to}_${source}`;
      if (state.fullAnalyticDataLoading === key || !source) {
        if (state.analyticFullData !== []) {
          dispatch('setAnalyticFullData', []);
        }
        return;
      }
      if (rootState.cacheAnalyticFullData[key] && !force) {
        if (state.analyticFullData !== rootState.cacheAnalyticFullData[key]) {
          dispatch('setAnalyticFullData', rootState.cacheAnalyticFullData[key]);
        }
        dispatch('setFullAnalyticDataLoading', '');
        return;
      }

      if (isShowingLoaderAnalyticData(pageName)) {
        dispatch('setIsGlobalLoaderVisible', true, { root: true });
      }
      dispatch('setFullAnalyticDataLoading', key);

      let excludeNDVI = false;
      if (pageName === PageName.TABLE) {
        excludeNDVI = true;
      }

      API.getAnalyticFullData(
        rootState.selectedUnit.id,
        state.fromTo.from,
        state.fromTo.to,
        source,
        isAllowToLoadUnpublishedAnalytics(rootState.userInfo, rootState.selectedCountry),
        excludeNDVI
      )
        .then((data: AnalyticData[]) => {
          if (state.fullAnalyticDataLoading === key) {
            const result = data || [];
            dispatch('setCacheAnalyticFullData', { key, data: result }, { root: true });
            if (state.analyticFullData !== result) {
              dispatch('setAnalyticFullData', result);
            }
            dispatch('setFullAnalyticDataLoading', '');
          }
        })
        .finally(() => {
          if (isShowingLoaderAnalyticData(pageName)) {
            dispatch('setIsGlobalLoaderVisible', false, { root: true });
          }
        });
    }
  },
  updateAnalyticTableData: ({ state, dispatch, commit, rootState }) => {
    if (!rootState.selectedUnit) {
      return;
    }

    const source = Constants.productSurveySource[state.selectedProductType];

    dispatch('setIsGlobalLoaderVisible', true, { root: true });

    const { filter, ...rest } = state.tablePagination;
    API.getAnalyticTableData({
      unit: rootState.selectedUnit.id,
      from: state.fromTo.from,
      to: state.fromTo.to,
      source,
      published: isAllowToLoadUnpublishedAnalytics(rootState.userInfo, rootState.selectedCountry),
      exclude_ndvi: true,
      calcCount: true,
      ...rest,
      ...filter
    })
      .then(({ data, count, ...rest }) => {
        const result = data || [];
        commit('setAnalyticTableData', result);
        if (count !== undefined && !Number.isNaN(count)) {
          commit('setTotalAnalyticTableData', count);
        }
      })
      .finally(() => {
        dispatch('setIsGlobalLoaderVisible', false, { root: true });
      });
  },
  updateAnalyticData: ({ state, dispatch, rootState }, { force, pageName }) => {
    if (!rootState.selectedUnit) {
      return;
    }
    if (isAllowToUpdateAnalyticData(pageName)) {
      const source = Constants.productSurveySource[state.selectedProductType];
      const key = `${rootState.selectedUnit.id}_${state.fromTo.from}_${state.fromTo.to}_${source}`;
      if (state.analyticDataLoading === key || !source) {
        if (state.analyticData !== []) {
          dispatch('setAnalyticData', []);
        }
        return;
      }
      if (rootState.cacheAnalyticData[key] && !force) {
        if (state.analyticData !== rootState.cacheAnalyticData[key]) {
          dispatch('setAnalyticData', rootState.cacheAnalyticData[key]);
        }
        return;
      }

      if (isShowingLoaderAnalyticData(pageName)) {
        dispatch('setIsGlobalLoaderVisible', true, { root: true });
      }
      dispatch('setAnalyticDataLoading', key);

      return API.getAnalyticData(
        rootState.selectedUnit.id,
        state.fromTo.from,
        state.fromTo.to,
        source,
        isAllowToLoadUnpublishedAnalytics(rootState.userInfo, rootState.selectedCountry)
      )
        .then((data: AnalyticData[]) => {
          const result = data || [];
          dispatch('setCacheAnalyticData', { key, data: result }, { root: true });
          if (state.analyticData !== result) {
            dispatch('setAnalyticData', result);
          }
        })
        .finally(() => {
          if (isShowingLoaderAnalyticData(pageName)) {
            dispatch('setIsGlobalLoaderVisible', false, { root: true });
          }
          dispatch('setAnalyticDataLoading', '');
        });
    }
  },
  setParcelsSummary: ({ commit }, parcelsSummary: AnalyticSummaryItem[]) => {
    commit('parcelsSummary', parcelsSummary);
  },
  updateParcelsSummary: ({ state, dispatch, rootState }) => {
    if (state.selectedParcels.length) {
      if (state.selectedAnalyticType === 'vegetative-index') {
        const tasks = state.selectedParcels.map((parcel: Parcel) => {
          return API.getVegetativeIndexDates(rootState.selectedUnit.id).then((items: AnalyticSummaryItem[]) => {
            if (items && items.length) {
              return items.map((summaryItem: AnalyticSummaryItem) => {
                return {
                  ...summaryItem,
                  ParcelId: parcel.id
                };
              });
            }
            return null;
          });
        });
        Promise.all(tasks).then((result) => {
          const analyticSummaryItems = result.flat() as AnalyticSummaryItem[];
          const data = analyticSummaryItems
            .filter((summaryItem: AnalyticSummaryItem) => !!summaryItem)
            .map((summaryItem: AnalyticSummaryItem) => {
              return {
                ...summaryItem,
                MomentDate: stringToMomentDate(summaryItem.Date)
              };
            });
          dispatch('setParcelsSummary', data);
        });
      } else {
        const currentYear = new Date().getFullYear();
        const tasks = state.selectedParcels.map((parcel: Parcel) => {
          return API.getParcelSummary(parcel.id, `${currentYear - 100}-01-01`, `${currentYear + 100}-01-01`).then(
            (items: AnalyticSummaryItem[]) => {
              if (items && items.length) {
                return items.map((summaryItem: AnalyticSummaryItem) => {
                  return {
                    ...summaryItem,
                    ParcelId: parcel.id
                  };
                });
              }
              return null;
            }
          );
        });
        Promise.all(tasks).then((result) => {
          const analyticSummaryItems = result.flat() as AnalyticSummaryItem[];
          const data = analyticSummaryItems
            .filter((summaryItem: AnalyticSummaryItem) => !!summaryItem)
            .map((summaryItem: AnalyticSummaryItem) => {
              return {
                ...summaryItem,
                MomentDate: stringToMomentDate(summaryItem.Date)
              };
            });
          dispatch('setParcelsSummary', data);
        });
      }
    } else {
      dispatch('setParcelsSummary', []);
    }
  },

  updateDefaultVegetativeIndexLayer: ({ state, commit, rootState }) => {
    const layer = Object.keys(rootState.vegetativeDisplayNames)[0].toString().toUpperCase();
    const updatedLayers = state.selectedLayers.map((obj) => {
      if (obj.groupName === 'VEGETATIVE_INDEX') {
        return { ...obj, name: layer };
      }
      return obj;
    });
    commit('setSelectedLayers', updatedLayers);
  },
  setHistogramData: ({ commit }, histogramData: any) => {
    commit('setHistogramData', histogramData);
  },
  unSelectAllLayers: ({ commit, state }, groupName: LayerGroupName) => {
    const selectedLayers = state.selectedLayers.filter((selectedLayer) => selectedLayer.groupName !== groupName);
    commit('setSelectedLayers', selectedLayers);
  },
  unSelectLayer: ({ commit, state }, layer: SelectedLayer) => {
    const selectedLayers = state.selectedLayers.filter(
      (selectedLayer) => !(selectedLayer.groupName === layer.groupName && selectedLayer.name === layer.name)
    );
    commit('setSelectedLayers', selectedLayers);
  },
  selectLayer: ({ commit, dispatch, state, rootState }, { layer, pageName }) => {
    const selectedLayers = [...state.selectedLayers];
    const selectedLayerIndex = selectedLayers.findIndex((selectedLayer) => selectedLayer.groupName === layer.groupName);

    const group = rootState.availableLayerGroups.find((layerGroup: LayerGroup) => {
      return layerGroup.name === layer.groupName && layerGroup.layers.includes(layer.name);
    });
    const isMultipleInGroup = group ? group.isMultiple : false;

    if (selectedLayerIndex !== -1 && !isMultipleInGroup) {
      selectedLayers[selectedLayerIndex] = {
        ...selectedLayers[selectedLayerIndex],
        name: layer.name
      };
    } else {
      const isAdded =
        selectedLayers.find(
          (selectedLayer) => selectedLayer.groupName === layer.groupName && selectedLayer.name === layer.name
        ) !== undefined;
      if (!isAdded) {
        selectedLayers.push(layer);
      }
    }
    commit('setSelectedLayers', selectedLayers);
  },
  cloneState: ({ commit }, stateClone: AnalyticState) => {
    commit('setSelectedLayers', [...stateClone.selectedLayers]);
    commit('setSelectedParcels', [...stateClone.selectedParcels]);
    commit('setSelectedParcel', {
      parcel: stateClone.selectedParcel,
      survey: stateClone.selectedSurvey
    });
    commit('setFromTo', { ...stateClone.fromTo });
    commit('setTimelineFromTo', { ...stateClone.timelineFromTo });
  },
  transparency: ({ commit }, { value }) => {
    commit('setTransparency', value);
  },
  setTablePagination: ({ commit }, pagination: TablePagination) => {
    commit('setTablePagination', pagination);
  }
};

export const mutations: MutationTree<AnalyticState> = {
  parcelsSummary: (state: AnalyticState, summary: AnalyticSummaryItem[]) => {
    state.parcelsSummary = summary;
  },
  setAnalyticData: (state: AnalyticState, analyticData: AnalyticData[]) => {
    state.analyticData = analyticData;
  },
  setAnalyticTableData: (state: AnalyticState, analytics: GroupedAnalyticData[]) => {
    state.analyticTableData = analytics;
  },
  setAnalyticFullData: (state: AnalyticState, analyticFullData: AnalyticData[]) => {
    state.analyticFullData = analyticFullData;
  },
  setSelectedAnalyticType: (state: AnalyticState, { productType, analyticType }) => {
    state.selectedAnalyticType = analyticType || constants.productAnalyticMap[productType];
  },
  setFromTo: (state: AnalyticState, fromTo: FromTo) => {
    state.fromTo = fromTo;
  },
  setTimelineFromTo: (state: AnalyticState, timelineFromTo: FromTo) => {
    state.timelineFromTo = timelineFromTo;
  },
  setAnalyticDataLoading: (state: AnalyticState, key: string) => {
    state.analyticDataLoading = key;
  },
  setFullAnalyticDataLoading: (state: AnalyticState, key: string) => {
    state.fullAnalyticDataLoading = key;
  },
  setFilteredAnalyticData: (state: AnalyticState, filteredAnalyticData: AnalyticData[]) => {
    state.filteredAnalyticData = filteredAnalyticData;
  },
  setFilteredFullAnalyticData: (state: AnalyticState, filteredFullAnalyticData: AnalyticData[]) => {
    state.filteredFullAnalyticData = filteredFullAnalyticData;
  },
  setSelectedParcel: (state: AnalyticState, { parcel, survey }) => {
    state.selectedParcel = parcel;
    state.selectedSurvey = survey;
  },
  setSelectedParcels: (state: AnalyticState, selectedParcels: Parcel[]) => {
    state.selectedParcels = selectedParcels;
  },
  setSelectedSurveys: (state: AnalyticState, selectedSurveys: Survey[]) => {
    state.selectedSurveys = selectedSurveys;
  },
  updateSelectedParcel: (state: AnalyticState, parcel: Parcel) => {
    if (state.selectedParcel && state.selectedParcel.id === parcel.id) {
      state.selectedParcel.NDVIValues = parcel.NDVIValues;
      state.selectedParcel.StdNDVI = parcel.StdNDVI;
    }
    if (state.selectedParcels) {
      const selected = state.selectedParcels.find((x) => x.id === parcel.id);
      if (selected) {
        selected.NDVIValues = parcel.NDVIValues;
        selected.StdNDVI = parcel.StdNDVI;
      }
    }
  },
  setHistogramData: (state: AnalyticState, histogramData: any) => {
    state.histogramData = histogramData;
  },
  setProductType: (state: AnalyticState, productType: ProductType) => {
    state.selectedProductType = productType;
  },
  setProductGroup: (state: AnalyticState, productGroup: ProductGroupName) => {
    state.selectedProductGroup = productGroup;
  },
  setSelectedLayers: (state: AnalyticState, selectedLayers: SelectedLayer[]) => {
    state.selectedLayers = selectedLayers;
  },
  setTransparency: (state: AnalyticState, transparency: number) => {
    state.transparency = transparency;
  },
  setTablePagination: (state: AnalyticState, pagination: TablePagination) => {
    state.tablePagination = pagination;
  },
  setTotalAnalyticTableData: (state: AnalyticState, total: number) => {
    state.totalAnalyticTableData = total;
  }
};

export const getters: GetterTree<AnalyticState, State> = {
  analyticSummary(state: AnalyticState, getters, rootState: State): AnalyticSummaryItem[] {
    return state.selectedParcels && state.selectedParcels.length
      ? state.parcelsSummary
      : rootState.selectedFarm
      ? rootState.farmSummary
      : rootState.unitSummary;
  },

  totalParcelArea(state: AnalyticState, getters, rootState: State): number {
    return state.filteredAnalyticData.reduce((acc, analyticData: AnalyticData) => {
      const parcel = rootState.parcels[analyticData.ParcelID];
      if (parcel && analyticMatchFilters(analyticData, rootState.mapParcelFilters, state.fromTo, rootState.parcels)) {
        return acc + parcel.Area;
      }
      return acc;
    }, 0);
  }
};

export const analytic: Module<AnalyticState, State> = { namespaced: true, state, actions, mutations, getters };
export const analyticSecondary: Module<AnalyticState, State> = { namespaced: true, state, actions, mutations, getters };
