
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Pie, mixins } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins;

@Component({
  extends: Pie,
  mixins: [reactiveProp]
})
export default class PieChart extends Vue {
  @Prop() chartData: any;
  @Prop() options: any;
  public renderChart!: (chartData: any, options: any) => void;
  public addPlugin: (plugin?: unknown) => void;

  created(): void {
    this.addPlugin(ChartDataLabels);
  }

  mounted(): void {
    this.renderChart(this.chartData, this.options);
  }
}
