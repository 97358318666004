












import { Component, Prop, Vue } from 'vue-property-decorator';
import ParcelInfo from '@/components/info/Parcel.vue';
import LegendInfo from '@/components/info/Legend.vue';
import ParcelsInfo from '@/components/info/ParcelsInfo.vue';
import TipsInfo from '@/components/TipsInfo.vue';

@Component({
  components: {
    ParcelInfo,
    LegendInfo,
    ParcelsInfo,
    TipsInfo
  }
})
export default class RightSide extends Vue {
  @Prop() analyticDataLayer;
  @Prop() groundTruth = null;

  updateAnalyticData(): void {
    this.$emit('updateAnalyticData', true);
  }
}
