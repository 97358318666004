






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LayerProductGroup } from '@/interfaces/layerProductGroup';
import LayerSelectorItem from '@/components/radar/LayerSelectorItem.vue';
import { LayerGroupName } from '@/enums/layerGroupName';
import { ProductType } from '@/enums/productType';
import { SelectedLayer } from '@/interfaces/selectedLayer';
import { Parcel } from '@/interfaces/parcel';
import { LayerName } from '@/enums/layerName';
import constants from '@/services/constants';
import { Country } from '@/interfaces/country';
import { ProductGroupName } from '@/enums/productGroupName';
import { LayerGroup } from '@/interfaces/layerGroup';
import { isAdvancedModeEnabled } from '@/services/user';

@Component({
  components: {
    LayerSelectorItem
  }
})
export default class LayerSelector extends Vue {
  @Prop() transparency: number;
  // props for LayerSelectorItem
  // TODO: do only one level of nested components with props should (smart component connected to store and dump via props)
  @Prop() selectedProductType: ProductType;
  @Prop() selectedProductGroup: ProductGroupName;
  @Prop() selectedCountry: Country;
  @Prop() selectedParcelsCount: number;
  @Prop() selectedLayers: SelectedLayer[];
  @Prop() histogramData: any;
  @Prop() ndviZonesRange: [number, number];
  @Prop() ndviRange: number;
  @Prop() selectedParcel: Parcel;
  @Prop() applyFilterGroundMeasurements: boolean;
  @Prop() isSplitMode: boolean;
  @Prop() availableLayerGroups: LayerGroup[];

  transparencyValue = 0;

  @Watch('transparency')
  transparencyChanged(): void {
    this.transparencyValue = this.transparency;
  }

  mounted(): void {
    this.transparencyValue = this.transparency;
  }

  get productLayers(): LayerProductGroup[] {
    let productLayers = constants.defaultProductGroupAnalyticLayersConfiguration[this.selectedProductGroup];
    const layerGroupName = constants.productGroupToAnalyticLayerGroup[this.selectedProductGroup];
    const layerGroup = this.availableLayerGroups.find((layerGroup: LayerGroup) => {
      return layerGroup.name === layerGroupName && layerGroup.allowedProductGroup === this.selectedProductGroup;
    });
    if (this.selectedProductGroup === 'vegetative-index') {
      const allowedIndices = Object.keys(this.$store.state.vegetativeDisplayNames);
      const filteredIndices = productLayers.filter((obj) =>
        allowedIndices.includes(obj.defaultLayer?.toString().toLowerCase())
      );
      return filteredIndices;
    }
    if (layerGroup) {
      const list = productLayers.map((config) => {
        return {
          ...config,
          layers: config.layers.filter(({ name }) => layerGroup.layers.includes(name))
        };
      });
      return list.filter((config) => config.layers.length > 0);
    }
    return productLayers;
  }

  transparencyChange(value: number): void {
    this.$emit('transparencyChange', value);
  }

  handleChangeNdviZonesRange(range: [number, number]): void {
    this.$emit('changeNdviZonesRange', range);
  }

  handleApplyFilterGroundMeasurementsChange(isSelected: boolean): void {
    this.$emit('applyFilterGroundMeasurementsChange', isSelected);
  }

  handleApplyParcelPixel(isSelected: boolean): void {
    this.$emit('applyParcelPixel', isSelected);
  }

  handleLayerSelect(
    layerGroupName: LayerGroupName,
    name: LayerName,
    isChecked: boolean,
    mutuallyExclusive?: LayerName[]
  ): void {
    if (isChecked) {
      if (mutuallyExclusive && mutuallyExclusive.length) {
        mutuallyExclusive.forEach((mutuallyExclusiveName: LayerName) => {
          if (mutuallyExclusiveName !== name) {
            this.$emit('unSelectLayer', { groupName: layerGroupName, name: mutuallyExclusiveName });
          }
        });
      }
      this.$emit('selectLayer', {
        layer: { groupName: layerGroupName, name },
        pageName: this.$router.currentRoute.name
      });
    } else {
      this.$emit('unSelectLayer', { groupName: layerGroupName, name });
    }
  }

  handleBackgroundLayerSelect(backgroundLayers: LayerName[], name: LayerName): void {
    backgroundLayers.forEach((name: LayerName) => {
      this.$emit('unSelectLayer', { groupName: LayerGroupName.BACKGROUND_INSIDE, name });
    });
    this.$emit('selectLayer', {
      layer: { groupName: LayerGroupName.BACKGROUND_INSIDE, name },
      pageName: this.$router.currentRoute.name
    });
  }

  handleProductSelect(layerGroupName: LayerGroupName, productType: ProductType, layerName: LayerName): void {
    if (this.selectedProductType !== productType) {
      this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
      const payload = { productType, pageName: this.$router.currentRoute.name, preSelectedAnalyticLayerName: null };
      if (layerGroupName === LayerGroupName.ANALYTIC && layerName) {
        payload.preSelectedAnalyticLayerName = layerName;
      }
      this.$emit('setProductType', payload);
    } else if (this.$store.state.analytic.selectedLayers.every((x) => x.name !== layerName)) {
      this.$emit('unSelectAllLayers', layerGroupName);
      this.$emit('selectLayer', {
        layer: { groupName: layerGroupName, name: layerName },
        pageName: this.$router.currentRoute.name
      });
    }
  }
}
