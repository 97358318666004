









































import { Component, Vue } from 'vue-property-decorator';
import SatDashboard from '@/components/dashboard/Sat.vue';
import { ndviBucket, sugarContentPredictionBucket, sugarContentYieldBucket } from '@/services/analyticData';
import { ProductGroupName } from '@/enums/productGroupName';
import { ProductType } from '@/enums/productType';
import { getLocalizedDate } from '@/services/date';
import API from '@/services/api';

@Component({
  components: {
    SatDashboard
  }
})
export default class Dashboard extends Vue {
  productGroupName = ProductGroupName;
  productType = ProductType;
  sugarContentPredictionBucket = sugarContentPredictionBucket;
  ndviBucket = ndviBucket;
  sugarContentYieldBucket = sugarContentYieldBucket;
  getLocalizedDate = getLocalizedDate;

  productsWithDashboard = [ProductGroupName.SUGAR_CONTENT_YIELD, ProductGroupName.SUGAR_CONTENT_PREDICTION];

  mounted(): void {
    if (
      this.$store.state.analytic.selectedProductType !== null &&
      this.$store.state.analytic.selectedProductType !== undefined
    ) {
      this.runActivityLog();
    }
    this.$watch(
      () => this.$store.state.analytic.selectedProductType,
      (newValue) => {
        if (newValue !== null && newValue !== undefined) {
          this.runActivityLog();
        }
      }
    );
  }

  runActivityLog(): void {
    API.userActivityLog(
      this.$store.state.userInfo.Email,
      this.$store.state.analytic.selectedProductType,
      'Dashboard'
    ).then((result) => {
      this.$store.dispatch('setUserActivity', result);
    });
  }
}
