






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BarChart from '@/components/charts/BarChart.vue';
import Constants from '@/services/constants';
import { getColor, sugarContentPredictionBucket } from '@/services/analyticData';
import { AnalyticData } from '@/interfaces/analyticData';
import { stringToMomentDate } from '@/services/date';

@Component({
  components: {
    BarChart
  }
})
export default class SugarContentPredictionInfo extends Vue {
  @Prop() analyticData: AnalyticData[];

  chartData = null;
  chartOptions = {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'week',
            tooltipFormat: Constants.DATE_FORMAT_LOCALIZED
          },
          offset: true,
          distribution: 'series'
        }
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 18
          }
        }
      ]
    }
  };

  @Watch('analyticData')
  private onAnalyticDataChanged() {
    if (this.analyticData && this.analyticData.length) {
      const data = [];
      const harvestDates = [...(this.$store.state.analytic.selectedParcel.HarvestDates || [])].sort();
      const firstHarvestDate = harvestDates.length ? stringToMomentDate(harvestDates[0]) : null;

      this.analyticData.forEach((analytic: AnalyticData) => {
        const date = stringToMomentDate(analytic.SurveyDate);
        if (firstHarvestDate && date.isSameOrAfter(firstHarvestDate)) {
          return;
        }
        data.push({
          x: stringToMomentDate(analytic.SurveyDate),
          y: Math.round(analytic.Value * 100) / 100,
          color: getColor(analytic.Value, sugarContentPredictionBucket)
        });
      });

      data.sort((a, b) => a.x.valueOf() - b.x.valueOf());

      this.chartData = {
        datasets: [
          {
            datalabels: {
              display: false
            },
            backgroundColor: data.map((d) => d.color),
            barPercentage: 0.5,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            data
          }
        ]
      };
    }
  }
}
