








































































































import { Component, Vue } from 'vue-property-decorator';
import { AnalyticData } from '@/interfaces/analyticData';
import {
  isValueInBucket,
  sugarContentPredictionBucket,
  sugarContentYieldBucket,
  getParcelCropStage,
  getParcelCropStageInDays,
  getCurrentHarvestDate,
  isAllowToLoadUnpublishedAnalytics,
  eradicatedColor,
  analyticMatchFilters,
  parcelMatchFilters,
  isAnalyticDataValid
} from '@/services/analyticData';
import { LayerGroupName } from '@/enums/layerGroupName';
import { LayerName } from '@/enums/layerName';
import constants from '@/services/constants';
import moment from 'moment';
import { ProductType } from '@/enums/productType';
import { Parcel } from '@/interfaces/parcel';
import Constants from '@/services/constants';
import API from '@/services/api';
import { getLocalizedDate, stringToMomentDate } from '@/services/date';
import { PageName } from '@/enums/pageName';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';

interface HarvestedInfo {
  harvested: {
    [key: string]: Parcel;
  };
  partialHarvested: {
    [key: string]: Parcel;
  };
}

@Component({
  filters: {
    round: (value) => {
      if (value !== null && value !== undefined) {
        return Math.round(value);
      }
    },
    ha: (value) => {
      if (value) {
        const result = Math.round(value / 10000);
        return `${result}ha`;
      }
    },
    date(value: string) {
      return getLocalizedDate(value);
    }
  }
})
export default class LegendInfo extends Vue {
  productType = ProductType;

  getParcelsCountInBucket(color: string): number {
    return this.bucketParcels[color] ? this.bucketParcels[color].parcels.length : 0;
  }

  get bucketParcels() {
    const analyticData = this.$store.state.analytic.filteredAnalyticData;
    const harvestedInfo = this.getHarvestedInfo();

    const bucketParcels = {};
    const colors = Object.keys(this.legend);
    analyticData.forEach((analytic: AnalyticData) => {
      const isAnalyticHarvested = !!(
        (harvestedInfo && harvestedInfo.harvested && harvestedInfo.harvested[analytic.ParcelID]) ||
        (harvestedInfo && harvestedInfo.partialHarvested && harvestedInfo.partialHarvested[analytic.ParcelID])
      );
      const matchFilters = analyticMatchFilters(
        analytic,
        this.$store.state.mapParcelFilters,
        this.$store.state.analytic.fromTo,
        this.$store.state.parcels
      );

      if (!isAnalyticHarvested && matchFilters) {
        const color = colors.find((color: string) => {
          return isValueInBucket(analytic.Value, this.legend[color]);
        });

        if (color) {
          if (!bucketParcels[color]) {
            bucketParcels[color] = {
              parcels: []
            };
          }
          bucketParcels[color].parcels.push(analytic);
        }
      }
    });

    return bucketParcels;
  }

  sorted(parcels: Parcel[]): Parcel[] {
    parcels.sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      if (a.id === b.id) {
        return 0;
      }
    });
    return parcels;
  }

  selectParcel(parcel: Parcel): void {
    this.$store.dispatch('analytic/setSelectedParcel', { parcel });
  }

  get amountOfFields(): number {
    const harvestedInfo = this.getHarvestedInfo();
    const uniqParcelIds: Set<string> = new Set();
    this.$store.state.analytic.filteredAnalyticData.forEach((analyticData: AnalyticData) => {
      const isAnalyticHarvested = !!(
        (harvestedInfo && harvestedInfo.harvested && harvestedInfo.harvested[analyticData.ParcelID]) ||
        (harvestedInfo && harvestedInfo.partialHarvested && harvestedInfo.partialHarvested[analyticData.ParcelID])
      );

      if (
        !isAnalyticHarvested &&
        isAnalyticDataValid(analyticData) &&
        analyticMatchFilters(
          analyticData,
          this.$store.state.mapParcelFilters,
          this.$store.state.analytic.fromTo,
          this.$store.state.parcels
        )
      ) {
        uniqParcelIds.add(analyticData.ParcelID);
      }
    });
    return uniqParcelIds.size;
  }

  get hasLegend(): boolean {
    return this.hasAnalyticLegend || this.isRadarLegend;
  }

  get hasAnalyticLegend(): boolean {
    return this.legend && this.$store.state.analytic.filteredAnalyticData.length;
  }

  get filteredAnalyticsMap() {
    const filteredAnalyticsMap: { [parcel: string]: AnalyticData } = {};
    this.$store.state.analytic.filteredAnalyticData.forEach((analytic: AnalyticData) => {
      filteredAnalyticsMap[analytic.ParcelID] = analytic;
    });
    return filteredAnalyticsMap;
  }

  get isBoundaries(): boolean {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.FIELD_INFO
    );
    return fieldInfoGroup.name === LayerName.BOUNDARIES;
  }
  get isAllowToPublishUnpublish(): boolean {
    return (
      this.hasAnalyticLegend &&
      this.$store.state.analytic.fromTo.from === this.$store.state.analytic.fromTo.to &&
      isAllowToLoadUnpublishedAnalytics(this.$store.state.userInfo, this.$store.state.selectedCountry)
    );
  }

  get isRadarLegend(): boolean {
    if (this.$router.currentRoute.name === PageName.RADAR) {
      if (this.$store.state.analytic.selectedProductType === ProductType.FIELD_INFO) {
        return true;
      } else if (this.$store.state.analytic.selectedProductType === ProductType.VEGETATIVE_INDEX) {
        return true;
      } else {
        return false;
      }
    }

    // TODO: rm once new radar will be in prod
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.FIELD_INFO
    );

    return [
      LayerName.STATUS,
      LayerName.VARIETY,
      LayerName.PLANTING_DATE,
      LayerName.NUMBER_OF_CYCLES,
      LayerName.FARM,
      LayerName.BOUNDARIES,
      LayerName.HARVESTED,
      LayerName.CROP_STAGE
    ].includes(fieldInfoGroup.name);
  }

  get radarTitleDate(): string {
    if (this.$store.state.analytic.fromTo.from === this.$store.state.analytic.fromTo.to) {
      return this.$root
        .$t('onDate', {
          date: getLocalizedDate(this.$store.state.analytic.fromTo.from)
        })
        .toString();
    }
    return this.$root
      .$t('fromToDates', {
        from: getLocalizedDate(this.$store.state.analytic.fromTo.from),
        to: getLocalizedDate(this.$store.state.analytic.fromTo.to)
      })
      .toString();
  }

  get headerTitleDate(): string {
    if (this.$store.state.analytic.fromTo.from === this.$store.state.analytic.fromTo.to) {
      return this.$root
        .$t('onDate', {
          date: getLocalizedDate(this.$store.state.analytic.fromTo.from)
        })
        .toString();
    }

    let minDate = null;
    let maxDate = null;
    const analyticSummaryItems = this.$store.getters['analytic/analyticSummary'].filter(
      (analyticSummaryItem: AnalyticSummaryItem) => {
        return analyticSummaryItem.Analytic === this.$store.state.analytic.selectedAnalyticType;
      }
    );
    const momentFrom = stringToMomentDate(this.$store.state.analytic.fromTo.from);
    const momentTo = stringToMomentDate(this.$store.state.analytic.fromTo.to);
    analyticSummaryItems.forEach((analyticSummaryItem: AnalyticSummaryItem) => {
      if (
        analyticSummaryItem.MomentDate.isSameOrAfter(momentFrom) &&
        analyticSummaryItem.MomentDate.isSameOrBefore(momentTo)
      ) {
        if (!minDate || analyticSummaryItem.MomentDate.isBefore(minDate)) {
          minDate = analyticSummaryItem.MomentDate;
        }
        if (!maxDate || analyticSummaryItem.MomentDate.isAfter(maxDate)) {
          maxDate = analyticSummaryItem.MomentDate;
        }
      }
    });
    if (minDate && maxDate) {
      const from = minDate.format(Constants.DATE_FORMAT);
      const to = maxDate.format(Constants.DATE_FORMAT);

      if (from === to) {
        return this.$root
          .$t('onDate', {
            date: getLocalizedDate(from)
          })
          .toString();
      }
      return this.$root
        .$t('fromToDates', {
          from: getLocalizedDate(from),
          to: getLocalizedDate(to)
        })
        .toString();
    }
    return this.$root
      .$t('fromToDates', {
        from: getLocalizedDate(this.$store.state.analytic.fromTo.from),
        to: getLocalizedDate(this.$store.state.analytic.fromTo.to)
      })
      .toString();
  }

  get additionalLegend() {
    const filterParcels = (parcelId: string) =>
      this.filteredAnalyticsMap[parcelId] &&
      analyticMatchFilters(
        this.filteredAnalyticsMap[parcelId],
        this.$store.state.mapParcelFilters,
        this.$store.state.analytic.fromTo,
        this.$store.state.parcels
      );
    let legend = null;
    const harvestedInfo = this.getHarvestedInfo();
    if (harvestedInfo) {
      legend = {};
      legend[constants.harvestedColor] = {
        label: this.$i18n.t('harvestedInLast4Months').toString(),
        value: Object.keys(harvestedInfo.harvested).length
      };
      if (harvestedInfo.partialHarvested !== null) {
        legend[constants.partialHarvestColor] = {
          label: this.$i18n.t('partialHarvested').toString(),
          value: Object.keys(harvestedInfo.partialHarvested).length
        };
      }
    }
    return legend;
  }

  private getHarvestedInfo(): HarvestedInfo {
    if (
      this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_PREDICTION ||
      this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_YIELD
    ) {
      return this.getSugarContentHarvestedInfo();
    }
    return null;
  }

  get updatedLegendName(): string {
    if (this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_PREDICTION) {
      return '14.5 % and above';
    } else if (this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_YIELD) {
      return '140 ton/ha and above';
    }
    return '';
  }

  private getSugarContentHarvestedInfo(): HarvestedInfo {
    const harvested = {};
    const momentTo = stringToMomentDate(this.$store.state.analytic.fromTo.to);
    this.$store.getters.filteredParcelIds.forEach((parcelId: string) => {
      const parcel = this.$store.state.parcels[parcelId] as Parcel;
      if (
        parcel &&
        parcel.HarvestDates &&
        parcel.HarvestDates.length &&
        parcelMatchFilters(parcel, this.$store.state.mapParcelFilters, this.$store.state.analytic.fromTo) &&
        momentTo.isSameOrAfter(stringToMomentDate(parcel.HarvestDates[0]))
      ) {
        harvested[parcel.id] = parcel;
      }
    });
    return {
      harvested,
      partialHarvested: null
    };
  }

  get legend() {
    switch (this.$store.state.analytic.selectedProductType) {
      case ProductType.SUGAR_CONTENT_PREDICTION:
        return sugarContentPredictionBucket;
      case ProductType.SUGAR_CONTENT_YIELD: {
        return sugarContentYieldBucket;
      }
      default:
        return null;
    }
  }

  get getMinValue(): string {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    let filteredValues = [];
    const key = fieldInfoGroup.name.toLowerCase();

    for (let i = 0; i < this.$store.state.vegetativeIndexSummary.data.length; i++) {
      if (this.$store.state.vegetativeIndexSummary.data[i].index_averages[key] !== null) {
        filteredValues.push(this.$store.state.vegetativeIndexSummary.data[i].index_averages[key]);
      }
    }

    return this.$store.state.vegetativeIndexSummary.data?.reduce((min, p) => {
      const currentValue = p.index_averages[key];
      return currentValue !== null && currentValue < min ? currentValue : min;
    }, filteredValues?.[0]);
  }

  get getMaxValue(): string {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    const key = fieldInfoGroup.name.toLowerCase();

    return this.$store.state.vegetativeIndexSummary.data?.reduce((max, p) => {
      const currentValue = p.index_averages[key];
      return currentValue !== null && currentValue > max ? currentValue : max;
    }, this.$store.state.vegetativeIndexSummary.data[0].index_averages[key]);
  }

  private normalize(ndvi, min, max): any {
    if (ndvi !== null) {
      const value = (ndvi - min.toFixed(1)) / (max.toFixed(1) - min.toFixed(1));
      return value.toFixed(1);
    } else {
      return null;
    }
  }

  private generateColor(vegetativeIndex, maxValue): any {
    if (vegetativeIndex !== 'NaN') {
      const green = [0, 255, 0];
      const yellow = [255, 255, 0];
      const red = [255, 0, 0];

      let color;
      if (vegetativeIndex <= 0) {
        color = red;
      } else if (vegetativeIndex >= maxValue) {
        color = green;
      } else {
        const percent = (vegetativeIndex - 0) / (maxValue - 0);
        const r = Math.round(yellow[0] + percent * (green[0] - yellow[0]));
        const g = Math.round(yellow[1] + percent * (green[1] - yellow[1]));
        const b = Math.round(yellow[2] + percent * (green[2] - yellow[2]));
        color = [r, g, b];
      }

      const hexCode =
        '#' +
        color
          .map((component) => {
            const hex = component.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
          })
          .join('');

      return hexCode;
    }
  }

  private generateResultArray(a, b): any {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    const key = fieldInfoGroup.name.toLowerCase();
    const normalizeArray = this.$store.state.vegetativeIndexSummary?.data.map((x) => ({
      value: x.index_averages[key]?.toFixed(1),
      normalizeValue: this.normalize(x.index_averages[key]?.toFixed(1), a, b)
    }));

    const colorArray = normalizeArray.map((x) => ({
      value: x.normalizeValue,
      color: this.generateColor(x.normalizeValue, b)
    }));

    const uniqueMap = {};

    const uniqueArray = colorArray.filter((obj) => {
      const id = obj.value;
      if (!uniqueMap[id] && id !== 'NaN') {
        uniqueMap[id] = true;
        return true;
      }
      return false;
    });

    return uniqueArray.sort((a, b) => a.value - b.value);
  }

  get radarLegend() {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.FIELD_INFO
    );
    switch (fieldInfoGroup.name) {
      case LayerName.STATUS:
        return {
          [this.$root.$t('fresh').toString()]: constants.statusColorMap.Fresh,
          [this.$root.$t('ratoon').toString()]: constants.statusColorMap.Ratoon
        };
      case LayerName.VARIETY:
        return constants.varietiesColorMap;
      case LayerName.NUMBER_OF_CYCLES:
        return constants.numberOfCyclesColorMap;
      case LayerName.PLANTING_DATE: {
        const map = {};
        Object.keys(constants.plantingDateColorMap).forEach((number) => {
          map[moment.months()[number]] = constants.plantingDateColorMap[number];
        });
        return map;
      }
      case LayerName.FARM: {
        const map = {};
        constants.farmsColorMap.forEach((item) => {
          map[item.name] = item.color;
        });
        return map;
      }
      case LayerName.HARVESTED:
        return {
          [this.$root.$t('harvestedToDate').toString()]: constants.harvestedColor,
          [this.$root.$t('partialHarvested').toString()]: constants.partialHarvestColor
        };
      case LayerName.BOUNDARIES:
        return {};
      case LayerName.CROP_STAGE:
        return constants.cropStageColorMap;
      default:
        return null;
    }
  }

  get vegetativeIndicesRadarLegend() {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    if (fieldInfoGroup.name !== 'BOUNDARIES') {
      const viArray = [
        {
          value: 'Poor',
          color: '#ff0000'
        },
        {
          value: 'Average',
          color: '#FFFF00'
        },
        {
          value: 'Good',
          color: '#50C878'
        },
        {
          value: 'Excellent',
          color: '#097969'
        }
      ];

      switch (fieldInfoGroup.name) {
        case LayerName.NDVI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.EVI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.VARI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.RECI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.MSAVI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.GNDVI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.NDWI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.SAVI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.OSAVI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.ARVI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.SIPI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.GCI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        case LayerName.NDMI: {
          const map = {};
          viArray.forEach((item) => {
            map[item.value] = item.color;
          });
          return map;
        }
        default:
          return null;
      }
    }
    return '';
  }

  get radarCropStageBucketParcels() {
    const bucketParcels = {};
    const from = moment(this.$store.state.analytic.fromTo.from, constants.DATE_FORMAT);
    const to = moment(this.$store.state.analytic.fromTo.to, constants.DATE_FORMAT);
    (Object.values(this.$store.state.parcels) as Parcel[]).forEach((parcel: Parcel) => {
      if (
        moment(parcel.Created, constants.DATE_FORMAT).isBefore(from) &&
        moment(parcel.Deleted, constants.DATE_FORMAT).isAfter(to)
      ) {
        const months = getParcelCropStage(parcel, this.$store.state.analytic.fromTo.to);
        if (!bucketParcels[months]) {
          bucketParcels[months] = [];
        }
        bucketParcels[months].push(parcel);
      }
    });
    return bucketParcels;
  }

  getRadarCountInBucket(bucket: number, index: number): string {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.FIELD_INFO
    );
    if (fieldInfoGroup.name === LayerName.HARVESTED) {
      const to = moment(this.$store.state.analytic.fromTo.to, constants.DATE_FORMAT);
      const startOfYear = to.clone().startOf('year');
      const area = (Object.values(this.$store.state.parcels) as Parcel[]).reduce((acc: number, parcel: Parcel) => {
        const cropStage = getParcelCropStageInDays(parcel, this.$store.state.analytic.fromTo.to);
        const currentHarvest = getCurrentHarvestDate(parcel, this.$store.state.analytic.fromTo.to);
        if (index === 1) {
          if (
            cropStage >= 0 &&
            cropStage <= 15 &&
            parcel.PartialHarvest &&
            parcel.PartialHarvest.indexOf(currentHarvest) >= 0
          ) {
            acc += parcel.Area;
          }
        } else {
          if (
            (parcel.HarvestDates && parcel.HarvestDates.indexOf(currentHarvest) >= 0) ||
            (cropStage > 15 && parcel.PartialHarvest && parcel.PartialHarvest.indexOf(currentHarvest) >= 0)
          ) {
            const momentHarvestDate = moment(currentHarvest, constants.DATE_FORMAT);
            if (momentHarvestDate.isSameOrBefore(to) && momentHarvestDate.isSameOrAfter(startOfYear))
              acc += parcel.Area;
          }
        }
        return acc;
      }, 0);
      return `${Math.round(area / 100) / 100} ha`;
    }
    if (fieldInfoGroup.name === LayerName.CROP_STAGE) {
      const bucketParcels = this.radarCropStageBucketParcels[bucket] || [];
      const area = bucketParcels.reduce((acc: number, parcel: Parcel) => acc + parcel.Area, 0);
      return `${Math.round(area / 100) / 100} ha - ${
        Math.round(((bucketParcels.length * 100) / Object.keys(this.$store.state.parcels).length) * 100) / 100
      }%`;
    }
    return null;
  }

  getFarmName(parcel: Parcel): string {
    if (parcel && this.$store.state.selectedUnitHierarchy) {
      const farm = this.$store.state.selectedUnitHierarchy.Farms.find((farm) => farm.id === parcel.FarmID);
      if (farm) {
        return farm.Name;
      }
    }
    return '';
  }

  get radarTitle(): string {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.FIELD_INFO
    );
    switch (fieldInfoGroup.name) {
      case LayerName.NUMBER_OF_CYCLES:
        return this.$root.$t('numberOfCycles').toString();
      case LayerName.PLANTING_DATE:
        return this.$root.$t('plantingDate').toString();
      case LayerName.FARM:
        return this.$root.$t('farms').toString();
      case LayerName.STATUS:
        return this.$root.$t('status').toString();
      case LayerName.VARIETY:
        return this.$root.$t('variety').toString();
      case LayerName.HARVESTED:
        return this.$root.$t('inSeasonHarvest').toString();
      case LayerName.CROP_STAGE:
        return this.$root.$t('cropStage').toString();
      case LayerName.BOUNDARIES:
        return this.$root.$t('layerLabel.BOUNDARIES').toString();
      default:
        return '';
    }
  }

  get vegetativeIndexRadarTitle(): string {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.VEGETATIVE_INDEX
    );
    switch (fieldInfoGroup.name) {
      case LayerName.NDVI:
        return this.$store.state.vegetativeDisplayNames.ndvi;
      case LayerName.EVI:
        return this.$store.state.vegetativeDisplayNames.evi;
      case LayerName.VARI:
        return this.$store.state.vegetativeDisplayNames.vari;
      case LayerName.RECI:
        return this.$store.state.vegetativeDisplayNames.reci;
      case LayerName.MSAVI:
        return this.$store.state.vegetativeDisplayNames.msavi;
      case LayerName.GNDVI:
        return this.$store.state.vegetativeDisplayNames.gndvi;
      case LayerName.NDWI:
        return this.$store.state.vegetativeDisplayNames.ndwi;
      case LayerName.SAVI:
        return this.$store.state.vegetativeDisplayNames.savi;
      case LayerName.OSAVI:
        return this.$store.state.vegetativeDisplayNames.osavi;
      case LayerName.ARVI:
        return this.$store.state.vegetativeDisplayNames.arvi;
      case LayerName.SIPI:
        return this.$store.state.vegetativeDisplayNames.sipi;
      case LayerName.GCI:
        return this.$store.state.vegetativeDisplayNames.gci;
      case LayerName.NDMI:
        return this.$store.state.vegetativeDisplayNames.ndmi;
      default:
        return '';
    }
  }

  get analyticTitle(): string {
    switch (this.$store.state.analytic.selectedProductType) {
      case ProductType.SUGAR_CONTENT_PREDICTION:
        return this.$root.$t('sugarContentPrediction').toString();
      case ProductType.SUGAR_CONTENT_YIELD:
        return this.$root.$t('sugarYield').toString();
      default:
        return '';
    }
  }

  get analyticColumnTitle(): string {
    switch (this.$store.state.analytic.selectedProductType) {
      case ProductType.SUGAR_CONTENT_YIELD:
        return this.$root.$t('interval').toString();
      default:
        return this.$root.$t('percentage').toString();
    }
  }

  getRadarColumnValue(key): string {
    const fieldInfoGroup = this.$store.state.analytic.selectedLayers.find(
      (layer) => layer.groupName === LayerGroupName.FIELD_INFO
    );
    switch (fieldInfoGroup.name) {
      case LayerName.CROP_STAGE:
        return key === '12' ? this.$root.$t('monthsOrMore').toString() : this.$root.$t('months').toString();
      default:
        return '';
    }
  }

  get analyticColumnValue(): string {
    switch (this.$store.state.analytic.selectedProductType) {
      case ProductType.SUGAR_CONTENT_YIELD:
        return this.$root.$t('tonHa').toString();
      default:
        return '%';
    }
  }

  getAnalyticColumnLabel(color: string): string {
    const first = this.legend[color][0];
    const second = this.legend[color][1];

    if (second === Infinity) {
      return `>${first}`;
    }

    if (first === 14.5 && second === 100) {
      return '14.5 % and above';
    }

    if (first === 140 && second === 1000) {
      return '140 ton/ha and above';
    }

    return `${first}-${second}`;
  }

  downloadShape(): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.download = `${this.$store.state.selectedUnitHierarchy}-Shapes.zip`;
    link.href = `${API.PRODUCT_MANAGER_URL}/unit-shape?unit=${this.$store.state.selectedUnitHierarchy.id}&date=${this.$store.state.analytic.fromTo.to}&format=shp`;
    link.click();
    link.remove();
  }

  hasUnPublishedSurveys(): boolean {
    return this.$store.state.analytic.filteredAnalyticData.some((analytic: AnalyticData) => !analytic.Published);
  }

  cropMonitoringCSV(): void {
    this.$store.dispatch('setIsGlobalLoaderVisible', true);
    API.getCropMonitoringReport(this.$store.state.selectedUnit.id, this.$store.state.analytic.fromTo.to).finally(() => {
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
    });
  }

  async publishSurveys(isUnPublish = false): Promise<void> {
    await this.$store.dispatch('setIsGlobalLoaderVisible', true);
    await API.publish(
      this.$store.state.selectedUnit.id,
      this.$store.state.analytic.fromTo.to,
      this.$store.state.analytic.selectedAnalyticType,
      isUnPublish
    );
    this.$emit('updateAnalyticData');
    await this.$store.dispatch('setIsGlobalLoaderVisible', false);
  }
}
