export enum LayerName {
  SUGAR_CONTENT_PREDICTION = 'SUGAR_CONTENT_PREDICTION',
  SUGAR_CONTENT_YIELD = 'SUGAR_CONTENT_YIELD',
  SATELLITE = 'SATELLITE',
  SATELLITE_PLACES = 'SATELLITE_PLACES',
  SATELLITE_ROADS = 'SATELLITE_ROADS',
  OSM = 'OSM',
  GOOGLE_SATELLITE = 'GOOGLE_SATELLITE',
  BOUNDARIES = 'BOUNDARIES',
  STATUS = 'STATUS',
  VARIETY = 'VARIETY',
  PLANTING_DATE = 'PLANTING_DATE',
  NUMBER_OF_CYCLES = 'NUMBER_OF_CYCLES',
  HARVESTED = 'HARVESTED',
  CROP_STAGE = 'CROP_STAGE',
  FARM = 'FARM',
  YIELD = 'YIELD',
  VEGETATIVE_INDEX = 'VEGETATIVE_INDEX',
  NDVI = 'NDVI',
  EVI = 'EVI',
  VARI = 'VARI',
  RECI = 'RECI',
  MSAVI = 'MSAVI',
  GNDVI = 'GNDVI',
  NDWI = 'NDWI',
  SAVI = 'SAVI',
  OSAVI = 'OSAVI',
  ARVI = 'ARVI',
  SIPI = 'SIPI',
  GCI = 'GCI',
  NDMI = 'NDMI'
}
