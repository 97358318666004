











import Menu from '@/components/Menu.vue';
import Sidebar from '@/components/Sidebar.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import API from '@/services/api';
import pako from 'pako';

import { FromTo } from '@/interfaces/fromTo';
import { Unit } from '@/interfaces/unit';
import { Parcel } from '@/interfaces/parcel';
import { UnitHierarchy } from '@/interfaces/unitHierarchy';
import { FarmHierarchy } from '@/interfaces/farmHierarchy';
import { VegetativeIndex } from '@/interfaces/vegetativeIndex';
import { PageName } from '@/enums/pageName';
import { isAllowToUpdateAnalyticData, isAllowToUpdateFullAnalyticData } from '@/services/analyticData';
import { Farm } from '@/interfaces/farm';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { stringToMomentDate } from '@/services/date';
import { Route } from 'vue-router';
import { checkSupported } from '@/services/unsupportedBrowser';
import { AuthorizeResponse } from '@/interfaces/auth';
import { LayerName } from '@/enums/layerName';
import { ProductType } from '@/enums/productType';
import { Role } from '@/enums/role';
import moment from 'moment';
import constants from '@/services/constants';

@Component({
  components: {
    Menu,
    Sidebar
  }
})
export default class BaseTemplate extends Vue {
  pageName = PageName;

  private refreshSessionInterval = 600000; // millisecond, 10 min

  get currentRoute(): Route {
    return this.$route;
  }

  get fromTo(): FromTo {
    return this.$store.state.analytic.fromTo;
  }

  get selectedProductType(): ProductType {
    return this.$store.state.analytic.selectedProductType;
  }

  get selectedUnit(): Unit {
    return this.$store.state.selectedUnit;
  }

  get selectedFarm(): Farm {
    return this.$store.state.selectedFarm;
  }

  get selectedParcels(): Parcel[] {
    return this.$store.state.analytic.selectedParcels;
  }

  mounted(): void {
    if (checkSupported()) {
      API.authorize().then((response: AuthorizeResponse) => {
        if (response) {
          if (response.UserInfo && this.$root.$i18n.locale !== response.UserInfo.Language) {
            this.$root.$i18n.locale = response.UserInfo.Language;
          }
          moment.locale(this.$root.$i18n.locale);
          constants.DATE_FORMAT_LOCALIZED = moment.localeData().longDateFormat('L');
          if (response.UserInfo && !response.UserInfo.BaseMap) {
            response.UserInfo.BaseMap = LayerName.SATELLITE_PLACES;
          }
          this.$store.dispatch('setUserInfo', response.UserInfo);
          API.getVegetativeIndicesName().then((result) => {
            this.$store.dispatch('setVegetativeIndexDisplayName', result);
            this.$store.dispatch('analytic/updateDefaultVegetativeIndexLayer');
          });
          API.vegetativeIndicesAccess().then((result) => {
            this.$store.dispatch('setVegetativeIndicesAccess', result);
          });
          API.getAnalyticsAccess(response.UserInfo.Email).then((result) => {
            this.$store.dispatch('setAnalyticsAccess', result);
          });
        }
      });
    }

    setInterval(() => {
      API.refreshSession();
    }, this.refreshSessionInterval);
  }

  @Watch('currentRoute')
  onCurrentRouteChanged(): void {
    this.updateAnalyticData();
  }

  @Watch('selectedParcels')
  onSelectedParcelsChanged(): void {
    this.updateParcelsSummary();
    this.updateAnalyticData();
    this.$store.dispatch('setVegetativeIndexSummary', []);
    this.updateVegetativeIndex(this.$store.state.selectedUnit);
  }

  @Watch('selectedFarm')
  onSelectedFarmChanged(): void {
    this.updateFarmSummary();
    if (isAllowToUpdateAnalyticData(this.$router.currentRoute.name as PageName)) {
      this.$store.dispatch('analytic/setAnalyticData', [...this.$store.state.analytic.analyticData]);
    }
    if (isAllowToUpdateFullAnalyticData(this.$router.currentRoute.name as PageName)) {
      this.$store.dispatch('analytic/setAnalyticFullData', [...this.$store.state.analytic.analyticFullData]);
    }
  }

  @Watch('fromTo')
  onFromToChanged(): void {
    this.updateAnalyticData();
    this.$store.dispatch('setVegetativeIndexSummary', []);
    this.updateVegetativeIndex(this.$store.state.selectedUnit);
  }

  @Watch('selectedProductType')
  onSelectedProductTypeChanged(): void {
    this.updateAnalyticData();
    this.$store.dispatch('setVegetativeIndexSummary', []);
    this.updateVegetativeIndex(this.$store.state.selectedUnit);
  }

  private updateFarmSummary(): void {
    if (this.$store.state.selectedFarm) {
      const currentYear = new Date().getFullYear();
      API.getFarmSummary(
        this.$store.state.selectedFarm.id,
        `${currentYear - 100}-01-01`,
        `${currentYear + 100}-01-01`
      ).then((analyticSummaryItems: AnalyticSummaryItem[]) => {
        const data = (analyticSummaryItems || []).map((summaryItem: AnalyticSummaryItem) => {
          return {
            ...summaryItem,
            MomentDate: stringToMomentDate(summaryItem.Date)
          };
        });
        this.$store.dispatch('setFarmSummary', data);
      });
    } else {
      this.$store.dispatch('setFarmSummary', []);
    }
  }

  private updateParcelsSummary(): void {
    this.$store.dispatch('analytic/updateParcelsSummary');
  }

  private updateAnalyticData(force = false): void {
    this.$store.dispatch('analytic/updateAnalyticData', { pageName: this.$router.currentRoute.name, force });
    this.$store.dispatch('analytic/updateAnalyticFullData', { pageName: this.$router.currentRoute.name, force });
  }

  private updateUnitHierarchy(unit: Unit): Promise<void> {
    return API.getUnitHierarchy(unit.id).then((unitHierarchy: UnitHierarchy) => {
      const parcels = [];
      if (unitHierarchy?.Farms?.length) {
        unitHierarchy.Farms.sort((a, b) => a.Name.localeCompare(b.Name));
        unitHierarchy.Farms.forEach((farm: FarmHierarchy) => {
          if (farm.Parcels && farm.Parcels.length) {
            parcels.push(...farm.Parcels);
            farm.Parcels.sort((a, b) => a.id.localeCompare(b.id));
          }
        });
      }
      this.$store.dispatch('setParcels', parcels);
      this.$store.dispatch('setSelectedUnitHierarchy', unitHierarchy);
      if (this.$store.state.preSelectedParcel) {
        const selectedParcel = this.$store.state.preSelectedParcel;
        const momentTo = stringToMomentDate(this.$store.state.analytic.fromTo.to);
        if (
          stringToMomentDate(selectedParcel.Created).isBefore(momentTo) &&
          stringToMomentDate(selectedParcel.Deleted).isAfter(momentTo)
        ) {
          this.$store.dispatch('analytic/setSelectedParcel', { parcel: selectedParcel });
        }
      }
    });
  }

  private updateUnitSummary(unit: Unit): Promise<void> {
    const currentYear = new Date().getFullYear();
    return API.getUnitSummary(unit.id, `${currentYear - 100}-01-01`, `${currentYear + 100}-01-01`).then(
      (analyticSummaryItems: AnalyticSummaryItem[]) => {
        const data = (analyticSummaryItems || []).map((summaryItem: AnalyticSummaryItem) => {
          return {
            ...summaryItem,
            MomentDate: stringToMomentDate(summaryItem.Date)
          };
        });
        this.$store.dispatch('setUnitSummary', data);
      }
    );
  }

  private vegetativeIndexDates(unit: Unit): Promise<void> {
    return API.getVegetativeIndexDates(unit.id).then((analyticSummaryItems: AnalyticSummaryItem[]) => {
      const data = (analyticSummaryItems || []).map((summaryItem: AnalyticSummaryItem) => {
        return {
          ...summaryItem,
          MomentDate: stringToMomentDate(summaryItem.Date)
        };
      });
      const dates = [...this.$store.state.unitSummary, ...data];
      this.$store.dispatch('setUnitSummary', dates);
    });
  }

  private updateVegetativeIndex(unit: Unit): Promise<void> {
    if (
      this.$store.state.unitSummary.length !== 0 &&
      this.$store.state.analytic.selectedProductType === 'vegetative-index'
    ) {
      const vegetativeIndexDates = this.$store.state.unitSummary
        .filter((analyticSummaryItem: AnalyticSummaryItem) => {
          return analyticSummaryItem.Analytic === this.$store.state.analytic.selectedAnalyticType;
        })
        .sort((a: AnalyticSummaryItem, b: AnalyticSummaryItem) => a.MomentDate.valueOf() - b.MomentDate.valueOf());
      if (
        vegetativeIndexDates?.length > 0 &&
        vegetativeIndexDates?.some((data) => data.Date === this.$store.state.analytic.fromTo.from)
      ) {
        this.$store.dispatch('setIsGlobalLoaderVisible', true);
        return API.getCropMonitoringColor(unit.id, this.$store.state.analytic.fromTo.from)
          .then((result) => {
            const url = result;

            fetch(url)
              .then((response) => {
                return response.arrayBuffer();
              })
              .then((buffer) => {
                const zipData = new Uint8Array(buffer);

                const decompressedData = pako.ungzip(zipData, { to: 'string' });

                this.$store.dispatch('setVegetativeIndexSummary', JSON.parse(decompressedData) || []);
              });
          })
          .finally(() => {
            this.$store.dispatch('setIsGlobalLoaderVisible', false);
          });
      }
    }
  }

  private cropMonitoringMinMax(unit: Unit): Promise<void> {
    const vegetativeIndexDates = this.$store.state.unitSummary
      .filter((analyticSummaryItem: AnalyticSummaryItem) => {
        return analyticSummaryItem.Analytic === this.$store.state.analytic.selectedAnalyticType;
      })
      .sort((a: AnalyticSummaryItem, b: AnalyticSummaryItem) => a.MomentDate.valueOf() - b.MomentDate.valueOf());
    if (
      this.$store.state.analytic.selectedProductType === 'vegetative-index' &&
      vegetativeIndexDates?.some((data) => data.Date === this.$store.state.analytic.fromTo.from)
    ) {
      return API.getMinMaxCropMonitoring(unit.id, this.$store.state.analytic.fromTo.from).then((result) => {
        this.$store.dispatch('setMinMaxCropMonitoring', result);
      });
    }
  }

  @Watch('selectedUnit')
  onUnitChanged(unit: Unit): void {
    this.$store.dispatch('analytic/clearSelectedParcels');
    if (unit) {
      this.$store.dispatch('setSelectedUnitHierarchy', null);
      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      Promise.all([this.updateUnitHierarchy(unit), this.updateUnitSummary(unit), this.vegetativeIndexDates(unit)])
        .then(() => {
          this.updateAnalyticData();
        })
        .finally(() => {
          this.$store.dispatch('setIsGlobalLoaderVisible', false);
        });
      API.getAnalyticsList(this.$store.state?.selectedUnit?.id, this.$store.state.userInfo.Email).then((result) => {
        this.$store.dispatch('setAnalyticsListResponse', result);
      });
    }
  }

  get isAdminMenuAvailable(): boolean {
    return this.$store.state.userInfo && this.$store.state.userInfo.Roles;
  }
}
