





















































































import { PageName } from '@/enums/pageName';
import { Component, Vue, Watch } from 'vue-property-decorator';
import API from '@/services/api';
import Auth from '@/services/auth';
import constants from '@/services/constants';
import { Role } from '@/enums/role';

@Component
export default class Menu extends Vue {
  pageName = PageName;
  query = {};
  defaultSelectedKeys = [this.pageName.RADAR];
  selectedKeys: string[] = [];

  get isAdminMenuAvailable(): boolean {
    return (
      this.$store.state.userInfo &&
      this.$store.state.userInfo.Roles &&
      this.$store.state.userInfo.Roles.includes(Role.MAHINDRA_ADMIN)
    );
  }

  get isIdTrue(): boolean {
    const selectedId = this.$store.state.selectedUnit && this.$store.state.selectedUnit.id;
    const ids = this.$store.state.analyticsAccess && this.$store.state.analyticsAccess.data;
    if (ids) {
      const analyticsKeys = Object.keys(ids);
      return analyticsKeys.includes(selectedId);
    }
    return false;
  }

  get isHelpMenuAvailable(): boolean {
    return (
      this.$store.state.selectedCountry && !constants.indiaCountryIds.includes(this.$store.state.selectedCountry.id)
    );
  }

  get isSplitScreenMode(): boolean {
    return this.$store.state.isSplitScreenMode;
  }

  isPageAlreadyActive(pageName: PageName): boolean {
    return this.$router.currentRoute.name === pageName;
  }

  get isPageDisabledOnSplitMode(): boolean {
    return this.isSplitScreenMode && this.$router.currentRoute.name === PageName.RADAR;
  }

  get isPageDisabledOnEditingMode(): boolean {
    return this.$store.state.isShapeEditingMode;
  }

  mounted(): void {
    this.query = this.$route.query;
    this.selectedKeys = [this.$route.name];
  }

  @Watch('$route')
  onRouteChange(to): void {
    this.selectedKeys = [to.name];
  }

  async logout(): Promise<void> {
    localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.SELECTED_COUNTRY_ID);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.SELECTED_ORGANIZATION_ID);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.SELECTED_UNIT_ID);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.SELECTED_FARM_ID);
    localStorage.removeItem('email');
    await API.logout();
    await Auth.doLogin();
  }

  openHelp(): void {
    if (this.$store.state.userInfo.Language === 'en' || this.$store.state.userInfo.Language === 'es') {
      window.open(
        'https://docs.google.com/document/u/1/d/e/2PACX-1vQfeeUt7HMlgH9P3RhNuedrbGJYRPGJcK7eG90M2rlk1IQjrnkM53vbpCsOhSY8rqnn8ec0D-E5S2uC/pub',
        'target',
        '_blank'
      );
    }

    if (this.$store.state.userInfo.Language === 'pt-BR') {
      window.open(
        'https://docs.google.com/document/u/1/d/e/2PACX-1vQ4y3KU3awjo_yg93Xh4qmOTB_69wkeMkcOxwlJ8EQkR7X5I0sRIavfH03ZDOdhMGh2DdbkZlMQF0Pb/pub',
        'target',
        '_blank'
      );
    }
  }
}
