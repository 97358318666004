






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LineChart from '@/components/charts/LineChart.vue';
import { getParcelCycle, getParcelStatus, parcelMatchFilters } from '@/services/analyticData';
import { DashboardAnalyticData } from '@/interfaces/dashboardAnalyticData';
import { stringToMomentDate } from '@/services/date';
import Constants from '@/services/constants';
import moment from 'moment';
import { CHART_TYPE, getColor } from '@/services/charts';

@Component({
  components: {
    LineChart
  }
})
export default class AnalyticsLineChartPerDate extends Vue {
  @Prop() analytics: DashboardAnalyticData[];
  @Prop() label: string;
  @Prop() hideNumberOfCycles: boolean;

  selectedType: string = CHART_TYPE.VARIETY;
  CHART_TYPE = CHART_TYPE;

  chartData = null;
  chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 12,
        usePointStyle: true,
        fontSize: 14,
        fontColor: '#161C26',
        fontFamily: 'Inter'
      }
    },
    tooltips: {
      enabled: true
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: '#4E596D'
          }
        }
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'month',
            tooltipFormat: Constants.DATE_FORMAT_LOCALIZED
          },
          ticks: {
            fontColor: '#4E596D'
          },
          offset: true
        }
      ]
    }
  };

  mounted(): void {
    this.setupChartData();
  }

  @Watch('analytics')
  onAnalyticDataChanged(): void {
    this.setupChartData();
  }

  setupChartData(): void {
    this.chartData = this.getChartData();
  }

  private getChartData(): any {
    const data = {};
    let countParcels;
    this.analytics.forEach((analyticData: DashboardAnalyticData) => {
      const parcel = this.$store.state.parcels[analyticData.ParcelID];
      const momentTo = stringToMomentDate(this.$store.state.analytic.fromTo.to);
      if (parcel) {
        if (
          parcel &&
          parcel.HarvestDates &&
          parcel.HarvestDates.length &&
          parcelMatchFilters(parcel, this.$store.state.mapParcelFilters, this.$store.state.analytic.fromTo) &&
          momentTo.isSameOrAfter(stringToMomentDate(parcel.HarvestDates[0]))
        ) {
          countParcels = null;
        } else {
          let fieldName = null;
          if (this.selectedType === CHART_TYPE.VARIETY) {
            fieldName = parcel.Variety;
          }
          if (this.selectedType === CHART_TYPE.CROP_SEASON && parcel.CropSeason !== undefined) {
            fieldName = parcel.CropSeason;
          }
          if (this.selectedType === CHART_TYPE.STATUS) {
            fieldName = getParcelStatus(
              parcel,
              this.$store.state.analytic.fromTo.to,
              this.$root.$i18n,
              this.$store.state.selectedCountry
            );
          }
          if (this.selectedType === CHART_TYPE.FARM_NAME && this.$store.state.selectedUnitHierarchy) {
            const farm = this.$store.state.selectedUnitHierarchy.Farms.find((farm) => farm.id === parcel.FarmID);
            if (farm) {
              fieldName = farm.Name + (farm.Code ? ` (${farm.Code})` : '');
            }
          }
          if (this.selectedType === CHART_TYPE.NUMBER_OF_CYCLES) {
            fieldName = getParcelCycle(parcel, analyticData.SurveyDate);
          }
          if (fieldName !== null) {
            const date = stringToMomentDate(analyticData.SurveyDate).unix();
            data[fieldName] = data[fieldName] || { totalArea: 0, dates: {} };
            data[fieldName].totalArea += parcel.Area;
            data[fieldName].dates[date] = data[fieldName].dates[date] || { totalValue1: 0, totalValue2: 0 };
            data[fieldName].dates[date].totalValue1 += analyticData.value1;
            data[fieldName].dates[date].totalValue2 += analyticData.value2;
          }
        }
      }
    });

    let labels = Object.keys(data)
      .sort((a: string, b: string) => (data[a].totalArea > data[b].totalArea ? -1 : 1))
      .slice(0, 20);

    if (this.selectedType === CHART_TYPE.NUMBER_OF_CYCLES) {
      labels = Object.keys(data).sort((a: string, b: string) => (parseInt(a) < parseInt(b) ? -1 : 1));
    }

    const dates = [
      ...new Set(labels.map((key) => Object.keys(data[key].dates).map((key) => parseInt(key))).flat())
    ].sort((a, b) => (a < b ? -1 : 1));

    const datasets = labels.map((key) => {
      const color = getColor(this.selectedType, key);
      const sortedDatesData = [];
      dates.forEach((date) => {
        const dateData = data[key].dates[date.toString()];
        if (dateData) {
          sortedDatesData.push({
            x: moment.unix(date),
            y: dateData.totalValue2 ? Math.round((dateData.totalValue1 / dateData.totalValue2) * 100 * 100) / 100 : 0
          });
        }
      });

      return {
        datalabels: {
          labels: {
            title: null
          }
        },
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        pointRadius: 2,
        fill: false,
        lineTension: 0,
        label: key === 'undefined' ? this.$t('undefined') : key,
        data: sortedDatesData
      };
    });

    return { datasets };
  }
}
