var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mapbox',{attrs:{"access-token":_vm.mapBoxToken,"map-options":{
    container: 'map-container-' + _vm.mapId,
    style: {
      version: 8,
      sources: {},
      layers: [],
      id: 'mahindra',
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf'
    },
    preserveDrawingBuffer: true,
    dragRotate: false,
    touchZoomRotate: false,
    boxZoom: false,
    attributionControl: !_vm.attributionPosition,
    logoPosition: _vm.logoPosition
  },"scale-control":{ show: _vm.showScaleControl },"nav-control":{ show: _vm.showNavControl, position: 'bottom-left' }},on:{"map-load":_vm.loaded}})}
var staticRenderFns = []

export { render, staticRenderFns }