


















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Organization } from '@/interfaces/organization';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { SearchResult } from '@/interfaces/searchResult';
import { Country } from '@/interfaces/country';
import API from '@/services/api';
import { Parcel } from '@/interfaces/parcel';
import { Survey } from '@/interfaces/survey';
import { RadarProductTabItem } from '@/interfaces/productTabItem';
import { PageName } from '@/enums/pageName';
import RadarProductTypeSelector from '@/components/radar/ProductTypeSelector.vue';
import { ProductType } from '@/enums/productType';
import constants from '@/services/constants';
import { LayerGroupName } from '@/enums/layerGroupName';
import { LayerName } from '@/enums/layerName';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { FromTo } from '@/interfaces/fromTo';
import ModeSelector from '@/components/radar/ModeSelector.vue';
import { ProductGroupName } from '@/enums/productGroupName';
import { Role } from '@/enums/role';

@Component({
  components: {
    RadarProductTypeSelector,
    ModeSelector
  }
})
export default class Header extends Vue {
  @Prop({ default: false }) showModeSelector: boolean;
  @Prop() isSplitScreenMode: boolean;

  onChangeMode(isSplit: boolean): void {
    this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
    this.$emit('onChangeMode', isSplit);
  }

  selectedResult: string = null;
  organizations2021 = [
    '1] DCM Shriram Ltd, Loni Mill',
    '2] DCM Shriram Ltd, Ajbapur Mill',
    '3] Loknete Sundar Raoji Solanke Sugar Mill',
    '4] Shreenath Mhaskoba Sakhar Karkhana Limited',
    '5] Chadha Sugars and Industries Private Limited (CSIPL)',
    '6] Rana Sugars Limited',
    '7] The Sanjivani (Takli) Sahakari Sakhar Karkhana Ltd',
    '8] DCM Shriram Ltd, Loni Mill'
  ];

  organizations2020 = [
    'Balrampur Chini Mills Limited, Balrampur (4)',
    'Balrampur Chini Mills Limited, Kumbhi (5)',
    'DCM Shriram Ltd (1)',
    'Daund (7)',
    'Mahindra',
    'Mahindra Mill1 - Uttar Pradesh',
    'Mahindra Mill2 - Maharashtra',
    'The Ajnala Coop. Sugar Mills Ltd (Sugarfed Punjab) (3)',
    'Rana Sugars Pvt Ltd (6)',
    'Vitthalrao Shinde Sahakari Sakhar Karkhana Ltd (2)'
  ];

  organizations2022 = [
    'AB Sugars Ltd',
    'DCM 2022',
    'EID Parry Mill 2022',
    'Kranti Agrani Mill',
    'Loknete Sundar Raoji Solanke Sugar Mill 2022',
    'Narmada Mill',
    'Natural Sugars Mill 2022',
    'Ramdev Mill',
    'Samarth Sugars',
    'Sanjeevani Mill',
    'EID 22-23 Additional GT',
    'EID Parry Mill 2022-23'
  ];

  organizations2023 = [
    'DCM 2023',
    'EID Parry Mill 2023-24',
    'Lokmangal Mill',
    'Loknete Sundar Raoji Solanke Sugar Mill',
    'Pravara Mill',
    'Rajaram Bapu Mill',
    'Sanjeevani Mill 2023',
    'Saraswati Mill',
    'Sitaram Mill',
    'Vitthalrao Shinde Mill',
    '[AGDD-DEMO] Sanjeevani Mill 2023',
    '[DEMO] Saraswati Mill Samples',
    'Venkateshkrupa Mill'
  ];

  searchResults = [];
  seasons = [
    {
      year: '2020-21',
      id: 1
    },
    {
      year: '2021-22',
      id: 2
    },
    {
      year: '2022-23',
      id: 3
    },
    {
      year: '2023-24',
      id: 4
    },
    {
      year: '2024-25',
      id: 5
    }
  ];

  selectedSeason = '2024-25';

  private productTabsRadar: RadarProductTabItem[] = constants.defaultProductTabsRadar.map(
    (productGroup: ProductGroupName) => {
      return {
        productGroup,
        disabled: false
      };
    }
  );

  mounted(): void {
    this.selectedResult = this.createSearchKey(
      localStorage.getItem(constants.LOCAL_STORAGE_KEYS.SELECTED_COUNTRY_ID),
      localStorage.getItem(constants.LOCAL_STORAGE_KEYS.SELECTED_ORGANIZATION_ID),
      localStorage.getItem(constants.LOCAL_STORAGE_KEYS.SELECTED_UNIT_ID),
      localStorage.getItem(constants.LOCAL_STORAGE_KEYS.SELECTED_FARM_ID),
      ''
    );
    if (this.$store.state.countries.length === 0) {
      API.getCountries().then((countries: Country[]) => {
        this.$store.dispatch('setCountries', countries || []);
        if (this.$route.query.surveyId) {
          this.loadBySurveyId();
        } else if (this.$route.query.parcelId) {
          this.loadByParcelId();
        } else {
          this.selectCountry();
        }
      });
    }
    this.$store.dispatch('setSelectedSeasonYear', this.selectedSeason);
  }

  get isRadarPage(): boolean {
    return this.currentPage === PageName.RADAR;
  }

  get isDashboardPage(): boolean {
    return this.currentPage === PageName.DASHBOARD;
  }

  get currentPage(): string {
    return this.$route.name;
  }

  get fromTo(): FromTo {
    return this.$store.state.analytic.fromTo;
  }

  get selectedProductType(): ProductType {
    return this.$store.state.analytic.selectedProductType;
  }

  get farmSummary(): AnalyticSummaryItem[] {
    return this.$store.state.farmSummary;
  }

  get selectedUnit(): Unit {
    return this.$store.state.selectedUnit;
  }

  get logoUrl(): string {
    if (
      this.$store.state.selectedOrganization &&
      this.$store.state.selectedOrganization.Logo &&
      this.$store.state.selectedOrganization.Logo.length > 0
    ) {
      return `url(${this.$store.state.selectedOrganization.Logo})`;
    }
    return '';
  }

  @Watch('selectedUnit')
  onChangeOfUnit(): void {
    this.getFilteredProductTabsRadar();
  }

  getFilteredProductTabsRadar(): RadarProductTabItem[] {
    const productGroupsWithoutCropStress = ['sugaryield', 'sat-sugarcontent', 'field_info'];
    const vegetativeIndexVisibility = this.$store.state.selectedUnit?.id;
    const allowedUnitsforVegetativeIndex = Object.keys(this.$store.state.vegetativeIndicesAccess);
    if (allowedUnitsforVegetativeIndex.includes(vegetativeIndexVisibility)) {
      if (this.$store.state.vegetativeIndicesAccess[vegetativeIndexVisibility] === true) {
        return this.productTabsRadar.filter((tab: RadarProductTabItem) => {
          return this.$store.state.availableProductGroups.includes(tab.productGroup);
        });
      }
    } else {
      if (this.$store.state.analytic.selectedProductType === 'vegetative-index') {
        let productGroup = 'sugaryield';
        this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
        this.$store.dispatch('analytic/setProductGroup', { productGroup, pageName: this.$router.currentRoute.name });
        return this.productTabsRadar.filter((tab: RadarProductTabItem) => {
          return productGroupsWithoutCropStress.includes(tab.productGroup);
        });
      } else {
        return this.productTabsRadar.filter((tab: RadarProductTabItem) => {
          return productGroupsWithoutCropStress.includes(tab.productGroup);
        });
      }
    }
  }

  unSelectFarm(): void {
    this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
    this.$store.dispatch('setSelectedFarm', null);
  }

  selectParcel(parcel: Parcel): void {
    this.$store.dispatch('analytic/setSelectedParcel', { parcel });
  }

  private loadBySurveyId(): void {
    API.getSurvey(this.$route.query.surveyId as string).then((survey: Survey) => {
      if (survey) {
        API.getParcel(survey.ParcelID).then((parcel: Parcel) => {
          this.$store.dispatch('setPreSelectedParcel', { parcel, survey });
          API.getFarm(parcel.FarmID).then((farm: Farm) => {
            API.getUnit(farm.UnitID).then((unit: Unit) => {
              this.selectedResult = this.createSearchKey(unit.CountryID, unit.OrganizationID, unit.id, farm.id, '');
              this.selectCountry();
            });
          });
        });
      } else {
        this.selectCountry();
      }
    });
  }

  private loadByParcelId(): void {
    API.getParcel(this.$route.query.parcelId as string).then((parcel: Parcel) => {
      if (!parcel || !parcel?.id) return;
      this.$store.dispatch('setPreSelectedParcel', { parcel });
      API.getFarm(parcel.FarmID).then((farm: Farm) => {
        API.getUnit(farm.UnitID).then((unit: Unit) => {
          this.selectedResult = this.createSearchKey(unit.CountryID, unit.OrganizationID, unit.id, farm.id, '');
          this.selectCountry();
        });
      });
    });
  }

  onSelectCountry(country: Country): void {
    this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
    this.selectCountry(country);
  }

  onSelectSeason(season): void {
    this.selectSeason(season);
    this.$store.dispatch('setSelectedSeasonYear', this.selectedSeason);
  }

  private selectSeason(season): void {
    this.selectedSeason = season;
    this.getOrganizations();
  }

  get isAdminMenuAvailable(): boolean {
    return (
      this.$store.state.userInfo &&
      this.$store.state.userInfo.Roles &&
      this.$store.state.userInfo.Roles.includes(Role.MAHINDRA_ADMIN)
    );
  }

  private selectCountry(presetCountry?: Country): void {
    if (this.$store.state.countries?.length) {
      const selectedCountry =
        presetCountry ||
        (this.selectedResult
          ? this.$store.state.countries.find((x) => x.id === this.selectedResult.split('-')[0])
          : null);
      const country = selectedCountry || this.$store.state.countries[0];
      this.$store.dispatch('setSelectedCountry', country);
      this.getOrganizations();
    }
  }

  selectResult(result: string): void {
    this.selectedResult = result;
    const pts = result.split('-');
    if (pts.length == 5 && pts[4].length > 0) {
      const selectedFarm = this.selectedResult ? this.$store.state.farms.find((x) => x.id === pts[3]) : null;
      this.selectFarm(selectedFarm);
    } else {
      this.selectCountry();
    }
  }

  private createSearchKey(
    countryID: string,
    organizationID: string,
    unitId: string,
    farmId: string,
    parcelId: string
  ): string {
    return `${countryID}-${organizationID}-${unitId}-${farmId}-${parcelId}`;
  }

  search(key: string): void {
    this.searchResults = [];
    const unitId = this.$store.state.selectedUnit ? this.$store.state.selectedUnit.id : '';
    if (unitId.length > 0 || key.length >= 3) {
      API.search(key, unitId).then((results: SearchResult[]) => {
        if (results) {
          const regEx = new RegExp(key, 'gi');
          this.searchResults = results.map((result: SearchResult) => {
            const name = `${
              result.ParcelName.length === 0 ? result.OrganizationName + ' / ' + result.UnitName + ' / ' : ''
            }${result.FarmName} ${result.FarmCode || ''} ${
              result.ParcelName.length > 0 ? '/ ' + result.ParcelName : ''
            }`;
            return {
              Name: name,
              NameFormatted: name.replace(regEx, (match, val) => {
                return '<b>' + match + '</b>';
              }),
              Key: this.createSearchKey(
                result.CountryID,
                result.OrganizationID,
                result.UnitID,
                result.FarmID,
                result.ParcelID
              )
            };
          });
        }
      });
    }
  }

  onSelectOrganization(organization: Organization): void {
    this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
    this.selectOrganization(organization);
  }

  private selectOrganization(organization: Organization): void {
    this.$store.dispatch('setSelectedOrganization', { organization, pageName: this.$router.currentRoute.name });
    this.$store.dispatch('setSelectedUnit', null);
    this.$store.dispatch('setSelectedFarm', null);
    this.$store.dispatch('setParcels', []);
    this.$store.dispatch('analytic/selectLayer', {
      layer: {
        groupName: LayerGroupName.FIELD_INFO,
        name: LayerName.BOUNDARIES
      },
      pageName: this.$router.currentRoute.name
    });
    this.setUnits([]);
    this.setFarms([]);
    this.getUnits();
  }

  onSelectUnit(unit: Unit): void {
    this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
    this.selectUnit(unit);
  }

  private selectUnit(unit: Unit): void {
    this.$store.dispatch('setSelectedUnit', { ...unit }); // to trigger store change for the same unit to update parcels list all the time when select changed
    this.$store.dispatch('setSelectedFarm', null);
    this.$store.dispatch('analytic/selectLayer', {
      layer: {
        groupName: LayerGroupName.FIELD_INFO,
        name: LayerName.BOUNDARIES
      },
      pageName: this.$router.currentRoute.name
    });
    this.getFarms();
  }

  onSelectFarm(farm: Farm): void {
    this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
    this.selectFarm(farm);
  }

  private selectFarm(farm: Farm): void {
    this.$store.dispatch('setSelectedFarm', farm);
    if (!this.selectedResult) {
      this.searchResults = [];
    } else {
      this.getParcel();
    }
  }

  getOrganizations(): void {
    if (this.$store.state.selectedCountry) {
      API.getOrganizations(this.$store.state.selectedCountry.id).then((result: Organization[]) => {
        let value;
        if (this.isAdminMenuAvailable) {
          if (this.selectedSeason === '2020-21') {
            value = this.getTwentyTwentyOrganizationsBySeason(result);
          } else if (this.selectedSeason === '2021-22') {
            value = this.getTwentyTwentyOneOrganizationsBySeason(result);
          } else if (this.selectedSeason === '2022-23') {
            value = this.getTwentyTwentyTwoOrganizationsBySeason(result);
          } else if (this.selectedSeason === '2023-24') {
            value = this.getTwentyTwentyThreeOrganizationsBySeason(result);
          } else {
            value = this.getCurrentYearOrganizationsBySeason(result);
          }
        } else {
          value = result;
        }
        this.$store.dispatch('setOrganizations', value || []);
        const selectedOrganization = this.selectedResult
          ? this.$store.state.organizations.find((x) => x.id === this.selectedResult.split('-')[1])
          : null;
        const organization = selectedOrganization ?? this.$store.state.organizations?.[0] ?? null;
        this.selectOrganization(organization);
      });
    }
  }

  getCurrentYearOrganizationsBySeason(result): any {
    let organizationsSeason = [];
    for (let i = 0; i < result.length; i++) {
      if (
        !this.organizations2020.includes(result[i]['Name']) &&
        !this.organizations2021.includes(result[i]['Name']) &&
        !this.organizations2022.includes(result[i]['Name']) &&
        !this.organizations2023.includes(result[i]['Name'])
      ) {
        organizationsSeason.push(result[i]);
      }
    }

    return organizationsSeason;
  }

  getTwentyTwentyOneOrganizationsBySeason(result): any {
    let organizationsSeason = [];
    for (let i = 0; i < result.length; i++) {
      if (this.organizations2021.includes(result[i]['Name'])) {
        organizationsSeason.push(result[i]);
      }
    }

    return organizationsSeason;
  }

  getTwentyTwentyOrganizationsBySeason(result): any {
    let organizationsSeason = [];
    for (let i = 0; i < result.length; i++) {
      if (this.organizations2020.includes(result[i]['Name'])) {
        organizationsSeason.push(result[i]);
      }
    }
    return organizationsSeason;
  }

  getTwentyTwentyTwoOrganizationsBySeason(result): any {
    let organizationsSeason = [];
    for (let i = 0; i < result.length; i++) {
      if (this.organizations2022.includes(result[i]['Name'])) {
        organizationsSeason.push(result[i]);
      }
    }
    return organizationsSeason;
  }

  getTwentyTwentyThreeOrganizationsBySeason(result): any {
    let organizationsSeason = [];
    for (let i = 0; i < result.length; i++) {
      if (this.organizations2023.includes(result[i]['Name'])) {
        organizationsSeason.push(result[i]);
      }
    }
    return organizationsSeason;
  }

  private setUnits(units: Unit[]): void {
    this.$store.dispatch('setUnits', units || []);
  }

  private setFarms(farms: Farm[]): void {
    this.$store.dispatch('setFarms', farms || []);
  }

  getUnits(): void {
    this.setUnits([]);
    this.setFarms([]);
    this.$store.dispatch('setParcels', []);
    if (this.$store.state.selectedOrganization) {
      API.getUnits(this.$store.state.selectedOrganization.id).then((result: Unit[]) => {
        this.setUnits(result);
        const selectedUnit = this.selectedResult
          ? this.$store.state.units.find((x) => x.id === this.selectedResult.split('-')[2])
          : null;
        const unit = selectedUnit ?? this.$store.state.units?.[0] ?? null;
        this.selectUnit(unit);
      });
    }
  }

  getFarms(): void {
    if (this.$store.state.selectedUnit) {
      API.getFarms(this.$store.state.selectedUnit.id).then((result: Farm[]) => {
        this.setFarms(result);
        const selectedFarm = this.selectedResult
          ? this.$store.state.farms.find((x) => x.id === this.selectedResult.split('-')[3])
          : null;
        this.selectFarm(selectedFarm);
      });
    }
  }

  getParcel(): void {
    if (this.selectedResult) {
      const parcelId = this.selectedResult.split('-')[4];
      if (parcelId.length > 0) {
        API.getParcel(parcelId).then((parcel: Parcel) => {
          this.selectParcel(parcel);
        });
      }
    }
    this.selectedResult = null;
  }

  farmName(farm: Farm): string {
    return farm.Name + (farm.Code && farm.Code.length > 0 ? ' (' + farm.Code + ')' : '');
  }
}
