









import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditableParcel } from '@/interfaces/editableParcel';
import constants from '@/services/constants';

@Component({
  components: {}
})
export default class Legend extends Vue {
  @Prop() parcels: EditableParcel[];

  get items(): any[] {
    const items = [];
    const newCount = this.parcels.filter(
      (parcel: EditableParcel) => parcel.isNew && !parcel.isReplacedAnotherParcel && !parcel.isDeleted
    ).length;
    if (newCount) {
      items.push({
        title: this.$t('newParcels'),
        count: newCount,
        color: constants.editShapesColors.NEW_PARCEL_COLOR
      });
    }
    const replacedCount = this.parcels.filter(
      (parcel: EditableParcel) => parcel.isReplacedAnotherParcel && !parcel.isDeleted
    ).length;
    if (replacedCount) {
      items.push({
        title: this.$t('newParcelsReplacingExistingOnes'),
        count: replacedCount,
        color: constants.editShapesColors.REPLACED_PARCEL_COLOR
      });
    }
    const unchangedCount = this.parcels.filter((parcel: EditableParcel) => !parcel.isNew && !parcel.isDeleted).length;
    if (unchangedCount) {
      items.push({
        title: this.$t('unchangedParcels'),
        count: unchangedCount,
        color: constants.editShapesColors.DEFAULT_PARCEL_COLOR
      });
    }
    return items;
  }
}
