import constants from '@/services/constants';

export const CHART_TYPE = {
  OVERALL: 'overall',
  FARM_NAME: 'farmName',
  VARIETY: 'variety',
  STATUS: 'status',
  NUMBER_OF_CYCLES: 'numberOfCycles',
  CROP_STAGE: 'cropStage',
  CROP_SEASON: 'cropSeason'
};

export function getColor(type: string, key: string): string {
  let color = null;
  if (type === CHART_TYPE.OVERALL) {
    color = '#029973';
  }
  if (type === CHART_TYPE.CROP_STAGE) {
    color = constants.cropStageColorMap[parseInt(key)];
  }
  if (type === CHART_TYPE.NUMBER_OF_CYCLES) {
    color = constants.numberOfCyclesColorMap[parseInt(key)];
  }
  if (type === CHART_TYPE.STATUS) {
    color = constants.statusColorMap[key];
  }
  if (type === CHART_TYPE.VARIETY) {
    color = constants.varietiesColorMap[key];
  }
  if (type === CHART_TYPE.FARM_NAME) {
    const farmColorMap = constants.farmsColorMap.find((farmColorMap) => farmColorMap.name === key);
    if (farmColorMap) {
      color = farmColorMap.color;
    }
  }
  return color || '#000000';
}
