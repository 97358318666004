









































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import JsonCSV from 'vue-json-csv';
import { AnalyticType } from '@/enums/analyticType';
import { GroupedAnalyticData, TableItem, TableItemAnalyticType } from '@/interfaces/tableItem';
import { ProductType } from '@/enums/productType';
import { AnalyticData } from '@/interfaces/analyticData';
import {
  getParcelCropStageAsString,
  getParcelCycle,
  getParcelStatus,
  getUnitMeasure,
  isAllowToLoadUnpublishedAnalytics,
  isAnalyticDataValid
} from '@/services/analyticData';
import moment, { FromTo } from 'moment';
import Constants from '@/services/constants';
import { m2ToHa } from '@/services/units';
import { getLocalizedDate, stringToDate } from '@/services/date';
import { AnalyticDataType } from '@/enums/AnalyticDataType';
import { Role } from '@/enums/role';
import { GroundTruthData } from '@/interfaces/groundTruthData';
import { Parcel } from '@/interfaces/parcel';
import { AntdTablePagination, PaginationFilters, TablePagination } from '@/interfaces/pagination';
import { Column } from 'ant-design-vue/types/table/column';
import API from '@/services/api';
import { Farm } from '@/interfaces/farm';
import { saveAs } from 'file-saver';
import qs from 'query-string';
import { DownloadFileBaseUrl } from '@/enums/downloadFileBaseUrl';

export interface ColumnFilter {
  text: string;
  value: string;
}

const NOT_AVAILABLE = 'N/A';

@Component({
  components: {
    downloadCsv: JsonCSV
  }
})
export default class Table extends Vue {
  groundTruthData: GroundTruthData = null;
  exportModalVisible = false;
  DATE_FORMAT_LOCALIZED = Constants.DATE_FORMAT_LOCALIZED;

  exportForm = {
    from: this.$store.state.analytic.fromTo.from,
    to: this.$store.state.analytic.fromTo.to
  };

  get fromTo(): FromTo {
    return this.$store.state.analytic.fromTo;
  }

  get selectedProductType(): ProductType {
    return this.$store.state.analytic.selectedProductType;
  }

  get selectedUnit(): ProductType {
    return this.$store.state.selectedUnit;
  }

  get pagination(): TablePagination {
    return this.$store.state.analytic.tablePagination;
  }

  get paginationOptionsUI(): AntdTablePagination {
    const { skip, limit } = this.pagination;
    const safeLimit = Math.max(limit, 10);
    const current = Math.max((skip + safeLimit) / safeLimit, 1);
    const total = this.$store.state.analytic.totalAnalyticTableData;
    return {
      showSizeChanger: true,
      pageSizeOptions: ['10', '50', '100', '500', '1000'],
      total,
      current,
      pageSize: safeLimit
    };
  }

  get labels(): { [key: string]: string } {
    const titles = {
      gtDate: this.$root.$t('gtDate').toString(),
      sugarRecovery: this.$root.$t('sugarRecovery').toString(),
      sugarRecoveryError: this.$root.$t('error').toString(),
      sugarRecoveryErrorPercent: this.$root.$t('errorPercent').toString(),
      sugarRecoveryAccuracy: this.$root.$t('accuracy').toString()
    };
    this.columns.forEach((column) => {
      titles[column.dataIndex] = column.title;
    });
    return titles;
  }

  get fileNameParts(): string[] {
    const parts = [];
    if (this.$store.state.selectedOrganization) {
      parts.push(this.$store.state.selectedOrganization.Name);
      parts.push(this.$store.state.selectedSeasonYear);
    }
    if (this.checkedList.includes(AnalyticType.SUGAR_CONTENT_PREDICTION)) {
      parts.push('sugar_content_prediction');
    }
    if (this.checkedList.includes(AnalyticType.SUGAR_CONTENT_YIELD)) {
      parts.push('sugar_yield');
    }
    if (this.downloadHarvestedParcel) {
      parts.push('harvested_parcel');
    }
    if (this.downloadPartiallyHarvestedParcel) {
      parts.push('partially_harvested');
    }
    if (this.exportForm.from === this.exportForm.to) {
      parts.push(this.exportForm.from);
    }
    if (this.exportForm.from !== this.exportForm.to) {
      parts.push(this.exportForm.from);
      parts.push(this.exportForm.to);
    }

    return parts;
  }

  get fileName(): string {
    return this.fileNameParts.join('_');
  }

  get exportingLocales(): { [key: string]: string } {
    return {
      Unknown: this.$root.$t('unknown').toString(),
      Fresh: this.$root.$t('fresh').toString(),
      Ratoon: this.$root.$t('ratoon').toString(),
      'one month': this.$root.$tc('countMonths').toString(),
      months: this.$root.$t('months').toString()
    };
  }

  get exportingFields(): string[] {
    const columns = this.columns.map((column) => {
      return column.dataIndex;
    });

    const { Roles } = this.$store.state.userInfo;
    if (
      this.checkedList.includes(AnalyticType.SUGAR_CONTENT_PREDICTION) &&
      (Roles.includes(Role.ADMIN) || Roles.includes(Role.MAHINDRA_ADMIN))
    ) {
      return [
        ...columns,
        'sugarRecovery',
        'gtDate',
        'sugarRecoveryError',
        'sugarRecoveryErrorPercent',
        'sugarRecoveryAccuracy',
        'predictionAccuracy'
      ];
    }
    return columns;
  }

  analyticsCSV(): void {
    const source = Constants.productSurveySource[this.$store.state.analytic.selectedProductType];
    const { filter, ...rest } = this.pagination;
    const columns = this.exportingFields.reduce((acc, field) => {
      if (this.labels[field]) {
        acc.push({
          label: this.labels[field],
          value: field.replace('_view', '')
        });
      }
      return acc;
    }, []);
    this.$store.dispatch('setIsGlobalLoaderVisible', true);
    API.getAnalyticReports({
      unit: this.$store.state.selectedUnit.id,
      from: this.exportForm.from,
      to: this.exportForm.to,
      source,
      published: isAllowToLoadUnpublishedAnalytics(this.$store.state.userInfo, this.$store.state.selectedCountry),
      exclude_ndvi: true,
      calcCount: true,
      ...rest,
      ...filter,
      columns,
      fileName: this.fileName,
      locales: JSON.stringify(this.exportingLocales),
      locale: this.$root.$i18n.locale,
      harvested: this.downloadHarvestedParcel,
      partially_harvested: this.downloadPartiallyHarvestedParcel
    }).finally(() => {
      this.exportModalVisible = false;
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
    });
  }

  private allGroupOptions = [
    {
      label: this.$root.$t(`productTypeLabel.${ProductType.SUGAR_CONTENT_YIELD}`).toString(),
      value: AnalyticType.SUGAR_CONTENT_YIELD
    },
    {
      label: this.$root.$t(`productTypeLabel.${ProductType.SUGAR_CONTENT_PREDICTION}`).toString(),
      value: AnalyticType.SUGAR_CONTENT_PREDICTION
    },
    {
      label: this.$root.$t(`tableGroupOptionLabel.${AnalyticDataType.NUM_CYCLES}`).toString(),
      value: AnalyticDataType.NUM_CYCLES
    },
    {
      label: this.$root.$t(`tableGroupOptionLabel.${AnalyticDataType.STAGE}`).toString(),
      value: AnalyticDataType.STAGE
    }
  ];

  indeterminate = false; // set to true if default value of defaultCheckedList is not empty
  isFilterGroundMeasurementsChecked = false;
  checkAll = false;
  downloadHarvestedParcel = false;
  downloadPartiallyHarvestedParcel = false;
  checkedList = [];
  filters = {};
  defaultColumns: Pick<Column, 'filters' | 'title' | 'dataIndex' | 'sorter'>[] = [
    {
      title: this.$root.$t('date'),
      dataIndex: 'Date',
      sorter: (a: TableItem, b: TableItem) => stringToDate(a.Date).getTime() - stringToDate(b.Date).getTime()
    },
    {
      title: this.$root.$t('farm'),
      dataIndex: 'FarmName'
    },
    {
      title: this.$root.$t('parcel'),
      dataIndex: 'Name'
    },
    {
      title: this.$root.$t('farmerName'),
      dataIndex: 'FarmerName'
    },
    {
      title: this.$root.$t('areaHa'),
      dataIndex: 'Area',
      sorter: (a: TableItem, b: TableItem) => a.AreaVal - b.AreaVal
    },
    {
      title: this.$root.$t('status'),
      dataIndex: 'Status'
    },
    {
      title: this.$root.$t('cropSeason'),
      dataIndex: 'CropSeason'
    },
    {
      title: this.$root.$t('division'),
      dataIndex: 'Division'
    },
    {
      title: this.$root.$t('variety'),
      dataIndex: 'Variety'
    },
    {
      title: this.$root.$t('ratoonCycle'),
      dataIndex: 'RatoonCycle'
    },
    {
      title: this.$root.$t('plantingDate'),
      dataIndex: 'PlantingDate',
      sorter: (a: TableItem, b: TableItem) =>
        stringToDate(a.PlantingDate).getTime() - stringToDate(b.PlantingDate).getTime()
    },
    {
      title: this.$root.$t('cropType'),
      dataIndex: 'CropType'
    },
    {
      title: this.$root.$t(`tableGroupOptionLabel.${AnalyticDataType.NUM_CYCLES}`),
      dataIndex: 'cycles'
    },
    {
      title: this.$root.$t(`tableGroupOptionLabel.${AnalyticDataType.STAGE}`),
      dataIndex: 'stage'
    }
  ];
  columns = this.defaultColumns;
  tableData: TableItem[] = [];

  get analyticTableData(): GroupedAnalyticData[] {
    return this.$store.state.analytic.analyticTableData;
  }

  @Watch('fromTo')
  onDateChange() {
    this.exportForm = {
      from: this.$store.state.analytic.fromTo.from,
      to: this.$store.state.analytic.fromTo.to
    };
  }

  @Watch('fromTo')
  onFromToChange() {
    this.$store.dispatch('analytic/setTablePagination', {
      ...this.pagination,
      skip: 0,
      calcCount: true,
      filter: {
        ...this.pagination?.filter,
        analytic: this.checkedList,
        groundTruth: this.isFilterGroundMeasurementsChecked ? 1 : undefined
      }
    });
  }

  @Watch('selectedProductType')
  @Watch('selectedUnit')
  onProductChange() {
    this.cleanCheckedList();
    this.$store.dispatch('analytic/setTablePagination', {
      ...this.pagination,
      skip: 0,
      calcCount: true,
      filter: {
        ...this.pagination?.filter,
        analytic: this.checkedList,
        groundTruth: this.isFilterGroundMeasurementsChecked ? 1 : undefined
      }
    });
  }

  @Watch('pagination')
  onPaginationOptionsChanged(): void {
    this.$store.dispatch('analytic/updateAnalyticTableData');
  }

  @Watch('analyticTableData')
  onAnalyticDataChanged(): void {
    this.initTable();
  }

  private async initTable(): Promise<void> {
    this.$store.dispatch('setIsGlobalLoaderVisible', true);
    setTimeout(() => {
      this.updateTableValues();

      this.updateColumns();
      this.loadTableData();
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }, 100);
  }

  mounted(): void {
    this.$store.dispatch('analytic/updateAnalyticTableData');
    this.initTable();
    if (
      this.$store.state.analytic.selectedProductType !== null &&
      this.$store.state.analytic.selectedProductType !== undefined
    ) {
      this.runActivityLog();
    }
    this.$watch(
      () => this.$store.state.analytic.selectedProductType,
      (newValue) => {
        if (newValue !== null && newValue !== undefined) {
          this.runActivityLog();
        }
      }
    );
  }

  runActivityLog(): void {
    API.userActivityLog(this.$store.state.userInfo.Email, this.$store.state.analytic.selectedProductType, 'Table').then(
      (result) => {
        this.$store.dispatch('setUserActivity', result);
      }
    );
  }

  private updateTableValues(): void {
    const farms = [];
    this.$store.state.farms.forEach((farm: Farm) => {
      if (farm.id && farm.Name) {
        const farmName = `${farm.Name} ${farm.Code ? '(' + farm.Code + ')' : ''}`;
        if (!farms.some((f) => f.value === farm.id)) {
          farms.push({ text: farmName, value: farm.id });
        }
      }
    });

    const varieties = new Set<string>();
    const parcels: { [key: string]: Parcel } = this.$store.state.parcels;
    Object.values(parcels).forEach((parcel: Parcel) => {
      if (parcel.Variety) {
        varieties.add(parcel.Variety);
      }
    });

    const varietyOptions: ColumnFilter[] = Array.from(varieties).map((variety) => ({ text: variety, value: variety }));
    this.defaultColumns.find((c) => c.dataIndex === 'Variety').filters = this.sortFilterByText(varietyOptions);
    this.defaultColumns.find((c) => c.dataIndex === 'FarmName').filters = this.sortFilterByText(farms);
  }

  sortFilterByText(values: ColumnFilter[]): ColumnFilter[] {
    return values.sort((a: ColumnFilter, b: ColumnFilter) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      if (a.text === b.text) {
        return 0;
      }
    });
  }

  onExportDateChange(date: moment.Moment, name: string) {
    this.exportForm[name] = date?.format(Constants.DATE_FORMAT) ?? '';
  }

  get isGroundMeasurementsFilterAvailable(): boolean {
    return (
      this.checkedList.includes(AnalyticType.SUGAR_CONTENT_PREDICTION) ||
      this.checkedList.includes(AnalyticType.SUGAR_CONTENT_YIELD)
    );
  }

  get groupOptions() {
    const { analytic, selectedOrganization } = this.$store.state;
    const source = Constants.productSurveySource[analytic.selectedProductType];
    const availableProductTypes =
      selectedOrganization?.Products?.filter((product) => Constants.productSurveySource[product] === source) ?? [];
    const availableAnalytics = availableProductTypes.map((product) => Constants.productAnalyticMap[product]);
    return this.allGroupOptions.filter((group) => availableAnalytics.includes(group.value));
  }

  cleanCheckedList() {
    this.checkedList = [];
    this.checkAll = false;
    this.indeterminate = false;
    this.isFilterGroundMeasurementsChecked = false;
  }

  get defaultCheckedList() {
    return this.allGroupOptions.map((group) => group.value);
  }

  async loadTableData() {
    this.tableData = this.getTableItems();
  }

  private getTableItems(): TableItem[] {
    return this.analyticTableData.map((parcel) => {
      const plantingDate = getLocalizedDate(parcel.Planted);
      const parcelArea = m2ToHa(parcel.Area);
      const farmName = parcel.FarmName
        ? `${parcel.FarmName} ${parcel.FarmCode ? '(' + parcel.FarmCode + ')' : ''}`
        : '';
      const parcelStatus = getParcelStatus(
        { Status: parcel.Status, Planted: parcel.Planted, LLLat: parcel.LLLat } as Parcel,
        this.$store.state.analytic.fromTo.to,
        this.$root.$i18n,
        this.$store.state.selectedCountry
      );
      const parcelCycle = getParcelCycle({ Planted: parcel.Planted } as Parcel, this.$store.state.analytic.fromTo.to);
      const parcelCropStage = getParcelCropStageAsString(
        {
          HarvestDates: parcel.HarvestDates,
          PartialHarvest: parcel.PartialHarvest,
          Planted: parcel.Planted
        } as Parcel,
        this.$store.state.analytic.fromTo.to,
        this.$root.$i18n
      );

      const harvestDates = [...(parcel.HarvestDates || [])].sort();
      const firstHarvestDate = harvestDates.length ? getLocalizedDate(harvestDates[0]) : null;

      const rowKey = `${parcel.ParcelID}-${parcel.SurveyDate}`;

      const item: TableItem = {
        key: rowKey,
        id: parcel.ParcelID,
        PlantingDate: plantingDate,
        Name: parcel.Name,
        Area: parcelArea,
        AreaVal: parcel.Area,
        CropType: this.$t(parcel.CropTypeName).toString(),
        Date: getLocalizedDate(parcel.SurveyDate),
        Variety: parcel.Variety,
        Status: parcelStatus,
        FarmName: farmName,
        FarmerName: parcel.FarmerName,
        CropSeason: parcel.CropSeason,
        RatoonCycle: parcel.RatoonCycle,
        Division: parcel.Division,
        AnalyticType: [],
        [AnalyticDataType.NUM_CYCLES]: parcelCycle,
        [AnalyticDataType.STAGE]: parcelCropStage
      };

      parcel.Analytics.forEach((analytic) => {
        const val = this.calculateAnalyticValue(analytic.Value, analytic.Type, parcel.Area);
        item[`${analytic.Type}`] = val;
        item[`${analytic.Type}_view`] = this.getAnalyticUiValue(val, item);
        item.AnalyticType.push(analytic.Type);
      });

      return item;
    });
  }

  calculateAnalyticValue(value: number, analyticType: AnalyticType | AnalyticDataType, parcelArea: number): number {
    const calculationFnPerAnalytic = {
      default: (val: number): number => Math.round(val * 100) / 100
    };
    const calculationFn = calculationFnPerAnalytic[analyticType] || calculationFnPerAnalytic['default'];
    return calculationFn(value, parcelArea);
  }

  get defaultAnalyticProps(): { [key: string]: undefined } {
    // solve export csv issue, all fields should exist to create proper csv table
    // vue-json-csv doesn't emit eny error just cut the props
    const analyticTypeKeys = Object.keys(AnalyticType);
    const analyticDataTypeKeys = Object.keys(AnalyticDataType);
    return [...analyticTypeKeys, ...analyticDataTypeKeys].reduce((acc, analyticTypeKey) => {
      const enumValue = AnalyticType[analyticTypeKey];
      acc[enumValue] = undefined;
      return acc;
    }, {});
  }

  updateColumns(): void {
    const extraCols = [];
    this.checkedList.forEach((checkedItem) => {
      const option = this.groupOptions.find((groupOption) => {
        return groupOption.value === checkedItem;
      });
      if (option) {
        const unitMeasure = getUnitMeasure(option.value);
        extraCols.push({
          title: `${option.label} ${unitMeasure ? `(${unitMeasure})` : ''}`,
          dataIndex: option.value,
          scopedSlots: {
            customRender: 'analyticValue'
          }
        });
      }
    });

    this.columns = [...this.defaultColumns, ...extraCols];

    if (this.$store.state.selectedCountry && Constants.indiaCountryIds.includes(this.$store.state.selectedCountry.id)) {
      this.columns = this.columns.filter(
        (column) => !['harvestDate', 'cycles', 'stage', 'CropType'].includes(column.dataIndex)
      );
    }
  }

  onChange(checkedList: string[]): void {
    this.indeterminate = !!checkedList.length && checkedList.length < this.groupOptions.length;
    this.checkAll = checkedList.length === this.groupOptions.length;

    this.$store.dispatch('analytic/setTablePagination', {
      ...this.pagination,
      skip: 0,
      calcCount: true,
      filter: { ...this.pagination?.filter, analytic: this.checkedList }
    });
    this.updateColumns();
    this.loadTableData();
  }

  onHarvestedParcelChange(e): void {
    this.downloadHarvestedParcel = e.target.checked;
  }

  onPartiallyHarvestedParcelChange(e): void {
    this.downloadPartiallyHarvestedParcel = e.target.checked;
  }

  onCheckAllChange(e): void {
    this.checkedList = e.target.checked ? this.groupOptions.map((opt) => opt.value) : [];
    this.indeterminate = false;
    this.checkAll = e.target.checked;

    this.$store.dispatch('analytic/setTablePagination', {
      ...this.pagination,
      skip: 0,
      calcCount: true,
      filter: { ...this.pagination?.filter, analytic: this.checkedList }
    });
    this.updateColumns();
    this.loadTableData();
  }

  onRangeChange(field: string, range: [number, number]) {
    if (this.checkedList.includes(field)) {
      this.$store.dispatch('analytic/setTablePagination', {
        ...this.pagination,
        skip: 0,
        calcCount: true,
        filter: { ...this.pagination?.filter, Value: range }
      });
    }
  }

  onFilterGroundMeasurements() {
    this.$store.dispatch('analytic/setTablePagination', {
      ...this.pagination,
      skip: 0,
      calcCount: true,
      filter: { ...this.pagination?.filter, groundTruth: this.isFilterGroundMeasurementsChecked ? 1 : undefined }
    });
  }

  applyFilters(pagination: AntdTablePagination, filters, sort) {
    const { order, field } = sort;
    const queryField = this.columnToQueryField(field);
    const newSort = order && queryField ? `${queryField}:${order.includes('asc') ? 'asc' : 'desc'}` : undefined;
    const { current, pageSize } = pagination;

    const newFilters: PaginationFilters = {};
    Object.keys(filters).forEach((key) => (newFilters[this.columnToQueryField(key)] = filters[key]));
    if (this.isGroundMeasurementsFilterAvailable && this.isFilterGroundMeasurementsChecked) {
      newFilters.groundTruth = 1;
    }
    newFilters.analytic = this.checkedList;

    let skip = current * pageSize - pageSize;
    let calcCount = true;

    // TODO: always reset skip/limit if sort/filters change
    if (pageSize !== this.pagination.limit || newSort !== this.pagination.sort || Object.keys(filters).length > 0) {
      skip = 0;
      calcCount = true;
    }

    this.$store.dispatch('analytic/setTablePagination', {
      skip,
      calcCount,
      limit: pageSize,
      sort: newSort,
      filter: newFilters
    });

    this.loadTableData();
  }

  columnToQueryField(column: string): string {
    switch (column) {
      case 'Date':
        return 'SurveyDate';
      case 'PlantingDate':
        return 'Planted';
      case 'Area':
        return 'Area';
      case 'FarmName':
        return 'farmId';
      case 'Variety':
        return 'variety';
      case 'GroundTruth':
        return 'groundTruth';
      default:
        return column;
    }
  }

  get tableHeight(): number {
    // TODO: create template without absolute positioned blocks and which will adopt to inner content
    // TODO: find better make table scroll with fixed header
    const headerHeight = 58;
    const footerHeight = 57;
    const pagingHeight = 135;
    return window.innerHeight - headerHeight - footerHeight - pagingHeight - 100;
  }

  get tableScrollOpt(): { y: number; x?: number } {
    return { y: this.tableHeight };
  }

  getAnalyticUiValue(value: number, item: TableItem): string {
    if (
      (!value && item.Date === NOT_AVAILABLE) ||
      value === undefined ||
      !isAnalyticDataValid({ Value: value } as AnalyticData)
    ) {
      return NOT_AVAILABLE;
    }
    return `${value}`;
  }
}
