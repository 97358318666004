import { Popup } from 'mapbox-gl';
import turfCenter from '@turf/center';
import { feature, Geometry } from '@turf/helpers';

export const addMapTooltip = (
  map: mapboxgl.Map,
  layerId: string,
  getTitle: () => string,
  getDescription: () => string
) => {
  const CLASSES = {
    TOOLTIP: 'popup-tooltip',
    CONTENT: 'popup-content',
    TITLE: 'popup-title'
  };
  const TIMEOUT = 500;

  const popup = new Popup({
    closeButton: false,
    closeOnClick: false,
    className: CLASSES.TOOLTIP
  });

  let timeoutId = null;
  let geometry = null;
  let coordinates = null;

  const stopTimeout = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  map.on('mousemove', layerId, (e) => {
    coordinates = e.lngLat.wrap();
  });

  map.on('mouseenter', layerId, (e) => {
    stopTimeout();
    geometry = e.features[0].geometry as Geometry;
    timeoutId = setTimeout(() => {
      const content = `<div class='${CLASSES.TITLE}'>${getTitle()}</div><div class='${
        CLASSES.CONTENT
      }'>${getDescription()}</div>`;
      popup
        .setLngLat(coordinates || (turfCenter(feature(geometry)).geometry.coordinates as [number, number]))
        .setHTML(content)
        .addTo(map);
    }, TIMEOUT);
  });

  map.on('mouseleave', layerId, (e) => {
    if ((e?.originalEvent as any)?.toElement?.className?.indexOf(CLASSES.CONTENT) === -1) {
      stopTimeout();
      popup.remove();
    }
  });
};
