import Vue from 'vue';
import VueRouter from 'vue-router';
import BaseTemplate from '../views/Base-Template.vue';
import TablePage from '../views/Table-Page.vue';
import Dashboard from '../views/Dashboard-Page.vue';
import { PageName } from '@/enums/pageName';
import Profile from '@/views/Profile.vue';
import Admin from '@/views/Admin.vue';
import UnsupportedBrowser from '@/views/UnsupportedBrowser.vue';
import RadarPage from '@/views/Radar-Page.vue';
import Analytics from '@/views/Analytics.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: BaseTemplate,
    children: [
      {
        path: '',
        name: PageName.RADAR,
        component: RadarPage
      },
      {
        path: '/table',
        name: PageName.TABLE,
        component: TablePage
      },
      {
        path: '/profile',
        name: PageName.PROFILE,
        component: Profile
      },
      {
        path: '/admin',
        name: PageName.ADMIN,
        component: Admin
      },
      {
        path: '/analytics',
        name: PageName.ANALYTICS,
        component: Analytics
      },
      {
        path: '/dashboard',
        name: PageName.DASHBOARD,
        component: Dashboard
      }
    ]
  },
  {
    path: '/unsupported',
    name: PageName.UNSUPPORTED_BROWSER,
    component: UnsupportedBrowser
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
