






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SelectedLayer } from '@/interfaces/selectedLayer';
import { LayerGroupName } from '@/enums/layerGroupName';
import { LayerName } from '@/enums/layerName';
import constants from '@/services/constants';
import { LayerProductGroup } from '@/interfaces/layerProductGroup';
import { ProductType } from '@/enums/productType';
import { LayerProduct } from '@/interfaces/layerProduct';
import { Parcel } from '@/interfaces/parcel';
import { ProductGroupName } from '@/enums/productGroupName';

@Component({
  components: {}
})
export default class LayerSelectorItem extends Vue {
  @Prop() productLayer: LayerProductGroup;
  @Prop() selectedProductType: ProductType;
  @Prop() selectedProductGroup: ProductGroupName;
  @Prop() selectedParcelsCount: number;
  @Prop() selectedLayers: SelectedLayer[];

  @Prop() histogramData: any;
  @Prop() ndviZonesRange: [number, number];
  @Prop() ndviRange: number;
  @Prop() selectedParcel: Parcel;

  @Prop() applyFilterGroundMeasurements: boolean;
  @Prop() applyParcelPixel: boolean;
  @Prop() isSplitMode: boolean;

  get productLabel(): string {
    if (!this.productLayer.isLayersVisible) {
      if (this.selectedProductType === 'vegetative-index') {
        const key = this.productLayer.defaultLayer.toString().toLowerCase();
        return this.$store.state.vegetativeDisplayNames[key].toString();
      } else {
        return this.$t(`layerLabel.${this.productLayer.defaultLayer}`).toString();
      }
    }
    return this.$t(`productTypeLabel.${this.productLayer.name}`).toString();
  }

  getLayerLabel(name: LayerName): string {
    return this.$t(`layerLabel.${name}`).toString();
  }

  get productLayers(): LayerProduct[] {
    return this.productLayer.layers.filter((layer: LayerProduct) => {
      return (
        !layer.onlyForSingleSelectedParcel || (layer.onlyForSingleSelectedParcel && this.selectedParcelsCount === 1)
      );
    });
  }

  get layerGroupName(): LayerGroupName {
    return constants.productGroupToAnalyticLayerGroup[this.selectedProductGroup];
  }

  get isProductLayerSelected(): boolean {
    const isProductSelected = this.productLayer.name === this.selectedProductType;
    if (this.productLayer.isLayersVisible) {
      return isProductSelected;
    }
    return isProductSelected && this.isLayerSelected(this.productLayer.defaultLayer, this.layerGroupName);
  }

  isLayerSelected(name: LayerName, groupName: LayerGroupName): boolean {
    return (
      this.selectedLayers.find((layer: SelectedLayer) => layer.groupName === groupName && layer.name === name) !==
      undefined
    );
  }

  isBackgroundLayerSelected(name: LayerName): boolean {
    return this.isLayerSelected(name, LayerGroupName.BACKGROUND_INSIDE);
  }

  onProductSelect(productType: ProductType, layerName: LayerName): void {
    this.$emit('productSelect', this.layerGroupName, productType, layerName);
  }

  onLayerSelect(name: LayerName, isChecked: boolean, mutuallyExclusive?: LayerName[]): void {
    this.$emit('layerSelect', this.layerGroupName, name, isChecked, mutuallyExclusive);
  }

  onBackgroundLayerSelect(name: LayerName): void {
    this.$emit('backgroundLayerSelect', this.productLayer.backgroundLayers, name);
  }

  isGroundMeasurementsAnalyticSelected(): boolean {
    return (
      (this.selectedProductType === ProductType.SUGAR_CONTENT_YIELD ||
        this.selectedProductType === ProductType.SUGAR_CONTENT_PREDICTION) &&
      this.selectedLayers.find(
        (layer) =>
          layer.groupName === LayerGroupName.ANALYTIC &&
          (layer.name === LayerName.SUGAR_CONTENT_YIELD || layer.name === LayerName.SUGAR_CONTENT_PREDICTION)
      ) !== undefined
    );
  }

  applyFilterGroundMeasurementsChanged(isSelected: boolean): void {
    this.$emit('applyFilterGroundMeasurementsChange', isSelected);
  }

  applyParcelPixelChanged(isSelected: boolean): void {
    this.$emit('applyParcelPixel', isSelected);
  }
}
