













































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ModeSelector extends Vue {
  @Prop() isSplitScreenMode: boolean;

  selectMode(isSplit: boolean): void {
    this.$emit('onChange', isSplit);
  }
}
