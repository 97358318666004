





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditableParcel } from '@/interfaces/editableParcel';
import Constants from '@/services/constants';
import moment from 'moment';

@Component({
  components: {}
})
export default class ExistSurveysConfirm extends Vue {
  @Prop() parcels: EditableParcel[];
  changeCreatedDateToToday = true;

  get todayDate(): string {
    return moment().format(Constants.DATE_FORMAT_LOCALIZED);
  }

  onClose(): void {
    this.$emit('onClose', {
      cancel: true
    });
  }

  onContinue(): void {
    this.$emit('onClose', {
      cancel: false,
      changeCreatedDateToToday: this.changeCreatedDateToToday
    });
  }
}
