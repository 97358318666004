













import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnalyticData } from '@/interfaces/analyticData';

@Component
export default class SugarYieldInfo extends Vue {
  @Prop() analyticData: AnalyticData;
}
