












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WarningParcelInfo extends Vue {
  @Prop() title: string;
  @Prop() description: string;
}
