




























































import { Component, Vue } from 'vue-property-decorator';
import EditShapesMap from '@/components/radar/shapes-management/EditShapesMap.vue';
import EditShapesDialog from '@/components/radar/shapes-management/EditShapesDialog.vue';
import { Feature, featureCollection } from '@turf/helpers';
import { CropType } from '@/interfaces/cropType';
import API from '@/services/api';
import { Modal } from 'ant-design-vue';
import moment from 'moment';
import { EditableParcel } from '@/interfaces/editableParcel';
import { Shape } from '@/interfaces/shape';
import Constants from '@/services/constants';
import CreationHint from '@/components/radar/shapes-management/CreationHint.vue';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import EditShapesImportFromFile from '@/components/radar/shapes-management/EditShapesImportFromFile.vue';
import { getOverlapData } from '@/services/shapesManagement';
import Legend from '@/components/radar/shapes-management/Legend.vue';
import { stringToMomentDate } from '@/services/date';

@Component({
  components: {
    EditShapesMap,
    EditShapesDialog,
    CreationHint,
    EditShapesImportFromFile,
    Legend
  }
})
export default class EditShapesMode extends Vue {
  parcels: EditableParcel[] = [];
  cropTypes: CropType[] = [];
  isDrawing = false;
  isCreatingNewUnit = false;
  featureIdToRemove = null;
  private uniqParcelId = 0;
  private uniqUnitId = 0;
  blockedParcelIds: string[] = [];
  invalidParcelIds: string[] = [];
  newOrEditedParcelIds: string[] = [];
  selectedParcelId: string = null;
  unit: Unit = null;
  farms: Farm[] = [];
  selectedFarm: Farm = null;
  files: FileList = null;

  mounted(): void {
    const today = moment.utc().startOf('day');
    const parcels = (Object.values(this.$store.state.parcels) as EditableParcel[]).filter((parcel: EditableParcel) =>
      moment.utc(parcel.Deleted).isAfter(today)
    );
    this.parcels = parcels.map((parcel) => {
      return { ...parcel };
    });
    this.updateInvalidParcels();
  }

  created(): void {
    if (this.isNoUnitsAvailable) {
      this.createNewUnit(this.$store.state.selectedUnit);
    } else {
      this.unit = this.$store.state.selectedUnit;
      this.farms = this.$store.state.farms;
      this.selectedFarm = this.$store.state.selectedFarm || this.farms[0];
    }

    API.getCropTypes().then((cropTypes: CropType[]) => {
      this.cropTypes = cropTypes;
    });
    window.addEventListener('beforeunload', this.onClose);
  }

  beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.onClose);
  }

  onFilesChanged(list: FileList): void {
    this.files = list;
  }

  get isNoUnitsAvailable(): boolean {
    return (
      !this.$store.state.selectedUnit ||
      (this.$store.state.selectedUnitHierarchy &&
        this.$store.state.units.length === 1 &&
        (!this.$store.state.selectedUnitHierarchy.Farms || this.$store.state.selectedUnitHierarchy.Farms.length === 0))
    );
  }

  validateBeforeCreateNewUnit(): void {
    if (this.hasChangedParcels()) {
      Modal.confirm({
        title: this.$t('doYouWantToCreateNewUnit'),
        content: this.$t('youHaveNotSavedChanges'),
        onOk: () => {
          this.createNewUnit();
        }
      });
    } else {
      this.createNewUnit();
    }
  }

  private createNewUnit(selectedUnit?: Unit): void {
    this.unit =
      selectedUnit ||
      ({
        id: null,
        Name: `${this.$t('newUnit').toString()} ${this.uniqUnitId + 1}`,
        OrganizationID: this.$store.state.selectedOrganization.id,
        CountryID: this.$store.state.selectedCountry.id
      } as Unit);
    this.farms = [
      {
        id: null,
        Name: 'Demo Farm'
      } as Farm
    ];
    this.selectedFarm = this.farms[0];
    this.parcels = [];
    this.uniqUnitId++;
    this.isCreatingNewUnit = true;
  }

  onMapPreparedForNewUnit(): void {
    this.isCreatingNewUnit = false;
  }

  private isParcelValid(parcel: EditableParcel): boolean {
    return !!parcel.CropType && !!parcel.Name && !!parcel.Created && !!parcel.Planted;
  }

  private updateInvalidParcels(): void {
    this.invalidParcelIds = this.parcels
      .filter((parcel: EditableParcel) => !this.isParcelValid(parcel) && !parcel.isDeleted)
      .map((parcel: EditableParcel) => parcel.id);
  }

  onMapLoaded(): void {
    if (this.$store.state.shapeEditingModePreSelectedParcel) {
      this.onParcelSelected(this.$store.state.shapeEditingModePreSelectedParcel.id, true);
    }
  }

  onParcelSelected(id: string, needScroll = false): void {
    this.selectedParcelId = id;
    if (needScroll) {
      window.setTimeout(() => {
        const elements = document.querySelectorAll(`[data-edit-parcel-id='${id}']`);
        if (elements && elements.length) {
          elements[0].scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
      }, 100);
    }
  }

  changeDrawing(isActive: boolean): void {
    this.isDrawing = isActive;
  }

  onUpdateOverlapFeatureIds(ids: string[]): void {
    this.blockedParcelIds = ids;
  }

  async onImportParcels(parcels: EditableParcel[]): Promise<void> {
    await this.$store.dispatch('setIsGlobalLoaderVisible', true);
    const { overlapFeatureIds, overlapParcels, overlapParcelsMapping } = await getOverlapData(this.parcels, parcels);

    const newFarmIds = new Set([]);
    parcels.forEach((parcel: EditableParcel) => {
      if (overlapFeatureIds.includes(parcel.id)) {
        parcel.isReplacedAnotherParcel = true;
      }
      newFarmIds.add(parcel.FarmID);
    });
    const overlapParcelIds = overlapParcels.map(({ id }) => id);

    const farmIds = this.farms.map((farm: Farm) => farm.id);
    const newFarms = [...newFarmIds]
      .filter((id: string) => !farmIds.includes(id))
      .map((nameCode: string) => {
        const parts = nameCode.split('_');
        return {
          id: nameCode,
          Name: parts[1],
          Code: parts[2]
        } as Farm;
      });

    if (newFarms.length) {
      this.farms = this.farms.concat(newFarms);
    }

    this.parcels = [
      ...this.parcels.map((parcel: EditableParcel) => {
        return overlapParcelIds.includes(parcel.id)
          ? {
              ...parcel,
              replacedByAnotherParcelId: (overlapParcelsMapping[parcel.id] as EditableParcel).id,
              isDeleted: true,
              replacedDeletionDate: stringToMomentDate((overlapParcelsMapping[parcel.id] as EditableParcel).Created)
                .utc()
                .subtract(1, 'day')
                .endOf('day')
                .toISOString()
            }
          : parcel;
      }),
      ...parcels
    ];
    this.newOrEditedParcelIds = this.newOrEditedParcelIds
      .filter((id) => !overlapParcelIds.includes(id))
      .concat(parcels.map((parcel: EditableParcel) => parcel.id));
    this.updateInvalidParcels();
    await this.$store.dispatch('setIsGlobalLoaderVisible', false);
  }

  onCreatedFeature(feature: Feature): void {
    const createdDate =
      this.$store.state.units.length === 0 ||
      this.parcels.filter((parcel: EditableParcel) => !parcel.isDeleted).length === 0
        ? moment().subtract(1, 'year').toISOString()
        : new Date().toISOString();
    const parcel = {
      id: feature.id,
      FarmID: this.selectedFarm.id,
      Created: createdDate,
      Planted: createdDate,
      Name: `${this.$t('newParcel').toString()} ${this.uniqParcelId + 1}`,
      Shape: {
        GeoJson: featureCollection([feature])
      },
      isNew: true
    } as EditableParcel;
    this.parcels.push(parcel);
    this.newOrEditedParcelIds.push(parcel.id);
    this.updateInvalidParcels();
    this.uniqParcelId++;
    this.onParcelSelected(parcel.id, true);
    window.setTimeout(() => {
      this.changeDrawing(false);
    }, 500);
  }

  onStartEditingParcel(editParcel: EditableParcel): void {
    this.parcels = this.parcels.map((parcel: EditableParcel) => {
      return parcel.id === editParcel.id
        ? {
            ...parcel,
            isEdited: true
          }
        : parcel;
    });
    this.updateNewOrEditedParcelIds(editParcel.id);
  }

  private updateNewOrEditedParcelIds(id: string): void {
    const updatedIds = [];
    if (!this.newOrEditedParcelIds.includes(id)) {
      updatedIds.push(id);
    }
    this.newOrEditedParcelIds = [...this.newOrEditedParcelIds, ...updatedIds];
  }

  onUpdatedFeature(feature: Feature): void {
    this.parcels = this.parcels.map((parcel: EditableParcel) => {
      if (parcel.id === feature.id) {
        const newParcel = {
          ...parcel,
          isEdited: true,
          isShapeModified: true,
          Shape: {
            id: null,
            GeoJson: featureCollection([feature])
          } as Shape
        };
        if (!newParcel.isCreatedModified) {
          newParcel.Created = new Date().toISOString();
          newParcel.isCreatedModified = true;
        }
        return newParcel;
      }
      return parcel;
    });
    this.updateNewOrEditedParcelIds(feature.id as string);
  }

  onDeletedFeature(): void {
    this.featureIdToRemove = null;
  }

  async validateBeforeDelete(deletedParcel: EditableParcel): Promise<void> {
    if (!deletedParcel.isNew) {
      const res = await API.getParcelAnalytics(
        deletedParcel.id,
        deletedParcel.Created,
        moment().format(Constants.DATE_FORMAT)
      );
      if (res.Surveys?.length) {
        Modal.confirm({
          title: this.$t('areYouSure'),
          content: this.$t('parcelHasSurveys'),
          okText: this.$t('yesDelete').toString(),
          okType: 'danger',
          onOk: () => {
            this.onDeleteParcel(deletedParcel);
          }
        });
        return;
      }
    }
    this.onDeleteParcel(deletedParcel);
  }

  private onDeleteParcel(deletedParcel: EditableParcel): void {
    if (deletedParcel.isNew) {
      this.parcels = this.parcels.filter((parcel: EditableParcel) => parcel.id !== deletedParcel.id);
    } else {
      this.parcels = this.parcels.map((parcel: EditableParcel) => {
        return parcel.id === deletedParcel.id
          ? {
              ...parcel,
              isDeleted: true
            }
          : parcel;
      });
    }
    this.newOrEditedParcelIds = this.newOrEditedParcelIds.filter((id) => id !== deletedParcel.id);
    this.featureIdToRemove = deletedParcel.id;
    this.updateInvalidParcels();
  }

  async onParcelUpdate(updatedParcel: EditableParcel): Promise<void> {
    const updatedParcelFarm = this.farms.find((farm: Farm) => farm.id === updatedParcel.FarmID);
    if (!updatedParcelFarm) {
      await this.$store.dispatch('setIsGlobalLoaderVisible', true);
      this.farms = (await API.getFarms(this.unit.id)) || [];
      await this.$store.dispatch('setFarms', this.farms);
      await this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
    this.parcels = this.parcels.map((parcel: EditableParcel) =>
      parcel.id === updatedParcel.id ? updatedParcel : parcel
    );
    this.updateNewOrEditedParcelIds(updatedParcel.id);
    this.updateInvalidParcels();
  }

  onUnitUpdate(updatedUnit: Unit): void {
    this.unit = updatedUnit;
  }

  onFinished(): void {
    // Force reload vector tiles and refresh unit hierarchy with farms and parcel data
    const unit = { ...this.$store.state.selectedUnit };
    unit.LastUpdate = `${new Date().getTime()}`;
    this.$store.dispatch('setSelectedUnit', unit);
    this.$store.dispatch('setIsShapeEditingMode', false);
  }

  private hasChangedParcels(): boolean {
    return this.parcels.some((parcel: EditableParcel) => parcel.isEdited || parcel.isNew || parcel.isDeleted);
  }

  onCloseDialog(): void {
    if (this.hasChangedParcels()) {
      Modal.confirm({
        title: this.$t('doYouWantExit'),
        content: this.$t('youHaveNotSavedChanges'),
        onOk: () => {
          this.$store.dispatch('setIsShapeEditingMode', false);
        }
      });
    } else {
      this.$store.dispatch('setIsShapeEditingMode', false);
    }
  }

  onClose(event: Event): void {
    if (this.hasChangedParcels()) {
      event.preventDefault();
      event.returnValue = false;
    }
  }
}
