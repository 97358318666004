














import { Component, Vue, Watch } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import DefaultMode from '@/components/radar/modes/DefaultMode.vue';
import SplitScreenMode from '@/components/radar/modes/SplitScreenMode.vue';
import EditShapesMode from '@/components/radar/shapes-management/EditShapesMode.vue';
import { UnitHierarchy } from '@/interfaces/unitHierarchy';

@Component({
  components: {
    SplitScreenMode,
    DefaultMode,
    Header,
    EditShapesMode
  }
})
export default class RadarPage extends Vue {
  get selectedUnitHierarchy(): UnitHierarchy {
    return this.$store.state.selectedUnitHierarchy;
  }

  @Watch('selectedUnitHierarchy')
  onUnitHierarchyChanged(): void {
    if (
      this.selectedUnitHierarchy &&
      this.$store.state.units.length === 1 &&
      (!this.selectedUnitHierarchy.Farms || this.selectedUnitHierarchy.Farms.length === 0)
    ) {
      this.$store.dispatch('setIsShapeEditingMode', true);
    }
  }

  get isSplitScreenMode(): boolean {
    return this.$store.state.isSplitScreenMode;
  }

  get isEditShapesMode(): boolean {
    // return this.$store.state.isShapeEditingMode;
    // TODO: show empty unit rather than allowing shape editing functionality
    // uncomment line above if want to enable shape editing
    return false;
  }

  onChangeMode(isSplit: boolean): void {
    this.$store.dispatch('setIsSplitScreenMode', isSplit);
  }
}
