








import { Component, Vue } from 'vue-property-decorator';
import CoordinatesInfo from '@/components/radar/CoordinatesInfo.vue';
import { ProductType } from '@/enums/productType';

@Component({
  components: {
    CoordinatesInfo
  }
})
export default class FooterInfo extends Vue {
  productType = ProductType;
}
