
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

@Component({
  extends: Line,
  mixins: [reactiveProp]
})
export default class LineChart extends Vue {
  @Prop() chartData: any;
  @Prop() options: any;
  public renderChart!: (chartData: any, options: any) => void;
  public addPlugin: (plugin?: unknown) => void;

  @Watch('options')
  onOptionsChanged(): void {
    this.renderChart(this.chartData, this.options);
  }

  mounted(): void {
    this.renderChart(this.chartData, this.options);
    this.$emit('onChartAdded', this.$data._chart);
  }
}
