var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasLegend)?_c('div',{staticClass:"container-legend"},[(_vm.hasAnalyticLegend)?_c('div',{staticClass:"container-header"},[_c('div',{attrs:{"data-cy":"amountOfFields"}},[_vm._v(_vm._s(_vm.$tc('fieldsWithAnalytic', _vm.amountOfFields)))]),_c('div',{staticClass:"container-header-date"},[_vm._v(_vm._s(_vm.headerTitleDate))])]):_vm._e(),(_vm.isRadarLegend)?_c('div',{staticClass:"container-header"},[_c('div',{staticClass:"container-header-date"},[_vm._v(_vm._s(_vm.radarTitleDate))])]):_vm._e(),(_vm.hasAnalyticLegend)?_c('a-collapse',{attrs:{"activeKey":"analytic"}},[_c('a-collapse-panel',{key:"analytic",attrs:{"data-cy":"layerName","header":_vm.analyticTitle}},[_c('div',[_c('div',{staticClass:"item"},[_c('div',[_vm._v(_vm._s(_vm.analyticColumnTitle))]),_c('div',[_vm._v(_vm._s(_vm.$t('parcels')))])]),_vm._l((Object.keys(_vm.legend)),function(color){return _c('div',{key:color,staticClass:"item"},[_c('div',{staticClass:"item-title"},[_c('div',{staticClass:"item-fill",style:({
                backgroundColor: color
              })}),_c('span',{attrs:{"data-cy":"legendRange"}},[_vm._v(_vm._s(_vm.getAnalyticColumnLabel(color))+" "+_vm._s(_vm.getAnalyticColumnLabel(color) === _vm.updatedLegendName ? '' : _vm.analyticColumnValue))])]),_c('div',{staticClass:"value",attrs:{"data-cy":"parcelCount"}},[_vm._v(_vm._s(_vm.getParcelsCountInBucket(color)))])])}),(_vm.additionalLegend)?_c('div',{staticClass:"addl-legend"},_vm._l((Object.keys(_vm.additionalLegend)),function(color){return _c('div',{key:color,staticClass:"item"},[_c('div',{staticClass:"item-title"},[_c('div',{staticClass:"item-fill",style:({
                  border: color.toLowerCase() === '#ffffff' ? '1px solid #ccc' : null,
                  backgroundColor: color
                })}),_c('span',{attrs:{"data-cy":"additionalLabel"}},[_vm._v(_vm._s(_vm.additionalLegend[color].label))])]),_c('div',{staticClass:"value",attrs:{"data-cy":"additionalParcel"}},[_vm._v(_vm._s(_vm.additionalLegend[color].value))])])}),0):_vm._e()],2)])],1):_vm._e(),(_vm.isRadarLegend)?_c('a-collapse',{attrs:{"activeKey":"radar"}},[_c('a-collapse-panel',{key:"radar",attrs:{"header":_vm.$store.state.analytic.selectedProductType === _vm.productType.FIELD_INFO ? _vm.radarTitle : _vm.vegetativeIndexRadarTitle}},[_vm._l((Object.keys(
          _vm.$store.state.analytic.selectedProductType === _vm.productType.FIELD_INFO
            ? _vm.radarLegend
            : _vm.vegetativeIndicesRadarLegend
        )),function(key,index){return _c('div',{key:key,staticClass:"item"},[_c('div',{staticClass:"item-title"},[_c('div',{staticClass:"item-fill",style:({
              backgroundColor:
                _vm.$store.state.analytic.selectedProductType === _vm.productType.FIELD_INFO
                  ? _vm.radarLegend[key]
                  : _vm.vegetativeIndicesRadarLegend[key]
            })}),_c('span',{attrs:{"data-cy":"fieldInfoKey"}},[_vm._v(_vm._s(key)+" "+_vm._s(_vm.getRadarColumnValue(key)))])]),_c('div',{staticClass:"value",attrs:{"data-cy":"fieldInfoValue"}},[_vm._v(_vm._s(_vm.getRadarCountInBucket(key, index)))])])}),(_vm.$store.state.analytic.selectedProductType === _vm.productType.VEGETATIVE_INDEX)?_c('div',{staticClass:"crop-download"},[_c('a-button',{attrs:{"data-cy":"crop-monitoring","icon":"download","type":"primary"},on:{"click":_vm.cropMonitoringCSV}},[_vm._v(_vm._s(_vm.$t('downloadCropMonitoring')))])],1):_vm._e(),(_vm.$store.state.analytic.selectedProductType === _vm.productType.FIELD_INFO && _vm.isBoundaries)?_c('div',{staticClass:"download-buttons",attrs:{"data-html2canvas-ignore":"true"}},[_c('a-button',{attrs:{"data-cy":"downloadUnitShape","icon":"download","type":"primary"},on:{"click":function($event){return _vm.downloadShape()}}},[_vm._v(_vm._s(_vm.$t('downloadShape')))])],1):_vm._e()],2)],1):_vm._e(),(_vm.isAllowToPublishUnpublish)?_c('a-collapse',{attrs:{"activeKey":"admin"}},[_c('a-collapse-panel',{key:"admin",attrs:{"header":_vm.$t('admin')}},[(_vm.hasUnPublishedSurveys())?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.publishSurveys()}}},[_vm._v(_vm._s(_vm.$t('publish')))]):_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.publishSurveys(true)}}},[_vm._v(_vm._s(_vm.$t('unPublish')))])],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }