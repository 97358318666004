import { Map, MapboxGeoJSONFeature, Point } from 'mapbox-gl';

export class FeaturesSelector {
  private map: Map = null;
  private layerId: string = null;
  private start: Point;
  private current: Point;
  private box: HTMLDivElement;
  private canvas: HTMLElement;
  private onMouseDownFn = this.onMouseDown.bind(this);
  private onMouseMoveFn = this.onMouseMove.bind(this);
  private onMouseUpFn = this.onMouseUp.bind(this);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onFeaturesSelected = (features: MapboxGeoJSONFeature[]) => {};

  public init(map: Map, layerId: string, onFeaturesSelected: (features: MapboxGeoJSONFeature[]) => void): void {
    this.map = map;
    this.layerId = layerId;
    this.onFeaturesSelected = onFeaturesSelected;

    this.canvas = this.map.getCanvasContainer();
    this.canvas.addEventListener('mousedown', this.onMouseDownFn, true);
  }

  private mousePos(e): Point {
    const rect = this.canvas.getBoundingClientRect();
    return new Point(e.clientX - rect.left - this.canvas.clientLeft, e.clientY - rect.top - this.canvas.clientTop);
  }

  private onMouseDown(e): void {
    if (!(e.shiftKey && e.button === 0)) return;
    this.map.dragPan.disable();
    document.addEventListener('mousemove', this.onMouseMoveFn);
    document.addEventListener('mouseup', this.onMouseUpFn);
    this.start = this.mousePos(e);
  }

  private onMouseMove(e): void {
    this.current = this.mousePos(e);

    if (!this.box) {
      this.box = document.createElement('div');
      this.box.classList.add('boxdraw');
      this.canvas.appendChild(this.box);
    }

    const minX = Math.min(this.start.x, this.current.x),
      maxX = Math.max(this.start.x, this.current.x),
      minY = Math.min(this.start.y, this.current.y),
      maxY = Math.max(this.start.y, this.current.y);

    const pos = 'translate(' + minX + 'px,' + minY + 'px)';
    this.box.style.transform = pos;
    this.box.style['WebkitTransform'] = pos;
    this.box.style.width = maxX - minX + 'px';
    this.box.style.height = maxY - minY + 'px';
  }

  private onMouseUp(e): void {
    this.finish(this.box ? [this.start, this.mousePos(e)] : null);
  }

  private finish(bbox): void {
    document.removeEventListener('mousemove', this.onMouseMoveFn);
    document.removeEventListener('mouseup', this.onMouseUpFn);

    if (this.box) {
      this.box.parentNode.removeChild(this.box);
      this.box = null;
    }

    if (bbox) {
      const features = this.map.queryRenderedFeatures(bbox, {
        layers: [this.layerId]
      });
      this.onFeaturesSelected(features);
    }

    this.map.dragPan.enable();
  }
}
