









import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BarChart from '@/components/charts/BarChart.vue';
import { getParcelCropStage } from '@/services/analyticData';
import { DashboardAnalyticData } from '@/interfaces/dashboardAnalyticData';

@Component({
  components: {
    BarChart
  }
})
export default class AnalyticsByGrowthStageBarChart extends Vue {
  @Prop() analytics: DashboardAnalyticData[];
  @Prop() label: string;
  @Prop() showTotal: boolean;

  chartData = null;
  chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: '#4E596D',
            min: 0,
            max: 100
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: '#4E596D'
          }
        }
      ]
    }
  };

  mounted(): void {
    this.setupChartData();
  }

  @Watch('analytics')
  onAnalyticDataChanged(): void {
    this.setupChartData();
  }

  setupChartData(): void {
    const data = this.getChartData();
    const options = Object.assign({}, this.chartOptions);
    options.scales.yAxes[0].ticks.max = Math.ceil(Math.max(...data.datasets[0].data) / 10) * 10 + 10;
    this.chartOptions = options;
    this.chartData = data;
  }

  private getChartData(): any {
    const data = {};
    this.analytics.forEach((analyticData: DashboardAnalyticData) => {
      const parcel = this.$store.state.parcels[analyticData.ParcelID];
      if (parcel) {
        const stage = getParcelCropStage(parcel, this.$store.state.analytic.fromTo.to);
        data[stage] = data[stage] || { totalValue1: 0, totalValue2: 0 };
        data[stage].totalValue1 += analyticData.value1;
        data[stage].totalValue2 += analyticData.value2;
      }
    });

    const labels = Object.keys(data).sort((a: string, b: string) => (parseInt(a) > parseInt(b) ? 1 : -1));
    const datasets = [
      {
        datalabels: {
          color: '#161c26',
          align: 'top',
          anchor: 'end'
        },
        backgroundColor: '#029973',
        barThickness: 20,
        data: labels.map((name) => {
          if (this.showTotal) {
            return Math.round(data[name].totalValue1 * 100) / 100;
          }
          return data[name].totalValue2
            ? Math.round((data[name].totalValue1 / data[name].totalValue2) * 100 * 100) / 100
            : 0;
        })
      }
    ];

    return { datasets, labels };
  }
}
