













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class MapFilterTag extends Vue {
  @Prop() filter: string;
  @Prop() title: string;
  MAX_LENGTH = 19;

  get tag(): string {
    if (this.$store.state.mapParcelFilters[this.filter].length > 0) {
      const title = this.title ? `${this.title}: ` : '';
      return title + this.$store.state.mapParcelFilters[this.filter].join(', ');
    }
    return '';
  }

  handleClose(): void {
    this.$emit('close');
  }
}
