var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mapbox',{attrs:{"access-token":_vm.mapBoxToken,"map-options":{
    container: 'map-container-editing',
    style: 'mapbox://styles/mapbox/satellite-v9',
    preserveDrawingBuffer: true,
    dragRotate: false,
    touchZoomRotate: false,
    boxZoom: false
  },"scale-control":{ show: true },"nav-control":{ show: true, position: 'bottom-left' }},on:{"map-load":_vm.loaded}})}
var staticRenderFns = []

export { render, staticRenderFns }