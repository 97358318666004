





































































import { Component, Vue } from 'vue-property-decorator';
import { Farm } from '@/interfaces/farm';
import { MapCoordinates } from '@/interfaces/mapCoordinates';
import Map from '@/components/radar/Map.vue';
import FooterInfo from '@/components/radar/FooterInfo.vue';
import HeaderInfo from '@/components/radar/HeaderInfo.vue';
import Footer from '@/components/Footer.vue';
import RightSide from '@/components/radar/RightSide.vue';
import API from '@/services/api';
import { ParcelPixel } from '@/interfaces/parcelPixel';

@Component({
  components: {
    Map,
    FooterInfo,
    HeaderInfo,
    Footer,
    RightSide
  }
})
export default class DefaultMode extends Vue {
  initLatLon = null;
  analyticDataLayer = null;
  groundTruth = null;

  mounted(): void {
    if (this.$route.query.lat && this.$route.query.lon) {
      this.initLatLon = [
        parseFloat(this.$route.query.lon as string),
        parseFloat(this.$route.query.lat as string),
        this.$route.query.zoom ? parseInt(this.$route.query.zoom as string) : 16
      ];
    }
    if (
      this.$store.state.analytic.selectedProductType !== null &&
      this.$store.state.analytic.selectedProductType !== undefined
    ) {
      this.runActivityLog();
    }
    this.$watch(
      () => this.$store.state.analytic.selectedProductType,
      (newValue) => {
        if (newValue !== null && newValue !== undefined) {
          this.runActivityLog();
        }
      }
    );
  }

  runActivityLog(): void {
    API.userActivityLog(this.$store.state.userInfo.Email, this.$store.state.analytic.selectedProductType, 'Radar').then(
      (result) => {
        this.$store.dispatch('setUserActivity', result);
      }
    );
  }

  clearInitLatLon(): void {
    this.initLatLon = null;
  }

  updateAnalyticData(): void {
    this.$emit('updateAnalyticData', true);
  }

  handleFarmChange(farm: Farm): void {
    this.$store.dispatch('setSelectedFarm', farm);
  }

  handlePrintPagesChange(payload: any): void {
    this.$store.dispatch('setPrintPages', payload);
  }

  handleIsGlobalLoaderVisibleChange(isVisible: boolean): void {
    this.$store.dispatch('setIsGlobalLoaderVisible', isVisible);
  }

  handleClearSelectedParcels(): void {
    this.$store.dispatch('analytic/clearSelectedParcels');
  }

  handleSetPrint(payload: any): void {
    this.$store.dispatch('setPrint', payload);
  }

  handleSelectedParcelChange(payload: any): void {
    this.$store.dispatch('analytic/setSelectedParcel', payload);
  }

  handleSetMapCoordinates(coordinates: MapCoordinates): void {
    this.$store.dispatch('setMapCoordinates', coordinates);
  }

  handleSetHistogramData(histogramData: any): void {
    this.$store.dispatch('analytic/setHistogramData', histogramData);
  }

  setMultipleSelectedParcels(payload: any): void {
    this.$store.dispatch('analytic/setMultipleSelectedParcels', payload);
  }

  setNdviZonesRange(range: [number, number]): void {
    this.$store.dispatch('setNdviZonesRange', range);
  }

  setSoilErosionHistogramData(payload: any): void {
    this.$store.dispatch('setSoilErosionHistogramData', payload);
  }
}
