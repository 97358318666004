







































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Map from '@/components/radar/Map.vue';
import LayerSelector from '@/components/radar/LayerSelector.vue';
import { ProductType } from '@/enums/productType';
import Timeline from '@/components/Timeline.vue';
import ParcelsSelection from '@/components/ParcelsSelection.vue';
import syncMaps from '@mapbox/mapbox-gl-sync-move';
import { initDrawingFakeCursor } from '@/services/fake-cursor';
import mapboxgl from 'mapbox-gl';
import { Farm } from '@/interfaces/farm';
import { LayerGroupName } from '@/enums/layerGroupName';
import { Parcel } from '@/interfaces/parcel';
import { FromTo } from '@/interfaces/fromTo';
import DateItem from '@/components/radar/DateItem.vue';
import { Unit } from '@/interfaces/unit';

@Component({
  components: {
    Map,
    LayerSelector,
    Timeline,
    ParcelsSelection,
    DateItem
  }
})
export default class SplitScreenMode extends Vue {
  private leftMap: mapboxgl.Map = null;
  private rightMap: mapboxgl.Map = null;

  get secondarySelectedProductType(): ProductType {
    return this.$store.state.analytic.selectedProductType;
  }

  get secondaryFromTo(): FromTo {
    return this.$store.state.analyticSecondary.fromTo;
  }

  get selectedSecondaryParcels(): Parcel[] {
    return this.$store.state.analyticSecondary.selectedParcels;
  }

  get selectedFarm(): Farm {
    return this.$store.state.selectedFarm;
  }

  get selectedUnit(): Unit {
    return this.$store.state.selectedUnit;
  }

  get selectedParcel(): Parcel {
    return this.$store.state.analytic.selectedParcel;
  }

  get selectedSecondaryParcel(): Parcel {
    return this.$store.state.analyticSecondary.selectedParcel;
  }

  mounted(): void {
    this.$store.dispatch('clearMapParcelFilter'); // reset filters
    this.syncMapsState();
    this.updateSecondaryAnalyticData();
    this.$store.dispatch('setNdviZonesRange', [0, 1]);
  }

  @Watch('selectedSecondaryParcels')
  onSelectedParcelsChanged(): void {
    this.$store.dispatch('analyticSecondary/updateParcelsSummary');
  }

  @Watch('secondaryFromTo')
  onFromToChanged(): void {
    this.updateSecondaryAnalyticData();
  }

  @Watch('secondarySelectedProductType')
  onSelectedProductTypeChanged(): void {
    this.updateSecondaryAnalyticData();
  }

  @Watch('selectedFarm')
  onSelectedFarmChanged(): void {
    this.syncMapsState();
    this.updateSecondaryAnalyticData();
  }

  @Watch('selectedUnit')
  onSelectedUnitChanged(): void {
    this.$store.dispatch('analyticSecondary/clearSelectedParcels');
  }

  @Watch('selectedParcel')
  onSelectedParcelChanged(): void {
    // sync on search from header
    if (
      this.selectedParcel &&
      this.selectedSecondaryParcel &&
      this.selectedParcel.id !== this.selectedSecondaryParcel.id
    ) {
      this.$store.dispatch('analyticSecondary/setSelectedParcel', { parcel: this.selectedParcel });
    }
  }

  private syncMaps(): void {
    if (this.leftMap && this.rightMap) {
      syncMaps(this.leftMap, this.rightMap);
      initDrawingFakeCursor(this.leftMap, this.rightMap);
      initDrawingFakeCursor(this.rightMap, this.leftMap);
    }
  }

  onLeftMapLoaded(map: mapboxgl.Map): void {
    this.leftMap = map;
    this.syncMaps();
  }

  onRightMapLoaded(map: mapboxgl.Map): void {
    this.rightMap = map;
    this.syncMaps();
  }

  handleFarmChange(farm: Farm): void {
    this.$store.dispatch('setSelectedFarm', farm);
  }

  handleClearSelectedParcels(): void {
    this.$store.dispatch('analytic/clearSelectedParcels');
    this.$store.dispatch('analyticSecondary/clearSelectedParcels');
  }

  handleSecondaryClearSelectedParcels(): void {
    this.$store.dispatch('analyticSecondary/clearSelectedParcels');
    this.$store.dispatch('analytic/clearSelectedParcels');
  }

  handleUnSelectParcel(parcel: Parcel): void {
    this.$store.dispatch('analytic/unselectParcel', { parcel });
    this.$store.dispatch('analyticSecondary/unselectParcel', { parcel });
  }

  handleSelectedParcelChange(payload: any): void {
    this.$store.dispatch('analytic/setSelectedParcel', payload);
    this.$store.dispatch('analyticSecondary/setSelectedParcel', payload);
  }

  handleSecondarySelectedParcelChange(payload: any): void {
    this.$store.dispatch('analyticSecondary/setSelectedParcel', payload);
    this.$store.dispatch('analytic/setSelectedParcel', payload);
  }

  handleSetHistogramData(histogramData: any): void {
    this.$store.dispatch('analytic/setHistogramData', histogramData);
  }

  handleSecondarySetHistogramData(histogramData: any): void {
    this.$store.dispatch('analyticSecondary/setHistogramData', histogramData);
  }

  setMultipleSelectedParcels(payload: any): void {
    this.$store.dispatch('analytic/setMultipleSelectedParcels', payload);
    this.$store.dispatch('analyticSecondary/setMultipleSelectedParcels', payload);
  }

  setSecondaryMultipleSelectedParcels(payload: any): void {
    this.$store.dispatch('analyticSecondary/setMultipleSelectedParcels', payload);
    this.$store.dispatch('analytic/setMultipleSelectedParcels', payload);
  }

  setNdviZonesRange(range: [number, number]): void {
    this.$store.dispatch('setNdviZonesRange', range);
  }

  setSoilErosionHistogramData(payload: any): void {
    this.$store.dispatch('setSoilErosionHistogramData', payload);
  }

  handleTransparencyChange(value: number): void {
    this.$store.dispatch('analytic/transparency', { value });
  }

  handleSecondaryTransparencyChange(value: number): void {
    this.$store.dispatch('analyticSecondary/transparency', { value });
  }

  handleApplyFilterGroundMeasurementsChange(isSelected: boolean): void {
    this.$store.dispatch('applyFilterGroundMeasurements', isSelected);
  }

  handleLayerSelect(payload): void {
    this.$store.dispatch('analytic/selectLayer', payload);
  }

  handleSecondaryLayerSelect(payload): void {
    this.$store.dispatch('analyticSecondary/selectLayer', payload);
  }

  handleUnSelectLayer(payload): void {
    this.$store.dispatch('analytic/unSelectLayer', payload);
  }

  handleSecondaryUnSelectLayer(payload): void {
    this.$store.dispatch('analyticSecondary/unSelectLayer', payload);
  }

  handleUnSelectAllLayers(layerGroupName: LayerGroupName): void {
    this.$store.dispatch('analytic/unSelectAllLayers', layerGroupName);
  }

  handleSecondaryUnSelectAllLayers(layerGroupName: LayerGroupName): void {
    this.$store.dispatch('analyticSecondary/unSelectAllLayers', layerGroupName);
  }

  handleSetProductType(payload): void {
    this.$store.dispatch('analytic/setProductType', payload);
  }

  handleSecondarySetProductType(payload): void {
    this.$store.dispatch('analyticSecondary/setProductType', payload);
  }

  handleTimelineFromToChange(fromTo: FromTo): void {
    this.$store.dispatch('analytic/setTimelineFromTo', fromTo);
  }

  handleFromToChange(fromTo: FromTo): void {
    this.$store.dispatch('analytic/setFromTo', fromTo);
  }

  handleSecondaryTimelineFromToChange(fromTo: FromTo): void {
    this.$store.dispatch('analyticSecondary/setTimelineFromTo', fromTo);
  }

  handleSecondaryFromToChange(fromTo: FromTo): void {
    this.$store.dispatch('analyticSecondary/setFromTo', fromTo);
  }

  private updateSecondaryAnalyticData(force = false): void {
    this.$store.dispatch('analyticSecondary/updateAnalyticData', { pageName: this.$router.currentRoute.name, force });
    this.$store.dispatch('analyticSecondary/updateAnalyticFullData', {
      pageName: this.$router.currentRoute.name,
      force
    });
  }

  private syncMapsState(): void {
    this.$store.dispatch('analyticSecondary/cloneState', this.$store.state.analytic); // sync 2 maps
    this.$store.dispatch('analyticSecondary/setProductGroup', {
      productGroup: this.$store.state.analytic.selectedProductGroup,
      pageName: this.$router.currentRoute.name
    });
    this.$store.dispatch('analyticSecondary/setProductType', {
      productType: this.$store.state.analytic.selectedProductType,
      pageName: this.$router.currentRoute.name
    });

    this.$store.dispatch('analyticSecondary/setAnalyticData', [...this.$store.state.analytic.analyticData]);
    this.$store.dispatch('analyticSecondary/setAnalyticFullData', [...this.$store.state.analytic.analyticFullData]);
  }
}
