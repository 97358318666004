


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { RadarProductTabItem } from '@/interfaces/productTabItem';
import { ProductGroupName } from '@/enums/productGroupName';

@Component({})
export default class RadarProductTypeSelector extends Vue {
  @Prop() productTabs: RadarProductTabItem[];

  get selectedKey() {
    return this.$store.state.analytic.selectedProductGroup;
  }

  get activeKey(): ProductGroupName {
    const selectedProductTab = this.productTabs.find((tab: RadarProductTabItem) => {
      return tab.productGroup === this.$store.state.analytic.selectedProductGroup;
    });
    return selectedProductTab ? selectedProductTab.productGroup : this.productTabs[0]?.productGroup;
  }

  onProductGroupChange(productGroup: ProductGroupName): void {
    this.$store.dispatch('setPreSelectedParcel', { parcel: null, survey: null });
    this.$store.dispatch('analytic/setProductGroup', { productGroup, pageName: this.$router.currentRoute.name });
  }
}
