






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BarChart from '@/components/charts/BarChart.vue';
import { getParcelCycle, getParcelStatus, parcelMatchFilters } from '@/services/analyticData';
import { DashboardAnalyticData } from '@/interfaces/dashboardAnalyticData';
import { CHART_TYPE } from '@/services/charts';
import { Parcel } from '@/interfaces/parcel';
import { stringToMomentDate } from '@/services/date';
import { ProductType } from '@/enums/productType';

interface HarvestedInfo {
  harvested: {
    [key: string]: Parcel;
  };
  partialHarvested: {
    [key: string]: Parcel;
  };
}

@Component({
  components: {
    BarChart
  }
})
export default class AnalyticsBarChart extends Vue {
  @Prop() analytics: DashboardAnalyticData[];
  @Prop() label: string;
  @Prop() yAxesMax: number;
  @Prop() hideNumberOfCycles: boolean;
  @Prop() showTotal: boolean;

  selectedType: string = CHART_TYPE.VARIETY;
  CHART_TYPE = CHART_TYPE;

  chartData = null;
  chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: '#4E596D',
            min: 0,
            max: 100
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: '#4E596D'
          }
        }
      ]
    }
  };

  mounted(): void {
    this.setupChartData();
  }

  @Watch('analytics')
  onAnalyticDataChanged(): void {
    this.setupChartData();
  }

  setupChartData(): void {
    const data = this.getChartData();
    const options = Object.assign({}, this.chartOptions);
    options.scales.yAxes[0].ticks.max = this.yAxesMax || Math.ceil(Math.max(...data.datasets[0].data) / 10) * 10 + 10;
    this.chartOptions = options;
    this.chartData = data;
  }

  private getChartData(): any {
    const data = {};
    let numberOfParcels = [];
    let listOfParcels = [];
    let countParcels;
    this.analytics.forEach((analyticData: DashboardAnalyticData) => {
      listOfParcels.push(analyticData.ParcelID);
      const parcel = this.$store.state.parcels[analyticData.ParcelID];
      numberOfParcels.push(parcel);
      const momentTo = stringToMomentDate(this.$store.state.analytic.fromTo.to);
      if (parcel) {
        if (
          parcel &&
          parcel.HarvestDates &&
          parcel.HarvestDates.length &&
          parcelMatchFilters(parcel, this.$store.state.mapParcelFilters, this.$store.state.analytic.fromTo) &&
          momentTo.isSameOrAfter(stringToMomentDate(parcel.HarvestDates[0]))
        ) {
          countParcels = null;
        } else {
          if (this.selectedType === CHART_TYPE.VARIETY) {
            data[parcel.Variety] = data[parcel.Variety] || { totalValue1: 0, totalValue2: 0, totalArea: 0 };
            data[parcel.Variety].totalValue1 += analyticData.value1;
            data[parcel.Variety].totalValue2 += analyticData.value2;
            data[parcel.Variety].totalArea += parcel.Area;
          }
          if (this.selectedType === CHART_TYPE.CROP_SEASON && parcel.CropSeason !== undefined) {
            data[parcel.CropSeason] = data[parcel.CropSeason] || { totalValue1: 0, totalValue2: 0, totalArea: 0 };
            data[parcel.CropSeason].totalValue1 += analyticData.value1;
            data[parcel.CropSeason].totalValue2 += analyticData.value2;
            data[parcel.CropSeason].totalArea += parcel.Area;
          }

          if (this.selectedType === CHART_TYPE.STATUS) {
            const status = getParcelStatus(
              parcel,
              this.$store.state.analytic.fromTo.to,
              this.$root.$i18n,
              this.$store.state.selectedCountry
            );
            data[status] = data[status] || { totalValue1: 0, totalValue2: 0, totalArea: 0 };
            data[status].totalValue1 += analyticData.value1;
            data[status].totalValue2 += analyticData.value2;
            data[status].totalArea += parcel.Area;
          }
          if (this.selectedType === CHART_TYPE.FARM_NAME && this.$store.state.selectedUnitHierarchy) {
            const farm = this.$store.state.selectedUnitHierarchy.Farms.find((farm) => farm.id === parcel.FarmID);
            if (farm) {
              data[farm.Name] = data[farm.Name] || { totalValue1: 0, totalValue2: 0, totalArea: 0 };
              data[farm.Name].totalValue1 += analyticData.value1;
              data[farm.Name].totalValue2 += analyticData.value2;
              data[farm.Name].totalArea += parcel.Area;
            }
          }
          if (this.selectedType === CHART_TYPE.NUMBER_OF_CYCLES) {
            const cycle = getParcelCycle(parcel, analyticData.SurveyDate);
            data[cycle] = data[cycle] || { totalValue1: 0, totalValue2: 0, totalArea: 0 };
            data[cycle].totalValue1 += analyticData.value1;
            data[cycle].totalValue2 += analyticData.value2;
            data[cycle].totalArea += parcel.Area;
          }
        }
      }
    });

    const labels = Object.keys(data)
      .sort((a: string, b: string) => (data[a].totalArea > data[b].totalArea ? -1 : 1))
      .slice(0, 20)
      .sort((a: string, b: string) => {
        if (this.selectedType === CHART_TYPE.NUMBER_OF_CYCLES) {
          return parseInt(a) > parseInt(b) ? 1 : -1;
        }
        return a > b ? 1 : -1;
      });

    const datasets = [
      {
        datalabels: {
          color: '#161c26',
          align: 'top',
          anchor: 'end'
        },
        backgroundColor: '#029973',
        barThickness: 20,
        data: labels.map((name) => {
          if (this.showTotal) {
            return Math.round(data[name].totalValue1 * 100) / 100;
          }
          return data[name].totalValue2
            ? Math.round((data[name].totalValue1 / data[name].totalValue2) * 100 * 100) / 100
            : 0;
        })
      }
    ];

    return { datasets, labels: labels.map((label) => (label === 'undefined' ? this.$t('undefined') : label)) };
  }

  private getHarvestedInfo(): HarvestedInfo {
    if (
      this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_PREDICTION ||
      this.$store.state.analytic.selectedProductType === ProductType.SUGAR_CONTENT_YIELD
    ) {
      return this.getSugarContentHarvestedInfo();
    }
    return null;
  }

  private getSugarContentHarvestedInfo(): HarvestedInfo {
    const harvested = {};
    const momentTo = stringToMomentDate(this.$store.state.analytic.fromTo.to);
    this.$store.getters.filteredParcelIds.forEach((parcelId: string) => {
      const parcel = this.$store.state.parcels[parcelId] as Parcel;
      if (
        parcel &&
        parcel.HarvestDates &&
        parcel.HarvestDates.length &&
        parcelMatchFilters(parcel, this.$store.state.mapParcelFilters, this.$store.state.analytic.fromTo) &&
        momentTo.isSameOrAfter(stringToMomentDate(parcel.HarvestDates[0]))
      ) {
        harvested[parcel.id] = parcel;
      }
    });

    return {
      harvested,
      partialHarvested: null
    };
  }
}
