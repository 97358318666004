import Axios, { AxiosError } from 'axios';
import qs from 'query-string';
import { Shape } from '@/interfaces/shape';
import { Parcel } from '@/interfaces/parcel';
import { Farm } from '@/interfaces/farm';
import { Unit } from '@/interfaces/unit';
import { Survey } from '@/interfaces/survey';
import { Country } from '@/interfaces/country';
import { UnitHierarchy } from '@/interfaces/unitHierarchy';
import { AnalyticData } from '@/interfaces/analyticData';
import { FeatureCollection } from '@turf/helpers';
import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { SearchResult } from '@/interfaces/searchResult';
import { Organization } from '@/interfaces/organization';
import { AnalyticType } from '@/enums/analyticType';
import { UserInfoInput } from '@/interfaces/userInfo';
import Auth from '@/services/auth';
import { message, notification } from 'ant-design-vue';
import { SurveySource } from '@/enums/surveySource';
import { CropType } from '@/interfaces/cropType';
import { UploadRequest } from '@/interfaces/uploadRequest';
import { saveAs } from 'file-saver';
import { GetAnalyticReportsParams, GetAnalyticTableParams, GroupedAnalyticData } from '@/interfaces/tableItem';
import csv from 'csvtojson';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { DownloadFileBaseUrl } from '@/enums/downloadFileBaseUrl';
import store from '@/store';
import pako from 'pako';
class API {
  static PRODUCT_MANAGER_URL = `${process.env.VUE_APP_APPSERVER}/api`;

  static getSurvey(surveyId: string): Promise<Survey> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/survey?surveyId=${surveyId}`, {
      withCredentials: true,
      headers: {
        email: store?.state?.userInfo?.Email
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUnitHierarchy(unitId: string): Promise<UnitHierarchy> {
    return Axios.get(
      `${API.PRODUCT_MANAGER_URL}/hierarchy?unit=${unitId}&published=true&exclude_surveys=true&exclude_ndvi=true`,
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUnitSummary(unitId: string, from: string, to: string): Promise<AnalyticSummaryItem[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/unit-summary?unit=${unitId}&from=${from}&to=${to}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getVegetativeIndexDates(unitId: string): Promise<any> {
    return Axios.get(`https://smartharvest-data-manager.azurewebsites.net/data/pixel-data/avl-dates/${unitId}/`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getFarmSummary(farmId: string, from: string, to: string): Promise<AnalyticSummaryItem[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/farm-summary?farm=${farmId}&from=${from}&to=${to}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcelSummary(parcelId: string, from: string, to: string): Promise<AnalyticSummaryItem[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/parcel-summary?parcel=${parcelId}&from=${from}&to=${to}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcelPixel(unitId: string, date: string, parcelId: string): Promise<any> {
    return Axios.get(
      `https://smartharvest-data-manager.azurewebsites.net/data/pixel-data/${unitId}/${date}/${parcelId}/`,
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getAllVegetativeIndex(unitId: string, date: string): Promise<any> {
    return Axios.get(`https://smartharvest-data-manager.azurewebsites.net/data/parcel-data/${unitId}/${date}/`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcelPixelVegetativeIndex(unitId: string, date: string, parcelId: string): Promise<any> {
    return Axios.get(
      `https://smartharvest-data-manager.azurewebsites.net/data/pixel-data/v2/${unitId}/${date}/${parcelId}/`,
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getVegetativeIndicesName(): Promise<any> {
    return Axios.get(`https://smartharvest.blob.core.windows.net/viewer-configs/fe_indices_display_names.json`)
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static vegetativeIndicesAccess(): Promise<any> {
    return Axios.get(`https://smartharvest.blob.core.windows.net/viewer-configs/mill_crop_stress_config.json`)
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getAnalyticsAccess(email: string): Promise<any> {
    return Axios.get(`https://smartharvest-data-manager.azurewebsites.net/api/analytics-dashboard/mills?email=${email}`)
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getMinMaxCropMonitoring(unitId: string, date: string): Promise<any> {
    return Axios.get(
      `https://smartharvest-data-manager.azurewebsites.net/data/agewise-index-ranges/${unitId}/${date}/`,
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getAnalyticsList(unit_id: string, email: string): Promise<void> {
    return Axios.post(
      `https://smartharvest-data-manager.azurewebsites.net/api/analytics-dashboard/config?email=${email}`,
      { unit_id },
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static userActivityLog(email: string, tab: string, screen: string): Promise<void> {
    return Axios.post(
      `${process.env.VUE_APP_AZURE}/logs/user-activity`,
      { email, tab, screen },
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static transform(json: any): Promise<any> {
    return Axios.post(`${API.PRODUCT_MANAGER_URL}/transform`, json, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getAnalyticData(
    unitId: string,
    from: string,
    to: string,
    source: SurveySource,
    loadUnpublished = false
  ): Promise<AnalyticData[]> {
    const url = `${API.PRODUCT_MANAGER_URL}/analytic-data2?unit=${unitId}&from=${from}&to=${to}&source=${source}${
      loadUnpublished ? '' : '&published=true'
    }`;
    return Axios.get(url, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then(({ data }) => data || [])
      .catch(API.handleError);
  }

  static getAnalyticDataForParcel(
    parcelId: string,
    analyticType: AnalyticType,
    loadUnpublished = false
  ): Promise<AnalyticData[]> {
    const url = `${API.PRODUCT_MANAGER_URL}/analytic-data-parcel?parcelId=${parcelId}&analyticType=${analyticType}${
      loadUnpublished ? '' : '&published=true'
    }`;
    return Axios.get(url, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then(({ data }) => data || [])
      .catch(API.handleError);
  }

  static getAnalyticFullData(
    unitId: string,
    from: string,
    to: string,
    source: SurveySource,
    loadUnpublished = false,
    excludeNDVI = false
  ): Promise<AnalyticData[]> {
    const url = `${API.PRODUCT_MANAGER_URL}/analytic-data?unit=${unitId}&from=${from}&to=${to}&source=${source}${
      loadUnpublished ? '' : '&published=true'
    }${excludeNDVI ? '&exclude_ndvi=true' : ''}`;
    return Axios.get(url, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then(({ data }) => data || [])
      .catch(API.handleError);
  }

  static getAnalyticTableData(
    params: GetAnalyticTableParams
  ): Promise<{ data: GroupedAnalyticData[]; count?: number }> {
    const query = qs.stringify(params);
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/analytic-data-grouped?${query}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then(({ data }) => data)
      .catch(API.handleError) as Promise<{ data: GroupedAnalyticData[]; count?: number }>;
  }

  static getAnalyticReports(params: GetAnalyticReportsParams): Promise<any> {
    const query = qs.stringify({ ...params, columns: JSON.stringify(params.columns) });
    return Axios.get(`${DownloadFileBaseUrl.BASE_URL}/download/analytic-reports-url/?${query}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((res) => {
        if (params['groundTruth'] !== undefined) {
          saveAs(res.data.data.download, `${params.fileName}.zip`);
        } else {
          saveAs(res.data.data.download, `${params.fileName}.xlsx`);
        }
      })
      .catch(API.handleError);
  }

  static getAnalyticDataFromStorage(fileName: string, source: string, dt: string): Promise<FeatureCollection> {
    return Axios.get(`https://storage.googleapis.com/${source}/${fileName}.json?v=${dt}`)
      .then((result) => result.data)
      .catch(() => null);
  }

  static publish(unitId: string, date: string, analytic: AnalyticType, isUnPublish = false): Promise<void> {
    return Axios.get(
      `${API.PRODUCT_MANAGER_URL}/publish?unit=${unitId}&date=${date}&analytic=${analytic}${
        isUnPublish ? '' : '&publish=true'
      }`,
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }
  static getCountries(): Promise<Country[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/countries?valid=true`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcel(parcelId: string): Promise<Parcel> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/parcel?id=${parcelId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static patchParcel(data: Partial<Parcel>): Promise<{ success: boolean; error: string }> {
    return Axios.patch(`${API.PRODUCT_MANAGER_URL}/parcel`, data, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcelAnalytics(parcelId: string, from: string, to: string): Promise<Parcel> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/parcel-analytics?parcel=${parcelId}&from=${from}&to=${to}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcels(farmId: string): Promise<Parcel[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/parcels?farm=${farmId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getCropTypes(): Promise<CropType[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/crop-types`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static upload(payload: UploadRequest): Promise<void> {
    return Axios.post(`${API.PRODUCT_MANAGER_URL}/batch-upload`, payload, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getShape(shapeId: string): Promise<Shape> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/shape?id=${shapeId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getFarm(farmId: string): Promise<Farm> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/farm?id=${farmId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static createFarm(UnitID: string, Name: string, Code: string): Promise<{ ID: string }> {
    return Axios.post(
      `${API.PRODUCT_MANAGER_URL}/farm`,
      { UnitID, Name, Code },
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUnit(unitId: string): Promise<Unit> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/unit?id=${unitId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getClusterId(surveyId: string): Promise<string> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/cluster-id-by-survey-id?surveyId=${surveyId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => (result.data && result.data.id ? result.data.id : null))
      .catch(API.handleError);
  }

  static getJson<T>(url: string): Promise<T> {
    return Axios.get(url, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static authorize(): Promise<AuthorizeResponse> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/authorize`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static logout(): Promise<void> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/logout`, {
      withCredentials: true,
      headers: {
        email: store?.state?.userInfo?.Email
      }
    })
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static updateShape(shape: Shape): Promise<{ error?: string }> {
    return Axios.patch(`${API.PRODUCT_MANAGER_URL}/shape`, shape, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static changePassword(id: string, currentPassword: string, password: string): Promise<boolean> {
    return Axios.post(
      `${API.PRODUCT_MANAGER_URL}/change-user-password`,
      { id, password, currentPassword },
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static changeUserInfo(userUpdateInfo: UserInfoInput): Promise<void> {
    return Axios.patch(`${API.PRODUCT_MANAGER_URL}/user`, userUpdateInfo, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static saveGroundTruth(unitId: string, data: any): Promise<void> {
    return Axios.post(`${API.PRODUCT_MANAGER_URL}/save-ground-truth?unitID=${unitId}`, data, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static saveYieldGroundTruth(unitId: string, data: any): Promise<void> {
    return Axios.post(`${API.PRODUCT_MANAGER_URL}/save-yield-ground-truth?unitID=${unitId}`, data, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static search(key: string, unitId: string): Promise<SearchResult[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/search?key=${key}&unit=${unitId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getOrganizations(countryId: string): Promise<Organization[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/organizations?country=${countryId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUnits(orgId: string): Promise<Unit[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/units?org=${orgId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getFarms(unitId: string): Promise<Farm[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/farms?unit=${unitId}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getCropMonitoringReport(unitId: string, date: string): Promise<any> {
    return Axios.get(`${DownloadFileBaseUrl.BASE_URL}/download/crop-stress-report/?unit_id=${unitId}&date=${date}`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((res) => {
        if (res?.status) {
          saveAs(res.data.data.download, `crop_monitoring_report.xlsx`);
        } else {
          API.handleError;
        }
      })
      .catch(API.handleError);
  }

  static getCropMonitoringColor(unitId: string, date: string): Promise<any> {
    return Axios.get(`https://smartharvest-data-manager.azurewebsites.net/data/parcel-data/v3/${unitId}/${date}/`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data.data.download)
      .catch(API.handleError);
  }

  static getParcelPixelColor(unitId: string, date: string, parcelId: string) {
    return Axios.get(
      `https://smartharvest-data-manager.azurewebsites.net/data/pixel-data/v3/${unitId}/${date}/${parcelId}/`,
      {
        withCredentials: true,
        headers: {
          email: localStorage.getItem('email')
        }
      }
    )
      .then((result) => {
        const url = result.data.data.download;

        return fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.arrayBuffer();
          })
          .then((buffer) => {
            const zipData = new Uint8Array(buffer);

            if (zipData[0] !== 0x1f || zipData[1] !== 0x8b) {
              throw new Error('Invalid GZIP header');
            }

            const decompressedData = pako.ungzip(zipData, { to: 'string' });
            const parsedData = JSON.parse(decompressedData);
            return parsedData;
          });
      })
      .catch((error) => {
        API.handleError(error);
      });
  }
  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/refresh-session`, {
      withCredentials: true,
      headers: {
        email: localStorage.getItem('email')
      }
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static handleError(serviceError: AxiosError): void {
    if (serviceError.response?.status === 403) {
      API.userActivityLog(localStorage.getItem('email'), 'logout', '').finally(() => {
        Auth.doLogin();
      });
      return;
    }

    const errorMsg = serviceError.response?.data?.message ? serviceError.response.data.message : serviceError.message;
    notification.error({
      message: 'Error',
      description: errorMsg,
      duration: 5,
      placement: 'bottomRight'
    });

    throw serviceError;
  }
}

export default API;
