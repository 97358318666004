import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
  Dropdown,
  Button,
  Menu,
  Input,
  Icon,
  AutoComplete,
  Select,
  Tabs,
  Breadcrumb,
  Radio,
  Collapse,
  Col,
  Row,
  Divider,
  DatePicker,
  Table,
  Checkbox,
  Layout,
  Switch,
  PageHeader,
  Card,
  FormModel,
  Form,
  ConfigProvider,
  Slider,
  Spin,
  Popover,
  Tooltip,
  Alert,
  Modal,
  Carousel,
  Tag
} from 'ant-design-vue';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';
import constants from '@/services/constants';
import VueClipboard from 'vue-clipboard2';

Vue.use(Dropdown);
Vue.use(Select);
Vue.use(Button);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(AutoComplete);
Vue.use(Input);
Vue.use(Tabs);
Vue.use(Breadcrumb);
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(Col);
Vue.use(Row);
Vue.use(Divider);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(Checkbox);
Vue.use(Layout);
Vue.use(Switch);
Vue.use(PageHeader);
Vue.use(Card);
Vue.use(FormModel);
Vue.use(Form);
Vue.use(ConfigProvider);
Vue.use(Slider);
Vue.use(Popover);
Vue.use(Spin);
Vue.use(Tooltip);
Vue.use(Alert);
Vue.use(VueI18n);
Vue.use(VueClipboard);
Vue.use(Modal);
Vue.use(Carousel);
Vue.use(Tag);

Vue.config.productionTip = false;

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: ['Request failed with status code 403', 'Request failed with status code 404'],
    integrations: [new Integrations.Vue({ Vue, logErrors: true })]
  });
}

const i18n = new VueI18n({
  locale: localStorage.getItem(constants.LOCAL_STORAGE_KEYS.LANG) || 'en',
  fallbackLocale: 'en',
  messages: {
    en
  }
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
