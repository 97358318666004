import { ProductType } from '@/enums/productType';
import { AnalyticType } from '@/enums/analyticType';
import { LayerName } from '@/enums/layerName';
import { LayerGroupName } from '@/enums/layerGroupName';
import { ProductGroupName } from '@/enums/productGroupName';
import { ProductLayersConfiguration } from '@/interfaces/productLayersConfiguration';
import { SurveySource } from '@/enums/surveySource';

export default class Constants {
  static GT_PARSE_DATE_FORMAT = 'MM-DD-YYYY';
  static DATE_FORMAT = 'YYYY-MM-DD';
  static DATE_FORMAT_LOCALIZED = 'YYYY-MM-DD';
  static mapBoxToken =
    'pk.eyJ1Ijoic21hcnQtaGFydmVzdCIsImEiOiJja3VpMmhmMDIwNXpkMnFvNngzYzI3ZTB4In0.XI00yoTd31KryhtPFtX8EQ';

  static editShapesColors = {
    DEFAULT_PARCEL_COLOR: '#309257',
    REPLACED_PARCEL_COLOR: '#214B6A',
    NEW_PARCEL_COLOR: '#047FD8',
    BLOCKED_PARCEL_COLOR: '#cf260d',
    INVALID_PARCEL_COLOR: '#ECCC28'
  };

  static statusColorMap = {
    Fresh: '#63c258',
    Ratoon: '#f2e111'
  };

  static plantingDateColorMap = {
    0: '#bb4441',
    1: '#d13f29',
    2: '#e7600c',
    3: '#f79b0e',
    4: '#fcc910',
    5: '#f2e111',
    6: '#a1e329',
    7: '#63c258',
    8: '#309257',
    9: '#1d5734',
    10: '#117797',
    11: '#05397e'
  };

  static numberOfCyclesColorMap = {
    0: '#117797',
    1: '#46B32B',
    2: '#8CD029',
    3: '#D2ED27',
    4: '#FBE625',
    5: '#EFA222',
    6: '#E35D1F',
    7: '#D7191C'
  };

  static cropStageColorMap = {
    0: '#ae0204',
    1: '#e34721',
    2: '#ef7726',
    3: '#fba32c',
    4: '#fec12f',
    5: '#ffda30',
    6: '#fff331',
    7: '#d8fc2a',
    8: '#89f61b',
    9: '#3bef0b',
    10: '#03e20e',
    11: '#10c347',
    12: '#1d96a3'
  };

  static indiaCountryIds = ['5e4e9c5a72310000e6004b25', '5e7ebac48e117b6f00f5f502'];

  static harvestedColor = '#FFFFFF';
  static partialHarvestColor = '#cbf2ff';
  static CLOUDY_ANALYTIC_VALUE = -1;
  static TOO_YOUNG_ANALYTIC_VALUE = -2;
  static NO_CLUSTERING_ANALYTIC_VALUE = -3;

  static farmsColorMap = [];

  static varietiesColorMap = {};

  static productAnalyticMap = {
    [ProductType.SUGAR_CONTENT_PREDICTION]: AnalyticType.SUGAR_CONTENT_PREDICTION,
    [ProductType.SUGAR_CONTENT_YIELD]: AnalyticType.SUGAR_CONTENT_YIELD,
    [ProductType.VEGETATIVE_INDEX]: AnalyticType.VEGETATIVE_INDEX
  };

  static parcelLayersList: LayerName[] = [
    LayerName.BOUNDARIES,
    LayerName.FARM,
    LayerName.VARIETY,
    LayerName.STATUS,
    LayerName.PLANTING_DATE,
    LayerName.HARVESTED
    // TODO: not used for Mahindra
    // LayerName.NUMBER_OF_CYCLES,
    // LayerName.CROP_STAGE
  ];

  static vegetableIndexLayerList: LayerName[] = [
    LayerName.NDVI,
    LayerName.EVI,
    LayerName.VARI,
    LayerName.RECI,
    LayerName.MSAVI,
    LayerName.GNDVI,
    LayerName.NDWI,
    LayerName.SAVI,
    LayerName.OSAVI,
    LayerName.ARVI,
    LayerName.SIPI,
    LayerName.GCI,
    LayerName.NDMI
  ];

  static defaultAnalyticLayerName = {
    [ProductType.SUGAR_CONTENT_PREDICTION]: LayerName.SUGAR_CONTENT_PREDICTION,
    [ProductType.SUGAR_CONTENT_YIELD]: LayerName.SUGAR_CONTENT_YIELD
  };

  static LOCAL_STORAGE_KEYS = {
    LANG: 'lang',
    SELECTED_COUNTRY_ID: 'countryId',
    SELECTED_ORGANIZATION_ID: 'organizationId',
    SELECTED_UNIT_ID: 'unitId',
    SELECTED_FARM_ID: 'farmId',
    SELECTED_PRODUCT: 'product'
  };

  static defaultProductTabsRadar = [
    ProductGroupName.SUGAR_CONTENT_YIELD,
    ProductGroupName.SUGAR_CONTENT_PREDICTION,
    ProductGroupName.FIELD_INFO,
    ProductGroupName.VEGETATIVE_INDEX
  ];

  static defaultLayersConfigurationRadar = [
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.SUGAR_CONTENT_PREDICTION],
      overviewLayers: [LayerName.SUGAR_CONTENT_PREDICTION],
      allowedProductTypes: [ProductType.SUGAR_CONTENT_PREDICTION],
      allowedProductGroup: ProductGroupName.SUGAR_CONTENT_PREDICTION,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.SUGAR_CONTENT_YIELD],
      overviewLayers: [LayerName.SUGAR_CONTENT_YIELD],
      allowedProductTypes: [ProductType.SUGAR_CONTENT_YIELD],
      allowedProductGroup: ProductGroupName.SUGAR_CONTENT_YIELD,
      isMultiple: true
    },
    {
      name: LayerGroupName.FIELD_INFO,
      layers: Constants.parcelLayersList,
      allowedProductTypes: [ProductType.FIELD_INFO],
      allowedProductGroup: ProductGroupName.FIELD_INFO
    },
    {
      name: LayerGroupName.VEGETATIVE_INDEX,
      layers: Constants.vegetableIndexLayerList,
      allowedProductTypes: [ProductType.VEGETATIVE_INDEX],
      allowedProductGroup: ProductGroupName.VEGETATIVE_INDEX
    },
    {
      name: LayerGroupName.BASEMAP,
      layers: [
        LayerName.SATELLITE,
        LayerName.SATELLITE_PLACES,
        LayerName.SATELLITE_ROADS,
        LayerName.GOOGLE_SATELLITE,
        LayerName.OSM
      ],
      allowedProductTypes: [ProductType.SUGAR_CONTENT_PREDICTION, ProductType.SUGAR_CONTENT_YIELD]
    }
  ];

  static productSurveySource = {
    [ProductType.SUGAR_CONTENT_YIELD]: SurveySource.SATELLITE,
    [ProductType.SUGAR_CONTENT_PREDICTION]: SurveySource.SATELLITE
  };

  static productGroupToAnalyticLayerGroup = {
    [ProductGroupName.FIELD_INFO]: LayerGroupName.FIELD_INFO,
    [ProductGroupName.SUGAR_CONTENT_YIELD]: LayerGroupName.ANALYTIC,
    [ProductGroupName.SUGAR_CONTENT_PREDICTION]: LayerGroupName.ANALYTIC,
    [ProductGroupName.VEGETATIVE_INDEX]: LayerGroupName.VEGETATIVE_INDEX
  };

  static defaultProductGroupAnalyticLayersConfiguration: ProductLayersConfiguration = {
    [ProductGroupName.FIELD_INFO]: Constants.parcelLayersList.map((layer: LayerName) => {
      return {
        name: ProductType.FIELD_INFO,
        defaultLayer: layer,
        isLayersVisible: false,
        isHeaderVisible: true,
        layers: [
          {
            name: layer
          }
        ],
        backgroundLayers: []
      };
    }),
    [ProductGroupName.VEGETATIVE_INDEX]: Constants.vegetableIndexLayerList.map((layer: LayerName) => {
      return {
        name: ProductType.VEGETATIVE_INDEX,
        defaultLayer: layer,
        isLayersVisible: false,
        isHeaderVisible: false,
        isVegetativeIndexVisible: true,
        layers: [
          {
            name: layer
          }
        ],
        backgroundLayers: []
      };
    }),
    [ProductGroupName.SUGAR_CONTENT_YIELD]: [
      {
        name: ProductType.SUGAR_CONTENT_YIELD,
        defaultLayer: LayerName.SUGAR_CONTENT_YIELD,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.SUGAR_CONTENT_YIELD
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.SUGAR_CONTENT_PREDICTION]: [
      {
        name: ProductType.SUGAR_CONTENT_PREDICTION,
        defaultLayer: LayerName.SUGAR_CONTENT_PREDICTION,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.SUGAR_CONTENT_PREDICTION
          }
        ],
        backgroundLayers: []
      }
    ]
  };
}
