

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import constants from '@/services/constants';
import Mapbox from 'mapbox-gl-vue';
import moment from 'moment';
import Constants from '@/services/constants';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { feature, featureCollection } from '@turf/helpers';
import { GeoJSONSource } from 'mapbox-gl';
import bbox from '@turf/bbox';
import API from '@/services/api';
import { EditableParcel } from '@/interfaces/editableParcel';
import { Unit } from '@/interfaces/unit';
import turfCenter from '@turf/center';
import turfBuffer from '@turf/buffer';
import { getOverlapData } from '@/services/shapesManagement';

@Component({
  components: {
    Mapbox
  }
})
export default class EditShapesMap extends Vue {
  @Prop() isDrawing: boolean;
  @Prop() featureIdToRemove: string;
  @Prop() parcels: EditableParcel[];
  @Prop() invalidParcelIds: string[];
  @Prop() blockedParcelIds: string[];
  @Prop() selectedParcelId: string;
  @Prop() newOrEditedParcelIds: string[];
  @Prop() unit: Unit;
  @Prop() isCreatingNewUnit: boolean;

  mapBoxToken = constants.mapBoxToken;
  private map = null;
  private draw: MapboxDraw = null;

  private parcelsSourceId = 'parcelsSourceId';
  private parcelsLayerId = 'parcelsLayerId';
  private parcelsBorderLayerId = 'parcelsBorderLayerId';
  private overlapLayerId = 'overlapLayerId';
  private overlapSourceId = 'overlapSourceId';
  private editedParcelsLayerId = 'editedParcelsLayerId';
  private editedParcelsSourceId = 'editedParcelsSourceId';
  private editedParcelsBorderLayerId = 'editedParcelsBorderLayerId';
  private parcelNamesLayerId = 'parcelsNamesLayerId';
  private editedParcelNamesSourceId = 'editedParcelNamesSourceId';
  private editedParcelNamesLayerId = 'editedParcelsNamesLayerId';

  loaded(map): void {
    this.map = map;

    this.initMapLayers();
    this.onParcelStatusChange();

    this.map.on('click', this.parcelsLayerId, (ev) => {
      ev.originalEvent.preventDefault();
      if (!this.isDrawing) {
        this.$emit('onParcelSelected', ev.features[0].properties.id);
      }
    });

    this.map.on('click', this.editedParcelsLayerId, (ev) => {
      ev.originalEvent.preventDefault();
      if (!this.isDrawing) {
        this.$emit('onParcelSelected', ev.features[0].properties.id);
      }
    });

    this.map.on('click', (ev) => {
      if (!ev.originalEvent.defaultPrevented && !this.isDrawing) {
        this.draw.deleteAll();
        this.$emit('onParcelSelected', null);
      }
    });

    this.draw = new MapboxDraw({
      displayControlsDefault: false
    });
    this.map.addControl(this.draw, 'bottom-left');
    this.map.on('draw.create', this.onDrawCreate);
    this.map.on('draw.update', this.onDrawUpdate);
    this.$emit('onMapLoaded');
  }

  private removeLayer(layerIds: string[], sourceId: string): void {
    layerIds.forEach((layerId: string) => {
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
    });
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  private initMapLayers(): void {
    this.removeLayer([this.parcelsLayerId, this.parcelsBorderLayerId, this.parcelNamesLayerId], this.parcelsSourceId);
    this.removeLayer([this.overlapLayerId], this.overlapSourceId);
    this.removeLayer([this.editedParcelsLayerId, this.editedParcelsBorderLayerId], this.editedParcelsSourceId);
    this.removeLayer([this.editedParcelNamesLayerId], this.editedParcelNamesSourceId);

    if (this.unit.id && this.unit.LLLong && this.unit.LLLat && this.unit.URLong && this.unit.URLat) {
      const bounds = [this.unit.LLLong, this.unit.LLLat, this.unit.URLong, this.unit.URLat];
      this.addParcelsLayer(bounds);
      this.map.fitBounds(bounds, {
        duration: 0
      });
    } else if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        this.map.jumpTo({ center: [position.coords.longitude, position.coords.latitude], zoom: 18 });
      });
    }

    this.addEditedParcelsLayer();
    this.addOverlapLayer();
  }

  private getParcelColor(parcel: EditableParcel): string {
    if (this.blockedParcelIds.includes(parcel.id)) {
      return constants.editShapesColors.BLOCKED_PARCEL_COLOR;
    }
    if (this.invalidParcelIds.includes(parcel.id)) {
      return constants.editShapesColors.INVALID_PARCEL_COLOR;
    }
    if (parcel.isReplacedAnotherParcel) {
      return constants.editShapesColors.REPLACED_PARCEL_COLOR;
    }
    if (parcel.isNew) {
      return constants.editShapesColors.NEW_PARCEL_COLOR;
    }
    return constants.editShapesColors.DEFAULT_PARCEL_COLOR;
  }

  private getParcelsStylingRules(parcels: EditableParcel[]) {
    const matchByIdRules = [];
    parcels.forEach((parcel: EditableParcel) => {
      matchByIdRules.push(parcel.id, this.getParcelColor(parcel));
    });
    return ['match', ['get', 'id'], ...matchByIdRules, constants.editShapesColors.DEFAULT_PARCEL_COLOR];
  }

  @Watch('invalidParcelIds')
  @Watch('blockedParcelIds')
  private onParcelStatusChange(): void {
    if (this.map && this.parcels.length) {
      const rules = this.getParcelsStylingRules(this.parcels);
      if (this.map.getLayer(this.parcelsLayerId)) {
        this.map.setPaintProperty(this.parcelsLayerId, 'fill-color', rules);
      }
      this.map.setPaintProperty(this.editedParcelsLayerId, 'fill-color', rules);
    }
  }

  @Watch('isCreatingNewUnit')
  private onCreatingNewUnit(): void {
    if (this.isCreatingNewUnit) {
      this.initMapLayers();
      this.$emit('onPreparedToNewUnit');
    }
  }

  @Watch('newOrEditedParcelIds')
  private onNewOrEditedParcelIdsChange(): void {
    const newOrEditedParcels = [];
    const notEditedParcelIds = [];
    this.parcels.forEach((parcel: EditableParcel) => {
      if (!parcel.isDeleted) {
        if (parcel.isNew || parcel.isEdited) {
          newOrEditedParcels.push(parcel);
        } else {
          notEditedParcelIds.push(parcel.id);
        }
      }
    });
    const source = this.map.getSource(this.editedParcelsSourceId) as GeoJSONSource;
    if (source) {
      source.setData(
        featureCollection(
          newOrEditedParcels.map((parcel: EditableParcel) =>
            feature(parcel.Shape.GeoJson.features[0].geometry, { id: parcel.id })
          )
        )
      );
      const namesSource = this.map.getSource(this.editedParcelNamesSourceId) as GeoJSONSource;
      if (namesSource) {
        namesSource.setData(
          featureCollection(
            newOrEditedParcels.map((parcel: EditableParcel) =>
              feature(
                {
                  type: 'Point',
                  coordinates: turfCenter(feature(parcel.Shape.GeoJson.features[0].geometry)).geometry.coordinates
                },
                { description: parcel.Name }
              )
            )
          )
        );
      }
      if (newOrEditedParcels.length) {
        this.map.setPaintProperty(
          this.editedParcelsLayerId,
          'fill-color',
          this.getParcelsStylingRules(newOrEditedParcels)
        );
      }
    }
    if (this.map.getLayer(this.parcelsLayerId)) {
      this.map.setFilter(this.parcelsLayerId, ['in', ['get', 'id'], ['literal', notEditedParcelIds]], {
        validate: false
      });
      this.map.setFilter(this.parcelsBorderLayerId, ['in', ['get', 'id'], ['literal', notEditedParcelIds]], {
        validate: false
      });
      this.map.setFilter(this.parcelNamesLayerId, ['in', ['get', 'id'], ['literal', notEditedParcelIds]], {
        validate: false
      });
    }
    this.checkAndUpdateOverlaps(newOrEditedParcels);
  }

  @Watch('selectedParcelId')
  private async onSelectedParcelChange(): Promise<void> {
    const parcel = this.parcels.find((parcel: EditableParcel) => parcel.id === this.selectedParcelId);
    if (parcel) {
      if (!parcel.Shape) {
        parcel.Shape = await API.getShape(parcel.ShapeID);
        if (!parcel.Shape.GeoJson.features) {
          parcel.isShapeInValid = true;
        }
      }
      if (!parcel.isEdited) {
        this.$emit('onStartEditingParcel', parcel.id);
      }

      this.draw.deleteAll();
      if (!parcel.isShapeInValid) {
        const featureIds = this.draw.add(
          feature(parcel.Shape.GeoJson.features[0].geometry, { id: parcel.id }, { id: parcel.id })
        );
        this.draw.changeMode('simple_select', { featureIds });
        this.map.fitBounds(bbox(turfBuffer(parcel.Shape.GeoJson.features[0], 1)), {
          duration: 100
        });
      }
    }
  }

  @Watch('isDrawing')
  private onIsDrawingChanged(): void {
    if (this.isDrawing) {
      this.draw.deleteAll();
      this.draw.changeMode('draw_polygon');
    }
  }

  @Watch('featureIdToRemove')
  private onFeatureIdToRemoveChanged(): void {
    if (this.featureIdToRemove) {
      this.draw.deleteAll();
      this.$emit('onDeleted', this.featureIdToRemove);
    }
  }

  private addParcelsLayer(bounds: Array<number>): void {
    this.map.addSource(this.parcelsSourceId, {
      type: 'vector',
      tiles: [
        `${process.env.VUE_APP_VECTOR_TILES_SERVER}/tile/${this.unit.id}/${moment().format(
          Constants.DATE_FORMAT
        )}/{z}/{x}/{y}?v=${this.unit.LastUpdate}&ndvi`
      ],
      bounds
    });

    this.map.addLayer({
      id: this.parcelsLayerId,
      source: this.parcelsSourceId,
      'source-layer': 'geojsonLayer',
      type: 'fill',
      paint: {
        'fill-color': constants.editShapesColors.DEFAULT_PARCEL_COLOR,
        'fill-opacity': 0.6
      }
    });

    this.map.addLayer({
      id: this.parcelsBorderLayerId,
      source: this.parcelsSourceId,
      'source-layer': 'geojsonLayer',
      type: 'line',
      paint: {
        'line-color': '#EDEDED',
        'line-width': 1
      }
    });

    this.map.addLayer({
      id: this.parcelNamesLayerId,
      type: 'symbol',
      source: this.parcelsSourceId,
      'source-layer': 'nameLayer',
      minzoom: 13,
      layout: {
        'text-field': '{title}',
        'text-justify': 'center',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
  }

  private addEditedParcelsLayer(): void {
    this.map.addSource(this.editedParcelsSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      id: this.editedParcelsLayerId,
      type: 'fill',
      source: this.editedParcelsSourceId,
      paint: { 'fill-color': constants.editShapesColors.DEFAULT_PARCEL_COLOR, 'fill-opacity': 0.6 }
    });

    this.map.addLayer({
      id: this.editedParcelsBorderLayerId,
      source: this.editedParcelsSourceId,
      type: 'line',
      paint: {
        'line-color': '#EDEDED',
        'line-width': 1
      }
    });

    this.map.addSource(this.editedParcelNamesSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      id: this.editedParcelNamesLayerId,
      type: 'symbol',
      source: this.editedParcelNamesSourceId,
      layout: {
        'text-field': ['get', 'description'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-justify': 'center',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
  }

  private addOverlapLayer(): void {
    this.map.addSource(this.overlapSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      id: this.overlapLayerId,
      type: 'fill',
      source: this.overlapSourceId,
      paint: { 'fill-color': 'rgba(255, 0, 0, 0.5)', 'fill-outline-color': 'rgb(255, 0, 0)' }
    });
  }

  private onDrawCreate(e): void {
    this.$emit('onCreated', e.features[0]);
  }

  private onDrawUpdate(e): void {
    this.$emit('onUpdated', e.features[0]);
  }

  private async checkAndUpdateOverlaps(changedParcels: EditableParcel[]): Promise<void> {
    const { overlapFeatureIds, overlapFeatures } = await getOverlapData(this.parcels, changedParcels);
    this.$emit('overlapFeatureIds', overlapFeatureIds);

    const source = this.map.getSource(this.overlapSourceId) as GeoJSONSource;
    if (source) {
      source.setData(featureCollection(overlapFeatures as any));
    }
  }
}
