import { render, staticRenderFns } from "./WarningParcelInfo.vue?vue&type=template&id=7bf5ac57&scoped=true&"
import script from "./WarningParcelInfo.vue?vue&type=script&lang=ts&"
export * from "./WarningParcelInfo.vue?vue&type=script&lang=ts&"
import style0 from "./WarningParcelInfo.vue?vue&type=style&index=0&id=7bf5ac57&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bf5ac57",
  null
  
)

export default component.exports