





































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ParcelItem from '@/components/radar/shapes-management/ParcelItem.vue';
import ParcelForm from '@/components/radar/shapes-management/ParcelForm.vue';
import UploadStatus from '@/components/radar/shapes-management/UploadStatus.vue';
import { CropType } from '@/interfaces/cropType';
import { UploadRequest } from '@/interfaces/uploadRequest';
import API from '@/services/api';
import { message } from 'ant-design-vue';
import { EditableParcel } from '@/interfaces/editableParcel';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import UnitForm from '@/components/radar/shapes-management/UnitForm.vue';
import moment from 'moment';
import ExistSurveysConfirm from '@/components/radar/shapes-management/ExistSurveysConfirm.vue';
import Constants from '@/services/constants';
import { Parcel } from '@/interfaces/parcel';
import { stringToMomentDate } from '@/services/date';
import { isAdvancedModeEnabled } from '@/services/user';

@Component({
  components: {
    ParcelItem,
    ParcelForm,
    UploadStatus,
    UnitForm,
    ExistSurveysConfirm
  }
})
export default class EditShapesDialog extends Vue {
  @Prop() parcels: EditableParcel[];
  @Prop() cropTypes: CropType[];
  @Prop() invalidParcelIds: string[];
  @Prop() blockedParcelIds: string[];
  @Prop() selectedParcelId: string;
  @Prop() isDrawing: boolean;
  @Prop() unit: Unit;
  @Prop() farms: Farm[];
  @Prop() selectedFarm: Farm;
  @Prop() isNoUnitsAvailable: boolean;
  editParcel: EditableParcel = null;
  showUploadStatus = false;
  changeCreatedDateConfirmationForParcelIds = [];
  showEditUnit = false;
  showOnlyParcelsWithConflicts = false;

  openFilesSelector(): void {
    const input = this.$refs.input as any;
    input.value = '';
    input.click();
  }

  async handleFilesSelected(event: Event): Promise<void> {
    const files = (event.target as HTMLInputElement).files;
    this.$emit('onFilesChanged', files?.length ? files : null);
  }

  get hasChangedParcels(): boolean {
    return this.parcels.some((parcel: EditableParcel) => parcel.isEdited || parcel.isDeleted || parcel.isNew);
  }

  get hasInvalidOrBlockedParcels(): boolean {
    return this.parcelWithOutDeleted.some(
      (parcel: EditableParcel) => !this.isParcelValid(parcel) || this.isParcelBlocked(parcel)
    );
  }

  get filteredParcelWithOutDeleted(): EditableParcel[] {
    if (this.showOnlyParcelsWithConflicts) {
      return this.parcelWithOutDeleted.filter(
        (parcel: EditableParcel) => !this.isParcelValid(parcel) || this.isParcelBlocked(parcel)
      );
    }
    return this.parcelWithOutDeleted;
  }

  get parcelWithOutDeleted(): EditableParcel[] {
    return this.parcels.filter(({ isDeleted }) => !isDeleted);
  }

  get isAdvancedModeEnabled(): boolean {
    return isAdvancedModeEnabled(this.$store.state.userInfo);
  }

  isParcelValid(parcel: EditableParcel): boolean {
    return !this.invalidParcelIds.includes(parcel.id);
  }

  isParcelBlocked(parcel: EditableParcel): boolean {
    return this.blockedParcelIds.includes(parcel.id);
  }

  onBackClick(): void {
    this.$emit('onClose');
  }

  createNewUnit(): void {
    this.$emit('onCreateNewUnit');
  }

  onAddParcel(): void {
    this.$emit('onCreateNewParcel');
  }

  onDeleteParcel(parcel: EditableParcel): void {
    this.$emit('onDeleteParcel', parcel);
  }

  onSelectParcel(id: string): void {
    this.$emit('onParcelSelected', id);
  }

  onEditParcel(parcel: EditableParcel): void {
    this.editParcel = parcel;
  }

  onParcelUpdate(parcel: EditableParcel): void {
    this.$emit('onParcelUpdate', {
      ...this.editParcel,
      ...parcel,
      isEdited: true
    } as EditableParcel);
    this.editParcel = null;
  }

  onUnitUpdate(updatedUnit: Unit): void {
    this.$emit('onUnitUpdate', updatedUnit);
    this.showEditUnit = false;
  }

  onExistSurveysConfirm({ cancel, changeCreatedDateToToday }): void {
    if (!cancel) {
      if (changeCreatedDateToToday) {
        this.changeCreatedDateConfirmationForParcelIds.forEach((id: string) => {
          let parcel = this.parcels.find((parcel: EditableParcel) => parcel.id === id);
          if (parcel && parcel.isDeleted && parcel.replacedByAnotherParcelId) {
            parcel.replacedDeletionDate = moment.utc().subtract(1, 'day').endOf('day').toISOString();
            this.$emit('onParcelUpdate', parcel);
            parcel = this.parcels.find((p: EditableParcel) => p.id === parcel.replacedByAnotherParcelId);
          }
          if (parcel) {
            parcel.Created = new Date().toISOString();
            parcel.isCreatedModified = true;
            this.$emit('onParcelUpdate', parcel);
          }
        });
      }
      this.upload();
    }
    this.changeCreatedDateConfirmationForParcelIds = [];
  }

  async saveParcels(): Promise<void> {
    const modifiedParcelsToCheck = this.parcels.filter(
      (parcel: EditableParcel) => parcel.isEdited && parcel.isCreatedModified
    );
    const deletedParcelsReplacedToCheck = this.parcels.filter(
      (parcel: EditableParcel) => parcel.isDeleted && parcel.replacedDeletionDate
    );
    if (modifiedParcelsToCheck.length || deletedParcelsReplacedToCheck.length) {
      const modifiedTasks = modifiedParcelsToCheck.map((parcel: EditableParcel) =>
        API.getParcelAnalytics(parcel.id, parcel.Created, moment().format(Constants.DATE_FORMAT))
      );
      const deletedTasks = deletedParcelsReplacedToCheck.map((parcel: EditableParcel) =>
        API.getParcelAnalytics(parcel.id, parcel.replacedDeletionDate, moment().format(Constants.DATE_FORMAT))
      );
      const parcelsWithAnalytics = await Promise.all([...modifiedTasks, ...deletedTasks]);
      const withSurveys = parcelsWithAnalytics.filter((parcel: Parcel) => !!parcel?.Surveys?.length);
      if (withSurveys.length) {
        this.changeCreatedDateConfirmationForParcelIds = withSurveys.map((parcel: Parcel) => parcel.id);
        return;
      }
    }
    await this.upload();
  }

  async upload(): Promise<void> {
    await this.$store.dispatch('setIsGlobalLoaderVisible', true);

    const farmsById = {};
    this.parcels.forEach((parcel: EditableParcel) => {
      const farm = this.farms.find((farm: Farm) => farm.id === parcel.FarmID);
      if (!farmsById[parcel.FarmID]) {
        farmsById[parcel.FarmID] = {
          id: !parcel.FarmID || parcel.FarmID.startsWith('fake_') ? null : parcel.FarmID,
          Name: farm.Name,
          Code: farm.Code,
          Parcels: []
        };
      }
      if (parcel.isDeleted) {
        if (!parcel.isNew) {
          farmsById[parcel.FarmID].Parcels.push({
            id: parcel.id,
            Deleted: parcel.replacedDeletionDate ?? moment.utc().subtract(1, 'day').endOf('day').toISOString()
          });
        }
      } else if (parcel.isNew) {
        farmsById[parcel.FarmID].Parcels.push({
          id: '',
          Name: parcel.Name,
          Created: parcel.Created,
          Planted: parcel.Planted,
          Variety: parcel.Variety,
          CropID: parcel.CropType,
          LastHarvestDate: parcel.LastHarvestDate,
          Shape: parcel.Shape.GeoJson
        });
      } else if (parcel.isEdited) {
        const editedParcel = {
          id: parcel.id,
          Name: parcel.Name,
          Created: parcel.Created,
          Planted: parcel.Planted,
          Variety: parcel.Variety,
          CropID: parcel.CropType,
          LastHarvestDate: parcel.LastHarvestDate,
          Shape: parcel.Shape.GeoJson
        };
        if (parcel.isShapeModified) {
          farmsById[parcel.FarmID].Parcels.push({
            id: parcel.id,
            Deleted: stringToMomentDate(parcel.Created).utc().subtract(1, 'day').endOf('day').toISOString()
          });
          editedParcel.id = '';
        }
        farmsById[parcel.FarmID].Parcels.push(editedParcel);
      }
    });

    const uploadRequest: UploadRequest = {
      CountryID: this.$store.state.selectedCountry.id,
      OrganizationID: this.$store.state.selectedOrganization.id,
      Units: [
        {
          id: this.unit.id,
          Name: this.unit.Name,
          Farms: Object.values(farmsById)
        }
      ]
    };

    try {
      await API.upload(uploadRequest);
      this.showUploadStatus = true;
    } catch {
      message.error(this.$t('failedToUpdate').toString());
    } finally {
      await this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  onFinishUpload(): void {
    this.showUploadStatus = false;
    this.$emit('onFinished');
  }
}
