import Vue from 'vue';
import Vuex from 'vuex';
import { createLogger } from 'vuex';

import { LayerGroupName } from '@/enums/layerGroupName';
import { LayerName } from '@/enums/layerName';
import { Country } from '@/interfaces/country';
import { Farm } from '@/interfaces/farm';
import { FarmHierarchy } from '@/interfaces/farmHierarchy';
import { LayerGroup } from '@/interfaces/layerGroup';
import { Organization } from '@/interfaces/organization';
import { Parcel } from '@/interfaces/parcel';
import { State } from '@/interfaces/state';
import { Unit } from '@/interfaces/unit';
import { UnitHierarchy } from '@/interfaces/unitHierarchy';
import { UserInfo } from '@/interfaces/userInfo';
import constants from '@/services/constants';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { MapCoordinates } from '@/interfaces/mapCoordinates';
import ColorHash from 'color-hash';
import { Role } from '@/enums/role';
import { analytic, analyticSecondary } from '@/store/modules/analytic';
import { ProductGroupName } from '@/enums/productGroupName';
import { SeasonYear } from '@/interfaces/seasonYear';
import { ParcelPixel } from '@/interfaces/parcelPixel';
import { VegetativeIndex } from '@/interfaces/vegetativeIndex';
import { ParcelPixelVegetativeIndex } from '@/interfaces/parcelPixelVegetativeIndex';
import { VegetativeIndexDisplayName } from '@/interfaces/vegetativeIndexDisplayName';
import { VegetativeIndicesAccess } from '@/interfaces/vegetativeIndicesAccess';
import { AnalyticsAccess } from '@/interfaces/analyticsAccess';
import { MinMaxCropMonitoring } from '@/interfaces/minMaxCropMonitoring';
import { AnalyticsListResponse } from '@/interfaces/analyticsListResponse';
import { UserActivity } from '@/interfaces/userActivity';

Vue.use(Vuex);

const getAvailableProductGroupsAndLayers = (
  organization: Organization,
  defaultProductTabs: ProductGroupName[],
  userInfo: UserInfo,
  defaultLayersConfiguration
): [ProductGroupName[], LayerGroup[]] => {
  const organizationProductGroups = organization?.Products ? [...organization?.Products] : [];
  const organizationAdminProductGroups = organization?.AdminProducts ? [...organization?.AdminProducts] : [];

  let availableProductGroups = organization
    ? defaultProductTabs.filter((productGroup: ProductGroupName) => organizationProductGroups.includes(productGroup))
    : [ProductGroupName.SUGAR_CONTENT_PREDICTION];

  const isAdmin = userInfo && userInfo.Roles && userInfo.Roles.includes(Role.ADMIN);
  if (!isAdmin && organizationAdminProductGroups.length) {
    availableProductGroups = availableProductGroups.filter(
      (productGroup: ProductGroupName) => !organizationAdminProductGroups.includes(productGroup)
    );
  }

  const availableLayerGroups = [];
  if (organization) {
    defaultLayersConfiguration.forEach((layerGroup: LayerGroup) => {
      const layers =
        layerGroup.name === LayerGroupName.ANALYTIC
          ? layerGroup.layers
          : layerGroup.layers.filter((layer: LayerName) => organization.Layers.includes(layer));

      if (layerGroup.name === LayerGroupName.FIELD_INFO && !layers.includes(LayerName.BOUNDARIES)) {
        layers.unshift(LayerName.BOUNDARIES);
      }
      if (layers.length) {
        availableLayerGroups.push({
          ...layerGroup,
          layers
        });
      }
    });
  }

  return [availableProductGroups, availableLayerGroups];
};

export default new Vuex.Store<State>({
  plugins:
    process.env.VUE_APP_DEBUG_MODE === 'true'
      ? [
          createLogger({
            actionFilter: (action) => {
              return action.type !== 'setMapCoordinates';
            },
            logMutations: false
          })
        ]
      : [],
  strict: process.env.NODE_ENV !== 'production',
  state: {
    availableProductGroups: [ProductGroupName.FIELD_INFO, ProductGroupName.VEGETATIVE_INDEX],
    availableLayerGroups: [],
    mapParcelFilters: {
      stages: [],
      cycles: [],
      varieties: []
    },
    organizations: [],
    countries: [],
    selectedCountry: null,
    vegetativeDisplayNames: null,
    vegetativeIndicesAccess: null,
    analyticsAccess: null,
    minMaxCropMonitoring: null,
    analyticsListResponse: null,
    userActivity: null,
    selectedSeasonYear: null,
    selectedFarm: null,
    selectedOrganization: null,
    selectedUnit: null,
    selectedUnitHierarchy: null,
    cacheAnalyticData: {},
    cacheAnalyticFullData: {},
    unitSummary: [],
    farmSummary: [],
    preSelectedParcel: null,
    preSelectedSurvey: null,
    parcels: {},
    parcelPixelSummary: [],
    vegetativeIndexSummary: [],
    parcelPixelVegetativeIndex: [],
    units: [],
    farms: [],
    mapCoordinates: null,
    userInfo: null,
    applyFilterGroundMeasurements: false,
    applyParcelPixel: false,
    print: false,
    isGlobalLoaderVisible: false,
    globalLoaderCallsCounter: 0,
    printAllParcels: false,
    currentPage: 0,
    totalPages: 0,
    ndviZonesRange: [0, 1],
    soilErosionHistogramData: {},
    ndviRange: 100,
    isSplitScreenMode: false,
    isShapeEditingMode: false,
    shapeEditingModePreSelectedParcel: null
  },
  mutations: {
    setSelectedCountry: (state: State, country: Country) => {
      state.selectedCountry = country;
    },
    setVegetativeIndexDisplayName: (state: State, names: VegetativeIndexDisplayName) => {
      state.vegetativeDisplayNames = names;
    },
    setVegetativeIndicesAccess: (state: State, names: VegetativeIndicesAccess) => {
      state.vegetativeIndicesAccess = names;
    },
    setAnalyticsAccess: (state: State, names: AnalyticsAccess) => {
      state.analyticsAccess = names;
    },
    setMinMaxCropMonitoring: (state: State, names: MinMaxCropMonitoring) => {
      state.minMaxCropMonitoring = names;
    },
    setAnalyticsListResponse: (state: State, names: AnalyticsListResponse) => {
      state.analyticsListResponse = names;
    },
    setUserActivity: (state: State, names: UserActivity) => {
      state.userActivity = names;
    },
    setSelectedSeasonYear: (state: State, seasonYear: SeasonYear) => {
      state.selectedSeasonYear = seasonYear;
    },
    setSelectedFarm: (state: State, farm: Farm) => {
      state.selectedFarm = farm;
    },
    setSelectedOrganization: (state: State, organization: Organization) => {
      state.selectedOrganization = organization;
    },
    setOrganizations: (state: State, organizations: Organization[]) => {
      state.organizations = organizations;
    },
    setCountries: (state: State, countries: Country[]) => {
      state.countries = countries;
    },
    setAvailableProductsAndGroups: (state: State, { availableProductGroups, availableLayerGroups }) => {
      state.availableProductGroups = availableProductGroups;
      state.availableLayerGroups = availableLayerGroups;
    },
    setSelectedUnit: (state: State, unit: Unit) => {
      state.selectedUnit = unit;
    },
    setMapCoordinates: (state: State, mapCoordinates: MapCoordinates) => {
      state.mapCoordinates = mapCoordinates;
    },
    clearMapParcelFilter: (state: State) => {
      state.mapParcelFilters = { stages: [], cycles: [], varieties: [] };
    },
    updateMapParcelFilter: (state: State, data: { filter: string; value: Array<string | number> }) => {
      state.mapParcelFilters = {
        ...state.mapParcelFilters,
        [data.filter]: data.value
      };
    },
    updateParcel: (state: State, parcel: Parcel) => {
      if (parcel && state.parcels[parcel.id]) {
        state.parcels[parcel.id] = parcel;
      }
    },
    setPreSelectedParcel: (state: State, { parcel, survey }) => {
      state.preSelectedParcel = parcel;
      state.preSelectedSurvey = survey;
    },
    setParcels: (state: State, parcels: Parcel[]) => {
      const parcelsById = {};
      parcels.forEach((parcel: Parcel) => {
        parcelsById[parcel.id] = parcel;
      });
      state.parcels = parcelsById;
    },
    setUnits: (state: State, units: Unit[]) => {
      state.units = units;
    },
    setFarms: (state: State, farms: Farm[]) => {
      state.farms = farms;
    },
    setCacheAnalyticData: (state: State, cacheAnalyticData) => {
      state.cacheAnalyticData = cacheAnalyticData;
    },
    setCacheAnalyticFullData: (state: State, cacheAnalyticFullData) => {
      state.cacheAnalyticFullData = cacheAnalyticFullData;
    },
    setSelectedUnitHierarchy: (state: State, unitHierarchy: UnitHierarchy) => {
      state.selectedUnitHierarchy = unitHierarchy;
    },
    setUnitSummary: (state: State, summary: AnalyticSummaryItem[]) => {
      state.unitSummary = summary;
    },
    setParcelPixelSummary: (state: State, pixelSummary: ParcelPixel[]) => {
      state.parcelPixelSummary = pixelSummary;
    },
    setVegetativeIndexSummary: (state: State, viSummary: VegetativeIndex[]) => {
      state.vegetativeIndexSummary = viSummary;
    },
    setParcelPixelVegetativeIndex: (state: State, parcelPixelVISummary: ParcelPixelVegetativeIndex[]) => {
      state.parcelPixelVegetativeIndex = parcelPixelVISummary;
    },
    setFarmSummary: (state: State, summary: AnalyticSummaryItem[]) => {
      state.farmSummary = summary;
    },
    setUserInfo: (state: State, userInfo: UserInfo) => {
      if (localStorage.getItem('email') === null) {
        localStorage.setItem('email', userInfo ? userInfo.Email : null);
      }
      state.userInfo = userInfo;
    },
    setApplyFilterGroundMeasurements: (state: State, applyFilterGroundMeasurements: boolean) => {
      state.applyFilterGroundMeasurements = applyFilterGroundMeasurements;
    },
    setApplyParcelPixel: (state: State, applyParcelPixel: boolean) => {
      state.applyParcelPixel = applyParcelPixel;
    },
    setPrint: (state: State, { print, printAllParcels }) => {
      state.print = print;
      state.printAllParcels = printAllParcels;
    },
    setPrintPages: (state: State, { currentPage, totalPages }) => {
      state.currentPage = currentPage;
      if (totalPages !== null) state.totalPages = totalPages;
    },
    setNdviZonesRange: (state: State, ndviZonesRange: [number, number]) => {
      state.ndviZonesRange = ndviZonesRange;
    },
    setSoilErosionHistogramData: (state: State, { parcelId, histogramData }) => {
      const soilErosionHistogramData = Object.assign({}, state.soilErosionHistogramData, {
        [parcelId]: histogramData
      });
      state.soilErosionHistogramData = soilErosionHistogramData;
    },
    incrementGlobalLoaderCalls: (state: State) => {
      state.globalLoaderCallsCounter = state.globalLoaderCallsCounter + 1;
      if (state.globalLoaderCallsCounter !== 0) {
        state.isGlobalLoaderVisible = true;
      }
    },
    decrementGlobalLoaderCalls: (state: State) => {
      state.globalLoaderCallsCounter = Math.max(state.globalLoaderCallsCounter - 1, 0);
      if (state.globalLoaderCallsCounter === 0) {
        state.isGlobalLoaderVisible = false;
      }
    },
    setNdviRange: (state: State, range) => {
      state.ndviRange = range;
    },
    setIsSplitScreenMode: (state: State, isSplitScreenMode: boolean) => {
      state.isSplitScreenMode = isSplitScreenMode;
    },
    setIsShapeEditingMode: (state: State, isShapeEditingMode: boolean) => {
      state.isShapeEditingMode = isShapeEditingMode;
      if (!isShapeEditingMode) {
        state.shapeEditingModePreSelectedParcel = null;
      }
    },
    setShapeEditingModePreSelectedParcel: (state: State, shapeEditingModePreSelectedParcel: Parcel) => {
      state.shapeEditingModePreSelectedParcel = shapeEditingModePreSelectedParcel;
    }
  },
  actions: {
    setSelectedCountry: ({ commit }, country: Country) => {
      localStorage.setItem(constants.LOCAL_STORAGE_KEYS.SELECTED_COUNTRY_ID, country ? country.id : null);
      commit('setSelectedCountry', country);
    },
    setVegetativeIndexDisplayName: ({ commit }, names: VegetativeIndexDisplayName) => {
      commit('setVegetativeIndexDisplayName', names);
    },
    setVegetativeIndicesAccess: ({ commit }, names: VegetativeIndicesAccess) => {
      commit('setVegetativeIndicesAccess', names);
    },
    setAnalyticsAccess: ({ commit }, names: AnalyticsAccess) => {
      commit('setAnalyticsAccess', names);
    },
    setMinMaxCropMonitoring: ({ commit }, names: MinMaxCropMonitoring) => {
      commit('setMinMaxCropMonitoring', names);
    },
    setAnalyticsListResponse: ({ commit }, names: AnalyticsListResponse) => {
      commit('setAnalyticsListResponse', names);
    },
    setUserActivity: ({ commit }, names: UserActivity) => {
      commit('setUserActivity', names);
    },
    setSelectedSeasonYear: ({ commit }, seasonYear: SeasonYear) => {
      commit('setSelectedSeasonYear', seasonYear);
    },
    setSelectedFarm: ({ commit }, farm: Farm) => {
      localStorage.setItem(constants.LOCAL_STORAGE_KEYS.SELECTED_FARM_ID, farm ? farm.id : null);
      commit('setSelectedFarm', farm);
    },
    setSelectedOrganization: ({ commit, state, dispatch, getters }, { organization, pageName }) => {
      localStorage.setItem(
        constants.LOCAL_STORAGE_KEYS.SELECTED_ORGANIZATION_ID,
        organization ? organization.id : null
      );
      commit('setSelectedOrganization', organization);

      const [availableProductGroups, availableLayerGroups] = getAvailableProductGroupsAndLayers(
        organization,
        constants.defaultProductTabsRadar,
        state.userInfo,
        constants.defaultLayersConfigurationRadar
      );

      if (!availableProductGroups.includes(ProductGroupName.FIELD_INFO)) {
        availableProductGroups.push(ProductGroupName.FIELD_INFO);
      }

      if (!availableProductGroups.includes(ProductGroupName.VEGETATIVE_INDEX)) {
        availableProductGroups.push(ProductGroupName.VEGETATIVE_INDEX);
      }

      if (!availableProductGroups.includes(getters['analytic/selectedProductGroup'])) {
        const lastProduct = localStorage.getItem(constants.LOCAL_STORAGE_KEYS.SELECTED_PRODUCT) as ProductGroupName;
        const productToSelect = availableProductGroups.includes(lastProduct) ? lastProduct : availableProductGroups[0];
        dispatch('analytic/setProductGroup', { productGroup: productToSelect, pageName });
        dispatch('setIsSplitScreenMode', false);
      }

      commit('setAvailableProductsAndGroups', {
        availableProductGroups,
        availableLayerGroups
      });
    },
    setOrganizations: ({ commit }, organizations: Organization[]) => {
      commit('setOrganizations', organizations);
    },
    setCountries: ({ commit }, countries: Country[]) => {
      commit('setCountries', countries);
    },
    setSelectedUnit: ({ commit }, unit: Unit) => {
      localStorage.setItem(constants.LOCAL_STORAGE_KEYS.SELECTED_UNIT_ID, unit ? unit.id : null);
      commit('setSelectedUnit', unit);
    },
    clearMapParcelFilter: ({ commit }) => {
      commit('clearMapParcelFilter');
    },
    updateMapParcelFilter: ({ commit }, data: { filter: string; value: Array<string | number> }) => {
      commit('updateMapParcelFilter', data);
    },
    updateParcel: ({ commit }, parcel: Parcel) => {
      commit('updateParcel', parcel);
    },
    setPreSelectedParcel: ({ commit }, { parcel, survey }) => {
      commit('setPreSelectedParcel', { parcel, survey });
    },
    setMapCoordinates: ({ commit }, mapCoordinates: MapCoordinates) => {
      commit('setMapCoordinates', mapCoordinates);
    },
    setSelectedUnitHierarchy: ({ commit }, unitHierarchy: UnitHierarchy) => {
      constants.farmsColorMap = [];
      if (unitHierarchy && unitHierarchy.Farms && unitHierarchy.Farms.length) {
        unitHierarchy.Farms.forEach((farm: FarmHierarchy) => {
          constants.farmsColorMap.push({
            id: farm.id,
            name: farm.Name + (farm.Code ? ` (${farm.Code})` : ''),
            color: `#${Math.random().toString(16).substr(2, 6)}`
          });
        });
      }
      commit('setSelectedUnitHierarchy', unitHierarchy);
    },
    setParcels: ({ commit }, parcels: Parcel[]) => {
      constants.varietiesColorMap = {};
      const colorHash = new ColorHash();
      parcels.forEach((parcel: Parcel) => {
        if (parcel.Variety && !constants.varietiesColorMap[parcel.Variety]) {
          constants.varietiesColorMap[parcel.Variety] = colorHash.hex(parcel.Variety);
        }
      });
      commit('setParcels', parcels);
    },
    setUnits: ({ commit }, units: Unit[]) => {
      commit('setUnits', units);
    },
    setFarms: ({ commit }, farms: Farm[]) => {
      commit('setFarms', farms);
    },
    setCacheAnalyticFullData: ({ commit, state }, { key, data }) => {
      const cacheAnalyticFullData = Object.assign({}, state.cacheAnalyticFullData);
      const keys = Object.keys(cacheAnalyticFullData);
      if (keys.length > 9) {
        delete cacheAnalyticFullData[keys[0]];
      }
      cacheAnalyticFullData[key] = data;
      commit('setCacheAnalyticFullData', cacheAnalyticFullData);
    },
    setCacheAnalyticData: ({ commit, state }, { key, data }) => {
      const cacheAnalyticData = Object.assign({}, state.cacheAnalyticData);
      const keys = Object.keys(cacheAnalyticData);
      if (keys.length > 9) {
        delete cacheAnalyticData[keys[0]];
      }
      cacheAnalyticData[key] = data;
      commit('setCacheAnalyticData', cacheAnalyticData);
    },
    setUserInfo: ({ commit }, userInfo: UserInfo) => {
      commit('setUserInfo', userInfo);
    },
    updateUserInfo: ({ dispatch, state }, userInfo: Partial<UserInfo>) => {
      dispatch('setUserInfo', {
        ...state.userInfo,
        ...userInfo
      });
    },
    setUnitSummary: ({ commit }, unitSummary: AnalyticSummaryItem[]) => {
      commit('setUnitSummary', unitSummary);
    },
    setParcelPixelSummary: ({ commit }, pixelSummary: ParcelPixel[]) => {
      commit('setParcelPixelSummary', pixelSummary);
    },
    setVegetativeIndexSummary: ({ commit }, viSummary: VegetativeIndex[]) => {
      commit('setVegetativeIndexSummary', viSummary);
    },
    setParcelPixelVegetativeIndex: ({ commit }, parcelPixelVISummary: ParcelPixelVegetativeIndex[]) => {
      commit('setParcelPixelVegetativeIndex', parcelPixelVISummary);
    },
    setFarmSummary: ({ commit }, farmSummary: AnalyticSummaryItem[]) => {
      commit('setFarmSummary', farmSummary);
    },
    applyFilterGroundMeasurements: ({ commit }, applyFilterGroundMeasurements) => {
      commit('setApplyFilterGroundMeasurements', applyFilterGroundMeasurements);
    },
    applyParcelPixel: ({ commit }, applyParcelPixel) => {
      commit('setApplyParcelPixel', applyParcelPixel);
    },
    setPrint: ({ commit }, { print, printAllParcels }) => {
      commit('setPrint', { print, printAllParcels });
    },
    setPrintPages: ({ commit }, { currentPage, totalPages }) => {
      commit('setPrintPages', { currentPage, totalPages });
    },
    setNdviZonesRange: ({ commit }, ndviZonesRange: [number, number]) => {
      commit('setNdviZonesRange', ndviZonesRange);
    },
    setSoilErosionHistogramData: ({ commit }, { parcelId, histogramData }) => {
      commit('setSoilErosionHistogramData', { parcelId, histogramData });
    },
    setIsGlobalLoaderVisible: ({ commit }, isVisible: boolean) => {
      if (isVisible) {
        commit('incrementGlobalLoaderCalls');
      } else {
        commit('decrementGlobalLoaderCalls');
      }
    },
    setNdviRange: ({ commit }, range: number) => {
      commit('setNdviRange', range);
    },
    setIsSplitScreenMode: ({ commit }, isSplitScreenMode: boolean) => {
      commit('setIsSplitScreenMode', isSplitScreenMode);
    },
    setIsShapeEditingMode: ({ commit }, isShapeEditingMode: boolean) => {
      commit('setIsShapeEditingMode', isShapeEditingMode);
    },
    setShapeEditingModePreSelectedParcel: ({ commit }, shapeEditingModePreSelectedParcel: Parcel) => {
      commit('setShapeEditingModePreSelectedParcel', shapeEditingModePreSelectedParcel);
    }
  },
  modules: {
    analytic,
    analyticSecondary
  },
  getters: {
    filteredParcelIds(state): string[] {
      const parcelIds = Object.keys(state.parcels);

      if (!state.selectedFarm) {
        return parcelIds;
      }
      const selectedFarmId = state.selectedFarm.id;
      const farms = state.selectedUnitHierarchy ? state.selectedUnitHierarchy.Farms : [];
      const farmData = farms.find((farm) => farm.id === selectedFarmId);

      if (!farmData) {
        return parcelIds;
      }

      const relatedParcelsId = farmData.Parcels.map((parcel) => parcel.id);

      return parcelIds.filter((id: string) => relatedParcelsId.includes(id));
    }
  }
});
