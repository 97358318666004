

































import { Component, Vue } from 'vue-property-decorator';
import API from '@/services/api';

@Component
export default class Analytics extends Vue {
  currentTab = 1;
  loading = true;
  iframeUrl =
    this.$store.state.analyticsListResponse &&
    this.$store.state.analyticsListResponse.data &&
    this.$store.state.analyticsListResponse.data.tabs[0].url;

  mounted() {
    if (
      this.$store.state.analytic.selectedProductType !== null &&
      this.$store.state.analytic.selectedProductType !== undefined
    ) {
      this.runActivityLog();
    }
  }

  runActivityLog(): void {
    API.userActivityLog(
      this.$store.state.userInfo.Email,
      this.$store.state.analytic.selectedProductType,
      'Analytics'
    ).then((result) => {
      this.$store.dispatch('setUserActivity', result);
    });
  }

  handleTabClick(tabId: number): void {
    this.currentTab = tabId;
    const selectedTab = this.$store.state.analyticsListResponse.data.tabs.find((tab) => tab.id === tabId);

    if (selectedTab) {
      this.iframeUrl = selectedTab.url;
    } else {
      this.iframeUrl = this.$store.state.analyticsListResponse.data.tabs[0]['url'];
    }
    this.loading = true;
  }

  tabsAvailable() {
    return (
      this.$store.state.analyticsListResponse &&
      this.$store.state.analyticsListResponse.data &&
      this.$store.state.analyticsListResponse.data.tabs &&
      this.$store.state.analyticsListResponse.data.tabs.length
    );
  }

  onIframeLoad(): void {
    this.loading = false;
  }
}
