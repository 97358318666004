import { AnalyticDataType } from '@/enums/AnalyticDataType';
import { AnalyticType } from '@/enums/analyticType';
import { Parcel } from '@/interfaces/parcel';
import moment, { Moment } from 'moment';
import constants from '@/services/constants';
import { ProductType } from '@/enums/productType';
import { LayerName } from '@/enums/layerName';
import { LayerGroupName } from '@/enums/layerGroupName';
import { LayerGroup } from '@/interfaces/layerGroup';
import { stringToDate, stringToMomentDate } from '@/services/date';
import { IVueI18n } from 'vue-i18n';
import { PageName } from '@/enums/pageName';
import { Country } from '@/interfaces/country';
import { Role } from '@/enums/role';
import Constants from '@/services/constants';
import { UserInfo } from '@/interfaces/userInfo';
import { AnalyticData } from '@/interfaces/analyticData';
import { GroundTruthData } from '@/interfaces/groundTruthData';
import { FromTo } from '@/interfaces/fromTo';

export const ndviBucket = {
  '#ff0000': [0, 0.1],
  '#ff6600': [0.1, 0.2],
  '#ffcc00': [0.2, 0.4],
  '#cce800': [0.4, 0.6],
  '#66ba00': [0.6, 0.8],
  '#008c00': [0.8, 1]
};

export const sugarContentPredictionBucket = {
  '#803380': [0, 9],
  '#FF00FF': [9, 10],
  '#A52A2A': [10, 10.5],
  '#EC390C': [10.5, 11],
  '#F1640D': [11, 11.5],
  '#F6960E': [11.5, 12],
  '#FCC910': [12, 12.5],
  '#AFC53C': [12.5, 13],
  '#63C268': [13, 13.5],
  '#408C4E': [13.5, 14],
  '#1D5734': [14, 14.5],
  '#05397E': [14.5, 100]
};

export const sugarContentYieldBucket = {
  '#EC390C': [0, 20],
  '#F1640D': [20, 35],
  '#F6960E': [35, 50],
  '#FCC910': [50, 65],
  '#AFC53C': [65, 80],
  '#408C4E': [80, 95],
  '#1D5734': [95, 110],
  '#05397E': [110, 125],
  '#3D057E': [125, 140],
  '#803380': [140, 1000]
};

export const defaultColor = 'rgba(0, 0, 0, 0)';
export const defaultSelectedParcelColor = 'rgba(242, 255, 0, 1)';

export const defaultSelectedParcelsColor = '#848484';
export const selectedParcelsColor = ['#FF7F00', '#0455A4', '#E73F7A', '#6839AA', '#A71E4E', '#2883DD', '#c9bd57'];
export const eradicatedColor = '#000296';

export function isValueInBucket(value: number, range: [number, number]) {
  return value >= range[0] && value < range[1];
}

export function getColor(value: number, bucket): string {
  const color = Object.keys(bucket).find((key: string) => {
    return isValueInBucket(value, bucket[key]);
  });
  return color || defaultColor;
}

export const unitsPerAnalytic = {
  [AnalyticDataType.STAGE]: ' mon',
  [AnalyticDataType.NUM_CYCLES]: '',
  [AnalyticType.SUGAR_CONTENT_YIELD]: 'ton/ha'
};

export function getUnitMeasure(analyticType: AnalyticType | AnalyticDataType): string {
  return unitsPerAnalytic[analyticType] !== undefined ? unitsPerAnalytic[analyticType] : '%';
}

export function getParcelCycle(parcel: Parcel, date: string): number {
  const year = stringToDate(date).getFullYear();
  const planted = stringToMomentDate(parcel.Planted);
  const plantedYear = planted.year();
  let cycle = year - plantedYear;
  if (planted.isAfter(moment(new Date(plantedYear, 11, 1))) && cycle > 1) {
    cycle -= 1;
  }
  return cycle;
}

export function getParcelCropStage(parcel: Parcel, date: string): number {
  const months = Math.ceil(getParcelCropStageInMonths(parcel, date));
  return months > 12 ? 12 : months;
}

function getParcelCropStageInUnit(
  parcel: Parcel,
  date: string,
  unitOfTime: moment.unitOfTime.Diff,
  precise?: boolean
): number {
  let to = stringToMomentDate(date);
  const now = moment();
  if (to.isAfter(now)) {
    to = now;
  }
  const harvestDate = getCurrentHarvestDate(parcel, date);
  return to.diff(stringToMomentDate(harvestDate), unitOfTime, precise);
}

export function getParcelCropStageInMonths(parcel: Parcel, date: string): number {
  return getParcelCropStageInUnit(parcel, date, 'months', true);
}

export function getParcelCropStageInDays(parcel: Parcel, date: string): number {
  return getParcelCropStageInUnit(parcel, date, 'day');
}

export function getParcelStatus(parcel: Parcel, toDate: string, i18n: IVueI18n, country: Country): string {
  if (country && constants.indiaCountryIds.includes(country.id)) {
    return parcel.Status ? i18n.t(parcel.Status.toLowerCase()).toString() : i18n.t('unknown').toString();
  }
  if (!parcel.Planted) {
    return i18n.t('unknown').toString();
  }
  const date = moment(new Date(stringToDate(toDate).getFullYear() - 2, parcel.LLLat < 0 ? 11 : 5, 1));
  if (stringToMomentDate(parcel.Planted).isSameOrAfter(date)) {
    return i18n.t('fresh').toString();
  }
  return i18n.t('ratoon').toString();
}

export function getParcelCropStageAsString(parcel: Parcel, date: string, i18n: IVueI18n): string {
  const stage = getParcelCropStage(parcel, date);
  if (stage < 0 || isNaN(stage)) {
    return i18n.t('unknown').toString();
  }
  return i18n.tc('countMonths', stage).toString();
}
export function getCurrentHarvestDate(parcel: Parcel, date: string): string {
  const dt = stringToMomentDate(date);
  if (parcel.HarvestDates || parcel.PartialHarvest) {
    const dates = [...(parcel.HarvestDates || []), ...(parcel.PartialHarvest || [])].sort().reverse();
    for (let i = 0; i < dates.length; i++) {
      if (stringToMomentDate(dates[i]).isSameOrBefore(dt)) {
        return dates[i];
      }
    }
  }
  if (stringToMomentDate(parcel.Planted).isBefore(dt)) {
    return parcel.Planted;
  }
  return '';
}
export function getProductTypesForAnalyticAndSurvey(analyticType: AnalyticType): ProductType[] {
  const productsForAnalytic = Object.keys(constants.productAnalyticMap).filter((productType: string) => {
    return constants.productAnalyticMap[productType] === analyticType;
  });
  return productsForAnalytic as ProductType[];
}

export function isAnalyticLayerAllowedForProduct(
  layerGroups: LayerGroup[],
  name: LayerName,
  productType: ProductType
): boolean {
  return (
    layerGroups.find((config) => {
      return (
        config.name === LayerGroupName.ANALYTIC &&
        config.allowedProductTypes.includes(productType) &&
        config.layers.includes(name)
      );
    }) !== undefined
  );
}

export function isAllowToUpdateAnalyticData(currentPage: PageName): boolean {
  return [PageName.DASHBOARD, PageName.RADAR].includes(currentPage);
}

export function isAllowToUpdateFullAnalyticData(currentPage: PageName): boolean {
  return [PageName.DASHBOARD].includes(currentPage);
}

export function isAllowToLoadUnpublishedAnalytics(userInfo: UserInfo, country: Country): boolean {
  return (
    userInfo &&
    userInfo.Roles &&
    country &&
    (userInfo.Roles.includes(Role.ADMIN) || userInfo.Roles.includes(Role.MAHINDRA_ADMIN)) &&
    Constants.indiaCountryIds.includes(country.id)
  );
}

export const analyticMatchFilters = (
  analyticData: Partial<AnalyticData>,
  mapParcelFilters,
  fromTo: FromTo,
  parcels: { [key: string]: Parcel }
) => {
  const parcel = parcels[analyticData.ParcelID];

  const { stages, cycles, varieties }: { [key: string]: Array<string | number> } = mapParcelFilters;
  return (
    (varieties.length === 0 || (parcel?.Variety && varieties.includes(parcel.Variety))) &&
    (stages.length === 0 || (parcel && stages.includes(`${getParcelCropStage(parcel, fromTo.to)}`))) &&
    (cycles.length === 0 ||
      (analyticData.SurveyDate && parcel && cycles.includes(`${getParcelCycle(parcel, analyticData.SurveyDate)}`)))
  );
};

export function isShowingLoaderAnalyticData(currentPage: PageName): boolean {
  return [PageName.DASHBOARD, PageName.TABLE].includes(currentPage);
}

export const parcelMatchFilters = (parcel: Parcel, mapParcelFilters, fromTo: FromTo) => {
  const { stages, cycles, varieties }: { [key: string]: Array<string | number> } = mapParcelFilters;
  return (
    (varieties.length === 0 || (parcel?.Variety && varieties.includes(parcel.Variety))) &&
    (stages.length === 0 || (parcel && stages.includes(getParcelCropStage(parcel, fromTo.to)))) &&
    (cycles.length === 0 || (parcel && cycles.includes(getParcelCycle(parcel, fromTo.to))))
  );
};

export const getGroundTruthFieldsForSurvey = (
  parcel: Parcel,
  date: string,
  value: number,
  groundTruthData: GroundTruthData
) => {
  let sugarRecovery = null;
  let sugarRecoveryError = null;
  let sugarRecoveryErrorPercent = null;
  let sugarRecoveryAccuracy = null;
  let gtDate = null;

  if (parcel && groundTruthData[parcel.Name]) {
    const dates = Object.keys(groundTruthData[parcel.Name])
      .map((date: string) => stringToMomentDate(date))
      .sort((a: moment.Moment, b: moment.Moment) => a.unix() - b.unix());
    let groundTruthDate: Moment = null;
    const surveyDate = stringToMomentDate(date);
    const surveyDatePrediction = surveyDate.clone().add(7, 'days');
    const surveyDatePredictionMinus7 = surveyDatePrediction.clone().subtract(7, 'days');
    const surveyDatePredictionPlus7 = surveyDatePrediction.clone().add(7, 'days');
    dates.forEach((momentDate: moment.Moment) => {
      if (
        momentDate.isSameOrAfter(surveyDatePredictionMinus7) &&
        momentDate.isSameOrBefore(surveyDatePredictionPlus7)
      ) {
        if (!groundTruthDate) {
          groundTruthDate = momentDate;
        } else {
          const groundTruthDiff = Math.abs(surveyDatePrediction.diff(groundTruthDate));
          const momentDateDiff = Math.abs(surveyDatePrediction.diff(momentDate));
          if (momentDateDiff <= groundTruthDiff) {
            groundTruthDate = momentDate;
          }
        }
      }
    });

    if (groundTruthDate) {
      gtDate = groundTruthDate.format(Constants.DATE_FORMAT);
      const values = groundTruthData[parcel.Name][gtDate];
      if (values.length) {
        const data = values[values.length - 1];
        sugarRecovery = data.sugarRecovery;
        sugarRecoveryError = Math.abs(value - sugarRecovery);
        sugarRecoveryErrorPercent = (sugarRecoveryError * 100) / sugarRecovery;
        sugarRecoveryAccuracy = 100 - sugarRecoveryErrorPercent;
      }
    }
  }
  return {
    sugarRecovery,
    sugarRecoveryError,
    sugarRecoveryErrorPercent,
    sugarRecoveryAccuracy,
    gtDate
  };
};

export const getYieldGroundTruthFieldsForSurvey = (
  parcel: Parcel,
  date: string,
  value: number,
  groundTruthData: GroundTruthData
) => {
  let sugarYieldRecovery = null;
  let sugarYieldRecoveryError = null;
  let sugarYieldRecoveryErrorPercent = null;
  let sugarYieldRecoveryAccuracy = null;
  let gtDate = null;
  let predictionDate = null;
  let predictionDateValue = null;
  if (parcel && groundTruthData[parcel.Name]) {
    const dates = Object.values(groundTruthData[parcel.Name]);
    const yieldDate = stringToMomentDate(dates[0].toString());

    let groundTruthDate: Moment = null;
    const surveyDate = stringToMomentDate(date);
    const surveyDatePrediction = surveyDate.clone().add(7, 'days');
    const surveyDatePredictionMinus7 = surveyDatePrediction.clone().subtract(7, 'days');
    const surveyDatePredictionPlus7 = surveyDatePrediction.clone().add(7, 'days');

    if (yieldDate.isSameOrAfter(surveyDatePredictionMinus7) && yieldDate.isSameOrBefore(surveyDatePredictionPlus7)) {
      if (!groundTruthDate) {
        groundTruthDate = yieldDate;
      }
    }

    if (groundTruthDate) {
      gtDate = groundTruthDate.format(Constants.DATE_FORMAT);
      predictionDate = date;
      predictionDateValue = value;
      const values = Object.values(groundTruthData[parcel.Name]);
      if (values.length) {
        sugarYieldRecovery = values[1];
        sugarYieldRecoveryError = Math.abs(value - sugarYieldRecovery);
        sugarYieldRecoveryErrorPercent = (sugarYieldRecoveryError * 100) / sugarYieldRecovery;
        sugarYieldRecoveryAccuracy = 100 - sugarYieldRecoveryErrorPercent;
      }
    }
  }
  return {
    gtDate,
    predictionDate,
    predictionDateValue,
    sugarYieldRecovery,
    sugarYieldRecoveryError,
    sugarYieldRecoveryErrorPercent,
    sugarYieldRecoveryAccuracy
  };
};

export const isAnalyticDataValid = (analyticData: AnalyticData): boolean => {
  return ![
    constants.CLOUDY_ANALYTIC_VALUE,
    constants.NO_CLUSTERING_ANALYTIC_VALUE,
    constants.TOO_YOUNG_ANALYTIC_VALUE
  ].includes(analyticData.Value);
};

export const isSurveyValid = (surveyDate: string, parcel: Parcel): boolean => {
  return stringToMomentDate(surveyDate).isAfter(stringToMomentDate(parcel.Planted));
};

export const transformFilteredAnalyticsToMap = (analytics: AnalyticData[]) => {
  return analytics.reduce((acc, item) => {
    if (isAnalyticDataValid(item)) {
      acc[item.ParcelID] = item;
    }
    return acc;
  }, {});
};
