












































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Feature, featureCollection, FeatureCollection, MultiPolygon, polygon, Polygon } from '@turf/helpers';
import { message } from 'ant-design-vue';
import API from '@/services/api';
import * as shp from 'shpjs';
import JSZip from 'jszip';
import csvtojson from 'csvtojson';
import truncate from '@turf/truncate';
import union from '@turf/union';
import cleanCoords from '@turf/clean-coords';
import kinks from '@turf/kinks';
import simplify from '@turf/simplify';
import { FieldDecoratorOptions, WrappedFormUtils } from 'ant-design-vue/types/form/form';
import moment from 'moment';
import { CropType } from '@/interfaces/cropType';
import { EditableParcel } from '@/interfaces/editableParcel';
import Constants from '@/services/constants';

@Component({
  components: {}
})
export default class EditShapesImportFromFile extends Vue {
  @Prop() files: FileList;
  @Prop() parcels: EditableParcel[];
  @Prop() cropTypes: CropType[];
  private previewPropertiesInitial = {
    cropType: '',
    parcelNameTitle: '',
    creationDate: '',
    plantingDate: '',
    lastHarvestDate: '',
    variety: '',
    status: '',
    cropRotation: '',
    farmName: '',
    code: ''
  };

  shape: FeatureCollection = null;
  fileName: string = null;
  form: WrappedFormUtils;
  disabled = true;
  creationDateMask = Constants.DATE_FORMAT_LOCALIZED;
  plantingDateMask = Constants.DATE_FORMAT_LOCALIZED;
  lastHarvestDateMask = Constants.DATE_FORMAT_LOCALIZED;
  useSpecificFields = false;
  showRawData = false;
  previewProperties = Object.assign({}, this.previewPropertiesInitial);
  private uniqParcelId = 0;

  private testPolygon = polygon([
    [
      [13.0050659, 82.0135588],
      [13.0586242, 82.0122231],
      [13.0421447, 82.0198529],
      [13.0050659, 82.0135588]
    ]
  ]);

  get decorators(): { [key: string]: FieldDecoratorOptions } {
    let cropTypeId = null;
    if (this.parcels.length) {
      cropTypeId = this.parcels[0].CropType;
    }

    return {
      cropType: {
        initialValue: cropTypeId,
        rules: [{ required: true, message: this.$t('cropTypeIsRequired').toString() }]
      },
      parcelName: {
        rules: [{ required: true, message: this.$t('parcelNameIsRequired').toString() }]
      },
      cropRotation: {
        initialValue: null
      },
      farmName: {
        rules: [{ max: 50 }]
      },
      farmCode: {
        rules: [{ max: 50 }]
      },
      variety: {
        rules: [{ max: 50 }]
      },
      plantingDate: {
        rules: [{ required: true, message: this.$t('plantingDateIsRequired').toString() }]
      },
      creationDate: {
        rules: [{ required: true, message: this.$t('creationDateIsRequired').toString() }]
      },
      lastHarvestDate: {
        rules: []
      },
      specificFields: {
        rules: []
      }
    };
  }

  created(): void {
    this.form = this.$form.createForm(this, {
      name: 'import_parcels',
      onFieldsChange: (t, p, fields) => {
        const fieldsArray: { errors?: string[]; touched: boolean }[] = Object.values(fields);
        const errorExist = fieldsArray.some((field) => Array.isArray(field.errors));
        const formTouched = fieldsArray.some((field) => field.touched);
        // dirty property is not updated, relying on touched
        this.disabled = errorExist || !formTouched;

        const cropType = fields.cropType.value
          ? this.cropTypes.find((cropType: CropType) => cropType.id === fields.cropType.value)
          : '';
        this.previewProperties.code = this.properties[fields.farmCode.value] ?? fields.farmCode.value ?? '';
        this.previewProperties.creationDate =
          this.properties[fields.creationDate.value] ?? fields.creationDate.value ?? '';
        this.previewProperties.cropRotation =
          this.properties[fields.cropRotation.value] ?? fields.cropRotation.value ?? '';
        this.previewProperties.cropType = cropType ? this.$t(cropType.Name).toString() : '';
        this.previewProperties.status = this.properties[fields.status.value] ?? fields.status.value ?? '';
        this.previewProperties.farmName = this.properties[fields.farmName.value] ?? fields.farmName.value ?? '';
        this.previewProperties.variety = this.properties[fields.variety.value] ?? fields.variety.value ?? '';
        this.previewProperties.lastHarvestDate =
          this.properties[fields.lastHarvestDate.value] ?? fields.lastHarvestDate.value ?? '';
        this.previewProperties.parcelNameTitle =
          this.properties[fields.parcelName.value] ?? fields.parcelName.value ?? '';
        this.previewProperties.plantingDate =
          this.properties[fields.plantingDate.value] ?? fields.plantingDate.value ?? '';
      }
    } as any);
  }

  get properties(): any {
    if (this.shape && this.shape.features && this.shape.features.length) {
      return { ...this.shape.features[0].properties };
    }
    return {};
  }

  get propertiesKeys(): string[] {
    return Object.keys(this.properties);
  }

  onClose(): void {
    this.fileName = null;
    this.shape = null;
    this.creationDateMask = Constants.DATE_FORMAT_LOCALIZED;
    this.plantingDateMask = Constants.DATE_FORMAT_LOCALIZED;
    this.lastHarvestDateMask = Constants.DATE_FORMAT_LOCALIZED;
    this.useSpecificFields = false;
    this.previewProperties = Object.assign({}, this.previewPropertiesInitial);
  }

  private getFieldDate(properties, property: string, mask: string): string {
    let date = moment(properties[property] ?? property, mask);
    if (!date.isValid()) {
      date = moment().startOf('year');
    }
    return date.toISOString();
  }

  apply(e: Event): void {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err) {
        const features = this.useSpecificFields
          ? this.shape.features.filter((feature: Feature) => {
              return values.specificFields.every(
                (property: string) => feature.properties[property] !== undefined && feature.properties[property] !== ''
              );
            })
          : this.shape.features;
        const parcels = features.map((feature: Feature) => {
          const farmName = feature.properties[values.farmName] ?? values.farmName ?? 'Default Farm';
          const farmCode = feature.properties[values.farmCode] ?? values.farmCode ?? '';
          const parcelId = this.fileName + this.uniqParcelId++;
          const featureProperties = feature.properties ? { ...feature.properties } : {};
          featureProperties.id = parcelId;
          return {
            id: parcelId,
            CropType: values.cropType,
            FarmID: `fake_${farmName}_${farmCode}`,
            Created: this.getFieldDate(feature.properties, values.creationDate, this.creationDateMask),
            Planted: this.getFieldDate(feature.properties, values.plantingDate, this.plantingDateMask),
            LastHarvestDate: values.lastHarvestDate
              ? this.getFieldDate(feature.properties, values.lastHarvestDate, this.lastHarvestDateMask)
              : null,
            Status: feature.properties[values.status] ?? values.status ?? null,
            Variety: feature.properties[values.variety] ?? values.variety ?? null,
            Name: feature.properties[values.parcelName],
            Shape: {
              GeoJson: featureCollection([
                {
                  ...feature,
                  properties: featureProperties
                }
              ])
            },
            isNew: true
          } as EditableParcel;
        });
        this.$emit('onImportParcels', parcels);
        this.onClose();
      }
    });
  }

  getFormatedValue(value): string {
    return value ?? '-';
  }

  @Watch('files')
  private async onFilesChanged(): Promise<void> {
    if (this.files) {
      await this.$store.dispatch('setIsGlobalLoaderVisible', true);
      let shapeName = this.files[0].name;
      let jsonData: FeatureCollection = featureCollection([]);
      try {
        if (this.files.length > 1) {
          for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].name.toLowerCase().endsWith('.shp')) {
              shapeName = this.files[i].name;
              break;
            }
          }
          jsonData = (await this.shpFilesToGeoJson(this.files)) as FeatureCollection;
        } else {
          if (shapeName.toLowerCase().endsWith('.zip')) {
            jsonData = await this.shpZipToGeoJson(await this.readFile(this.files[0], false));
          } else if (shapeName.toLowerCase().endsWith('.csv')) {
            jsonData = await this.csvToJson((await this.readFile(this.files[0], true)) as string);
          } else {
            jsonData = JSON.parse((await this.readFile(this.files[0], true)) as string) as FeatureCollection;
            jsonData = (await this.transform(jsonData)) as FeatureCollection;
          }
        }
      } catch (e) {
        message.error('Something wrong with provided files, check console for details.');
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.fileName = shapeName;
      await this.onJsonDataLoaded(jsonData);
      await this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  private async onJsonDataLoaded(jsonData: FeatureCollection): Promise<void> {
    this.shape = this.fixShape(jsonData);
  }

  private async csvToJson(text: string): Promise<FeatureCollection> {
    //Used noheader, eventhough there is header,
    //as same column heading is used in multiple columns.
    const rows = await csvtojson({ noheader: true }).fromString(text);
    let jsonData = featureCollection([]);
    let firstRow = true;
    rows.forEach((row) => {
      if (firstRow) {
        firstRow = false;
        return true;
      }
      let points = Object.keys(row).length - 10;
      const coords = [];
      for (let i = 0; i < points; i += 2) {
        if (row['field' + (5 + i)].trim().length == 0) break;
        coords.push([parseFloat(row['field' + (5 + i + 1)]), parseFloat(row['field' + (5 + i)])]);
      }
      coords.push([parseFloat(row['field6']), parseFloat(row['field5'])]);

      if (coords.filter((x) => x.filter((y) => y === 0 || isNaN(y)).length > 0).length > 0) {
        return true;
      }
      jsonData.features.push({
        type: 'Feature',
        properties: {
          Parcel: row['field1'],
          Status: row['field2'],
          PlantingDate: row['field3'],
          Variety: row['field' + (5 + points)],
          Circle: row['field' + (8 + points)],
          Village: row['field' + (10 + points)],
          VillageCode: row['field' + (9 + points)]
        },
        geometry: { type: 'Polygon', coordinates: [coords] }
      });
    });
    return jsonData;
  }

  private async transform(json: any): Promise<any> {
    if (
      json.crs &&
      json.crs.properties &&
      json.crs.properties.name &&
      json.crs.properties.name.indexOf(':4326') === -1 &&
      json.crs.properties.name.indexOf('CRS84') === -1
    ) {
      return await API.transform(json);
    }
    return json;
  }

  private validatePrjFile(prjStr: string): boolean {
    if (prjStr.indexOf('D_South_American_1969') !== -1) {
      message.error('South American Datum is not supported, please convert file to WGS84');
      return false;
    }
    return true;
  }

  private async shpFilesToGeoJson(files: FileList) {
    if (files.length !== 3) {
      message.error('All 3 shape file components must be selected.');
      return;
    }
    let shpBuffer, prjStr, dbf: any;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name.toLowerCase();
      if (fileName.endsWith('.shp')) {
        shpBuffer = await this.readFile(files[i], false);
      } else if (fileName.endsWith('.prj')) {
        prjStr = await this.readFile(files[i], true);
      } else if (fileName.endsWith('.dbf')) {
        dbf = await this.readFile(files[i], false);
      }
    }
    if (!shpBuffer || !dbf || !prjStr) {
      message.error('Invalid shape file data');
      return null;
    }
    if (!this.validatePrjFile(prjStr as string)) {
      return null;
    }
    const shapes = shp.combine([shp.parseShp(shpBuffer, prjStr), shp.parseDbf(dbf)]);
    if (!shapes) {
      message.error('Invalid shape file data');
      return null;
    }
    return shapes;
  }

  private async readFile(file: File, isText: boolean) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      if (isText) fileReader.readAsText(file);
      else fileReader.readAsArrayBuffer(file);
    });
  }

  private async shpZipToGeoJson(input: any) {
    try {
      const zip = new JSZip();
      const data = await zip.loadAsync(input);
      let validFile = true;
      let prjFile = null;
      ['shp', 'prj', 'dbf'].map((ext) => {
        const file = Object.keys(data.files).find((key) => key.slice(-3).toLowerCase() === ext);
        if (!file) {
          message.error(ext + ' file must exist');
          validFile = false;
          return null;
        }
        if (ext === 'prj') {
          prjFile = file;
        }
      });
      if (prjFile) {
        const prjStr = await data.files[prjFile].async('string');
        if (!this.validatePrjFile(prjStr)) {
          return null;
        }
      }
      if (validFile) {
        return await shp(input);
      }
    } catch (err) {
      message.error('Invalid shape file. Error: ' + err);
    }
    return null;
  }

  private simplifyPolygon(polygon: Feature<Polygon>): Feature<Polygon> {
    try {
      const tv = truncate(polygon, { precision: 8 });
      const cv = cleanCoords(tv);
      if (this.isValidPolygon(cv)) {
        try {
          if (kinks(cv).features.length === 0) {
            // extra check if polygon is valid
            union(cv, this.testPolygon);
            return simplify(cv, { tolerance: 0.00000001, highQuality: true });
          }
          // this.kinksCounter++;
          throw new Error('kinks');
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err, JSON.stringify(cv));
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err, JSON.stringify(polygon));
    }
    return null;
  }

  private isValidPolygon(polygon: Feature<Polygon>): boolean {
    const rings = polygon.geometry.coordinates;
    if (rings.length === 0) {
      return false;
    }
    for (let i = 0; i < rings.length; i++) {
      if (rings[i].length < 4) {
        return false;
      }
    }
    return true;
  }

  private fixShape(shape: FeatureCollection): FeatureCollection {
    // this.kinksCounter = 0;
    if (shape && shape.features && shape.features.length) {
      const features: Array<Feature<Polygon>> = [];
      shape.features.forEach((feature: Feature) => {
        if (feature.geometry.type === 'Polygon') {
          const simplified = this.simplifyPolygon(feature as Feature<Polygon>);
          if (simplified) {
            features.push(simplified);
          }
        }
        if (feature.geometry.type === 'MultiPolygon') {
          const multiPolygon = feature as Feature<MultiPolygon>;
          multiPolygon.geometry.coordinates.forEach((coords) => {
            try {
              const simplified = this.simplifyPolygon(polygon(coords, { ...feature.properties }));
              if (simplified) {
                features.push(simplified);
              }
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err, JSON.stringify(coords));
            }
          });
        }
      });
      if (features.length) {
        return featureCollection(features);
      }
    }
    return null;
  }
}
