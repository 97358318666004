









import { Component, Vue } from 'vue-property-decorator';
import GlobalLoader from '@/components/GlobalLoader.vue';
import en from 'ant-design-vue/lib/locale-provider/en_US';
import { PageName } from '@/enums/pageName';
import { checkSupported } from '@/services/unsupportedBrowser';
import constants from '@/services/constants';

@Component({
  components: {
    GlobalLoader
  }
})
export default class App extends Vue {
  locales = {
    en: en
  };

  mounted(): void {
    if (!checkSupported() && this.$router.currentRoute.name !== PageName.UNSUPPORTED_BROWSER) {
      this.$router.push({ name: PageName.UNSUPPORTED_BROWSER });
    }
    if (this.$route.query.product) {
      localStorage.setItem(constants.LOCAL_STORAGE_KEYS.SELECTED_PRODUCT, this.$route.query.product as string);
    }
  }

  get loaderTip(): string {
    return this.$store.state.print
      ? this.$t('printProgress', {
          curPage: this.$store.state.currentPage,
          total: this.$store.state.totalPages
        }).toString()
      : null;
  }

  get locale() {
    return this.locales[this.$root.$i18n.locale] || this.locales.en;
  }
}
