
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AnalyticData } from '@/interfaces/analyticData';
import { getParcelCropStage, getParcelStatus, isAnalyticDataValid, isSurveyValid } from '@/services/analyticData';
import AnalyticsPieChart from '@/components/dashboard/charts/AnalyticsPieChart.vue';
import AnalyticsBarChart from '@/components/dashboard/charts/AnalyticsBarChart.vue';
import AnalyticsByMonthBarChart from '@/components/dashboard/charts/AnalyticsByMonthBarChart.vue';
import AnalyticsByGrowthStageBarChart from '@/components/dashboard/charts/AnalyticsByGrowthStageBarChart.vue';
import { DashboardAnalyticData } from '@/interfaces/dashboardAnalyticData';
import AnalyticsLineChartPerDate from '@/components/dashboard/charts/AnalyticsLineChartPerDate.vue';
import AnalyticsLineChartPerGrowthStage from '@/components/dashboard/charts/AnalyticsLineChartPerGrowthStage.vue';
import Constants from '@/services/constants';
import { ProductGroupName } from '@/enums/productGroupName';
import { ProductType } from '@/enums/productType';
import { LayerName } from '@/enums/layerName';
import { FromTo } from '@/interfaces/fromTo';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { AnalyticType } from '@/enums/analyticType';
import moment from 'moment';
import constants from '@/services/constants';
import GlobalLoader from '@/components/GlobalLoader.vue';

@Component({
  components: {
    AnalyticsPieChart,
    AnalyticsBarChart,
    AnalyticsByMonthBarChart,
    AnalyticsByGrowthStageBarChart,
    AnalyticsLineChartPerDate,
    AnalyticsLineChartPerGrowthStage,
    GlobalLoader
  }
})
export default class SatDashboard extends Vue {
  productGroupName = ProductGroupName;
  productType = ProductType;
  layerName = LayerName;
  analyticType = AnalyticType;

  selectedVarieties: string[] = [];
  selectedStatuses: string[] = [];
  selectedCropSeasons: string[] = [];
  selectedStages: number[] = [];

  private cachedAnalytics: { [key: string]: AnalyticData[] } = {};

  @Prop() bucket;
  @Prop() title;
  @Prop() titlePieChart;
  @Prop() titleBarChart;
  @Prop() titleByDateChart;
  @Prop() titleByStageChart;
  @Prop() yAxesMax: number;

  get selectedOption(): string {
    return this.$store.state.analytic.selectedProductType;
  }

  get timelineFromTo(): FromTo {
    return this.$store.state.analytic.timelineFromTo;
  }

  get selectedProductType(): ProductType {
    return this.$store.state.analytic.selectedProductType;
  }

  get selectedUnit(): Unit {
    return this.$store.state.selectedUnit;
  }

  get selectedFarm(): Farm {
    return this.$store.state.selectedFarm;
  }

  get chartPerDateAnalytics(): DashboardAnalyticData[] {
    return this.dashboardFullAnalyticData;
  }

  zoomTipFormatter(value: number, onlyMonth = false): string {
    return moment.unix(value / 1000).format(onlyMonth ? 'MMM YYYY' : constants.DATE_FORMAT_LOCALIZED);
  }

  get statuses() {
    const list = [];
    this.$store.state.analytic.filteredFullAnalyticData.forEach((analytic: AnalyticData) => {
      if (isAnalyticDataValid(analytic)) {
        const parcel = this.$store.state.parcels[analytic.ParcelID];
        if (parcel) {
          list.push(
            getParcelStatus(
              parcel,
              this.$store.state.analytic.fromTo.to,
              this.$root.$i18n,
              this.$store.state.selectedCountry
            )
          );
        }
      }
    });
    return [...new Set(list)].sort();
  }

  get stages() {
    const list = [];
    this.$store.state.analytic.filteredFullAnalyticData.forEach((analytic: AnalyticData) => {
      if (isAnalyticDataValid(analytic)) {
        const parcel = this.$store.state.parcels[analytic.ParcelID];
        if (parcel) {
          list.push(getParcelCropStage(parcel, this.$store.state.analytic.fromTo.to));
        }
      }
    });
    return [...new Set(list)].sort((a, b) => (a < b ? -1 : 1));
  }

  get varieties() {
    const list = [];
    this.$store.state.analytic.filteredFullAnalyticData.forEach((analytic: AnalyticData) => {
      if (isAnalyticDataValid(analytic)) {
        const parcel = this.$store.state.parcels[analytic.ParcelID];
        if (parcel) {
          list.push(parcel.Variety);
        }
      }
    });
    return [...new Set(list)].sort();
  }

  get cropSeasons() {
    const list = [];
    this.$store.state.analytic.filteredFullAnalyticData.forEach((analytic: AnalyticData) => {
      if (isAnalyticDataValid(analytic)) {
        const parcel = this.$store.state.parcels[analytic.ParcelID];
        if (parcel) {
          list.push(parcel.CropSeason);
        }
      }
    });
    return [...new Set(list)].sort();
  }

  private getDashboardAnalyticData(analyticData: AnalyticData[]): DashboardAnalyticData[] {
    return analyticData.map((analyticData: AnalyticData) => {
      const parcel = this.$store.state.parcels[analyticData.ParcelID];
      let area = 0;
      if (parcel && analyticData.Value) {
        area = parcel.Area * (analyticData.Value / 100);
      }
      return {
        ...analyticData,
        value1: area,
        value2: parcel ? parcel.Area : 0
      };
    });
  }

  get dashboardAnalyticData(): DashboardAnalyticData[] {
    return this.getDashboardAnalyticData(this.analyticDataFiltered);
  }

  get dashboardFullAnalyticData(): DashboardAnalyticData[] {
    return this.getDashboardAnalyticData(this.analyticFullDataFiltered);
  }

  get analyticDataFiltered(): AnalyticData[] {
    return this.$store.state.analytic.filteredAnalyticData.filter((analyticData: AnalyticData) =>
      this.isApplicableAnalytic(analyticData)
    );
  }

  get analyticFullDataFiltered(): AnalyticData[] {
    return this.$store.state.analytic.filteredFullAnalyticData.filter((analyticData: AnalyticData) =>
      this.isApplicableAnalytic(analyticData)
    );
  }

  private isApplicableAnalytic(analyticData: AnalyticData): boolean {
    if (!isAnalyticDataValid(analyticData)) {
      return false;
    }
    const parcel = this.$store.state.parcels[analyticData.ParcelID];
    if (parcel) {
      if (this.selectedVarieties.length && !this.selectedVarieties.includes(parcel.Variety)) {
        return false;
      }
      if (this.selectedCropSeasons.length && !this.selectedCropSeasons.includes(parcel.CropSeason)) {
        return false;
      }
      if (
        this.selectedStatuses.length &&
        !this.selectedStatuses.includes(
          getParcelStatus(
            parcel,
            this.$store.state.analytic.fromTo.to,
            this.$root.$i18n,
            this.$store.state.selectedCountry
          )
        )
      ) {
        return false;
      }
      if (
        !this.isStageBarChartHidden &&
        this.selectedStages.length &&
        !this.selectedStages.includes(getParcelCropStage(parcel, this.$store.state.analytic.fromTo.to))
      ) {
        return false;
      }
    }
    return true;
  }

  get isStageBarChartHidden(): boolean {
    return (
      this.$store.state.selectedCountry && Constants.indiaCountryIds.includes(this.$store.state.selectedCountry.id)
    );
  }
}
